import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { VenueService } from '../../venue/components/venue.http.service';
import { BadgeModule } from '../badge.module';
import { BadgeService } from './badge.http.service';

@Component({
  selector: 'badge-component',
  templateUrl: 'badge.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [UtilitiesService, BadgeService, VenueService],
})
export class BadgeComponent implements OnInit {
  currentUser: User;

  public badgeList: BadgeModule[] = [];
  public badge: BadgeModule = new BadgeModule();

  public venues: VenueModule[] = [];

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  isReqComplete = false;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;

  listviewtypes: any[];
  viewType: any = null;
  public pageState: StateMaintain = {
    pageName: 'badgeForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };
  isSysAdmin = false;
  venueBind: number;

  constructor(
    private badgeService: BadgeService,
    private utilitiesService: UtilitiesService,
    private venueService: VenueService,
    private loaderService: LoaderService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.badge.Venue = new VenueModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    this.venueBind = null;
    this.venueBind = this.authState.getStoredContextVenueId();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllBadgeList(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllBadgeList(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.searchString = '';
    this.badgeList = null;
    this.count = 0;
    this.badge = new BadgeModule();
    this.badge.Venue = new VenueModule();

    this.getAllVenues('', -1, -1);
    setTimeout(() => {
      const element = document.getElementById('badgeNumber');
      element.focus();
    }, 1000);
  }

  public setUpdateForm() {
    this.badgeList = null;
    this.count = 0;
    this.searchString = '';
    this.getAllVenues('', -1, -1);
  }

  public hasData(): boolean {
    return this.badgeList !== null && this.badgeList.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBadgeList(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public BadgePageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBadgeList(this.searchString, this.page, this.size, this.viewType);
  }

  public clearFormData(BadgeForm: NgForm) {
    this.searchString = '';
    this.venues = [];
    BadgeForm.reset();
    this.badge = new BadgeModule();
    this.badge.Venue = new VenueModule();

    this.getAllBadgeList(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllBadgeList(this.searchString, this.page, this.size, this.viewType);
  }

  public badgeSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBadgeList(searchString, 1, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllBadgeList(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBadgeList('', this.page, this.size, this.viewType);
  }

  public getAllBadgeList(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.badgeService.GetAll(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.badgeList = data;
        if (this.badgeList.length < 0 && this.badgeList[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log('badgeService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
        this.loaderService.display(false);
      },
      () => (this.count = this.badgeList[0] ? this.badgeList[0].Count : 0),
    );
  }

  public getBadgeById(BadgeId: number) {
    if (this.authState.canAccess('BADGE_VIEW')) {
      this.loaderService.display(true);
      const CPbadge = new BadgeModule();
      CPbadge.BadgeCPId = this.authState.AESEncryptText(BadgeId);

      this.badgeService.GetSingle(CPbadge).subscribe(
        (data) => {
          this.badge = data;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'gatewayService getGatewayById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Badge', 'You do not have permission to view badge details.');
    }
  }

  public addBadge(BadgeForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (BadgeForm.status == 'INVALID' && BadgeForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Badge', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.badgeService.Add(this.utilitiesService.stripScript(this.badge)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Badge', data.Message);
        this.clearFormData(BadgeForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('badgeService Add Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public updateBadge(BadgeForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (BadgeForm.status == 'INVALID' && BadgeForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Badge', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.badge.IsActive = this.badge.IsActive ? 1 : 0;
    this.badgeService.Update(this.utilitiesService.stripScript(this.badge)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Badge', data.Message);
        this.clearFormData(BadgeForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('badgeService Update Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;

        if (!this.badgeList && !this.badge.BadgeId) {
          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj[0]) {
            this.badge.Venue.VenueId = obj[0].VenueId;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService GetAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }
}
