<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="countries">COUNTRIES</span>
        <span class="list-heading" *ngIf="!countries && !country.CountryId">ADD COUNTRY</span>
        <span class="list-heading" *ngIf="!countries && country.CountryId">EDIT COUNTRY</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12" *ngIf="countries">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading">
          <ngx-atlas-select
            (model)="CountryPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section">
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Country
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="countriesSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="countries">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive">
          <table class="table table-hover table-responsive" *ngIf="hasData()">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('CountryName', columnSortOrder)">
                  Country
                  <span *ngIf="selectedColumnName == 'CountryName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'CountryName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let item of countries
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getCountryById(item.CountryId)">
                <td>{{ item.CountryName }}</td>
                <td *ngIf="item.IsActive" style="width: 7%">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!item.IsActive" style="width: 7%">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!countries">
    <form #countryForm="ngForm" class="form-horizontal">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div>
          <div class="col-md-12 col-xs-12 form-group">
            <!--<div class="form-group col-lg-12 col-md-12 col-sm-12">-->
            <label for="CountryName"><span id="red-circle">&#x25CF;</span> Country Name</label>
            <div>
              <input
                id="CountryName"
                type="text"
                name="CountryName"
                spellcheck="false"
                maxlength="50"
                [(ngModel)]="country.CountryName"
                placeholder="Country Name"
                class="form-control"
                required
                #CountryName="ngModel"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div *ngIf="CountryName.errors && (CountryName.dirty || CountryName.touched)" class="alert alert-danger">
                <div [hidden]="!CountryName.errors.required" class="text-danger">Country name is required.</div>
                <div [hidden]="!CountryName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
            <!--</div>-->
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group" *ngIf="country.CountryId">
              <div>
                <mat-checkbox [(ngModel)]="country.IsActive" name="isActive">Is Active</mat-checkbox>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type pull-right btn-cancel"
              (click)="clearFormData(countryForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!country.CountryId"
              class="btn btn-type btn-save"
              [disabled]="!countryForm.form.valid || isReqComplete"
              (click)="addCountry(countryForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="country.CountryId"
              class="btn btn-type btn-save"
              [disabled]="!countryForm.form.valid || isReqComplete"
              (click)="updateCountry(countryForm)"
            />
          </div>
          <!--</div>-->
        </div>
      </div>
    </form>
  </div>
</section>
