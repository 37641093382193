import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { CountryModule } from '../country/country.module';
import { StateComponent } from './components/state.component';
import { StateRoutes } from './state.routes';

@NgModule({
  imports: [CommonModule, StateRoutes, FormsModule, NgxPaginationModule, HomeModule],

  declarations: [StateComponent],
})
export class StateModule extends GenericEntity {
  public StateId: number;
  public StateCPId: string;
  public StateName: string;
  public Country: CountryModule;
}
