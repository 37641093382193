import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeModule } from '../../home/home.module';
import { BeaconModule } from '../../staffscheduling/beacon/beacon.module';
import { LocationModule } from '../../staffscheduling/location/location.module';
import { User } from '../../usermanagement/user/user.model';
import { WorkorderModule, WorkorderStatus } from '../workorder/workorder.module';
import { WorkorderQCImagesModule } from '../workorder/workorderdetails.module';
import { QCWorkOrderComponent } from './components/qcworkorder.component';
import { QCWOrkorderRoutes } from './qcworkorder.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    QCWOrkorderRoutes,
    HomeModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
    SharedModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [QCWorkOrderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QCWorkOrderModule {}

export class WorkorderTaskComment extends GenericEntity {
  public WorkorderTaskCommentId: number;
  // public workorderId: number;
  public Comment: string;
  public QcComment: string;
  public TaskDetails: WorkorderTask[];
  public TaskDetail: WorkorderTask;
  public WorkorderTransaction: WorkorderInspectionLite;
  public WorkorderQCImages: WorkorderQCImages[];
  public WorkorderInspections: WorkorderInspection[];
}

export class WorkorderTask extends GenericEntity {
  public WorkorderTaskId: number;
  // public workorderId: number;
  public WorkorderTaskCommentId: number;
  public QcInput: string;
  public Task: Tasks;
}

export class WorkorderTransaction extends GenericEntity {
  public WorkorderTransactionId: number;
  // public workorderId: number;
  public AssignedTo: number;
  public Comments: string;
  public PriorityId: number;
  public SeverityId: number;
  public StatusId: number;
  public QcAssignedTo: number;
  public UpdatedBy: number;
  public MinuteElasped: number;
  public GenerateNewWorkOrder: number;
}

export class WorkorderQCImages extends GenericEntity {
  public WorkorderQCImageId: number;
  public WorkorderId: number;
  public ImageName: string;
  public ImageType: string;
}

export class WorkorderInspection extends GenericEntity {
  public WorkorderInspectionId: number;
  public InspectionTask: string;
  public Sequence: number;
  // public workorderId: number;
  public QcInput: string;
  public WorkorderTaskComment: WorkorderTaskComment;
  public WorkorderInspections: WorkorderInspection[];
  public WorkorderTransaction: WorkorderTransaction;
}

export class Tasks extends GenericEntity {
  public TaskId: number;
  public TaskName: string;
  public Sequence: number;
  public ImageName: string;
  public ImageType: string;
  public QcInput: string;
}

export class WorkorderInspectionLite extends GenericEntity {
  public WorkorderInspectionId: number;
  public InspectionTask: string;
  public Sequence: number;
  public QcInput: string;
}

/////////// ----:QC:---- ///////////////

export class QCMaster extends GenericEntity {
  public QcId: number;
  public QcCPId: string;
  public Title: string;
  public Description: string;
  public Location: LocationModule;
  public LocationId: number;
  public Latitude: string;
  public Longitude: string;
  public Comments: string;
  public Level: number;
  public ScheduleStartDate: any;
  public VenueId: number;
  public QcTransactionId: number;
  public WorkorderId: number;
  public PreQCFeedback: string;
  public PreCleaningFeedback: string;
  public QCTransaction: QCTransaction;
  public QCTaskComment: QCTaskComment;
  public QCTaskFeedbacks: QCTaskFeedback[];
  public QCTaskFeedback: QCTaskFeedback[];
  public QCInventoryRefills: QCInventoryRefill[];
  public AssignedTo: User;
  public QCAssignedTo: User;
  public ParentWorkorderId: number;
  public QCImages: WorkorderQCImagesModule[];
  public QCComplitionDate: any;
  public QCWorkorderDetails: WorkorderModule;
  public QCBeacons: QCBeacon[];
  public QCstatus: WorkorderStatus[];
}

export class QCTransaction extends GenericEntity {
  public QcTransactionId: number;
  public QcId: number;
  public WorkorderId: number;
  public Comments: string;
  public StatusId: number;
  public Status: string;
  public AssignedTo: number;
  public GenerateNewWorkorder: number;
}

export class QCTaskComment extends GenericEntity {
  public QcTaskCommentId: number;
  public QcId: number;
  public QcComment: string;
}

export class QCTaskFeedback extends GenericEntity {
  public QcTaskId: number;
  public QcId: number;
  public TaskId: number;
  public TaskName: string;
  public QcTaskCommentId: number;
  public QcInput: string;
  public QcComment: string;

  public ImageName: string;
  public ImageType: string;
}

export class QCInventoryRefill extends GenericEntity {
  public QcInventoryRefillId: number;
  public QcId: number;
  public InventoryId: number;
  public InventoryName: string;
  public Quantity: number;

  public ImageName: string;
  public ImageType: string;
}

export class QCBeacon extends GenericEntity {
  public QcId: number;
  public QcBeaconId: number;
  // public QcMaster: QCMaster;
  public Beacon: BeaconModule;
  public OptIn: any;
  public OptOut: any;
  public BatteryLevel: number;
  public BatteryVoltage: number;
  public timeElapsed: string;
}
