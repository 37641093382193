import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { WorkorderCreatorAttachments } from '../../staffscheduling/location/location.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule],

  declarations: [],
})
export class WorkorderDetailsModule {
  public CleaningAlertNumber: number;
  public Name: string;
  public VenueName: string;
  public BuildingName: string;
  public RestRoomName: string;
  public CleaningAlertCreateTime: any;
  public AssignedToName: string;
  public Beacons: WorkorderDetailsBeaconModule[];
  public Badges: WorkorderDetailsBeaconModule[];
  public Inventory: WorkorderDetailsInventoryModule[];
  public Task: WorkorderDetailsTaskCommentModule;
  public Status: WorkorderDetailsStatusModule[];
  public WorkorderQCImages: WorkorderQCImagesModule[];
  public IsBadgeEnable: number;
  public CleaningAlertCompletionDate: any;
  public InitialSurvey: string;
  public CreatorComments: string;
  public WorkorderCreatorAttachments: WorkorderCreatorAttachments[];
}

export class WorkorderDetailsStatusModule {
  public StatusId: number;
  public Status: string;
  public Time: any;
  public UserName: string;
}

export class WorkorderDetailsBeaconModule {
  public Name: number;
  public Optin: any;
  public Optout: any;
  public BatteryLevel: any;
  public TimeElapsed: string;
  public Tag: string;
}

export class CleaningAlertDetailsBadge {
  public Name: number;
  public Optin: any;
  public Optout: any;
  public BatteryLevel: any;
  public TimeElapsed: string;
  public Tag: string;
}

export class WorkorderDetailsTaskCommentModule {
  public WorkorderTaskCommentId: number;
  public TaskDetails: WorkorderDetailsTaskModule[];
  public Comment: string;
  public QcComment: string;
}

export class WorkorderDetailsTaskModule {
  public ItemName: number;
  public IsActive: any;
}

export class WorkorderDetailsInventoryModule {
  public ItemName: number;
  public Status: string;
}

export class WorkorderQCImagesModule {
  public WorkorderId: number;
  public WorkorderQCImageId: number;
  public ImageName: string;
  public ImageType: string;
}
