import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';

import { HomeModule } from '../home/home.module';
import { ImportDataComponent } from './components/importdata.component';
import { ImportDataRoutes } from './importdata.routes';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, ImportDataRoutes, HomeModule, SharedModule],
  declarations: [ImportDataComponent],
})
export class ImportDataModule extends GenericEntity {
  public Id: number;
  public ImportTransactionId: number;
  public Identifier: string;
  public ImportFileName: string;
  public Message: string;
  public ImportTransactionFiles: ImportTransactionFileModule[] = [];
  public LogFiles: ImportTransactionFileModule[] = [];
}

export class ImportTransactionFileModule {
  public SerialNumber: number;
  public fileName: string;
}

export enum ImportTypes {
  USER = 'USER',
  LOCATION = 'LOCATION',
  LOCATIONTEMPLATE = 'LOCATIONTEMPLATE',
}
