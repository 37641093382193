<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-user heading-icon"></i>
        <span class="list-heading" *ngIf="userRoles">USER ROLES</span>
        <span class="list-heading" *ngIf="!userRoles && !userRole.UserRoleId">ADD USER ROLE</span>
        <span class="list-heading" *ngIf="!userRoles && userRole.UserRoleId">EDIT USER ROLE</span>
      </div>

      <div class="col-lg-9 col-sm-12 col-md-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="userRoles">
          <ngx-atlas-select
            (model)="RolePageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="userRoles && !userRole.UserRoleId && ('ROLE_ADD' | canAccess)"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add User Role
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="userRoles">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="userRoles">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="userRoleSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div
          class="col-lg-4 col-md-6 col-sm-6 col-xs-6 pull-right"
          *ngIf="!userRoles && ('USER_ROLE_PERMISSION_TEMPLATE_VIEW' | canAccess)"
        >
          <ngx-atlas-select
            (model)="changeUserRoleTemplate($event)"
            [list]="userRoleTemplates"
            [idField]="'UserRoleTemplateId'"
            [textField]="'RoleTemplateName'"
            [placeholder]="'Select User Role Template'"
            [allowClear]="true"
            [multiple]="false"
            [required]="false"
            [selectedValue]="userRoleTemplateId"
          >
          </ngx-atlas-select>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="userRoles">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive">
          <table class="table table-hover table-responsive" *ngIf="hasData()">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('RoleName', columnSortOrder)">
                  User Role
                  <span *ngIf="selectedColumnName == 'RoleName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'RoleName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('ServiceProviderName', columnSortOrder)">
                  Service Provider
                  <span *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="let role of userRoles | paginate: { itemsPerPage: size, currentPage: page, totalItems: count }"
            >
              <tr (click)="getUserRoleById(role.UserRoleId, role.ServiceProviderId)">
                <td>{{ role.RoleName }}</td>
                <td>{{ role.ServiceProviderName }}</td>
                <td *ngIf="role.IsActive" style="width: 7%">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!role.IsActive" style="width: 7%">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!userRoles">
    <form #userRoleForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="RoleName"><span id="red-circle">&#x25CF;</span> Role Name</label>
            <div>
              <input
                id="Rolename"
                type="text"
                placeholder="Role Name"
                name="Rolename"
                class="form-control"
                [pattern]="utilitiesService.alphanumericRegex"
                [(ngModel)]="userRole.RoleName"
                required
                minlength="4"
                maxlength="250"
                #RoleName="ngModel"
                autocomplete="off"
              />
              <div *ngIf="RoleName.errors && (RoleName.dirty || RoleName.touched)" class="alert alert-danger">
                <div [hidden]="!RoleName.errors.required" class="text-danger">Role name is required.</div>
                <div [hidden]="!RoleName.errors.minlength" class="text-danger">
                  Role name should have 4 characters long.
                </div>
                <div [hidden]="!RoleName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="ServiceProvider"
              ><span id="red-circle" *ngIf="!isSysAdmin">&#x25CF;</span> Service Provider</label
            >
            <div>
              <ngx-atlas-select
                (model)="userRole.ServiceProviderId = $event"
                [list]="serviceProviders"
                [idField]="'ServiceProviderId'"
                [textField]="'ServiceProviderName'"
                [placeholder]="'Select Service Provider'"
                [allowClear]="true"
                [multiple]="false"
                [disabled]="isSysAdmin"
                [required]="!isSysAdmin"
                [selectedValue]="userRole.ServiceProviderId"
              >
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group" *ngIf="userRole.UserRoleId">
              <mat-checkbox [(ngModel)]="userRole.IsActive" name="isActive">Is Active</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 permissionscroll form-group">
          <div class="d-inline-block">
            <div *ngIf="isCheckBoxVisible" style="margin-left: -15px">
              <mat-checkbox
                class="form-check-input"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="isAllFeatureConfigurationChecked"
                (change)="onAllCheckedChange($event)"
                >All</mat-checkbox
              >
            </div>
            <ngx-treeview
              #treeviewComponent
              [items]="items"
              [config]="config"
              [itemTemplate]="itemTemplate"
              (selectedChange)="onSelectedChange($event)"
            >
            </ngx-treeview>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type pull-right btn-cancel"
            (click)="clearFormData(userRoleForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!userRole.UserRoleId"
            class="btn btn-type btn-save"
            [disabled]="!userRoleForm.form.valid || isReqComplete || !userRole.ServiceProviderId"
            (click)="addUserRole(userRoleForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="userRole.UserRoleId"
            class="btn btn-type btn-save"
            [disabled]="
              !userRoleForm.form.valid || isReqComplete || !userRole.ServiceProviderId || !('ROLE_EDIT' | canAccess)
            "
            (click)="updateUserRole(userRoleForm)"
          />
          <input
            type="button"
            value="Create User Role Template From This Role"
            *ngIf="'USER_ROLE_PERMISSION_TEMPLATE_ADD' | canAccess"
            class="btn btn-type btn-save save-and-new"
            [disabled]="!userRole.ServiceProviderId"
            (click)="openRoleTemplateModalPopup()"
          />
        </div>
      </div>
    </form>
  </div>
</section>

<div class="modal" id="templateModal" [ngStyle]="templateModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">User Role Template</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeRoleTemplateModal()"
          style="margin-top: -35px"
        >
          &times;
        </button>
      </div>
      <div class="modal-body" #scrolltop id="scrolltop">
        <form #userRoleTemplateForm="ngForm" class="form-horizontal">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 form-section">
              <div class="col-md-6 col-xs-12 form-group">
                <label for="RoleTemplateName"><span id="red-circle">&#x25CF;</span> User Role Template Name</label>
                <div>
                  <input
                    id="RoleTemplateName"
                    type="text"
                    placeholder="User Role Template Name"
                    name="RoleTemplateName"
                    class="form-control"
                    [pattern]="utilitiesService.alphanumericRegex"
                    [(ngModel)]="userRoleTemplate.RoleTemplateName"
                    required
                    minlength="4"
                    maxlength="250"
                    #RoleTemplateName="ngModel"
                    autocomplete="off"
                  />
                  <div
                    *ngIf="RoleTemplateName.errors && (RoleTemplateName.dirty || RoleTemplateName.touched)"
                    class="alert alert-danger"
                  >
                    <div [hidden]="!RoleTemplateName.errors.required" class="text-danger">
                      User role template name is required.
                    </div>
                    <div [hidden]="!RoleTemplateName.errors.minlength" class="text-danger">
                      User role template name should have 4 characters long.
                    </div>
                    <div [hidden]="!RoleTemplateName.errors.pattern" class="text-danger">
                      {{ utilitiesService.alphanumericRegexMessage }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-xs-12 form-group">
                <label for="ServiceProvider"
                  ><span id="red-circle" *ngIf="!isSysAdmin">&#x25CF;</span> Service Provider</label
                >
                <div>
                  <ngx-atlas-select
                    (model)="userRoleTemplate.ServiceProviderId = $event"
                    [list]="serviceProviders"
                    [idField]="'ServiceProviderId'"
                    [textField]="'ServiceProviderName'"
                    [placeholder]="'Select Service Provider'"
                    [allowClear]="true"
                    [multiple]="false"
                    [disabled]="isSysAdmin"
                    [required]="!isSysAdmin"
                    [selectedValue]="userRoleTemplate.ServiceProviderId"
                  >
                  </ngx-atlas-select>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 permissionscroll form-group">
              <div class="col-lg-12 col-md-12 col-sm-12 d-inline-block">
                <div *ngIf="isCheckBoxVisibleTemplate" style="margin-left: -15px">
                  <mat-checkbox
                    class="form-check-input"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="isAllFeatureConfigurationCheckedTemplate"
                    (change)="onAllCheckedChangeTemplate($event)"
                    >All</mat-checkbox
                  >
                </div>
                <ngx-treeview
                  #treeviewComponentTemplate
                  [items]="itemsTemplate"
                  [config]="config"
                  [itemTemplate]="itemTemplate"
                  (selectedChange)="onSelectedChangeTemplate($event)"
                >
                </ngx-treeview>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
              <input
                type="button"
                value="Cancel"
                class="btn btn-type pull-right btn-cancel"
                (click)="closeRoleTemplateModal()"
              />
              <input
                type="button"
                value="Save"
                *ngIf="!userRoleTemplate.UserRoleTemplateId"
                class="btn btn-type btn-save"
                [disabled]="!userRoleTemplateForm.form.valid || isReqComplete || !userRoleTemplate.ServiceProviderId"
                (click)="addUserRoleTemplate(userRoleTemplateForm)"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template
  #itemTemplate
  let-item="item"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
>
  <div class="form-inline row-item" style="display: inline-flex">
    <i
      *ngIf="item.children"
      (click)="onCollapseExpand()"
      aria-hidden="true"
      class="fa"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed"
    ></i>
    <div class="form-check">
      <mat-checkbox
        class="form-check-input"
        [(ngModel)]="item.checked"
        (change)="onCheckedChange()"
        [disabled]="item.disabled"
        [indeterminate]="item.indeterminate"
      >
        {{ item.text }}</mat-checkbox
      >
    </div>
  </div>
</ng-template>
