import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { VenueModule } from '../../venuemanagement/venue/venue.module';
import { BeaconRoutes } from './beacon.routes';
import { BeaconComponent } from './components/beacon.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [CommonModule, BeaconRoutes, FormsModule, NgxPaginationModule, HomeModule, SharedModule],

  declarations: [BeaconComponent],
})
export class BeaconModule extends GenericEntity {
  public BeaconId: string;
  public BeaconCPId: string;
  public BeaconCode: string;
  public BeaconName: string;
  public Identifier: string;
  public UuId: string;
  public MajorId: number;
  public MinorId: number;
  public Range: any;
  public BeaconType: number;
  public BatteryLevel: any;
  public Tag: string;
  public Venue: VenueModule;
}
