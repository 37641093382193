import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { VenueModule } from '../venue/venue.module';
import { BadgeRoutes } from './badge.routes';
import { BadgeComponent } from './components/badge.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, BadgeRoutes, HomeModule, SharedModule],

  declarations: [BadgeComponent],
})
export class BadgeModule extends GenericEntity {
  public BadgeId: number;
  public BadgeCPId: string;
  public BadgeNumber: string;
  public Venue: VenueModule;
  public BadgeName: string;
  public UserNames: string;
}
