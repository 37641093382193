import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { VenueService } from 'src/app/features/venuemanagement/venue/components/venue.http.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { LocationService } from '../../location/components/location.http.service';
import { ShiftService } from '../../shift/components/shift.http.service';
import { BeaconModule } from '../beacon.module';
import { BeaconService } from './beacon.http.service';

@Component({
  selector: 'beacon.component',
  templateUrl: 'beacon.component.html',
  providers: [BeaconService, UtilitiesService, VenueService, LocationService, ShiftService],
})
export class BeaconComponent implements OnInit {
  currentUser: User;

  public beacons: BeaconModule[] = [];
  public beacon: BeaconModule = new BeaconModule();
  public venues: VenueModule[] = [];
  // public venue: VenueModule = new VenueModule();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  isReqComplete = false;
  beaconTypes: any[] = [
    { idfield: 1, textfield: 'iBeacon' },
    { idfield: 2, textfield: 'Eddystone' },
  ];
  public buildingDisabled = false;
  public zoneDisabled = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  venueBind: number;
  public pageState: StateMaintain = {
    pageName: 'beaconForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private venueService: VenueService,
    private loaderService: LoaderService,
    private beaconService: BeaconService,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.beacon.Venue = new VenueModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();

    this.venueBind = null;
    this.venueBind = this.authState.getStoredContextVenueId();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllBeacons(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllBeacons(this.searchString, this.page, this.size, this.viewType);
    }
  }

  /// this funcation used to get result as per page size change
  public BeaconPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBeacons(this.searchString, this.page, this.size, this.viewType);
  }

  public setAddForm() {
    this.searchString = '';
    this.beacons = null;
    this.count = 0;
    this.beacon = new BeaconModule();
    this.beacon.Venue = new VenueModule();

    this.getAllVenues('', -1, -1);
    setTimeout(() => {
      const element = document.getElementById('BeaconCode');
      element.focus();
    }, 1000);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.beacons = null;
    this.count = 0;
    this.getAllVenues('', -1, -1);
  }

  public clearFormData(beaconForm: NgForm) {
    this.searchString = '';
    this.beacon = new BeaconModule();
    this.beacon.Venue = new VenueModule();
    beaconForm.reset();
    this.getAllBeacons(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllBeacons(this.searchString, this.page, this.size, this.viewType);
  }

  public beaconsSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBeacons(searchString, 1, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.beacons !== null && this.beacons.length > 0;
  }

  public getAllBeacons(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.beaconService.GetBeaconList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.beacons = data;
        if (this.beacons.length < 0 && this.beacons[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'beaconService getAllBeacons Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.beacons[0] ? this.beacons[0].Count : 0),
    );
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBeacons(this.searchString ? this.searchString : '', this.page, this.size, this.viewType);
  }

  public addBeacon(BeaconForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (BeaconForm.status == 'INVALID' && BeaconForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Beacon', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.beacon.BeaconName = this.utilitiesService.removeInnerSpaces(this.beacon.BeaconName);
    if (this.beacon.BeaconName === null || this.beacon.BeaconName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }
    this.beacon.AddedBy = this.currentUser.UserId;

    this.beaconService.Add(this.utilitiesService.stripScript(this.beacon)).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.clearFormData(BeaconForm);
        this.utilitiesService.smmodal('Beacon', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('beaconService addBeacon Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public updateBeacon(BeaconForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (BeaconForm.status == 'INVALID' && BeaconForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Beacon', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.beacon.BeaconName = this.utilitiesService.removeInnerSpaces(this.beacon.BeaconName);
    if (this.beacon.BeaconName === null || this.beacon.BeaconName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }
    this.beacon.BeaconName = this.beacon.BeaconName.trim();

    this.beacon.ModifiedBy = this.currentUser.UserId;
    this.beacon.IsActive = this.beacon.IsActive ? 1 : 0;

    if (this.beacon.BeaconType === 1) {
      this.beacon.Identifier = null;
    } else {
      this.beacon.UuId = null;
      this.beacon.MajorId = null;
      this.beacon.MinorId = null;
    }

    this.beaconService.Update(this.utilitiesService.stripScript(this.beacon)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.clearFormData(BeaconForm);
        this.utilitiesService.smmodal('Beacon', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'beaconService updateBeacon Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getBeacontById(BeaconId: number) {
    if (this.authState.canAccess('BEACON_VIEW')) {
      this.loaderService.display(true);
      const CPbeacon = new BeaconModule();
      CPbeacon.BeaconCPId = this.authState.AESEncryptText(BeaconId);
      this.beaconService.GetSingle(CPbeacon).subscribe(
        (data) => {
          this.beacon = data;
          this.beacon.BeaconType = this.beacon.UuId ? 1 : 2;
          this.setUpdateForm();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'beaconService getBeacontById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    } else {
      this.utilitiesService.smmodal('Beacon', 'You do not have permission to view beacon details.');
    }
  }

  // Get list of venues
  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        if (!this.beacons && !this.beacon.BeaconId) {
          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj[0]) {
            this.beacon.Venue.VenueId = obj[0].VenueId;
            // console.log(obj[0].venueId);
            this.beacon.Venue.BeaconManufacturer = obj[0].BeaconManufacturer;
          } else {
            this.beacon.Venue.BeaconManufacturer = null;
          }
        }
        this.venues = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService getAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public changeVenue($event: any) {
    this.beacon.Venue.VenueId = $event;
    this.beacon.Venue.BeaconManufacturer = null;

    if (this.venues !== null && this.venues.length > 0) {
      const obj = this.venues.filter(function (v) {
        return v.VenueId === $event;
      });
      if (obj[0] !== null) {
        this.beacon.Venue.BeaconManufacturer = obj[0].BeaconManufacturer ? obj[0].BeaconManufacturer : null;
      }
    }
  }

  public beaconTypeSelection($event: any) {
    this.beacon.BeaconType = $event;
    // if (this.beacon.beaconType !== null) {
    //     this.isBeaconType = true;
    // } else {
    //     this.isBeaconType = false;
    //     this.beacon.identifier = null;
    //     this.beacon.uuId = null;
    //     this.beacon.majorId = null;
    //     this.beacon.minorId = null;
    // }

    if (this.beacon.BeaconType === 1) {
      this.beacon.Identifier = null;
    } else if (this.beacon.BeaconType === 2) {
      this.beacon.UuId = null;
      this.beacon.MajorId = null;
      this.beacon.MinorId = null;
    } else {
      this.beacon.UuId = null;
      this.beacon.MajorId = null;
      this.beacon.MinorId = null;

      this.beacon.Identifier = null;
    }
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBeacons(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllBeacons(this.searchString, this.page, this.size, this.viewType);
  }

  keyPressCheck($event: any) {
    if ($event.key == 'e' || $event.key == 'E' || $event.key == '-') {
      $event.preventDefault();
      return false;
    }

    if ($event.target.value.length > 10) {
      $event.preventDefault();
      return false;
    }
  }
}
