import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import {
  DefaultTreeviewEventParser,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewModule,
} from 'ngx-treeview';

import { LocationSensor, SensorModule } from '../../corporationmanagement/sensor/sensor.module';
import { SensorTypeModule } from '../../corporationmanagement/sensortype/sensortype.module';
import { FeatureConfiguration, Module } from '../../serviceprovider/serviceprovider.module';
import { BuildingModule } from '../../venuemanagement/building/building.module';
import { GatewayModule } from '../../venuemanagement/gateway/getway.module';
import { VenueModule } from '../../venuemanagement/venue/venue.module';
import { BeaconModule } from '../beacon/beacon.module';
import { ZoneModule } from '../zone/zone.module';
import { LocationTemplateComponent } from './components/locationtemplate.component';
import { GateRoutes } from './locationtemplate.routes';

import {
  LocationElementModule,
  LocationInventoryModule,
  LocationModule,
  LocationTaskModule,
  VenueLocationTouchlessFeedbackReason,
} from '../location/location.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    GateRoutes,
    FormsModule,
    NgxPaginationModule,
    HomeModule,
    RouterModule,
    TreeviewModule,
    SharedModule,
  ],

  providers: [
    TreeviewConfig,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],

  declarations: [LocationTemplateComponent],
})
export class LocationTemplateModule extends GenericEntity {
  public LocationTemplateCPId: number;
  public LocationTemplateId: number;
  public LocationTemplateName: string;
  public LocationId: number;
  public LocationCPId: string;
  public VenueCPId: string;
  public LocationName: string;
  public Latitude: number;
  public Longitude: number;
  public Altitude: number;
  public LocationType: string;
  public LevelId: number;
  public LevelName: string;
  public LevelOrdinal: number;
  // public restroomFeedback: string;
  public SensorTypeAvgColor: string;
  public LastUpdated: any;
  public RestroomFeedback: RestroomFeedback[];
  public Zone: ZoneModule;
  public LocationBeacons: LocationBeacon[];
  public LocationSensors: LocationSensor[];
  public SensorTypes: SensorTypeModule[];
  public sensorTypesWithoutStallOccupancy: SensorTypeModule[];
  public Building: BuildingModule;
  public Venue: VenueModule;
  public RandomNumber: number;
  public RandomPercentage: number;
  /// this StallOcupancySensorType property is to display details of male canvas
  public StallOccupancySensorType: SensorTypeModule;
  public VisitorCountPercentage: number;
  public LocationMapFiles: any[] = [];
  public LastCleanedDate: any;
  public CleaningOpenedDate: any;
  public LastVisited: any;
  public SurveyIconColor: string;
  public isGaugeShow: any;
  public PeopleCounterThreshold: number;
  public isAQShow: any;
  public HeadCount: any;
  public AirQuality: number;
  public AQStatusColor: string;
  public ariaExpanded: boolean;
  public NextRestroomDistance: number;
  public NextRestroomDirection: string;
  public LocationStatus: string;
  public LocationSVGMap: string;
  public isPeopleCounterShow: any;
  public isLinkShow: any;

  public LocationGateways: LocationGateway[];
  public LocationTasks: LocationTaskModule[];
  public LocationInventories: LocationInventoryModule[];
  public LocationElements: LocationElementModule[];
  public Modules: Module[] = [];
  public LocationFeatureConfigurations: FeatureConfiguration[] = [];
  public isCA = false;

  public MapID: any;

  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public LocationList: LocationModule[];
  public LocationFeedbackReasons: VenueLocationTouchlessFeedbackReason[];
  public TouchlessFeedbackIdentifier: string;
}

export class LocationBeacon extends GenericEntity {
  public LocationBeaconId: number;
  public Beacon: BeaconModule;
}

export class RestroomFeedback extends GenericEntity {
  public feedback: string;
  public icon: string;
  public date: any;
}

export class LocationSensorDetails extends GenericEntity {
  public locationSensorId: number;
  public SensorType: SensorTypeModule;
  public Sensors: SensorModule[] = [];
  public Threshold: any;
  public WarnPercentage: any;
}

export class LocationGenderType extends GenericEntity {
  public LocationTypeId: number;
  public LocationTypeName: string;
  public LocationTypeCode: string;
  public LocationTypeDescription: string;
}

export class LocationGateway extends GenericEntity {
  public LocationGatewayId: number;
  // public LocationTemplateId: number;
  public Gateway: GatewayModule;
}

export class LocationTemplateImport extends GenericEntity {
  public LocationTemplateId: number;
  public LocationTemplateName: string;
  public IsSelected: boolean;
}
export class LocationTemplateImportRequest extends GenericEntity {
  public LocationTemplateImportList: LocationTemplateImport[];
  public VenueId: number;
}

export class ImportLocationTemplateLog extends GenericEntity {
  public DownloadId: number;
  public SourceVenueId: number;
  public DestinationVenueId: number;
  public Status: string;
  public SourceVenueName: number;
  public DestinationVenueName: number;
  public UploadedBy: string;
  public AddedOn: Date;
}
export class LocationTemplateImportValidationResponse extends GenericEntity {
  public isSuccess: boolean;
  public Message: string;
  public MisMatchedLocationTypes: string;
  public MisMatchedElements: string;
  public MisMatchedAreas: string;
  public MisMatchedTasks: string;
  public MisMatchedInventories: string;
}
