import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';

import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { AirQualitySensorTransactionModule } from '../../corporationmanagement/sensor/sensor.module';
import { LocationModule, RestroomFeedback } from '../../staffscheduling/location/location.module';
import { ZoneModule } from '../../staffscheduling/zone/zone.module';
import { BuildingModule } from '../../venuemanagement/building/building.module';
@Injectable({
  providedIn: 'root',
})
export class RestroomOverviewService {
  private headers: HttpHeaders;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetAllRestRoomDetails = (body: string, page: number, size: number): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/zone/locations/' + page + '/' + size;
    return this.http.post<LocationModule[]>(_url, body);
  };

  /**
   * Method to get the location overview details
   */
  public getLocationOverviewDetails(body: string, page: number, size: number): Observable<LocationModule[]> {
    const _url: string = this.configuration.Server + 'venue/location-overview/' + page + '/' + size;
    return this.http.post<LocationModule[]>(_url, body);
  }

  public GetAllBuildings = (body: any): Observable<BuildingModule[]> => {
    const _url: string = this.configuration.Server + 'venue/dashboard/buildings';
    return this.http.post<BuildingModule[]>(_url, body);
  };

  public GetAllZones = (body: any): Observable<ZoneModule[]> => {
    const _url: string = this.configuration.Server + 'venue/dashboard/zones';
    return this.http.post<ZoneModule[]>(_url, body);
  };

  public GetAllLocationsDropdownList = (body: string): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/dashboard/locations';
    return this.http.post<LocationModule[]>(_url, body);
  };

  public GetAirQualityDetails = (body: LocationModule): Observable<AirQualitySensorTransactionModule> => {
    const _url: string = this.configuration.Server + 'venue/location/airthinx/sensor/details';
    return this.http.post<AirQualitySensorTransactionModule>(_url, body);
  };

  public UpdateStatus = (body: LocationModule): Observable<LocationModule> => {
    const _url: string = this.configuration.Server + 'venue/location/status';
    return this.http.put<LocationModule>(_url, body);
  };

  public CleaningAlertAttatchments(filedata: any[], WorkorderCreatorAttachmentId: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + 'workorder/creator/attachment';

      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      xhr.setRequestHeader('WorkorderId', WorkorderCreatorAttachmentId);
      xhr.setRequestHeader('LastAttachmentKey', filedata.length.toString());

      const formData = new FormData();

      for (let i = 0; i < filedata.length; i++) {
        if (filedata[i].attachmentImageFile != null) {
          let sequence = i + 1;
          let fileName = 'creatorAttachment_' + WorkorderCreatorAttachmentId + '_' + sequence;
          formData.append(fileName, filedata[i].attachmentImageFile, filedata[i].attachmentImageFile.name);
        }
      }
      xhr.send(formData);
    });
  }

  public CleaningAlertStatus = (body: any): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/check/request/status';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public GetGuestFeedbackDetails = (body: any): Observable<RestroomFeedback[]> => {
    const _url: string = this.configuration.Server + 'venue/location/survey/details';
    return this.http.post<RestroomFeedback[]>(_url, body);
  };
}
