<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-5 col-md-5 col-sm-12 form-heading">
        <i class="fa fa-user heading-icon"></i>
        <span class="list-heading" *ngIf="userLicenseRequests">USER LICENSE REQUEST LOGS</span>
      </div>

      <div class="col-lg-7 col-sm-12 col-md-12">
        <div
          id="page-size"
          class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading"
          *ngIf="userLicenseRequests"
        >
          <ngx-atlas-select
            (model)="userLicenseRequestPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-3 col-md-4 col-sm-4 col-xs-12 input-group pull-right add-section"
          *ngIf="'USER_LICENSE_REQUEST' | canAccess"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="getLicenseRequestDetails()">
            Add/Remove License Request
          </button>
        </div>

        <div
          class="col-lg-5 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search"
          style="margin-right: 10px"
          *ngIf="userLicenseRequests"
        >
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="userLicenseRequestSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="userLicenseRequests">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('TraxRequestId', columnSortOrder)">
                  Request #
                  <span *ngIf="selectedColumnName == 'TraxRequestId' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'TraxRequestId' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('ServiceProviderName', columnSortOrder)">
                  Service Provider Name
                  <span *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('FirstName', columnSortOrder)">
                  User Name
                  <span *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('RequestDate', columnSortOrder)">
                  Requested Date
                  <span *ngIf="selectedColumnName == 'RequestDate' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'RequestDate' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('RequestStatus', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let licenseRequest of userLicenseRequests
                  | paginate: { itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getUserLicenseRequestLogById(licenseRequest.TraxRequestId)">
                <td>{{ licenseRequest.TraxRequestId }}</td>
                <td>{{ licenseRequest.ServiceProviderName }}</td>
                <td>{{ licenseRequest.FirstName }} {{ licenseRequest.LastName }}</td>
                <td>{{ licenseRequest.RequestDate | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                <td>{{ licenseRequest.RequestStatus }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div
    class="modal col-lg-12 col-md-12 col-sm-12 col-xs-12"
    id="viewRequestLogModal"
    [ngStyle]="viewRequestLogModal ? { display: 'block' } : { display: 'none' }"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h3 style="display: inline">Request Log Details</h3>
          <button type="button" class="close" data-dismiss="modal" (click)="closeRequestLogModal()">&times;</button>
        </div>

        <div class="modal-body" style="text-align: left">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 form-group">
              <label class="col-md-6">Request # </label>
              <div class="col-md-6">{{ traxRequestId }}</div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
              <div *ngIf="!licenseRequestLogHasData()">
                <h3 class="listerror">Sorry no data found!</h3>
              </div>

              <div class="box-body table-responsive" *ngIf="licenseRequestLogHasData()">
                <table class="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th class="table-sort-header">Request Status</th>
                      <th class="table-sort-header">Requested Date</th>
                    </tr>
                  </thead>

                  <tbody *ngFor="let licenseRequest of userLicenseRequestLog.SProviderSalesforceRequestResponseLogs">
                    <tr>
                      <td>{{ licenseRequest.RequestStatus }}</td>
                      <td>{{ licenseRequest.RequestDate | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeRequestLogModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="checkContractIdModal"
    [ngStyle]="checkContractIdModal ? { display: 'block' } : { display: 'none' }"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-size: 20px !important">User License Request</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="closecheckContractIdModalModal()"
            style="margin-top: -35px"
          >
            &times;
          </button>
        </div>

        <div class="modal-body" style="text-align: left">
          <p style="text-align: center">
            Unable to send your request to salesforce. Contract Id is not present for this service provider.
          </p>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closecheckContractIdModalModal()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="licenseRequestModal"
    [ngStyle]="licenseRequestModal ? { display: 'block' } : { display: 'none' }"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">User License Request</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="clearUserLicenceRequestData(requestLicenseForm)"
            style="margin-top: -37px"
          >
            &times;
          </button>
        </div>

        <div class="modal-body" style="text-align: left; font-size: 14px !important; max-height: 375px">
          <form #requestLicenseForm="ngForm" name="requestLicenseForm" role="form" class="form-horizontal">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <label for="serviceProvider"> Service Provider: </label>&nbsp;{{
                    licenseRequestDetail.ServiceProviderName
                  }}
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group" style="text-align: center">
                <mat-radio-group
                  class="example-radio-group"
                  [(ngModel)]="selectedRequestType"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-radio-button
                    #button
                    class="example-radio-button"
                    *ngFor="let option of licenseRequestTypes"
                    [value]="option.RequestTypeId"
                    (click)="checkLicenseRequestType(option.RequestTypeId, option.RequestTypeName)"
                    style="margin-right: 20px"
                  >
                    {{ option.RequestTypeName }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="currentInternalUserLicense"> Current Internal User License(s): </label>&nbsp;{{
                    licenseRequestDetail.TotalInternalUserLicenseCount
                  }}
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="currentExternalUserLicense"> Current External User License(s): </label>&nbsp;{{
                    licenseRequestDetail.TotalExternalUserLicenseCount
                  }}
                </div>
              </div>

              <!--<div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label for="activeInternalUserLicense"> Active Internal User License(s): </label>&nbsp;{{licenseRequestDetail.TotalInternalActiveUsersPresent}}
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label for="activeExternalUserLicense"> Active External User License(s): </label>&nbsp;{{licenseRequestDetail.TotalExternalActiveUsersPresent}}
                                </div>
                            </div>-->

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="RequestedUserLicense"> Request Internal User License(s)</label>
                  <div>
                    <input
                      type="number"
                      autocomplete="invalid"
                      class="form-control"
                      name="RequestedInternalLicenseCount"
                      placeholder="Requested Internal User Licenses"
                      [(ngModel)]="licenseRequestPayload.RequestedInternalLicenseCount"
                      #RequestedInternalLicenseCount="ngModel"
                      min="1"
                      pattern="^\s*-?[0-9]{1,7}\s*$"
                      (input)="changeUserAgree()"
                      oninput="validity.valid||(value='');"
                    />
                    <div
                      *ngIf="
                        RequestedInternalLicenseCount.errors &&
                        (RequestedInternalLicenseCount.dirty || RequestedInternalLicenseCount.touched)
                      "
                      class="alert alert-danger"
                      style="padding: 5px"
                    >
                      <div [hidden]="!RequestedInternalLicenseCount.errors.pattern" class="text-danger">
                        Maximum 7 digits allowed.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="RequestedUserLicense"> Request External User License(s)</label>
                  <div>
                    <input
                      type="number"
                      autocomplete="invalid"
                      class="form-control"
                      name="RequestedExternalLicenseCount"
                      placeholder="Requested External User Licenses"
                      [(ngModel)]="licenseRequestPayload.RequestedExternalLicenseCount"
                      #RequestedExternalLicenseCount="ngModel"
                      min="1"
                      pattern="^\s*-?[0-9]{1,7}\s*$"
                      (input)="changeUserAgree()"
                      oninput="validity.valid||(value='');"
                    />
                    <div
                      *ngIf="
                        RequestedExternalLicenseCount.errors &&
                        (RequestedExternalLicenseCount.dirty || RequestedExternalLicenseCount.touched)
                      "
                      class="alert alert-danger"
                      style="padding: 5px"
                    >
                      <div [hidden]="!RequestedExternalLicenseCount.errors.pattern" class="text-danger">
                        Maximum 7 digits allowed.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group" *ngIf="perMonthCharges && isAddLicenseRequestShow">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div
                    *ngIf="
                      licenseRequestPayload.RequestedInternalLicenseCount &&
                      !licenseRequestPayload.RequestedExternalLicenseCount
                    "
                  >
                    <p *ngIf="checkBillingDate && internalProRate && internalProRateTotal">
                      Users will be billed at a pro-rate of <b>${{ internalProRate | number: '1.2-2' }}</b
                      >/user (total <b>${{ internalProRateTotal | number: '1.2-2' }}</b
                      >) for internal users for this billing cycle.
                    </p>

                    <p>
                      For regular billing period the users will be billed at a monthly rate of
                      <b>${{ licenseRequestDetail.InternalUserLicenseFee }}</b
                      >/user (total<b>
                        ${{
                          licenseRequestDetail.InternalUserLicenseFee *
                            licenseRequestPayload.RequestedInternalLicenseCount
                        }}</b
                      >) for internal users.
                    </p>
                  </div>

                  <div
                    *ngIf="
                      !licenseRequestPayload.RequestedInternalLicenseCount &&
                      licenseRequestPayload.RequestedExternalLicenseCount
                    "
                  >
                    <p *ngIf="checkBillingDate && externalProRate && externalProRateTotal">
                      Users will be billed at a pro-rate of <b>${{ externalProRate | number: '1.2-2' }}</b
                      >/user (total <b>${{ externalProRateTotal | number: '1.2-2' }}</b
                      >) for external users for this billing cycle.
                    </p>

                    <p>
                      For regular billing period the users will be billed at a monthly rate of
                      <b>${{ licenseRequestDetail.ExternalUserLicenseFee }}</b
                      >/user (total<b>
                        ${{
                          licenseRequestDetail.ExternalUserLicenseFee *
                            licenseRequestPayload.RequestedExternalLicenseCount
                        }}</b
                      >) for external users.
                    </p>
                  </div>

                  <div
                    *ngIf="
                      licenseRequestPayload.RequestedInternalLicenseCount &&
                      licenseRequestPayload.RequestedExternalLicenseCount
                    "
                  >
                    <p
                      *ngIf="
                        checkBillingDate &&
                        internalProRate &&
                        internalProRateTotal != 0 &&
                        externalProRate &&
                        externalProRateTotal != 0
                      "
                    >
                      Users will be billed at a pro-rate of
                      <b>${{ internalProRate | number: '1.2-2' }}</b
                      >/user (total <b>${{ internalProRateTotal | number: '1.2-2' }}</b
                      >) for internal users and <b>${{ externalProRate | number: '1.2-2' }}</b
                      >/user (total <b>${{ externalProRateTotal | number: '1.2-2' }}</b
                      >) for external users for this billing cycle.
                    </p>

                    <p
                      *ngIf="
                        checkBillingDate && internalProRate && internalProRateTotal != 0 && externalProRateTotal == 0
                      "
                    >
                      Users will be billed at a pro-rate of <b>${{ internalProRate | number: '1.2-2' }}</b
                      >/user (total <b>${{ internalProRateTotal | number: '1.2-2' }}</b
                      >) for internal users for this billing cycle.
                    </p>

                    <p
                      *ngIf="
                        checkBillingDate && externalProRate && externalProRateTotal != 0 && internalProRateTotal == 0
                      "
                    >
                      Users will be billed at a pro-rate of <b>${{ externalProRate | number: '1.2-2' }}</b
                      >/user (total <b>${{ externalProRateTotal | number: '1.2-2' }}</b
                      >) for external users for this billing cycle.
                    </p>

                    <p>
                      For regular billing period the users will be billed at a monthly rate of
                      <b>${{ licenseRequestDetail.InternalUserLicenseFee }}</b
                      >/user (total
                      <b
                        >${{
                          licenseRequestDetail.InternalUserLicenseFee *
                            licenseRequestPayload.RequestedInternalLicenseCount
                        }}</b
                      >) for internal users and <b>${{ licenseRequestDetail.ExternalUserLicenseFee }}</b
                      >/user (total
                      <b
                        >${{
                          licenseRequestDetail.ExternalUserLicenseFee *
                            licenseRequestPayload.RequestedExternalLicenseCount
                        }}</b
                      >) for external users.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <mat-checkbox [(ngModel)]="licenseRequestPayload.IsUserAgree" name="isUserAgree"></mat-checkbox
                  >&nbsp;&nbsp;
                  <!--<span style="font-weight: bold;">I agree to the <a (click)="openTermsandConditions()" style="cursor:pointer;">Terms & Conditions</a> and that I have read them.</span>-->
                  <span style="font-weight: bold"
                    >I agree to the above listed fees associated with this transaction.</span
                  >
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button
              class="btn btn-type btn-save"
              [disabled]="
                !requestLicenseForm.form.valid ||
                isReqComplete ||
                !licenseRequestPayload.IsUserAgree ||
                !selectedRequestType ||
                (!licenseRequestPayload.RequestedInternalLicenseCount &&
                  !licenseRequestPayload.RequestedExternalLicenseCount)
              "
              (click)="createLicenseRequest(requestLicenseForm)"
            >
              Request
            </button>
            <button
              class="btn btn-type btn-cancel"
              data-dismiss="modal"
              (click)="clearUserLicenceRequestData(requestLicenseForm)"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="licenseRequestErrorModal"
    [ngStyle]="licenseRequestErrorModal ? { display: 'block' } : { display: 'none' }"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-size: 20px !important">User License Removal Request</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="closeLicenseRequestLicenseErrorModal()"
            style="margin-top: -35px"
          >
            &times;
          </button>
        </div>

        <div class="modal-body" style="text-align: left">
          <p>{{ requestErrorMessage }}</p>
          <p style="color: #be3f42; font-weight: bold" *ngIf="!('DELETE_USER' | canAccess)">
            You do not have permissions to remove user.
          </p>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-save" (click)="getInternalExternalUsersList()">Yes</button>
            <button
              class="btn btn-type btn-cancel"
              data-dismiss="modal"
              (click)="closeLicenseRequestLicenseErrorModal()"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="internalExternalUserModal"
    [ngStyle]="internalExternalUserModal ? { display: 'block' } : { display: 'none' }"
  >
    <div class="modal-dialog modal-lg" style="width: 90% !important">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-size: 20px !important">Delete User Licenses</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="closeInternalExternalUserModal()"
            style="margin-top: -35px"
          >
            &times;
          </button>
        </div>

        <div class="modal-body" style="text-align: left; max-height: 380px">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p style="color: #be3f42; font-weight: bold" *ngIf="!('DELETE_USER' | canAccess)">
                You do not have permissions to remove user.
              </p>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <!--<h4 style="font-weight: bold;">Internal Users</h4>-->
              <div class="box-body table-responsive" style="max-height: 325px">
                <table class="table table-hover table-responsive" style="font-size: 12px">
                  <thead>
                    <tr>
                      <th style="font-size: 12px !important" *ngIf="'DELETE_USER' | canAccess">Select</th>
                      <th style="font-size: 12px !important">User</th>
                      <th style="font-size: 12px !important; width: 8%">User Type</th>
                      <th style="font-size: 12px !important">User Role</th>
                      <th style="font-size: 12px !important">Email</th>
                      <th style="font-size: 12px !important">Corporation</th>
                      <th style="font-size: 12px !important">Venue</th>
                    </tr>
                  </thead>

                  <tbody *ngFor="let user of internalExternalUserList">
                    <tr>
                      <td *ngIf="'DELETE_USER' | canAccess">
                        <mat-checkbox
                          id="user_{{ user.UserId }}"
                          name="user_{{ user.UserId }}"
                          [(ngModel)]="user.IsUserDelete"
                        ></mat-checkbox>
                      </td>
                      <td>{{ user.FirstName }} {{ user.LastName }}</td>
                      <td>{{ user.UserType }}</td>
                      <td>{{ user.UserRole }}</td>
                      <td>{{ user.Email }}</td>
                      <td>{{ user.Corporations }}</td>
                      <td>{{ user.Venues }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!--<div class="col-lg-12 col-md-12 col-sm-12" *ngIf="licenseRequestPayload.RequestedExternalLicenseCount">
                            <h4 style="font-weight: bold;">External Users</h4>
                            <div class="box-body table-responsive" style="max-height: 200px;">
                                <table class="table table-hover table-responsive" style="font-size: 12px;">
                                    <thead>
                                        <tr>
                                            <th style="font-size: 12px !important;" *ngIf="('DELETE_USER' | canAccess)">Select</th>
                                            <th style="font-size: 12px !important;">User</th>
                                            <th style="font-size: 12px !important;">User Role</th>
                                            <th style="font-size: 12px !important;">Email</th>
                                            <th style="font-size: 12px !important;">Corporation</th>
                                            <th style="font-size: 12px !important;">Venue</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngFor="let external of internalEnternalUsers.ExternalUserList">
                                        <tr>
                                            <td *ngIf="('DELETE_USER' | canAccess)">
                                                <mat-checkbox id="externalUser_{{external.UserId}}" name="externalUser_{{external.UserId}}" [(ngModel)]="external.IsUserDelete"></mat-checkbox>
                                            </td>
                                            <td>{{external.FirstName}} {{external.LastName}}</td>
                                            <td>{{external.UserRole}}</td>
                                            <td>{{external.Email}}</td>
                                            <td>{{external.Corporations}}</td>
                                            <td>{{external.Venues}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>-->
          </div>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button
              class="btn btn-type btn-delete"
              [disabled]="!('DELETE_USER' | canAccess)"
              (click)="openDeleteUserConfirmationModal()"
            >
              Delete
            </button>
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeInternalExternalUserModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="deleteUserConfirmationModal"
    [ngStyle]="deleteUserConfirmationModal ? { display: 'block' } : { display: 'none' }"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-size: 20px !important">User License Removal Request</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="closeDeleteUserConfirmationModal()"
            style="margin-top: -35px"
          >
            &times;
          </button>
        </div>

        <div class="modal-body" style="text-align: left">
          <p>Are you sure you want to delete the user(s)? This action cannot be undone!</p>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-save" (click)="deactivateUser()">Yes</button>
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeDeleteUserConfirmationModal()">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
