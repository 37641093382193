import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alertmodal.component.html',
  styleUrls: ['./alertmodal.scss'],
})
export class AlertComponent {
  // https://gitlab.com/angkarn9/ng6-material-modal   // reference url

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  getAlertIcon() {
    switch (this.data.alertType) {
      case AlertType.check_circle_outline:
        return 'check_circle_outline';
      case AlertType.WARNING:
        return 'warning';
      case AlertType.error_outline:
        return 'error_outline';
      case AlertType.help_outline:
        return 'help_outline';
      case AlertType.delete_forever:
        return 'delete_forever';
    }
  }

  getAlertColor() {
    switch (this.data.alertColor) {
      case AlertColor.green:
        return 'green';
      case AlertColor.red:
        return 'red';
    }
  }
}

export class ModalAlertData {
  icon = '';
  title = '';
  content = '';
  alertType: AlertType = AlertType.check_circle_outline;
  alertColor: AlertColor = AlertColor.green;
  closeButtonLabel = '';
  flagVal = '';

  constructor(data?: any) {
    if (data) {
      this.icon = data.icon;
      this.title = data.title;
      this.content = data.content;
      this.alertType = data.alertType;
      this.alertColor = data.alertColor;
      this.closeButtonLabel = data.closeButtonLabel;
      this.flagVal = data.flagVal;
    }
  }
}

export enum AlertType {
  check_circle_outline,
  WARNING,
  error_outline,
  help_outline,
  delete_forever,
}

export enum AlertColor {
  green,
  red,
}
