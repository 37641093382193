export enum QRCodeType {
  LOCATIONS,
  TOUCHLESS_FEEDBACK,
  CLEANING_ALERT,
}

export interface IPrintQRRequest {
  locations: ILocationQrs[];
  qrCodeType: QRCodeType;
  isBlackWhitePrint?: boolean;
  size?: number;
  url?: string;
}

export interface ILocationQrs {
  locationId: number;
  isCleaningAlertEnabled: boolean;
  isTouchlessFeedbackEnabled: boolean;
}
