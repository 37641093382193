import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  public logostatus: BehaviorSubject<boolean> = new BehaviorSubject<any>('');

  changeLogo(value: any) {
    this.logostatus.next(value);
  }
}
