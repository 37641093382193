import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { BadgeModule } from '../badge.module';
@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  private headers: HttpHeaders;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (body: BadgeModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'badge/badge';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: BadgeModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'badge/badge';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetAll = (body: string, page: number, size: number): Observable<BadgeModule[]> => {
    const _url: string = this.configuration.Server + 'badge/all/badges/' + page + '/' + size;
    return this.http.post<BadgeModule[]>(_url, body);
  };

  public GetSingle = (body: BadgeModule): Observable<BadgeModule> => {
    const _url: string = this.configuration.Server + 'badge/details';
    return this.http.post<BadgeModule>(_url, body);
  };
}
