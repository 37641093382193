<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="zones">ZONES</span>
        <span class="list-heading" *ngIf="!zones && !zone.ZoneId">ADD ZONE</span>
        <span class="list-heading" *ngIf="!zones && zone.ZoneId">EDIT ZONE</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="zones">
          <ngx-atlas-select
            (model)="ZonePageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="zones && !zone.ZoneId && ('ZONE_ADD' | canAccess)"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Zone
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="zones">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="zones">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="zonesSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="zones">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('ZoneName', columnSortOrder)">
                  Zone Name
                  <span *ngIf="selectedColumnName == 'ZoneName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ZoneName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue Name
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('BuildingName', columnSortOrder)">
                  Building Name
                  <span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let zone of zones
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getZoneById(zone.ZoneId)">
                <td>{{ zone.ZoneName }}</td>
                <td>{{ zone.VenueName }}</td>
                <td>{{ zone.Building.BuildingName }}</td>
                <td *ngIf="zone.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!zone.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!zones">
    <form #ZoneForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="zonename"><span id="red-circle">&#x25CF;</span> Zone Name</label>
            <div>
              <input
                id="zoneName"
                type="text"
                class="form-control"
                name="Zonename"
                placeholder="Zone Name"
                [(ngModel)]="zone.ZoneName"
                required
                #zonename="ngModel"
                maxlength="49"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <!--(input)="getZoneName(zone.ZoneName)"-->
              <div *ngIf="zonename.errors && (zonename.dirty || zonename.touched)" class="alert alert-danger">
                <div [hidden]="!zonename.errors.required" class="text-danger">Zone name is required.</div>
                <!--<div [hidden]="!zonename.errors.pattern" class="text-danger">
                                    Zone name accepts only alphanumeric value & single space between words.
                                </div>-->
                <div [hidden]="!zonename.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12 form-group">
            <label for="venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <ngx-atlas-select
                (model)="getAllBuildings($event)"
                [list]="venues"
                [idField]="'VenueId'"
                [textField]="'VenueName'"
                [placeholder]="'Select Venue'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="zone.Building.Venue.VenueId"
              >
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="building"><span id="red-circle">&#x25CF;</span> Building</label>
            <div>
              <ngx-atlas-select
                (model)="checkIsValueSelected($event, 'building')"
                [list]="buildings"
                [idField]="'BuildingId'"
                [textField]="'BuildingName'"
                [placeholder]="'Select Building'"
                [allowClear]="true"
                [multiple]="false"
                [disabled]="buildingDisabled"
                [required]="true"
                [selectedValue]="zone.Building.BuildingId"
              >
              </ngx-atlas-select>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group" *ngIf="zone.ZoneId">
              <div>
                <div>
                  <mat-checkbox [(ngModel)]="zone.IsActive" name="isActive">Is Active</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(ZoneForm)"
          />
          <!--<input type="button" value="CANCEL" class="btn btn-type pull-right btn-cancel" (click)="clearFormData(ZoneForm)" />-->
          <input
            type="button"
            value="Save"
            *ngIf="!zone.ZoneId"
            class="btn btn-type btn-save"
            [disabled]="
              !ZoneForm.form.valid ||
              !zone.Building.BuildingId ||
              !zone.Building.Venue.VenueId ||
              isReqComplete ||
              !isBuildingInVenue
            "
            (click)="addZone(ZoneForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="zone.ZoneId"
            class="btn btn-type btn-save"
            [disabled]="
              !ZoneForm.form.valid ||
              !zone.Building.BuildingId ||
              !zone.Building.Venue.VenueId ||
              isReqComplete ||
              !isBuildingInVenue ||
              !('ZONE_EDIT' | canAccess)
            "
            (click)="updateZone(ZoneForm)"
          />
        </div>
      </div>
    </form>
  </div>
</section>
