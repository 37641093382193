import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { ZoneModule } from '../zone.module';
@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetZoneList = (body: string, page: number, size: number): Observable<ZoneModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/all/zone/' + page + '/' + size;
    return this.http.post<ZoneModule[]>(_url, body);
  };

  public GetSingle = (body: ZoneModule): Observable<ZoneModule> => {
    const _url: string = this.configuration.Server + 'workorder/zone/details';
    return this.http.post<ZoneModule>(_url, body);
  };

  public Add = (zone: ZoneModule, _isNearByZoneSelect: boolean): Observable<ResponseMessage> => {
    const toAdd = JSON.stringify({
      ZoneName: zone.ZoneName,
      building: { BuildingId: zone.Building.BuildingId },
      AddedBy: zone.AddedBy,
    });

    const _url: string = this.configuration.Server + 'workorder/zone';
    return this.http.post<ResponseMessage>(_url, toAdd);
  };

  public Update = (zone: ZoneModule, _isNearByZoneSelect: boolean): Observable<ResponseMessage> => {
    const toUpdate = JSON.stringify({
      ZoneId: zone.ZoneId,
      ZoneName: zone.ZoneName,
      building: { BuildingId: zone.Building.BuildingId },
      ModifiedBy: zone.ModifiedBy,
      IsActive: zone.IsActive,
    });

    const _url: string = this.configuration.Server + 'workorder/zone';
    return this.http.put<ResponseMessage>(_url, toUpdate);
  };
}
