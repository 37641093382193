import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { CountryComponent } from './components/country.component';
import { CountryRoutes } from './country.routes';

@NgModule({
  imports: [CommonModule, FormsModule, CountryRoutes, NgxPaginationModule, HomeModule],

  declarations: [CountryComponent],
})
export class CountryModule extends GenericEntity {
  public CountryId: number;
  public CountryCPId: string;
  public CountryName: string;
}
