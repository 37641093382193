import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';
import { Inventory } from '../../staffscheduling/inventory/inventory.module';
import { LocationModule } from '../../staffscheduling/location/location.module';
import { Task } from '../../staffscheduling/task/task.module';
import { User } from '../../usermanagement/user/user.model';
import { BuildingModule } from '../../venuemanagement/building/building.module';
import { InspectionModule } from '../inspectionlog/inspectionlog.module';
import { QCMaster } from '../qcworkorder/qcworkorder.module';
// import { WorkorderScheduleModule } from './workorderschedule.module';
import { AssetModule } from './asset.module';
// import { WorkorderRoutes } from './Workorder.routes';
import { WorkorderComponent } from './components/workorder.component';
import { WorkorderRoutes } from './workorder.routes';
import { WorkorderBeaconModule } from './workorderbeacon.module';
import { WorkorderDetailsModule } from './workorderdetails.module';
import { WorkorderTaskCommentModule } from './workordertaskcomment.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    WorkorderRoutes,
    FormsModule,

    NgxPaginationModule,
    HomeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SharedModule,
  ],

  declarations: [WorkorderComponent],
})
export class WorkorderModule extends GenericEntity {
  public WorkorderId: number;
  public WorkorderCPId: string;
  public LocationId: number;
  public LocationName: string;
  public Title: string;
  public Description: string;
  public WorkorderTypeId: number;
  public RequestLocation: string;
  public Longitude: number;
  public Latitude: number;
  public Level: number;
  public PinId: number;
  public AssetId: number;
  public AssetName: string;
  public ReportedById: number;
  public ReportedByEmail: string;
  public ReportedByContactNo: number;
  public ReportedBy: number;
  public ScheduleStartDate: any;
  public NotificationText: string;
  public CompletionTime: number;
  public ParentWorkorderId: number;
  public Asset: AssetModule;
  public WorkorderCategory: WorkorderCategoryModule;
  public WorkorderType: WorkorderTypeModule;
  public Building: BuildingModule;
  public WorkorderPriority: WorkorderPriority;
  public WorkorderSeverity: WorkorderSeverity;
  public WorkorderStatus: WorkorderStatus;
  public WorkorderTransaction: WorkorderTransaction;
  public AssignedTo: User;
  public Supervisor: User;
  public User: User;
  public CreateUser: User;
  public UpdatedUser: User;
  public Location: LocationModule;
  public Locations: LocationModule[];
  public WorkorderBeacons: WorkorderBeaconModule[];
  // public infaxRequest: WorkorderScheduleModule;
  public workorderTasks: Task[];
  public WorkorderInventory: Inventory[];
  public InitialSurvey: string;
  public WorkorderTaskComment: WorkorderTaskCommentModule;
  public IsReassign: number;
  public GenerateNewWorkOrder: number;
  public CleaningAlertCompletionDate: any;
  public InspectionId: number;
  public Inspection: InspectionModule;
  public WorkorderCompletionDate: any;

  public WorkorderComment: string;
  public IsCustodial: number;
}

export class Pin {
  public PinId: number;
  public PinColor: string;
}

export class WorkorderPriority {
  public PriorityId: number;
  public PriorityLevel: string;
}

export class WorkorderSeverity {
  public SeverityId: number;
  public SeverityLevel: string;
}

export class WorkorderStatus {
  public StatusId: number;
  public StatusCPId: string;
  public CPtype: string;
  public Status: string;
}

export class WorkorderTransaction {
  public AssingedTo: number;
  public Comments: string;
  public ClosureTemplate: string;
  public PriorityId: number;
  public SeverityId: number;
  public StatusId: number;
  public WorkorderId: number;
  public WorkorderTransactionId: number;
}

export class WorkorderCategoryModule {
  public WorkorderCategoryId: number;
  public WorkorderCategoryName: string;
  public Pin: Pin;
}

export class WorkorderTypeModule {
  public WorkorderTypeId: number;
  public WorkorderTypeName: string;
  public PM: number;
}

export enum WoStatus {
  Open = 1,
  Close = 2,
  QC_Pending = 3,
  Inprogress = 4,
  QC_Rejected = 5,
  QC_Approved = 6,
  No_Work_Completed = 7,
  Awaiting_QC = 8,
  No_QC = 9,
  QC_In_Progress = 10,
  Delay = 11,
  No_Agent_Available = 12,
  Awaiting_Supervisor = 13,
  Notify_Supervisor = 14,
  Return_To_Agent = 15,
  Awaiting_Delay = 16,
  Awaiting_Assignee = 17,
  Awaiting_Staff = 18,
  Assigned = 19,
  Declined = 20,
  Complete = 21,
  QC_Assigned = 22,
  Cancel = 23,
  Closed_Not_Complete = 24,
}

export class WorkorderAndQCLogAndHistoryDetails extends GenericEntity {
  public CleaningAlertDetails: WorkorderModule;
  public QCDetails: QCMaster;
  public WorkorderDetails: WorkorderDetailsModule;
}
