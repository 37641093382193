import { Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { Configuration } from 'src/app/app.constants';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { SubscriptionService } from 'src/app/features/usermanagement/subscription/components/subscription.http.service';
import { CityService } from 'src/app/features/venuemanagement/city/components/city.http.service';
import { CountryService } from 'src/app/features/venuemanagement/country/components/country.http.service';
import { StateService } from 'src/app/features/venuemanagement/state/components/state.http.service';
import { VenueService } from 'src/app/features/venuemanagement/venue/components/venue.http.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { LogoService } from 'src/app/shared/directives/logo.service';
import { ServiceProviderService } from '../../../serviceprovider/components/serviceprovider.http.service';
import { Module, ServiceProviderModule } from '../../../serviceprovider/serviceprovider.module';
import { SubscriptionModule } from '../../../usermanagement/subscription/subscription.module';
import { User } from '../../../usermanagement/user/user.model';
import { CityModule } from '../../../venuemanagement/city/city.module';
import { CountryModule } from '../../../venuemanagement/country/country.module';
import { StateModule } from '../../../venuemanagement/state/state.module';
import { ClientLogo } from '../../../venuemanagement/venue/venue.module';
import { CorporationIntegrationKey, CorporationModule, RelCorporationModuleFeature } from '../corporation.module';
import { CorporationService } from './corporation.http.service';
@Injectable({
  providedIn: 'root',
})
export class config extends TreeviewConfig {
  hasAllCheckBox = true;
  hasFilter = false;
  hasCollapseExpand = false;
  maxHeight = 400;
}

@Component({
  selector: 'corporation.component',
  templateUrl: 'corporation.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    CorporationService,
    UtilitiesService,
    CountryService,
    StateService,
    CityService,
    SubscriptionService,
    ServiceProviderService,
    VenueService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: config },
  ],
})
export class CorporationComponent implements OnInit {
  currentUser: User;
  @ViewChild('fileInput') fileInputVariable: any;
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  public Corporations: CorporationModule[] = [];
  public Corporation: CorporationModule = new CorporationModule();

  public countries: CountryModule[] = [];
  public states: StateModule[] = [];
  public cities: CityModule[] = [];

  public subscriptions: SubscriptionModule[] = [];
  public serviceProviders: ServiceProviderModule[] = [];
  public Modules: Module[] = [];
  public clientLogo: ClientLogo = new ClientLogo();

  public page = 1;
  public size = 10;
  public count = 0;
  public searchString = '';
  public pageSizes: any[] = [];

  public isReqComplete = false;
  public searchTerm: string;

  public listviewtypes: any[];
  public viewType: any = null;

  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;

  public showHidePassword = false;
  public stateDisabled = true;
  public cityDisabled = true;
  public pageState: StateMaintain = {
    pageName: 'corporationForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  public items: TreeviewItem[];
  public rows: string[];
  public config = {};

  public isAllFeatureConfigurationChecked = false;
  public isCheckBoxVisible = false;

  file: File = null;
  corporationDefaultLogo = 'assets/uploadImage.png';
  corporationLogoSrc: any = null;
  public isSysAdmin = false;
  public IsInspectionScoringBenchmarkShow = false;

  public keyRowId: number;
  public integrationKeyIndex = -1;
  public isPublisherKeySectionShow = false;

  constructor(
    private authState: AuthState,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private corporationService: CorporationService,
    private serviceProviderService: ServiceProviderService,
    private configuration: Configuration,
    private logoService: LogoService,
    private venueService: VenueService,
    private subscriptionService: SubscriptionService,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.isSysAdmin = this.currentUser.UserApplicationLevel == 'ADMIN';

    this.Corporation = new CorporationModule();
    this.Corporation.Country = new CountryModule();
    this.Corporation.State = new StateModule();
    this.Corporation.City = new CityModule();
    this.Corporation.Subscription = new SubscriptionModule();
    this.Corporation.ServiceProvider = new ServiceProviderModule();
    this.Corporation.RelCorporationModuleFeature = new RelCorporationModuleFeature();

    this.config = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500,
    };
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;

    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;

    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllCorporations(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllCorporations(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.corporationLogoSrc = null;
    this.file = null;

    this.searchString = '';
    this.isCheckBoxVisible = false;
    this.Corporations = null;
    this.count = 0;

    this.Corporation = new CorporationModule();
    this.Corporation.Country = new CountryModule();
    this.Corporation.State = new StateModule();
    this.Corporation.City = new CityModule();
    this.Corporation.Subscription = new SubscriptionModule();
    this.Corporation.ServiceProvider = new ServiceProviderModule();
    this.Corporation.RelCorporationModuleFeature = new RelCorporationModuleFeature();
    const module: Module[] = [];
    this.Corporation.CorporationIntegrationKey = [];
    this.Corporation.RelCorporationModuleFeature.Modules = module;

    this.getAllcountries('', -1, -1);
    this.getAllSubscriptions('', -1, -1);
    this.GetServiceProvidersDropdown('', -1, -1);

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      const element = document.getElementById('corporationName');
      element.focus();
    }, 1000);
    this.GetModuleList('', -1, -1, this.Corporation.ServiceProvider.ServiceProviderId);
  }

  public setUpdateForm() {
    this.count = 0;
    this.searchString = '';
    this.Corporations = null;

    this.getAllcountries('', -1, -1);
    this.GetServiceProvidersDropdown('', -1, -1);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  public clearFormData(CorporationForm: NgForm) {
    this.corporationLogoSrc = null;
    CorporationForm.reset();
    this.searchString = '';
    this.showHidePassword = false;
    this.isCheckBoxVisible = false;
    this.IsInspectionScoringBenchmarkShow = false;
    this.items = [];
    this.Modules = [];

    this.Corporation = new CorporationModule();
    this.Corporation.Country = new CountryModule();
    this.Corporation.State = new StateModule();
    this.Corporation.City = new CityModule();
    this.Corporation.Subscription = new SubscriptionModule();
    this.Corporation.ServiceProvider = new ServiceProviderModule();
    this.Corporation.RelCorporationModuleFeature = new RelCorporationModuleFeature();

    this.getAllCorporations(this.searchString, this.page, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.Corporations !== null && this.Corporations.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCorporations(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public CorporationPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCorporations(this.searchString, this.page, this.size, this.viewType);
  }

  public showHide(event: any) {
    if (event === 'Password') {
      this.showHidePassword = !this.showHidePassword;
    }
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCorporations(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllCorporations(this.searchString, this.page, this.size, this.viewType);
  }

  public getAllCorporations(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.corporationService.GetCorporationList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.Corporations = data;
        if (this.Corporations && this.Corporations.length > 0) {
          const newLocal =
            this.Corporations.length < 0 &&
            this.Corporations[0].Count / this.utilitiesService.initialPageSize < this.page;
          if (newLocal) {
            this.page = 1;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'corporationService getAllCorporations Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.Corporations[0] ? this.Corporations[0].Count : 0),
    );
  }

  public getCorporationById(CorporationId: number) {
    if (this.authState.canAccess('CORPORATION_VIEW')) {
      this.loaderService.display(true);
      this.isCheckBoxVisible = false;
      const CPcorporation = new CorporationModule();
      CPcorporation.CorporationCPId = this.authState.AESEncryptText(CorporationId);

      this.corporationService.GetSingle(CPcorporation).subscribe(
        (data) => {
          this.Corporation = data;
          this.loaderService.display(false);
          if (this.Corporation != null) {
            if (this.Corporation.AttachmentURL != null) {
              this.corporationLogoSrc = this.Corporation.AttachmentURL;
            } else {
              this.corporationDefaultLogo = 'assets/uploadImage.png';
            }

            if (this.Corporation.Subscription != null && this.Corporation.Subscription.SubscriptionId != null) {
              this.getAllSubscriptions('', -1, -1);
            }

            if (this.Corporation.Country != null && this.Corporation.Country.CountryId != null) {
              this.getAllstatesByCountry(this.Corporation.Country.CountryId);
            } else {
              this.getAllcountries('', -1, -1);
            }

            if (this.Corporation.State != null && this.Corporation.State.StateId != null) {
              this.getAllcitiesByState(this.Corporation.State.StateId);
            }

            if (
              this.Corporation.RelCorporationModuleFeature != null &&
              this.Corporation.RelCorporationModuleFeature.Modules != null &&
              this.Corporation.RelCorporationModuleFeature.Modules.length > 0
            ) {
              this.isCheckBoxVisible = true;
              let that = this;
              this.items = [];

              this.Corporation.RelCorporationModuleFeature.Modules.forEach(function (item) {
                let childrens = [];
                item.IsActive = item.IsActive ? true : false;

                if (item.ModuleCode == 'INSPECT' && item.IsActive == true) {
                  that.IsInspectionScoringBenchmarkShow = true;
                }
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    value: item.ModuleId,
                    checked: item.IsActive,
                    children: childrens,
                  }),
                );
              });

              if (this.items && this.items.length > 0) {
                let itemObj = this.items.filter((tree) => tree.checked == false || tree.checked == undefined);
                if (itemObj && itemObj.length > 0) {
                  this.isAllFeatureConfigurationChecked = false;
                } else {
                  this.isAllFeatureConfigurationChecked = true;
                }
              }
            }

            if (
              this.Corporation.CorporationIntegrationKey != null &&
              this.Corporation.CorporationIntegrationKey.length > 0
            ) {
              this.isPublisherKeySectionShow = true;
              for (let key = 0; key < this.Corporation.CorporationIntegrationKey.length; key++) {
                this.Corporation.CorporationIntegrationKey[key].IntegrationKeyRowId = key + 1;
                if (this.Corporation.CorporationIntegrationKey[key].IsDelete == null) {
                  this.Corporation.CorporationIntegrationKey[key].IsDelete = 0;
                }
                if (this.authState.canAccess('CORPORATION_EDIT_INTEGRATION_KEY')) {
                  this.Corporation.CorporationIntegrationKey[key].isAllowToEdit = false;
                } else {
                  this.Corporation.CorporationIntegrationKey[key].isAllowToEdit = true;
                }
              }
            } else {
              this.isPublisherKeySectionShow = false;
            }
          }
        },
        (error) => {
          console.log(
            'corporationService GetCorporationById Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Corporation', 'You do not have permission to view corporation details.');
    }
  }

  public addCorporation(corporationForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (corporationForm.status == 'INVALID' && corporationForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Corporation',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.Corporation.CorporationName = this.utilitiesService.removeInnerSpaces(this.Corporation.CorporationName);
    if (this.Corporation.CorporationName === null || this.Corporation.CorporationName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.Corporation.Email = this.Corporation.Email;
    this.Corporation.Password = this.Corporation.Password;

    this.Corporation.MobileNumber = this.Corporation.MobileNumber;
    this.Corporation.ZipCode = this.Corporation.ZipCode;

    this.Corporation.Country.CountryId = this.Corporation.Country.CountryId;
    this.Corporation.State.StateId = this.Corporation.State.StateId;
    this.Corporation.City.CityId = this.Corporation.City.CityId;
    this.Corporation.AddedBy = this.currentUser.UserId;
    this.Corporation.IsVenue = this.Corporation.IsVenue ? 1 : 0;
    this.Corporation.Subscription.SubscriptionId = this.Corporation.Subscription.SubscriptionId;
    // this.Corporation.SalesForceNumber = this.Corporation.SalesForceNumber;
    // this.Corporation.LicenseUserCount = this.Corporation.LicenseUserCount;

    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let that = this;
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked == true) {
          let Mod = new Module();
          Mod.ModuleId = it.value;
          Mod.ModuleName = it.text;
          Mod.IsActive = 1;
          that.Corporation.RelCorporationModuleFeature.Modules.push(Mod);
        }
      });
    } else {
      this.Corporation.RelCorporationModuleFeature.Modules = [];
    }

    if (this.Corporation.CorporationIntegrationKey != null && this.Corporation.CorporationIntegrationKey.length > 0) {
      for (let k = 0; k < this.Corporation.CorporationIntegrationKey.length; k++) {
        this.Corporation.CorporationIntegrationKey[k].IsActive = this.Corporation.CorporationIntegrationKey[k].IsActive
          ? 1
          : 0;
        this.Corporation.CorporationIntegrationKey[k].isAllowToEdit = this.Corporation.CorporationIntegrationKey[k]
          .isAllowToEdit
          ? 1
          : 0;
      }
    }

    this.corporationService.Add(this.utilitiesService.stripScript(this.Corporation)).subscribe(
      (data) => {
        if (this.file) {
          let that = this;
          this.corporationService
            .AddCoporationLogo(this.file, data.id)
            .then(function (_response) {
              that.restFun(corporationForm, data.Message);
            })
            .catch((err) => {
              console.log(err);
              this.restFun(corporationForm, data.Message);
            });
        } else {
          this.restFun(corporationForm, data.Message);
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'corporationService addCorporation Call Failed. Status:' + error.status + 'Status Text' + error.statusText,
        );
      },
    );
  }

  public restFun(corporationForm: any, msg: any) {
    this.page = 1;
    this.loaderService.display(false);
    this.isReqComplete = false;
    this.utilitiesService.smmodal('Corporation', msg);
    this.clearFormData(corporationForm);
  }

  public updateCorporation(CorporationForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (CorporationForm.status == 'INVALID' && CorporationForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Corporation',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.Corporation.CorporationName = this.utilitiesService.removeInnerSpaces(this.Corporation.CorporationName);
    if (this.Corporation.CorporationName === null || this.Corporation.CorporationName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.Corporation.FirstName = this.Corporation.FirstName;
    this.Corporation.LastName = this.Corporation.LastName;

    this.Corporation.Email = this.Corporation.Email;
    this.Corporation.Password = this.Corporation.Password;

    this.Corporation.MobileNumber = this.Corporation.MobileNumber;
    this.Corporation.ZipCode = this.Corporation.ZipCode;

    this.Corporation.Country.CountryId = this.Corporation.Country.CountryId;
    this.Corporation.State.StateId = this.Corporation.State.StateId;
    this.Corporation.City.CityId = this.Corporation.City.CityId;

    this.Corporation.ModifiedBy = this.currentUser.UserId;
    this.Corporation.IsActive = this.Corporation.IsActive ? 1 : 0;
    this.Corporation.IsVenue = this.Corporation.IsVenue ? 1 : 0;
    this.Corporation.Subscription.SubscriptionId = this.Corporation.Subscription.SubscriptionId;
    // this.Corporation.SalesForceNumber = this.Corporation.SalesForceNumber;
    // this.Corporation.LicenseUserCount = this.Corporation.LicenseUserCount;

    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let that = this;
      this.Corporation.RelCorporationModuleFeature.Modules = [];
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked == true) {
          let Mod = new Module();
          Mod.ModuleId = it.value;
          Mod.ModuleName = it.text;
          Mod.IsActive = 1;
          that.Corporation.RelCorporationModuleFeature.Modules.push(Mod);
        }
      });
    } else {
      this.Corporation.RelCorporationModuleFeature.Modules = [];
    }

    if (this.file) {
      this.corporationService.AddCoporationLogo(this.file, this.Corporation.CorporationId);
    } else if (this.corporationLogoSrc == null) {
      this.corporationService.RemoveCoporationLogo(this.Corporation.CorporationId).subscribe((_res) => {
        // console.log(res);
      });
    }

    if (this.Corporation.CorporationIntegrationKey != null && this.Corporation.CorporationIntegrationKey.length > 0) {
      for (let k = 0; k < this.Corporation.CorporationIntegrationKey.length; k++) {
        this.Corporation.CorporationIntegrationKey[k].IsActive = this.Corporation.CorporationIntegrationKey[k].IsActive
          ? 1
          : 0;
        this.Corporation.CorporationIntegrationKey[k].isAllowToEdit = this.Corporation.CorporationIntegrationKey[k]
          .isAllowToEdit
          ? 1
          : 0;
      }
    }

    this.corporationService.Update(this.utilitiesService.stripScript(this.Corporation)).subscribe(
      (data) => {
        const storedCorporation = this.authState.getStoredContextCorporationId();
        if (storedCorporation == this.Corporation.CorporationId) {
          setTimeout(() => {
            this.getMainLogo();
          }, 3000);
        }
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Corporation', data.Message);
        this.clearFormData(CorporationForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'corporationService updateCorporation Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public getAllcountries(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.countryService.GetCountryDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.countries = data;

        if (this.countries && this.countries.length > 0 && !this.Corporation.CorporationId) {
          const obj = this.countries.filter(
            (c) => c.CountryName === 'USA' || c.CountryName == 'United States Of America',
          );
          if (obj[0]) {
            this.Corporation.Country.CountryId = obj[0].CountryId;
            this.getAllstatesByCountry(this.Corporation.Country.CountryId);
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'countryService GetCountryDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public countryChange($event: any) {
    this.Corporation.Country.CountryId = $event;
    if (this.Corporation.Country.CountryId) {
      this.states = [];
      this.Corporation.State.StateId = null;
      this.getAllstatesByCountry(this.Corporation.Country.CountryId);

      this.cities = [];
      this.cityDisabled = true;
      this.Corporation.City.CityId = null;
    } else {
      this.states = [];
      this.stateDisabled = true;
      this.Corporation.State.StateId = null;

      this.cities = [];
      this.cityDisabled = true;
      this.Corporation.City.CityId = null;
    }
  }

  public getAllstatesByCountry(countryId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      country: { countryId },
    };

    if (countryId) {
      this.stateService.GetStateDropdown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.states = data;
          this.stateDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'stateService GetStateDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    } else {
      this.states = [];
      this.stateDisabled = true;
    }
  }

  public stateChange($event: any) {
    this.Corporation.State.StateId = $event;
    if (this.Corporation.State.StateId) {
      this.cities = [];
      this.Corporation.City.CityId = null;
      this.getAllcitiesByState(this.Corporation.State.StateId);
    } else {
      this.cities = [];
      this.cityDisabled = true;
      this.Corporation.City.CityId = null;
    }
  }

  public getAllcitiesByState(stateId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      state: { stateId },
    };

    if (stateId) {
      this.cityService.GetCitiesDropDown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.cities = data;
          this.cityDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'cityService GetCitiesDropDown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    } else {
      this.cities = [];
      this.cityDisabled = true;
    }
  }

  public corporationSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCorporations(searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllCorporations('', this.page, this.size, this.viewType);
  }

  public getAllSubscriptions(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 1 };

    this.subscriptionService.GetSubscriptionDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.subscriptions = data;
      },
      (error) => {
        console.log(
          'SubscriptionService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public GetServiceProvidersDropdown(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.serviceProviderService
      .GetServiceProviderListForDropdown(JSON.stringify(searchfilter), pageIndex, pageSize)
      .subscribe(
        (data) => {
          this.serviceProviders = data;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'countryService GetCountryDropdown Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
  }

  public changeServiceProvider($event: any) {
    this.Corporation.ServiceProvider.ServiceProviderId = $event;
    if (this.Corporation.ServiceProvider.ServiceProviderId) {
      this.Modules = [];
      this.items = [];
      this.GetModuleList('', -1, -1, this.Corporation.ServiceProvider.ServiceProviderId);
    } else {
      this.Modules = [];
      this.items = [];
      this.GetModuleList('', -1, -1, this.Corporation.ServiceProvider.ServiceProviderId);
    }
  }

  public GetModuleList(searchTerm: string, pageIndex: number, pageSize: number, ServiceProviderId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      CorporationId: this.Corporation.CorporationId,
      ServiceProvider: { ServiceProviderId },
    };

    this.corporationService.GetModueleList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.isCheckBoxVisible = true;
          let that = this;
          this.Modules = [];
          this.Modules = data;

          if (this.Modules != null && this.Modules.length > 0) {
            this.items = [];

            this.Modules.forEach(function (item) {
              let childrens = [];
              that.items.push(
                new TreeviewItem({
                  text: item.ModuleName,
                  value: item.ModuleId,
                  checked: false,
                  children: childrens,
                }),
              );
            });
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'corporationService GetModueleList Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public onSelectedChange(_downlineItems: DownlineTreeviewItem[]) {
    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let item = this.treeviewComponent.items.filter((tree) => tree.checked == false || tree.checked == undefined);
      if (item && item.length > 0) {
        let that = this;
        item.forEach(function (it) {
          if (it.text == 'Inspect' && it.checked == true) {
            that.IsInspectionScoringBenchmarkShow = true;
          } else if (it.text == 'Inspect' && (it.checked == false || it.checked == undefined)) {
            that.Corporation.InspectionScoringBenchmark = null;
            that.IsInspectionScoringBenchmarkShow = false;
          }
        });
        this.isAllFeatureConfigurationChecked = false;
      } else {
        this.isAllFeatureConfigurationChecked = true;
      }
    }

    if (_downlineItems != null && _downlineItems.length > 0) {
      for (let j = 0; j < _downlineItems.length; j++) {
        if (_downlineItems[j].item.text == 'Inspect' && _downlineItems[j].item.checked == true) {
          this.IsInspectionScoringBenchmarkShow = true;
        } else if (
          _downlineItems[j].item.text == 'Inspect' &&
          (_downlineItems[j].item.checked == false || _downlineItems[j].item.checked == undefined)
        ) {
          this.IsInspectionScoringBenchmarkShow = false;
        }
      }
    }
  }

  public onAllCheckedChange(event: any) {
    let that = this;
    this.treeviewComponent.items.forEach(function (it) {
      it.checked = event.checked;

      if (it.text == 'Inspect' && it.checked == true) {
        that.IsInspectionScoringBenchmarkShow = true;
      } else if (it.text == 'Inspect' && (it.checked == false || it.checked == undefined)) {
        that.IsInspectionScoringBenchmarkShow = false;
      }
      that.changeChildrenSelection(it, event.checked);
    });
  }

  public changeChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files[0]) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Corporation', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const extention = event.target.files[0].name.split('.');
      const ext = extention[extention.length - 1].toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg') {
        if (event.target.files && event.target.files[0]) {
          const filesize = event.target.files[0].size / Math.pow(1024, 2);
          if (filesize > this.utilitiesService.fileUploadSize) {
            this.utilitiesService.mdmodal(
              'Corporation',
              'Picture should not be more than ' + this.utilitiesService.fileUploadSize + ' MB.',
            );
            event.target.value = '';
            this.loaderService.display(false);
            return;
          } else {
            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.file = fileList[0];
              const reader = new FileReader();
              reader.onload = this._handleReaderLoaded.bind(this);
              reader.readAsBinaryString(this.file);
            }
          }
        }
      } else {
        this.utilitiesService.smmodal('Corporation', 'Please upload image of type .jpg, .jpeg, .png, .svg.');
        event.target.value = '';
      }
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.corporationLogoSrc = 'data:image/jpeg;base64,' + btoa(binaryString); // Converting binary string data.
  }

  fileReset() {
    this.fileInputVariable.nativeElement.value = '';
    this.file = null;
    this.corporationLogoSrc = null;
  }

  public getMainLogo() {
    this.venueService.GetClientLogo().subscribe(
      (data) => {
        this.clientLogo = data;
        this.authState.storeLogo(this.clientLogo);
        this.logoService.changeLogo(this.clientLogo);
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'venueService GetClientLogo Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public GenerateIntegrationKey() {
    this.loaderService.display(true);
    this.corporationService.GetCorporationIntegrationKey().subscribe(
      (data) => {
        if (data != null) {
          this.addIntegrtionKey(data);
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'corporationService GetCorporationIntegrationKey Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public addIntegrtionKey(integrationKeyValue: any) {
    this.isPublisherKeySectionShow = true;
    this.keyRowId = this.Corporation.CorporationIntegrationKey.length;
    this.keyRowId = this.keyRowId + 1;

    const newIntegrationKey = new CorporationIntegrationKey();
    if (integrationKeyValue != null || integrationKeyValue != '') {
      newIntegrationKey.IntegrationKeyValue = integrationKeyValue;
    } else {
      newIntegrationKey.IntegrationKeyValue = '';
    }

    newIntegrationKey.IntegrationKeyRowId = this.keyRowId;
    newIntegrationKey.IntegrationKeyId = null;
    newIntegrationKey.IntegrationKeyName = '';
    newIntegrationKey.IsActive = 1;
    newIntegrationKey.IsDelete = 0; // IsDelete = 0 means active record
    newIntegrationKey.isAllowToEdit = false;
    this.Corporation.CorporationIntegrationKey.push(newIntegrationKey);
  }

  public removeIntegrationKey(keyRowId: number) {
    for (let i = 0; i < this.Corporation.CorporationIntegrationKey.length; i++) {
      if (this.Corporation.CorporationIntegrationKey[i].IntegrationKeyRowId === keyRowId) {
        if (this.Corporation.CorporationIntegrationKey[i].IntegrationKeyId == null) {
          this.integrationKeyIndex = i;
          this.Corporation.CorporationIntegrationKey.splice(this.integrationKeyIndex, 1);
          i = i - 1;
        } else {
          this.Corporation.CorporationIntegrationKey[i].IsDelete = 1;
        }
      }
    }

    let keyObj = this.Corporation.CorporationIntegrationKey.filter((k) => k.IsDelete === 0);
    if (keyObj != null && keyObj.length === 0) {
      this.isPublisherKeySectionShow = false;
    }
  }
}
