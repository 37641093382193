<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-6 col-sm-12 form-heading">
        <i class="fa fa-podcast heading-icon"></i>
        <span class="list-heading" *ngIf="sensortypes">SENSOR TYPES</span>
        <span class="list-heading" *ngIf="!sensortypes && !sensortype.SensorTypeId">ADD SENSOR TYPE</span>
        <span class="list-heading" *ngIf="!sensortypes && sensortype.SensorTypeId">EDIT SENSOR TYPE</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-3 col-md-4 col-xs-12 pull-right list-heading" *ngIf="sensortypes">
          <ngx-atlas-select
            (model)="SensorTypesPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="sensortypes && !sensortype.SensorTypeId"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Sensor Type
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="sensortypes">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="sensortypes">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="sensorTypesSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="row" *ngIf="sensortypes">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="list-view-error">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th>Sensor Image</th>
                <th class="table-sort-header" (click)="sortColumn('SensorTypeName', columnSortOrder)">
                  Sensor Type
                  <span *ngIf="selectedColumnName == 'SensorTypeName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'SensorTypeName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let sensortype of sensortypes
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr>
                <td *ngIf="sensortype.SensorTypeImageName" class="width-20">
                  <img
                    src="{{ sensorTypeImgPath + sensortype.SensorTypeImageName }}"
                    class="img-responsive sensor-image"
                    alt="{{ sensortype.SensorTypeName }}"
                    data-placement="top"
                    title="{{ sensortype.SensorTypeName }}"
                    data-toggle="modal"
                    data-target="#imageModal"
                    (click)="openSensorImage(sensortype.SensorTypeImageName)"
                  />
                </td>
                <td *ngIf="!sensortype.SensorTypeImageName" class="width-20">-</td>
                <td class="verticalalign" (click)="getSensorTypeById(sensortype.SensorTypeId)">
                  {{ sensortype.SensorTypeName }}
                </td>
                <td
                  *ngIf="sensortype.IsActive"
                  class="verticalalign"
                  (click)="getSensorTypeById(sensortype.SensorTypeId)"
                >
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!sensortype.IsActive" class="verticalalign">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!sensortypes">
    <form #sensortypeForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-3 col-md-3 col-sm-3">
          <div>
            <div class="form-group">
              <div class="imgBox">
                <button
                  class="btn-primary btn btn-xs button-text"
                  *ngIf="sensorTypeImgSrc"
                  id="templateReset"
                  type="button"
                  (click)="fileReset()"
                >
                  <i class="fa fa-times"></i> Remove
                </button>
                <label class="custom-file">
                  <input
                    type="file"
                    #fileInput
                    id="file"
                    name="SensorTypeimage"
                    class="custom-file-input"
                    (change)="fileChange($event)"
                    style="display: none"
                    accept="image/*"
                  />
                  <span class="custom-file-control"></span>
                  <img
                    id="preview"
                    [src]="sensorTypeImgSrc != null ? sensorTypeImgSrc : sensorTypeImg"
                    class="sensortype-profile"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-9 col-md-9 col-sm-9">
          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="SensorTypeName"><span id="red-circle">&#x25CF;</span> Sensor Type Name</label>
            <input
              id="SensorTypeName"
              type="text"
              name="typename"
              class="form-control"
              [(ngModel)]="sensortype.SensorTypeName"
              required
              maxlength="120"
              [pattern]="utilitiesService.alphanumericRegex"
              #SensorTypeName="ngModel"
              placeholder="Sensor Type Name"
              autocomplete="off"
            />
            <div
              *ngIf="SensorTypeName.errors && (SensorTypeName.dirty || SensorTypeName.touched)"
              class="alert alert-danger"
            >
              <div [hidden]="!SensorTypeName.errors.required" class="text-danger">Sensor type name is required.</div>
              <div [hidden]="!SensorTypeName.errors.pattern" class="text-danger">
                {{ utilitiesService.alphanumericRegexMessage }}
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group" *ngIf="sensortype.SensorTypeId">
              <mat-checkbox [(ngModel)]="sensortype.IsActive" name="isActive">Is Active</mat-checkbox>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 btn-section">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type pull-right btn-cancel"
              (click)="clearFormData(sensortypeForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!sensortype.SensorTypeId"
              class="btn btn-type btn-save"
              [disabled]="!sensortypeForm.form.valid || isReqComplete"
              (click)="addSensorType(sensortypeForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="sensortype.SensorTypeId"
              class="btn btn-type btn-save"
              [disabled]="!sensortypeForm.form.valid || isReqComplete"
              (click)="updateSensorType(sensortypeForm)"
            />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="imageModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black; margin-top: -10px">
            &times;
          </button>
          <img class="img-responsive" src="{{ sensorImg }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>
</section>
