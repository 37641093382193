import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration } from 'src/app/app.constants';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { BuildingModule } from '../building.module';
@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  // create Terminal
  public Add = (building: BuildingModule): Observable<ResponseMessage> => {
    const toAdd = JSON.stringify({
      buildingName: building.BuildingName,
      venue: { VenueId: building.Venue.VenueId },
      buildingDisplayName: building.BuildingDisplayName,
      buildingMinTime: building.BuildingMinTime,
      buildingMaxTime: building.BuildingMaxTime,
      buildingAvgTime: building.BuildingAvgTime,
      buildingMedianTime: building.BuildingMedianTime,
      buildingAvgTimeperHour: building.BuildingAvgTimeperHour,
      buildingAvgCount: building.BuildingAvgCount,
      buildingRefNumber: building.BuildingRefNumber,
      buildingStartTime: building.BuildingStartTime,
      buildingEndTime: building.BuildingEndTime,
      buildingClosedTime: building.BuildingClosedTime,
      buildingRefNo: building.BuildingRefNo,
      AddedBy: building.AddedBy,
      // TermMinTime: terminal.TermMinTime,
      // TermMaxTime: terminal.TermMaxTime,
      // TermAvgTime: terminal.TermAvgTime,
      // TermMedianTime: terminal.TermMedianTime,
      // TermAvgTimeperHour: terminal.TermAvgTimeperHour,
      // TermAvgCount: terminal.TermAvgCount,
      // TermStartTime: terminal.TermStartTime,
      // TermEndTime: terminal.TermEndTime,
      // TermClosedTime: terminal.TermClosedTime,
      // TermRefNumber: terminal.TermRefNumber,
    });
    const _url: string = this.configuration.Server + 'venue/building';
    return this.http.post<ResponseMessage>(_url, toAdd);
  };

  // Update Terminal
  public Update = (building: BuildingModule): Observable<ResponseMessage> => {
    const toUpdate = JSON.stringify({
      ModifiedBy: building.ModifiedBy,
      venue: { VenueId: building.Venue.VenueId },
      buildingId: building.BuildingId,
      buildingName: building.BuildingName,
      buildingDisplayName: building.BuildingDisplayName,
      buildingMinTime: building.BuildingMinTime,
      buildingMaxTime: building.BuildingMaxTime,
      buildingAvgTime: building.BuildingAvgTime,
      buildingMedianTime: building.BuildingMedianTime,
      buildingAvgTimeperHour: building.BuildingAvgTimeperHour,
      buildingAvgCount: building.BuildingAvgCount,
      buildingRefNumber: building.BuildingRefNumber,
      buildingStartTime: building.BuildingStartTime,
      buildingEndTime: building.BuildingEndTime,
      buildingClosedTime: building.BuildingClosedTime,
      buildingRefNo: building.BuildingRefNo,
      IsActive: building.IsActive,
      // TermMinTime: terminal.TermMinTime,
      // TermMaxTime: terminal.TermMaxTime,
      // TermAvgTime: terminal.TermAvgTime,
      // TermMedianTime: terminal.TermMedianTime,
      // TermAvgTimeperHour: terminal.TermAvgTimeperHour,
      // TermAvgCount: terminal.TermAvgCount,
      // TermRefNumber: terminal.TermRefNumber,
      // TermStartTime: terminal.TermStartTime,
      // TermEndTime: terminal.TermEndTime,
      // TermClosedTime: terminal.TermClosedTime,
      // AirportName: terminal.airport.AirportName,
    });
    const _url: string = this.configuration.Server + 'venue/building';
    return this.http.put<ResponseMessage>(_url, toUpdate);
  };

  // Get list of Terminal
  GetBuildingList = (body: string, page: number, size: number): Observable<BuildingModule[]> => {
    const _url: string = this.configuration.Server + 'venue/all/building/' + page + '/' + size;
    return this.http.post<BuildingModule[]>(_url, body);
  };

  // Get Terminal by id
  public GetSingle = (body: BuildingModule): Observable<BuildingModule> => {
    const _url: string = this.configuration.Server + 'venue/building/details';
    return this.http.post<BuildingModule>(_url, body);
  };
}
