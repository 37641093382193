import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
// import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';

import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { GatewayModule } from '../getway.module';

@Injectable({
  providedIn: 'root',
})
export class GatewayService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetGatewayList = (body: string, page: number, size: number): Observable<GatewayModule[]> => {
    const _url: string = this.configuration.Server + 'badge/all/gateway/' + page + '/' + size;
    return this.http.post<GatewayModule[]>(_url, body);
  };

  public GetSingle = (body: GatewayModule): Observable<GatewayModule> => {
    const _url: string = this.configuration.Server + 'badge/gateway/details';
    return this.http.post<GatewayModule>(_url, body);
  };

  public Add = (body: GatewayModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'badge/gateway';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: GatewayModule): Observable<GatewayModule> => {
    const _url: string = this.configuration.Server + 'badge/gateway';
    return this.http.put<GatewayModule>(_url, body);
  };
}
