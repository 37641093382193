import { createSelector } from '@ngrx/store';
import { RootState } from '../root-state';

// This file defines selectors to access the state data.
// selectCurrentUser is a function that takes the root state and returns the userData state.
// selectUserData uses createSelector to get the user property from the userData state.

export const selectCurrentUser = (state: RootState) => state.userData;

export const selectUserData = createSelector(selectCurrentUser, (state) => state.user);

export const selectUserPermissions = createSelector(selectCurrentUser, (state) => state.permissions);
