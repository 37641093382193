import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { LevelModule } from '../../level/level.module';
import { VenueService } from '../../venue/components/venue.http.service';
import { VenueModule } from '../../venue/venue.module';
import { BuildingModule } from '../building.module';
import { BuildingService } from './building.http.service';

@Component({
  selector: 'building.component',
  templateUrl: 'building.component.html',
  providers: [BuildingService, UtilitiesService, VenueService],
})
export class BuildingComponent implements OnInit {
  currentUser: User;

  public Buildings: BuildingModule[] = [];
  public building: BuildingModule = new BuildingModule();

  public venues: VenueModule[] = [];
  // public venue: VenueModule = new VenueModule();

  page = 1;
  size = 10;
  count = 0;
  searchString = '';
  venueBind: number;
  pageSizes: any[] = [];

  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  public pageState: StateMaintain = {
    pageName: 'buildingForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private buildingService: BuildingService,
    private loaderService: LoaderService,
    private venueService: VenueService,
  ) {
    this.currentUser = this.authState.getCurrentUser();

    this.building.Venue = new VenueModule();
    this.building.Level = new LevelModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    this.getAllVenues('', -1, -1);
    this.venueBind = null;
    this.venueBind = this.authState.getStoredContextVenueId();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
    }
  }

  /// this funcation used to get result as per page size change
  public BuildingPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.Buildings = null;
    this.count = 0;
  }

  public setAddForm() {
    this.searchString = '';
    this.Buildings = null;
    this.count = 0;
    this.building = new BuildingModule();
    this.building.Venue = new VenueModule();
    this.building.Level = new LevelModule();

    this.getAllVenues('', -1, -1);
    setTimeout(() => {
      const element = document.getElementById('buildingName');
      element.focus();
    }, 1000);
  }

  public hasData(): boolean {
    return this.Buildings != null && this.Buildings.length > 0;
  }

  public clearFormData(BuildingForm: NgForm) {
    BuildingForm.reset();
    this.searchString = '';
    this.building = new BuildingModule();
    this.building.Venue = new VenueModule();
    this.building.Level = new LevelModule();
    this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
  }

  public buildingsSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBuildings(searchString, 1, this.size, this.viewType);
  }

  // Get List Of Building
  public getAllBuildings(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.buildingService.GetBuildingList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.Buildings = data;
        if (this.Buildings.length < 0 && this.Buildings[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'buildingService getAllBuildings Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.Buildings[0] ? this.Buildings[0].Count : 0),
    );
  }

  // Get Building By Id
  public getBuildingById(buildingId: number) {
    if (this.authState.canAccess('BUILDING_VIEW')) {
      this.loaderService.display(true);
      const CPbuilding = new BuildingModule();
      CPbuilding.BuildingCPId = this.authState.AESEncryptText(buildingId);

      this.buildingService.GetSingle(CPbuilding).subscribe(
        (data) => {
          this.building = data;
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'buildingService GetBuildingById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Building', 'You do not have permission to view building details.');
    }
  }

  // Create Building
  public addBuilding(BuildingForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (BuildingForm.status == 'INVALID' && BuildingForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Building', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.building.BuildingName = this.utilitiesService.removeInnerSpaces(this.building.BuildingName);
    if (this.building.BuildingName == null || this.building.BuildingName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.building.BuildingDisplayName = this.utilitiesService.removeInnerSpaces(this.building.BuildingDisplayName);
    if (this.building.BuildingDisplayName == null || this.building.BuildingDisplayName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }
    this.building.AddedBy = this.currentUser.UserId;

    this.buildingService.Add(this.utilitiesService.stripScript(this.building)).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Building', data.Message);
        this.clearFormData(BuildingForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'buildingService addBuilding Call Failed. Status:' + error.status + 'Status Text' + error.statusText,
        );
      },
    );
  }

  // Update Building
  public updateBuilding(BuildingForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (BuildingForm.status == 'INVALID' && BuildingForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Building', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.building.ModifiedBy = this.currentUser.UserId;
    this.building.BuildingName = this.utilitiesService.removeInnerSpaces(this.building.BuildingName);
    if (this.building.BuildingName == null || this.building.BuildingName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.building.BuildingDisplayName = this.utilitiesService.removeInnerSpaces(this.building.BuildingDisplayName);
    if (this.building.BuildingDisplayName == null || this.building.BuildingDisplayName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }
    this.building.IsActive = this.building.IsActive ? 1 : 0;

    this.buildingService.Update(this.utilitiesService.stripScript(this.building)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Building', data.Message);
        this.clearFormData(BuildingForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'buildingService updateBuilding Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  // Get List Of Venues
  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;

        if (!this.Buildings && !this.building.BuildingId) {
          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj && obj[0]) {
            this.building.Venue.VenueId = obj[0].VenueId;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService GetAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllBuildings(this.searchString, this.page, this.size, this.viewType);
  }
}
