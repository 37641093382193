<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="states">STATES</span>
        <span class="list-heading" *ngIf="!states && !state.StateId">ADD STATE</span>
        <span class="list-heading" *ngIf="!states && state.StateId">EDIT STATE</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12" *ngIf="states">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading">
          <ngx-atlas-select
            (model)="StatePageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section">
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add State
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="statesSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="states">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive">
          <table class="table table-hover table-responsive" *ngIf="hasData()">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('StateName', columnSortOrder)">
                  State
                  <span *ngIf="selectedColumnName == 'StateName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'StateName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('CountryName', columnSortOrder)">
                  Country
                  <span *ngIf="selectedColumnName == 'CountryName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'CountryName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let item of states
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getStateById(item.StateId)">
                <td>{{ item.StateName }}</td>
                <td>{{ item.Country.CountryName }}</td>
                <td *ngIf="item.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!item.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!states">
    <form #stateForm="ngForm" class="form-horizontal">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div>
          <div class="form-group col-md-12 col-xs-12">
            <label for="CountryName"><span id="red-circle">&#x25CF;</span> Country</label>
            <div>
              <ngx-atlas-select
                (model)="state.Country.CountryId = $event"
                [list]="countries"
                [idField]="'CountryId'"
                [textField]="'CountryName'"
                [placeholder]="'Select Country'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="state.Country.CountryId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="form-group col-md-12 col-xs-12">
            <label for="stateName"><span id="red-circle">&#x25CF;</span> State Name</label>
            <div>
              <input
                type="text"
                name="stateName"
                spellcheck="false"
                maxlength="50"
                [(ngModel)]="state.StateName"
                placeholder="State Name"
                class="form-control"
                required
                #stateName="ngModel"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div *ngIf="stateName.errors && (stateName.dirty || stateName.touched)" class="alert alert-danger">
                <div [hidden]="!stateName.errors.required" class="text-danger">State name is required.</div>
                <div [hidden]="!stateName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group" *ngIf="state.StateId">
              <div>
                <mat-checkbox [(ngModel)]="state.IsActive" name="isActive">Is Active</mat-checkbox>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type pull-right btn-cancel"
              (click)="clearFormData(stateForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!state.StateId"
              class="btn btn-type btn-save"
              [disabled]="!stateForm.form.valid || !state.Country.CountryId || isReqComplete"
              (click)="addState(stateForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="state.StateId"
              class="btn btn-type btn-save"
              [disabled]="!stateForm.form.valid || !state.Country.CountryId || isReqComplete"
              (click)="updateState(stateForm)"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
