import { Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { AuthState } from 'src/app/core/services/auth.service';
import { VenueService } from 'src/app/features/venuemanagement/venue/components/venue.http.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { SensorTypeService } from '../../../corporationmanagement/sensortype/components/sensortype.http.service';
import {
  FeatureConfiguration,
  Module,
  ModuleFeature,
  ModuleFeatureType,
} from '../../../serviceprovider/serviceprovider.module';
import { VenueTouchlessFeedbackConfig } from '../../../touchlessfeedbackconfiguration/touchless-feedback-config/touchless-feedback-config.module';
import { User } from '../../../usermanagement/user/user.model';
import { AreaModule } from '../../../venuemanagement/area/area.module';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { LevelModule } from '../../../venuemanagement/level/level.module';
import { InspectionScoreFactor, VenueModule } from '../../../venuemanagement/venue/venue.module';
import { BeaconService } from '../../beacon/components/beacon.http.service';
import { LocationService } from '../../location/components/location.http.service';
import {
  LocationElementModule,
  LocationInventoryModule,
  LocationModule,
  LocationTaskModule,
  VenueLocationTouchlessFeedbackReason,
} from '../../location/location.module';
import { LocationTypeService } from '../../locationtype/components/locationtype.http.service';
import { ImportLocationTypeLog, LocationTypeModule } from '../../locationtype/locationtype.module';
import { ShiftService } from '../../shift/components/shift.http.service';
import { ZoneService } from '../../zone/components/zone.http.service';
import { ZoneModule } from '../../zone/zone.module';
// import { Subscription } from 'rxjs/Subscription';
import { Subscription, interval } from 'rxjs';
import { StateMaintain } from 'src/app/core/models/common.model';
import { CorporationModule } from 'src/app/features/corporationmanagement/corporation/corporation.module';
import { LocationSetupService } from 'src/app/shared/services/locationSetup.service';
import {
  ImportLocationTemplateLog,
  LocationTemplateImport,
  LocationTemplateImportRequest,
  LocationTemplateImportValidationResponse,
  LocationTemplateModule,
} from '../locationtemplate.module';
import { LocationTemplateService } from './locationtemplate.http.service';

@Injectable({
  providedIn: 'root',
})
export class config extends TreeviewConfig {
  hasAllCheckBox = true;
  hasFilter = false;
  hasCollapseExpand = true;
  maxHeight = 400;
}

@Component({
  selector: 'locationtemplate.component',
  templateUrl: 'locationtemplate.component.html',
  styleUrls: ['./locationtemplate.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    LocationTemplateService,
    UtilitiesService,
    ZoneService,
    BeaconService,
    VenueService,
    ShiftService,
    LocationTypeService,
    SensorTypeService,
    LocationService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: config },
  ],
})
export class LocationTemplateComponent implements OnInit {
  myQuerySub: Subscription;
  currentUser: User;

  // @ViewChild('gateVenue') gateVenueVariable: any;
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  public venues: VenueModule[] = [];
  public venue: VenueModule = new VenueModule();
  public buildings: BuildingModule[] = [];

  public locations: LocationTemplateModule[] = [];
  public location: LocationTemplateModule = new LocationTemplateModule();

  public zones: ZoneModule[] = [];
  public levels: LevelModule[] = [];
  public listbeacons: LocationTemplateModule[] = [];

  public locationTypes: LocationTypeModule[] = [];
  public Modules: Module[] = [];

  public LocationsData: LocationModule[] = [];
  public Location: LocationModule = new LocationModule();
  public feedbackIdentifiers: VenueTouchlessFeedbackConfig[] = [];
  public areas: AreaModule[] = [];
  public scoreFactors: InspectionScoreFactor[] = [];

  public page = 1;
  public size = 10;
  public count = 0;
  public pageSizes: any[] = [];
  public searchString = '';

  public buildingDisabled = true;
  public levelDisabled = true;
  public zoneDisabled = true;
  public isReqComplete = false;
  // isBuildingInVenue = false;
  // isZoneInBuilding = false;
  // checkCount = 0;
  // pageNumbers: any[];
  // venueName: string;
  // buildingName: string;
  // restRoom: string;
  // alertMessage: string;
  // isLocationSensors = false;

  public listviewtypes: any[];
  public viewType: any = null;

  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;

  // isAdd = false;
  // public sId: any;
  // venueBind: number;

  public isBadgeShow = 0;
  public isLocationTasks = false;
  public isLocationInventories = false;
  public isLocationElements = false;
  public isLocationReasons = false;
  public isAllTaskChecked = false;
  public isAllInventoryChecked = false;
  public isAllElementChecked = false;
  public isAllFeatureConfigurationChecked = false;
  public isCheckBoxVisible = false;
  public pageState: StateMaintain = {
    pageName: 'locationTemplateForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  public isLocationTaskShow = false;
  public isLocationInventoryShow = false;
  public isLocationElementShow = false;
  public isLocationGetwayShow = false;
  public isLocationAreaShow = false;
  public retriveModules: any[] = [];
  public importConfirmationModal = false;
  public validateImportConfirmationResult: LocationTemplateImportValidationResponse =
    new LocationTemplateImportValidationResponse();
  public locationTemplateImportRequest: LocationTemplateImportRequest = new LocationTemplateImportRequest();

  public importConfirmationErrorModal = false;
  public importLocationTypeNotFoundModel = false;

  public cancelImportModel = false;
  public templateImportErrorStringLineOne = '';
  public templateImportErrorStringLineTwo = '';
  public templateImportErrorStringLineThree = '';

  public items: TreeviewItem[];
  public config = {};
  // fileExtension: any;
  public isModal = false;
  public locationModal = false;
  public formData: NgForm;
  public selectAllLocations = false;
  public totalElementWeightage = 0;
  public isLocationTemplateForm = false;
  public isImportLocationTemplateScreen = false;
  public importLocationTemplateCorporations: CorporationModule[] = [];
  public isCorporationDisabled = true;
  public selectedCorporationId: any;
  public importLocationTypeVenues: VenueModule[] = [];
  public isVenueDisabled = true;
  public selectedVenueId: any;
  public selectAllImportLocationTemplates = false;

  public importLocationType: ImportLocationTypeLog = new ImportLocationTypeLog();
  public importLogsData: ImportLocationTemplateLog[] = [];
  public isImportReqComplete = true;
  public importLogPage = 1;
  public importLogCount = 0;
  public importLocationTemplateForm: any;
  public storeImportLocationTemplates: LocationTemplateImport[] = [];
  public importLocationTemplates: LocationTemplateImport[] = [];
  public showTemplateImportCheckbox = false;

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private locationTemplateService: LocationTemplateService,
    private locService: LocationService,
    private venueService: VenueService,
    private shiftService: ShiftService,
    private locationTypeService: LocationTypeService,
    private locationService: LocationService,
    private loaderService: LoaderService,
    private locationSetupService: LocationSetupService,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.location = new LocationTemplateModule();
    this.location.Zone = new ZoneModule();
    this.location.Building = new BuildingModule();
    this.location.Building.Venue = new VenueModule();
    this.location.Building.Level = new LevelModule();

    this.config = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500,
    };
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;

    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.feedbackIdentifiers = [];
    // this.venueBind = this.authState.getStoredContextVenueId();
    // this.isModal = false;

    this.getModulesData();
    this.GetModuleList(this.authState.getStoredContextVenueId(), 0);
    this.getAllLocationTypes();
    this.getAllScoreFactors();
    this.getAllFeedbackConfigurationIdentifiers();
    this.getPageState();
  }

  public getModulesData() {
    this.isLocationTaskShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_TASK');
    this.isLocationInventoryShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_INVENTORIES');
    this.isLocationElementShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_ELEMENT');
    this.isLocationAreaShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_AREA');
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public LocationPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState.viewType = this.viewType;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }

  public setAddForm() {
    this.searchString = '';
    this.locations = null;
    this.count = 0;
    this.isLocationTemplateForm = true;
    // this.checkCount = 0;
    // this.isAdd = true;
    this.location = new LocationTemplateModule();
    this.location.Zone = new ZoneModule();
    this.location.Building = new BuildingModule();
    this.location.Building.Venue = new VenueModule();
    this.location.Building.Level = new LevelModule();
    this.getAllVenues('', -1, -1);
    setTimeout(() => {
      const element = document.getElementById('LocationTemplateName');
      element.focus();
    }, 1000);
    // this.GetModuleList(this.authState.getStoredContextVenueId(), 0);
    window.scrollTo(0, 0);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.locations = null;
    this.count = 0;
    this.isLocationTemplateForm = true;
    // this.checkCount = 0;
    // this.IsSVGTable = false;
    this.isCheckBoxVisible = false;
    this.getAllVenues('', -1, -1);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }
  public clearFormData(locationForm: NgForm) {
    if (locationForm) {
      locationForm.reset();
    }
    this.searchString = '';
    this.buildings = [];
    this.zones = [];
    this.zoneDisabled = true;
    this.isLocationTemplateForm = false;
    // this.isLocationSensors = false;
    // this.curPage = 1;
    // this.curPageOfSensor = 1;
    this.levels = [];
    this.levelDisabled = true;
    // this.isAdd = false;
    this.isBadgeShow = 0;
    this.items = [];
    this.Modules = [];
    this.isCheckBoxVisible = false;
    this.totalElementWeightage = 0;
    this.location = new LocationTemplateModule();
    this.location.Building = new BuildingModule();
    this.location.Building.Venue = new VenueModule();
    this.location.Building.Level = new LevelModule();
    this.location.Zone = new ZoneModule();

    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }

  public locationsSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(searchString, 1, this.size, this.viewType);
  }

  public addLocation(locationForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (locationForm.status == 'INVALID' && locationForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Location Template',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }
    const taskPayload: LocationTaskModule[] = [];
    const inventoryPayload: LocationInventoryModule[] = [];
    const elementPayload: LocationElementModule[] = [];
    const reasonPayload: VenueLocationTouchlessFeedbackReason[] = [];
    const _locationDetails: LocationTemplateModule = new LocationTemplateModule();

    _locationDetails.LocationTemplateName = this.utilitiesService.removeInnerSpaces(this.location.LocationTemplateName);
    if (_locationDetails.LocationTemplateName == null || _locationDetails.LocationTemplateName == '') {
      this.location.LocationTemplateName = _locationDetails.LocationTemplateName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _locationDetails.AddedBy = this.currentUser.UserId;
    _locationDetails.LevelId = this.location.Building.Level.LevelId;

    if (this.location.LocationTasks != null) {
      for (let i = 0; i < this.location.LocationTasks.length; i++) {
        const _locationTasks: LocationTaskModule = new LocationTaskModule();
        this.location.LocationTasks[i].IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;

        if (this.location.LocationTasks[i].IsActive == 1) {
          _locationTasks.TaskId = this.location.LocationTasks[i].TaskId;
          _locationTasks.IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;
          _locationTasks.VenueTaskId = this.location.LocationTasks[i].VenueTaskId;
          taskPayload.push(_locationTasks);
        }
      }
    }

    if (this.location.LocationInventories != null) {
      for (let i = 0; i < this.location.LocationInventories.length; i++) {
        const _locationInventories: LocationInventoryModule = new LocationInventoryModule();
        this.location.LocationInventories[i].IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;

        if (this.location.LocationInventories[i].IsActive == 1) {
          _locationInventories.InventoryId = this.location.LocationInventories[i].InventoryId;
          _locationInventories.IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;
          _locationInventories.VenueInventoryId = this.location.LocationInventories[i].VenueInventoryId;
          inventoryPayload.push(_locationInventories);
        }
      }
    }

    if (
      this.location.LocationElements != null &&
      this.location.LocationElements.length > 0 &&
      this.isLocationElements &&
      this.isLocationElementShow
    ) {
      let that = this;
      let isElementChecked = false;
      this.totalElementWeightage = 0;
      this.location.LocationElements.forEach(function (element) {
        if (element.IsActive == true) {
          that.totalElementWeightage += Number(element.ElementWeightage);
          isElementChecked = true;
        }
      });

      if (isElementChecked && (this.totalElementWeightage > 100 || this.totalElementWeightage < 100)) {
        this.utilitiesService.smmodal('Location Template', 'Total weight of elements should equal 100%.');
        this.isReqComplete = false;
        this.loaderService.display(false);

        let elementScroll = document.getElementById('elementConfig');
        if (true) {
          elementScroll.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        return;
      }

      for (let i = 0; i < this.location.LocationElements.length; i++) {
        const _locationElements: LocationElementModule = new LocationElementModule();
        this.location.LocationElements[i].IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;

        if (this.location.LocationElements[i].IsActive == 1) {
          _locationElements.ElementId = this.location.LocationElements[i].ElementId;
          _locationElements.IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;
          _locationElements.VenueElementId = this.location.LocationElements[i].VenueElementId;
          _locationElements.ElementWeightage = this.location.LocationElements[i].ElementWeightage;

          if (this.location.LocationElements[i].Area != null && this.location.LocationElements[i].Area.AreaId > 0) {
            const _locationAreaElements: AreaModule = new AreaModule();
            _locationAreaElements.AreaId = this.location.LocationElements[i].Area.AreaId;
            _locationAreaElements.IsActive = 1;
            _locationElements.Areas = [];
            _locationElements.Areas.push(_locationAreaElements);
          }

          if (
            this.location.LocationElements[i].InspectionScoreFactor != null &&
            this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId > 0
          ) {
            _locationElements.InspectionScoreFactor = new InspectionScoreFactor();
            _locationElements.InspectionScoreFactor.InspectionScoreFactorId =
              this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId;
            _locationElements.InspectionScoreFactor.ScoreFactorName =
              this.location.LocationElements[i].InspectionScoreFactor.ScoreFactorName;
            _locationElements.InspectionScoreFactor.IsActive = 1;
          } else {
            if (this.isLocationElementShow) {
              this.loaderService.display(false);
              this.isReqComplete = false;
              this.utilitiesService.smmodal('Location Template', 'Please select score factor.');
              return;
            }
          }
          elementPayload.push(_locationElements);
        }
      }
    }

    if (this.location.LocationFeedbackReasons != null) {
      for (let i = 0; i < this.location.LocationFeedbackReasons.length; i++) {
        const _locationReasons: VenueLocationTouchlessFeedbackReason = new VenueLocationTouchlessFeedbackReason();
        this.location.LocationFeedbackReasons[i].IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;

        if (this.location.LocationFeedbackReasons[i].IsActive == 1) {
          _locationReasons.LocationTemplateId = this.location.LocationFeedbackReasons[i].LocationTemplateId;
          _locationReasons.IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;
          _locationReasons.VenueFeedbackReasonId = this.location.LocationFeedbackReasons[i].VenueFeedbackReasonId;
          _locationReasons.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
          reasonPayload.push(_locationReasons);
        }
      }
    }

    _locationDetails.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
    _locationDetails.LocationTasks = taskPayload;
    _locationDetails.LocationInventories = inventoryPayload;
    _locationDetails.LocationElements = elementPayload;
    _locationDetails.LocationFeedbackReasons = reasonPayload;

    _locationDetails.Zone = new ZoneModule();
    _locationDetails.Zone.ZoneId = this.location.Zone.ZoneId;

    _locationDetails.Building = new BuildingModule();
    _locationDetails.Building.Venue = new VenueModule();
    _locationDetails.Building.BuildingId = this.location.Building.BuildingId;
    _locationDetails.VenueId = this.location.Building.Venue.VenueId;
    _locationDetails.Building.Venue.VenueId = this.location.Building.Venue.VenueId;

    let modulePayload: Module[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleFeatures = [];

          it.children.forEach(function (mf) {
            if (mf.checked || mf.checked == undefined) {
              let mFeature = new ModuleFeature();
              mFeature.ModuleFeatureId = mf.value;
              mFeature.ModuleFeatureTypes = [];

              mf.children.forEach(function (mft) {
                if (mft.checked || mft.checked == undefined) {
                  let mFeatureType = new ModuleFeatureType();
                  mFeatureType.FeatureTypeCode = mft.value;
                  mFeatureType.FeatureConfigurations = [];

                  mft.children.forEach(function (fc) {
                    if (fc.checked || fc.checked == undefined) {
                      let fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId = fc.value;
                      fConfig.IsActive = 1;

                      mFeatureType.FeatureConfigurations.push(fConfig);
                    }
                  });
                  mFeature.ModuleFeatureTypes.push(mFeatureType);
                }
              });
              module.ModuleFeatures.push(mFeature);
            }
          });
          modulePayload.push(module);
        }
      });
    }
    _locationDetails.Modules = modulePayload;
    _locationDetails.VenueLocationTypeId = this.location.VenueLocationTypeId;

    this.locationTemplateService.Add(this.utilitiesService.stripScript(_locationDetails)).subscribe(
      (data) => {
        this.page = 1;
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.clearFormData(locationForm);
        this.utilitiesService.smmodal('Location Template', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'locationService addLocation Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public updateLocation(locationForm: NgForm, type: number) {
    this.loaderService.display(true);
    this.isReqComplete = true;
    if (type == 1) {
      if (locationForm.status == 'INVALID' && locationForm.disabled == false) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal(
          'Location Template',
          'You cannot proceed ahead until all the fields are filled properly.',
        );
        return;
      }
    }

    const taskPayload: LocationTaskModule[] = [];
    const inventoryPayload: LocationInventoryModule[] = [];
    const elementPayload: LocationElementModule[] = [];
    const reasonPayload: VenueLocationTouchlessFeedbackReason[] = [];
    const _locationDetails: LocationTemplateModule = new LocationTemplateModule();

    _locationDetails.LocationTemplateId = this.location.LocationTemplateId;
    _locationDetails.LocationTemplateName = this.utilitiesService.removeInnerSpaces(this.location.LocationTemplateName);
    if (_locationDetails.LocationTemplateName == null || _locationDetails.LocationTemplateName == '') {
      this.location.LocationTemplateName = _locationDetails.LocationTemplateName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _locationDetails.ModifiedBy = this.currentUser.UserId;
    _locationDetails.LevelId = this.location.Building.Level.LevelId;
    _locationDetails.Building = new BuildingModule();
    _locationDetails.Building.Venue = new VenueModule();

    if (this.location.LocationTasks != null) {
      for (let i = 0; i < this.location.LocationTasks.length; i++) {
        const _locationTasks: LocationTaskModule = new LocationTaskModule();
        this.location.LocationTasks[i].IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;

        if (this.location.LocationTasks[i].IsActive == 1) {
          _locationTasks.TaskId = this.location.LocationTasks[i].TaskId;
          _locationTasks.IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;
          _locationTasks.VenueTaskId = this.location.LocationTasks[i].VenueTaskId;
          taskPayload.push(_locationTasks);
        }
      }
    }

    if (this.location.LocationInventories != null) {
      for (let i = 0; i < this.location.LocationInventories.length; i++) {
        const _locationInventories: LocationInventoryModule = new LocationInventoryModule();
        this.location.LocationInventories[i].IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;

        if (this.location.LocationInventories[i].IsActive == 1) {
          _locationInventories.InventoryId = this.location.LocationInventories[i].InventoryId;
          _locationInventories.IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;
          _locationInventories.VenueInventoryId = this.location.LocationInventories[i].VenueInventoryId;
          inventoryPayload.push(_locationInventories);
        }
      }
    }

    if (
      this.location.LocationElements != null &&
      this.location.LocationElements.length > 0 &&
      this.isLocationElements &&
      this.isLocationElementShow
    ) {
      let that = this;
      let isElementChecked = false;
      this.totalElementWeightage = 0;
      this.location.LocationElements.forEach(function (element) {
        if (element.IsActive == true) {
          that.totalElementWeightage += Number(element.ElementWeightage);
          isElementChecked = true;
        }
      });

      if (isElementChecked && (this.totalElementWeightage > 100 || this.totalElementWeightage < 100)) {
        this.utilitiesService.smmodal('Location Template', 'Total weight of elements should equal 100%.');
        this.isReqComplete = false;
        this.loaderService.display(false);

        let elementScroll = document.getElementById('elementConfig');
        if (true) {
          elementScroll.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        return;
      }

      for (let i = 0; i < this.location.LocationElements.length; i++) {
        const _locationElements: LocationElementModule = new LocationElementModule();
        this.location.LocationElements[i].IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;

        if (this.location.LocationElements[i].IsActive == 1) {
          _locationElements.ElementId = this.location.LocationElements[i].ElementId;
          _locationElements.IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;
          _locationElements.VenueElementId = this.location.LocationElements[i].VenueElementId;
          _locationElements.ElementWeightage = this.location.LocationElements[i].ElementWeightage;

          if (this.location.LocationElements[i].Area != null && this.location.LocationElements[i].Area.AreaId > 0) {
            const _locationAreaElements: AreaModule = new AreaModule();
            _locationAreaElements.AreaId = this.location.LocationElements[i].Area.AreaId;
            _locationAreaElements.AreaName = this.location.LocationElements[i].Area.AreaName;
            _locationAreaElements.IsActive = 1;
            _locationElements.Areas = [];
            _locationElements.Areas.push(_locationAreaElements);
          }

          if (
            this.location.LocationElements[i].InspectionScoreFactor != null &&
            this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId > 0
          ) {
            _locationElements.InspectionScoreFactor = new InspectionScoreFactor();
            _locationElements.InspectionScoreFactor.InspectionScoreFactorId =
              this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId;
            _locationElements.InspectionScoreFactor.ScoreFactorName =
              this.location.LocationElements[i].InspectionScoreFactor.ScoreFactorName;
            _locationElements.InspectionScoreFactor.IsActive = 1;
          } else {
            if (this.isLocationElementShow) {
              this.loaderService.display(false);
              this.isReqComplete = false;
              this.utilitiesService.smmodal('Location Template', 'Please select score factor.');
              return;
            }
          }

          elementPayload.push(_locationElements);
        }
      }
    }

    if (this.location.LocationFeedbackReasons != null) {
      for (let i = 0; i < this.location.LocationFeedbackReasons.length; i++) {
        const _locationReasons: VenueLocationTouchlessFeedbackReason = new VenueLocationTouchlessFeedbackReason();
        this.location.LocationFeedbackReasons[i].IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;

        if (this.location.LocationFeedbackReasons[i].IsActive == 1) {
          _locationReasons.LocationTemplateId = this.location.LocationFeedbackReasons[i].LocationTemplateId;
          _locationReasons.IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;
          _locationReasons.VenueFeedbackReasonId = this.location.LocationFeedbackReasons[i].VenueFeedbackReasonId;
          _locationReasons.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
          reasonPayload.push(_locationReasons);
        }
      }
    }

    _locationDetails.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
    _locationDetails.LocationTasks = taskPayload;
    _locationDetails.LocationInventories = inventoryPayload;
    _locationDetails.LocationElements = elementPayload;
    _locationDetails.LocationFeedbackReasons = reasonPayload;

    _locationDetails.Zone = new ZoneModule();
    _locationDetails.Zone.ZoneId = this.location.Zone.ZoneId;

    _locationDetails.Building = new BuildingModule();
    _locationDetails.Building.Venue = new VenueModule();
    _locationDetails.Building.BuildingId = this.location.Building.BuildingId;
    _locationDetails.VenueId = this.location.Building.Venue.VenueId;
    _locationDetails.Building.Venue.VenueId = this.location.Building.Venue.VenueId;
    _locationDetails.IsActive = this.location.IsActive ? 1 : 0;

    let modulePayload: Module[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleFeatures = [];

          it.children.forEach(function (mf) {
            if (mf.checked || mf.checked == undefined) {
              let mFeature = new ModuleFeature();
              mFeature.ModuleFeatureId = mf.value;
              mFeature.ModuleFeatureTypes = [];

              mf.children.forEach(function (mft) {
                if (mft.checked || mft.checked == undefined) {
                  let mFeatureType = new ModuleFeatureType();
                  mFeatureType.FeatureTypeCode = mft.value;
                  mFeatureType.FeatureConfigurations = [];

                  mft.children.forEach(function (fc) {
                    if (fc.checked || fc.checked == undefined) {
                      let fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId = fc.value;
                      fConfig.IsActive = 1;

                      mFeatureType.FeatureConfigurations.push(fConfig);
                    }
                  });
                  mFeature.ModuleFeatureTypes.push(mFeatureType);
                }
              });
              module.ModuleFeatures.push(mFeature);
            }
          });
          modulePayload.push(module);
        }
      });
    }
    _locationDetails.Modules = modulePayload;
    _locationDetails.VenueLocationTypeId = this.location.VenueLocationTypeId;
    // console.log(JSON.stringify(_locationDetails));

    this.locationTemplateService.Update(this.utilitiesService.stripScript(_locationDetails)).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.isReqComplete = false;
        if (type == 1) {
          this.clearFormData(locationForm);
          this.utilitiesService.smmodal('Location Template', data.Message);
        } else {
          this.LocationsData = [];
          this.getAllLocationsList();
          this.locationModal = true;
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'locationService updateLocation Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllLocations(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.locationTemplateService.GetLocationTemplateList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.locations = [];
        this.locations = data;
        if (this.locations.length < 0 && this.locations[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        if (this.locations != null && this.locations.length > 0) {
          this.locations.forEach(function (location) {
            if (location.LocationFeatureConfigurations != null) {
              location.LocationFeatureConfigurations.forEach(function (featureConfig) {
                if (
                  featureConfig.FeatureConfigurationCode != null &&
                  featureConfig.FeatureConfigurationCode == 'ENABLE_CLEANING_ALERTS'
                ) {
                  location.isCA = true;
                }
              });
            }
          });
          this.loaderService.display(false);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService getAllLocations Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.locations[0] ? this.locations[0].Count : 0),
    );
  }

  public hasData(): boolean {
    return this.locations ? this.locations != null && this.locations.length > 0 : false;
  }

  public getLocationById(LocationTemplateId: number) {
    if (this.authState.canAccess('LOCATION_TEMPLATE_VIEW')) {
      // this.isAdd = false;
      this.loaderService.display(true);
      const CPlocation = new LocationTemplateModule();
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);

      this.locationTemplateService.GetSingle(CPlocation).subscribe(
        (data) => {
          this.location = data;
          this.loaderService.display(false);

          if (this.location.Building.Venue.VenueId) {
            this.getAllBuildingsByVenue(this.location.Building.Venue.VenueId);
            if (this.location.LocationTemplateId) {
              this.GetModuleList(this.location.Building.Venue.VenueId, this.location.LocationTemplateId);
              this.getAllReasonsByVenue(
                this.location.Building.Venue.VenueId,
                this.location.LocationTemplateId,
                this.location.TouchlessFeedbackIdentifier,
              );
            } else {
              this.GetModuleList(this.location.Building.Venue.VenueId, 0);
            }
            if (this.location.Building.BuildingId) {
              this.getAllZonesByBuilding(this.location.Building.BuildingId);
              this.getAllLevelsByBuilding(this.location.Building.BuildingId);
            }
            if (this.location.VenueLocationTypeId && this.location.LocationTemplateId) {
              if (this.isLocationAreaShow) {
                this.getAllAreas(this.location.VenueLocationTypeId);
              }

              this.getAllTasksByVenue(
                this.location.Building.Venue.VenueId,
                this.location.LocationTemplateId,
                this.location.VenueLocationTypeId,
              );

              this.getAllInventoriesByVenue(
                this.location.Building.Venue.VenueId,
                this.location.LocationTemplateId,
                this.location.VenueLocationTypeId,
              );

              this.getAllElementsByVenue(
                this.location.Building.Venue.VenueId,
                this.location.LocationTemplateId,
                this.location.VenueLocationTypeId,
              );
            } else {
              if (this.isLocationAreaShow) {
                this.getAllAreas(this.location.VenueLocationTypeId);
              }
              this.getAllTasksByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId);
              this.getAllInventoriesByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId);
              this.getAllElementsByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId);
            }
          }

          window.scrollTo(0, 0);
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService getLocationById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal(
        'Location Template',
        'You do not have permission to view location template details.',
      );
    }
  }

  public getAllLocationTypes() {
    const searchfilter = {
      SearchQuery: '',
      GenericSearch: 0,
      IsActive: '1',
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.locationTypeService.GetAll(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.locationTypes = data;
      },
      (error) => {
        console.log(
          'locationTypeService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public changeLocationType($event) {
    if ($event != null) {
      this.location.VenueLocationTypeId = $event;
      if (this.location.LocationTemplateId) {
        if (this.isLocationAreaShow) {
          this.getAllAreas(this.location.VenueLocationTypeId);
        }
        this.getAllTasksByVenue(this.location.Building.Venue.VenueId, this.location.LocationTemplateId, $event);
        this.getAllInventoriesByVenue(this.location.Building.Venue.VenueId, this.location.LocationTemplateId, $event);
        this.getAllElementsByVenue(this.location.Building.Venue.VenueId, this.location.LocationTemplateId, $event);
      } else {
        if (this.isLocationAreaShow) {
          this.getAllAreas(this.location.VenueLocationTypeId);
        }
        this.getAllTasksByVenue(this.location.Building.Venue.VenueId, 0, $event);
        this.getAllInventoriesByVenue(this.location.Building.Venue.VenueId, 0, $event);
        this.getAllElementsByVenue(this.location.Building.Venue.VenueId, 0, $event);
      }
    } else {
      this.location.VenueLocationTypeId = null;
      this.location.LocationTasks = [];
      this.location.LocationInventories = [];
      this.location.LocationElements = [];
      this.isLocationTasks = false;
      this.isLocationInventories = false;
      this.isLocationElements = false;
    }
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;
        this.loaderService.display(false);
        this.isBadgeShow = 0;

        const obj = this.venues.filter((v) => v.VenueId == this.authState.getStoredContextVenueId());
        if (obj[0]) {
          this.isBadgeShow = obj[0].IsBadgeEnable;
        }

        if (!this.locations && !this.location.LocationTemplateId) {
          if (obj[0]) {
            this.location.Building.Venue.VenueId = obj[0].VenueId;
            this.location.Building.Venue.VenueName = obj[0].VenueName;
            this.getAllBuildingsByVenue(this.location.Building.Venue.VenueId);
            if (this.location.VenueLocationTypeId != null) {
              if (this.isLocationAreaShow) {
                this.getAllAreas(this.location.VenueLocationTypeId);
              }
              this.getAllTasksByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId);
              this.getAllInventoriesByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId);
              this.getAllElementsByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId);
            }
          }
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService getAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public changeVenue($event: number) {
    this.location.Building.Venue.VenueId = $event;
    this.isBadgeShow = 0;
    const obj = this.venues.filter((v) => v.VenueId == this.authState.getStoredContextVenueId());
    if (obj && obj[0]) {
      this.isBadgeShow = obj[0].IsBadgeEnable;
    }

    if (this.location.Building.Venue.VenueId) {
      this.items = [];
      this.Modules = [];
      this.GetModuleList(this.location.Building.Venue.VenueId, 0);

      this.buildings = [];
      this.location.Building.BuildingId = null;
      this.getAllBuildingsByVenue(this.location.Building.Venue.VenueId);

      if (this.location.VenueLocationTypeId != null && this.location.LocationTemplateId) {
        if (this.isLocationAreaShow) {
          this.getAllAreas(this.location.VenueLocationTypeId);
        }
        this.getAllTasksByVenue(
          this.location.Building.Venue.VenueId,
          this.location.LocationTemplateId,
          this.location.VenueLocationTypeId,
        );

        this.getAllInventoriesByVenue(
          this.location.Building.Venue.VenueId,
          this.location.LocationTemplateId,
          this.location.VenueLocationTypeId,
        );

        this.getAllElementsByVenue(
          this.location.Building.Venue.VenueId,
          this.location.LocationTemplateId,
          this.location.VenueLocationTypeId,
        );
      } else {
        this.location.LocationTasks = [];
        this.location.LocationInventories = [];
        this.location.LocationElements = [];
        this.isLocationTasks = false;
        this.isLocationInventories = false;
        this.isLocationElements = false;
      }
    } else {
      this.buildings = [];
      this.buildingDisabled = true;
      this.location.Building.BuildingId = null;

      this.zones = [];
      this.zoneDisabled = true;
      this.location.Zone.ZoneId = null;

      this.levels = [];
      this.levelDisabled = true;
      this.location.Building.Level.LevelId = null;

      this.location.LocationTasks = [];
      this.location.LocationInventories = [];
      this.location.LocationElements = [];
      this.isLocationTasks = false;
      this.isLocationInventories = false;
      this.isLocationElements = false;
    }
  }

  public getAllBuildingsByVenue(venueId: any) {
    if (venueId) {
      this.loaderService.display(true);
      const CPvenue = new VenueModule();
      CPvenue.VenueCPId = this.authState.AESEncryptText(venueId);

      this.locService.GetBuildingDropdown(CPvenue).subscribe(
        (data) => {
          this.buildings = data;
          this.buildingDisabled = false;
          this.loaderService.display(false);
        },
        (error) =>
          console.log(
            'locationService GetAllBuildings Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          ),
      );
    } else {
      this.buildings = [];
      this.location.Building.BuildingId = null;
      this.buildingDisabled = true;
      this.loaderService.display(false);
    }
  }

  public changeBuilding($event: number) {
    this.location.Building.BuildingId = $event;
    if (this.location.Building.BuildingId) {
      this.zones = [];
      this.location.Zone.ZoneId = null;
      this.getAllZonesByBuilding(this.location.Building.BuildingId);

      this.levels = [];
      this.location.Building.Level.LevelId = null;
      this.getAllLevelsByBuilding(this.location.Building.BuildingId);
    } else {
      this.zones = [];
      this.zoneDisabled = true;
      this.location.Zone.ZoneId = null;

      this.levels = [];
      this.levelDisabled = true;
      this.location.Building.Level.LevelId = null;
    }
  }

  public getAllZonesByBuilding(buildingId: any) {
    if (buildingId) {
      this.loaderService.display(true);
      const CPbuilding = new BuildingModule();
      CPbuilding.BuildingCPId = this.authState.AESEncryptText(buildingId);

      this.shiftService.GetZoneDropdown(CPbuilding).subscribe(
        (data) => {
          this.zones = data;
          this.zoneDisabled = false;
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            ' locationComponent shiftService GetAllZones Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
    } else {
      this.zones = [];
      this.location.Zone.ZoneId = null;
      this.loaderService.display(false);
    }
  }

  public changeZone($event: number) {
    this.location.Zone.ZoneId = $event;
  }

  public getAllLevelsByBuilding(buildingId: number) {
    if (buildingId) {
      this.loaderService.display(true);
      const CPbuilding = new BuildingModule();
      CPbuilding.BuildingCPId = this.authState.AESEncryptText(buildingId);

      this.locService.GetLevelDropdown(CPbuilding).subscribe(
        (data) => {
          this.levels = data;
          this.levelDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetLevelDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    } else {
      this.buildings = [];
      this.location.Building.BuildingId = null;
      this.loaderService.display(false);
    }
  }

  public getAllTasksByVenue(venueId: number, LocationTemplateId: number, LocationTypeId: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);
    CPlocation.VenueLocationTypeId = LocationTypeId;
    CPlocation.VenueLocationTypeId = this.location.VenueLocationTypeId;
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.locationTemplateService.GetAllTasksByVenue(CPlocation).subscribe(
      (data) => {
        this.location.LocationTasks = data;
        // this.taskCurPage = 1;
        if (this.location.LocationTasks.length > 0) {
          this.isLocationTasks = true;
        } else {
          this.isLocationTasks = false;
        }
        // this.totalTasks = this.location.LocationTasks.length;
        // this.taskNumberOfPages = Math.ceil(this.totalTasks / this.taskPageSize);
        this.ChangeTask();
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllTasksByVenue Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public hasVenueTasksData(): boolean {
    return this.location.LocationTasks != null && this.location.LocationTasks.length > 0;
  }

  public getAllInventoriesByVenue(venueId: number, LocationTemplateId: number, LocationTypeId: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.VenueLocationTypeId = LocationTypeId;
    CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);
    CPlocation.VenueLocationTypeId = this.location.VenueLocationTypeId;
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.locationTemplateService.GetAllInventoriesByVenue(CPlocation).subscribe(
      (data) => {
        this.location.LocationInventories = data;
        // this.inventoryCurPage = 1;
        if (this.location.LocationInventories.length > 0) {
          this.isLocationInventories = true;
        } else {
          this.isLocationInventories = false;
        }
        // this.totalInventories = this.location.LocationInventories.length;
        // this.inventoryNumberOfPages = Math.ceil(this.totalInventories / this.inventoryPageSize);
        this.ChangeInventory();
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllInventoriesByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public hasVenueInventoriesData(): boolean {
    return this.location.LocationInventories != null && this.location.LocationInventories.length > 0;
  }

  public getAllElementsByVenue(venueId: number, LocationTemplateId: number, LocationTypeId: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.VenueLocationTypeId = LocationTypeId;
    CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);
    CPlocation.VenueLocationTypeId = this.location.VenueLocationTypeId;
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.locationTemplateService.GetAllElementsByVenue(CPlocation).subscribe(
      (data) => {
        this.location.LocationElements = data;
        // this.elementCurPage = 1;
        if (this.location.LocationElements.length > 0) {
          this.isLocationElements = true;

          let that = this;
          this.totalElementWeightage = 0;
          this.location.LocationElements.forEach(function (element) {
            element.Area = new AreaModule();
            // If condition used only in update location template
            if (that.location.LocationTemplateId) {
              element.Areas.forEach(function (area) {
                element.Area.AreaId = area.AreaId;
              });
            }

            element.IsActive = element.IsActive ? true : false;
            if (element.ElementWeightage) {
              that.totalElementWeightage += Number(element.ElementWeightage);
            }
          });
        } else {
          this.isLocationElements = false;
        }
        // this.totalElements = this.location.LocationElements.length;
        // this.elementNumberOfPages = Math.ceil(this.totalElements / this.elementPageSize);
        this.ChangeIElement();
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllElementsByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public hasVenueElementsData(): boolean {
    return this.location.LocationElements != null && this.location.LocationElements.length > 0;
  }

  public checkLocationElementIsChecked(type: string, isChecked: any, elementId: number) {
    if (type == 'locationTemplateElement') {
      if (isChecked == false) {
        this.location.LocationElements.forEach(function (element) {
          if (element.VenueElementId == elementId) {
            element.ElementWeightage = null;
            element.Area.AreaId = null;
            element.Area.IsActive = 0;

            element.InspectionScoreFactor.InspectionScoreFactorId = null;
            element.InspectionScoreFactor.IsActive = 0;
          }
        });
      }

      let that = this;
      this.recalculaTotalWeightage(that);
    }
    if (this.location.LocationElements && this.location.LocationElements.length > 0) {
      let item = this.location.LocationElements.filter(
        (inventory) => inventory.IsActive == false || inventory.IsActive == 0,
      );
      if (item && item.length > 0) {
        this.isAllElementChecked = false;
      } else {
        this.isAllElementChecked = true;
      }
    }
  }

  private recalculaTotalWeightage(that: this) {
    this.totalElementWeightage = this.locationSetupService.totalWeightage(this.location.LocationElements);
  }

  public getAllReasonsByVenue(venueId: number, LocationTemplateId: number, TouchlessFeedbackIdentifier) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);
    CPlocation.TouchlessFeedbackIdentifier = TouchlessFeedbackIdentifier;

    this.locationTemplateService.GetAllReasonsByVenue(CPlocation).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.location.LocationFeedbackReasons = data;
          this.isLocationReasons = true;
        } else {
          this.location.LocationFeedbackReasons = [];
          this.isLocationReasons = false;
        }
        // this.reasonCurPage = 1;
        // this.totalReasons = this.location.LocationFeedbackReasons.length;
        // this.reasonNumberOfPages = Math.ceil(this.totalReasons / this.reasonPageSize);
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllReasonsByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public hasVenueReasonsData(): boolean {
    return this.location.LocationFeedbackReasons != null && this.location.LocationFeedbackReasons.length > 0;
  }

  public onSelectedChange(_downlineItems: DownlineTreeviewItem[]) {
    this.isAllFeatureConfigurationChecked = false;

    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let item = this.treeviewComponent.items.filter((tree) => tree.checked == false || tree.checked == undefined);
      if (item && item.length > 0) {
        this.isAllFeatureConfigurationChecked = false;
      } else {
        this.isAllFeatureConfigurationChecked = true;
      }
    }
  }

  public onAllCheckedChange(event: any) {
    let that = this;
    this.treeviewComponent.items.forEach(function (it) {
      it.checked = event.checked;
      that.changeChildrenSelection(it, event.checked);
    });
  }

  public changeChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  public GetModuleList(venueId: number, LocationTemplateId: number) {
    this.isCheckBoxVisible = false;
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.locationTemplateService.GetFeatureConfigurations(CPlocation).subscribe(
      (data) => {
        this.isCheckBoxVisible = false;

        if (data != null && data.length > 0) {
          this.isCheckBoxVisible = true;
          let that = this;
          this.Modules = [];
          this.Modules = data;
          if (this.Modules != null && this.Modules.length > 0) {
            this.items = [];

            this.Modules.forEach(function (item) {
              let childrens = [];
              item.ModuleFeatures.forEach(function (moduleFeature) {
                let ModuleFeatureChildrens = [];
                childrens.push({
                  text: moduleFeature.ModuleFeatureName,
                  value: moduleFeature.ModuleFeatureId,
                  children: ModuleFeatureChildrens,
                  collapsed: true,
                });

                moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
                  let ModuleFeatureTypesChildrens = [];
                  ModuleFeatureChildrens.push({
                    text: moduleFeatureType.FeatureTypeName,
                    value: moduleFeatureType.FeatureTypeCode,
                    children: ModuleFeatureTypesChildrens,
                    collapsed: true,
                  });

                  moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                    featureConfig.IsActive = featureConfig.IsActive ? true : false;
                    ModuleFeatureTypesChildrens.push({
                      text: featureConfig.FeatureConfigurationName,
                      checked: featureConfig.IsActive,
                      value: featureConfig.FeatureConfigurationId,
                      collapsed: true,
                    });
                  });
                });
              });
              that.items.push(
                new TreeviewItem({
                  text: item.ModuleName,
                  value: item.ModuleId,
                  checked: false,
                  children: childrens,
                  collapsed: true,
                }),
              );
            });
          }
        }
      },
      (error) => {
        console.log(
          'SubscriptionService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public closeModal() {
    this.isModal = false;
  }

  public openModal(formData: NgForm) {
    this.formData = formData;
    this.isModal = true;
  }

  public deleteTemplate() {
    if (this.location.Building.Venue.VenueId && this.location.LocationTemplateId) {
      const CPlocation = new LocationTemplateModule();
      CPlocation.Venue = new VenueModule();
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(this.location.LocationTemplateId);
      CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(this.location.Building.Venue.VenueId);

      this.locationTemplateService.Delete(CPlocation).subscribe(
        (_data) => {
          this.closeModal();
          this.clearData();
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.utilitiesService.snackBarWithAutoDismiss('Location Template', 'Location template deleted successfully!');
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          console.log(
            'inventoryService Delete Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    }
  }

  public clearData() {
    this.formData.reset();
    this.searchString = '';
    this.buildings = [];
    this.zones = [];
    this.zoneDisabled = true;
    // this.isLocationSensors = false;
    // this.curPage = 1;
    // this.curPageOfSensor = 1;
    this.levels = [];
    this.levelDisabled = true;
    // this.isAdd = false;
    this.isBadgeShow = 0;
    this.items = [];
    this.Modules = [];
    this.isCheckBoxVisible = false;
    this.location.Building = new BuildingModule();
    this.location.Building.Venue = new VenueModule();
    this.location.Building.Level = new LevelModule();
    this.location.Zone = new ZoneModule();
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }

  public getAllLocationsList() {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: '', GenericSearch: 0 };

    this.locationService.GetLocationDropDown(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.LocationsData = data;
        if (this.LocationsData != null && this.LocationsData.length > 0) {
          for (let i = 0; i < this.LocationsData.length; i++) {
            this.LocationsData[i].IsActive = 0;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'WOService GetAllLocation Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  hasselected() {
    for (let i = 0; i < this.LocationsData.length; i++) {
      this.LocationsData[i].IsActive = this.LocationsData[i].IsActive ? 1 : 0;
      if (this.LocationsData[i].IsActive > 0) {
        return true;
      }
    }
    return false;
  }

  public ApplyTemplateToLocations(locationForm: NgForm) {
    this.loaderService.display(true);
    const chkValue = this.hasselected();
    if (chkValue == false) {
      this.utilitiesService.smmodal('Location', 'Please select at least one location.');
      this.loaderService.display(false);
      return;
    }
    const _locationsDetails: LocationTemplateModule = new LocationTemplateModule();
    _locationsDetails.LocationList = [];
    _locationsDetails.LocationTemplateId = this.location.LocationTemplateId;
    this.LocationsData.forEach(function (location) {
      const loc = new LocationModule();
      location.IsActive = location.IsActive ? 1 : 0;
      if (location.IsActive == 1) {
        loc.LocationId = location.LocationId;
        _locationsDetails.LocationList.push(loc);
      }
    });
    this.locationTemplateService.ApplyTemplate(this.utilitiesService.stripScript(_locationsDetails)).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.closeLocationModal();
        this.utilitiesService.smmodal('Location', data.Message);
        this.clearFormData(locationForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'locationService addLocation Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  openLocationModal(locationForm: NgForm, type: number) {
    this.updateLocation(locationForm, type);
  }

  closeLocationModal() {
    this.LocationsData = [];
    this.locationModal = false;
    this.selectAllLocations = false;
  }

  public hasLocationsData(): boolean {
    return this.LocationsData ? this.LocationsData != null && this.LocationsData.length > 0 : false;
  }

  changeLocationSelect(_$event: any) {
    if (this.LocationsData != null && this.LocationsData.length > 0) {
      for (let i = 0; i < this.LocationsData.length; i++) {
        this.LocationsData[i].IsActive = this.LocationsData[i].IsActive ? 1 : 0;
        if (this.LocationsData[i].IsActive == 1) {
          this.selectAllLocations = true;
        } else {
          this.selectAllLocations = false;
          break;
        }
      }
    }
  }

  changeAllLocationSelect($event: any) {
    console.log($event);
    if (this.selectAllLocations == true) {
      for (let i = 0; i < this.LocationsData.length; i++) {
        this.LocationsData[i].IsActive = 1;
        this.selectAllLocations = true;
      }
    } else {
      for (let i = 0; i < this.LocationsData.length; i++) {
        this.LocationsData[i].IsActive = 0;
        this.selectAllLocations = false;
      }
    }
  }

  public getAllFeedbackConfigurationIdentifiers() {
    const searchfilter = { SearchQuery: '', GenericSearch: 0, IsActive: '1' };

    this.locationService.GetAllFeedbackConfigurationIdentifiers(JSON.stringify(searchfilter)).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.feedbackIdentifiers = data;
        } else {
          this.feedbackIdentifiers = [];
        }
      },
      (error) => {
        console.log(
          'locationTypeService getAllFeedbackConfigurationIdentifiers Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  ChangeFeedbackIdentifier($event) {
    this.location.TouchlessFeedbackIdentifier = $event;
    this.getAllReasonsByVenue(
      this.location.Building.Venue.VenueId,
      this.location.LocationTemplateId,
      this.location.TouchlessFeedbackIdentifier,
    );
  }

  ChangeSelectAllTask() {
    if (this.location.LocationTasks && this.location.LocationTasks.length > 0) {
      if (this.isAllTaskChecked == false) {
        for (let i = 0; i < this.location.LocationTasks.length; i++) {
          this.location.LocationTasks[i].IsActive = false;
        }
      } else {
        for (let j = 0; j < this.location.LocationTasks.length; j++) {
          this.location.LocationTasks[j].IsActive = true;
        }
      }
    }
  }

  ChangeTask() {
    if (this.location.LocationTasks && this.location.LocationTasks.length > 0) {
      let item = this.location.LocationTasks.filter((task) => task.IsActive == false || task.IsActive == 0);
      if (item && item.length > 0) {
        this.isAllTaskChecked = false;
      } else {
        this.isAllTaskChecked = true;
      }
    }
  }

  ChangeSelectAllInventory() {
    if (this.location.LocationInventories && this.location.LocationInventories.length > 0) {
      if (this.isAllInventoryChecked == false) {
        for (let i = 0; i < this.location.LocationInventories.length; i++) {
          this.location.LocationInventories[i].IsActive = false;
        }
      } else {
        for (let j = 0; j < this.location.LocationInventories.length; j++) {
          this.location.LocationInventories[j].IsActive = true;
        }
      }
    }
  }

  ChangeInventory() {
    if (this.location.LocationInventories && this.location.LocationInventories.length > 0) {
      let item = this.location.LocationInventories.filter(
        (inventory) => inventory.IsActive == false || inventory.IsActive == 0,
      );
      if (item && item.length > 0) {
        this.isAllInventoryChecked = false;
      } else {
        this.isAllInventoryChecked = true;
      }
    }
  }

  ChangeSelectAllElement() {
    if (this.location.LocationElements && this.location.LocationElements.length > 0) {
      if (this.isAllElementChecked == false) {
        for (let i = 0; i < this.location.LocationElements.length; i++) {
          this.location.LocationElements[i].IsActive = false;
          this.location.LocationElements[i].ElementWeightage = null;

          this.location.LocationElements[i].Area.AreaId = null;
          this.location.LocationElements[i].Area.IsActive = 0;

          this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId = null;
          this.location.LocationElements[i].InspectionScoreFactor.IsActive = 0;
        }
        this.totalElementWeightage = null;
      } else {
        for (let j = 0; j < this.location.LocationElements.length; j++) {
          this.location.LocationElements[j].IsActive = true;
        }
      }
    }
  }

  ChangeIElement() {
    if (this.location.LocationElements && this.location.LocationElements.length > 0) {
      let item = this.location.LocationElements.filter((element) => element.IsActive == false || element.IsActive == 0);
      if (item && item.length > 0) {
        this.isAllElementChecked = false;
      } else {
        this.isAllElementChecked = true;
      }
    }
  }

  public getAllAreas(venueLocationTypeId: number) {
    this.loaderService.display(true);
    const payload = new LocationTypeModule();
    payload.VenueLocationTypeCPId = this.authState.AESEncryptText(venueLocationTypeId);

    this.locationTemplateService.GetAllAreasByVenue(payload).subscribe(
      (data) => {
        this.areas = data;
        this.loaderService.display(false);
        this.getAllScoreFactors();
      },
      (error) => {
        this.loaderService.display(false);
        console.log('areaService getAllAreas Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public getAllScoreFactors() {
    const payload = new GenericEntity();
    //payload.VenueId = this.authState.getStoredContextVenueId();
    payload.ServiceProviderId = this.authState.getStoredContextServiceProviderId();

    this.locationService.GetAllScoreFactorsByVenue(payload).subscribe(
      (data) => {
        this.scoreFactors = data;
      },
      (error) => {
        console.log(
          'locationService GetAllScoreFactorsByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  keyPressCheck($event: any) {
    if (
      $event.key == 'e' ||
      $event.key == 'E' ||
      $event.key == '-' ||
      $event.key == '.' ||
      ($event.target.value === '' && $event.key == '0')
    ) {
      $event.preventDefault();
      return false;
    }

    if ($event.target.value.length > 3) {
      $event.preventDefault();
      return false;
    }
  }

  public hasFeedbackData(): boolean {
    return this.feedbackIdentifiers != null && this.feedbackIdentifiers.length > 0;
  }

  public goToImportLocationTemplates() {
    this.isLocationTemplateForm = false;
    this.isImportLocationTemplateScreen = true;
    this.importLocationTemplateCorporations = [];
    this.selectedCorporationId = null;
    this.isCorporationDisabled = true;
    this.importLogPage = 1;
    this.importConfirmationModal = false;
    this.importConfirmationErrorModal = false;
    this.importLocationTypeNotFoundModel = false;
    this.cancelImportModel = false;
    this.getAllImportLocationTemplateCorporations();
  }

  public venueChange($event: any) {
    this.selectedVenueId = $event;
    if (this.selectedVenueId) {
      this.importLocationTemplates = [];
      this.storeImportLocationTemplates = [];
      this.getAllImportLocationTemplates(this.selectedVenueId);
    } else {
      this.selectedVenueId = null;
      this.importLocationTemplates = [];
      this.storeImportLocationTemplates = [];
    }
  }
  public getAllImportLocationTemplates(venueId: number) {
    this.loaderService.display(true);
    const payload = new ImportLocationTemplateLog();
    payload.SourceVenueId = venueId;
    payload.DestinationVenueId = this.authState.getStoredContextVenueId();

    this.locationTemplateService.GetAllImportLocationTemplates(payload).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.importLocationTemplates = data;
          this.showTemplateImportCheckbox = true;
        } else {
          this.showTemplateImportCheckbox = false;
        }
      },
      (error) => {
        this.showTemplateImportCheckbox = false;
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetAllImportLocationTypes call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public selectedImportLocationTemplate(locationTemplateId: number, locationTemplateName: string, isSelected: boolean) {
    if (isSelected) {
      let locTypeObj = new LocationTemplateImport();
      locTypeObj.LocationTemplateId = locationTemplateId;
      locTypeObj.LocationTemplateName = locationTemplateName;
      this.storeImportLocationTemplates.push(locTypeObj);

      if (
        this.importLocationTemplates.length > 0 &&
        this.storeImportLocationTemplates.length > 0 &&
        this.importLocationTemplates.length === this.storeImportLocationTemplates.length
      ) {
        this.selectAllImportLocationTemplates = true;
      }
    } else {
      this.storeImportLocationTemplates = this.storeImportLocationTemplates.filter(
        (item) => item.LocationTemplateId !== locationTemplateId,
      );
      this.selectAllImportLocationTemplates = false;
    }
  }

  public selectedAllLocationTemplates(isSelected: boolean) {
    if (isSelected) {
      this.storeImportLocationTemplates = [];
      let that = this;
      this.importLocationTemplates.forEach(function (locType) {
        let locTypeObj = new LocationTemplateImport();
        locType.IsSelected = true;
        locTypeObj.LocationTemplateId = locType.LocationTemplateId;
        locTypeObj.LocationTemplateName = locType.LocationTemplateName;
        locTypeObj.IsSelected = true;
        that.storeImportLocationTemplates.push(locTypeObj);
      });
    } else {
      this.storeImportLocationTemplates = [];
      this.importLocationTemplates.forEach(function (locType) {
        locType.IsSelected = false;
      });
    }
  }
  public closeImportConfirmationModal() {
    this.importConfirmationModal = false;
  }
  public proceedImport() {
    this.ClearValidationErrorsForImport();
    this.importConfirmationErrorModal = false;
    this.ConfirmValidationForImport();
  }
  public cancelImport() {
    this.importConfirmationErrorModal = false;
    this.cancelImportModel = true;
    this.isImportReqComplete = true;
  }
  public closeImportPopup() {
    this.importLocationTypeNotFoundModel = false;
    this.cancelImportModel = true;
    this.isImportReqComplete = true;
  }
  public getImportLogData(page: number, isLoaderDisplay: boolean) {
    if (isLoaderDisplay) {
      this.loaderService.display(true);
    }
    const payload = {};

    this.locationTemplateService.GetAllImportedLocationTypes(payload, page, 10).subscribe(
      (data) => {
        if (isLoaderDisplay) {
          this.loaderService.display(false);
        }
        this.importLogsData = data;
        if (
          !!this.importLogsData &&
          this.importLogsData.length < 0 &&
          this.importLogsData[0].Count / this.utilitiesService.initialPageSize < this.page
        ) {
          this.importLogPage = 1;
        }
      },
      (error) => {
        if (isLoaderDisplay) {
          this.loaderService.display(false);
        }
        console.log(
          'locationTemplateService GetAllImportedLocationTypes call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
      () => (this.importLogCount = this.importLogsData[0] ? this.importLogsData[0].Count : 0),
    );
  }
  public async interval() {
    this.myQuerySub = interval(1000 * 60).subscribe(() => {
      this.getImportLogData(this.importLogPage, false);
    });
  }
  public getAllImportLocationTemplateCorporations() {
    this.showTemplateImportCheckbox = false;
    this.loaderService.display(true);
    const payload = {};

    this.locationTypeService.GetAllCorporations(payload).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.isCorporationDisabled = false;
          this.importLocationTemplateCorporations = data;
        }
        this.getImportLogData(this.importLogPage, true);
        this.interval();
      },
      (error) => {
        this.loaderService.display(false);
        this.getImportLogData(this.importLogPage, true);
        console.log(
          'locationTypeService GetAllCorporations call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }
  public corporationChange($event: any) {
    this.showTemplateImportCheckbox = false;
    this.selectedCorporationId = $event;
    if (this.selectedCorporationId) {
      this.importLocationTypeVenues = [];
      this.isVenueDisabled = true;
      this.selectedVenueId = null;

      this.importLocationTemplates = [];
      this.storeImportLocationTemplates = [];

      this.selectAllImportLocationTemplates = false;
      this.getAllImportLocationTypeVenues(this.selectedCorporationId);
    } else {
      this.importLocationTypeVenues = [];
      this.isVenueDisabled = true;
      this.selectedVenueId = null;
      this.selectedCorporationId = null;

      this.importLocationTemplates = [];
      this.storeImportLocationTemplates = [];
      this.selectAllImportLocationTemplates = false;
    }
  }
  public getAllImportLocationTypeVenues(corporationId: number) {
    this.loaderService.display(true);
    const payload = { CorporationId: corporationId };

    this.locationTypeService.GetAllVenues(payload).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.isVenueDisabled = false;
          this.importLocationTypeVenues = data;
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetAllVenues call failed. status:' + error.status + 'Status Text: ' + error.statusText,
        );
      },
    );
  }
  public backToLocationTemplateList() {
    this.isImportLocationTemplateScreen = false;

    this.importLocationTemplateCorporations = [];
    this.isCorporationDisabled = true;
    this.selectedCorporationId = null;

    this.importLocationTypeVenues = [];
    this.isVenueDisabled = true;
    this.selectedVenueId = null;

    this.importLocationTemplates = [];
    this.importLogsData = [];
    this.importLogPage = 1;
    this.importLogCount = 0;
    this.ClearValidationErrorsForImport();
    if (this.myQuerySub) {
      this.myQuerySub.unsubscribe();
    }
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType);
  }

  public openImportConfirmationModal(importLocationTemplateForm: NgForm) {
    this.importLocationTemplateForm = importLocationTemplateForm;
    this.importConfirmationModal = true;
  }

  public saveImportLocationTemplates() {
    this.closeImportConfirmationModal();
    this.loaderService.display(true);
    this.isImportReqComplete = false;
    let request = new LocationTemplateImportRequest();
    request.LocationTemplateImportList = this.storeImportLocationTemplates;
    request.VenueId = this.authState.getStoredContextVenueId();
    this.cancelImportModel = false;
    this.locationTemplateService.validateSaveImportLocationTemplates(request).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data.isSuccess) {
          this.SaveImportDataForImport(request);
        } else {
          this.locationTemplateImportRequest = request;
          this.validateImportConfirmationResult = data;
          if (!!data.MisMatchedLocationTypes) {
            this.templateImportErrorStringLineOne = 'No Location Template(s) have been imported.';
            this.templateImportErrorStringLineTwo =
              'The following Location Type(s) do not exist in the target Venue.  Please import or create them and try again.';
            this.templateImportErrorStringLineThree = data.MisMatchedLocationTypes;
            this.cancelImportModel = true;
            this.importLocationTypeNotFoundModel = true;
          }
          this.ConfirmValidationForImport();
        }
      },
      (error) => {
        this.loaderService.display(false);
        this.isImportReqComplete = true;

        console.log(error);
        if (error.status == 409) {
          if (error && error.error && error.error.Message) {
            this.utilitiesService.smmodal('Import Location Templates', error.error.Message);
          }
        }
      },
    );
  }

  public SaveImportDataForImport(locationTemplateImportRequest) {
    this.cancelImportModel = false;
    this.loaderService.display(true);
    this.importLocationTypeNotFoundModel = false;
    this.cancelImportModel = false;
    this.locationTemplateService.saveImportLocationTemplates(locationTemplateImportRequest).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.isImportReqComplete = true;
        this.utilitiesService.smmodal('Import Location Templates', data.Message);
        this.backToLocationTemplateList();
      },
      (error) => {
        this.loaderService.display(false);
        this.isImportReqComplete = true;

        console.log(error);
        if (error.status == 409) {
          if (error && error.error && error.error.Message) {
            this.utilitiesService.smmodal('Import Location Templates', error.error.Message);
          }
        }
      },
    );
  }
  public ClearValidationErrorsForImport() {
    this.importConfirmationErrorModal = false;
    this.importLocationTypeNotFoundModel = false;
    this.templateImportErrorStringLineOne = '';
    this.templateImportErrorStringLineTwo = '';
    this.templateImportErrorStringLineThree = '';
  }
  public ConfirmValidationForImport() {
    if (this.cancelImportModel) {
    } else if (!!this.validateImportConfirmationResult.MisMatchedTasks) {
      this.importConfirmationErrorModal = true;
      this.templateImportErrorStringLineOne =
        'The following Tasks are not configured either in the Source or the Target';
      this.templateImportErrorStringLineTwo = this.validateImportConfirmationResult.MisMatchedTasks;
      this.templateImportErrorStringLineThree =
        ' By continuing, Tasks of the Source will determine the Tasks checked in the Target.  Do you want to continue?';
      this.validateImportConfirmationResult.MisMatchedTasks = '';
    } else if (!!this.validateImportConfirmationResult.MisMatchedInventories) {
      this.importConfirmationErrorModal = true;
      this.templateImportErrorStringLineOne =
        'The following Inventories are not are not configured either in the Source or the Target';
      this.templateImportErrorStringLineTwo = this.validateImportConfirmationResult.MisMatchedInventories;
      this.templateImportErrorStringLineThree =
        ' By continuing, Inventories of the Source will determine the Inventories checked in the Target.  Do you want to continue?';
      this.validateImportConfirmationResult.MisMatchedInventories = '';
    } else if (!!this.validateImportConfirmationResult.MisMatchedElements) {
      this.importConfirmationErrorModal = true;
      this.templateImportErrorStringLineOne =
        'The following Elements are not configured either in the Source or the Target';
      this.templateImportErrorStringLineTwo = this.validateImportConfirmationResult.MisMatchedElements;
      this.templateImportErrorStringLineThree =
        ' By continuing, Elements of the Source will determine the Elements checked in the Target.  Do you want to continue?';
      this.validateImportConfirmationResult.MisMatchedElements = '';
    } else if (!!this.validateImportConfirmationResult.MisMatchedAreas) {
      this.importConfirmationErrorModal = true;
      this.templateImportErrorStringLineOne =
        'The following Areas are not configured either in the Source or the Target';
      this.templateImportErrorStringLineTwo = this.validateImportConfirmationResult.MisMatchedAreas;
      this.templateImportErrorStringLineThree =
        ' By continuing, Areas of the Source will determine the Areas checked in the Target.  Do you want to continue?';
      this.validateImportConfirmationResult.MisMatchedAreas = '';
    } else {
      this.SaveImportDataForImport(this.locationTemplateImportRequest);
    }
  }

  public DownloadImportLogFile(DownloadId: number) {
    this.loaderService.display(true);
    const payload = new ImportLocationTypeLog();
    payload.ImportLocationTemplateLogId = DownloadId;

    this.locationTemplateService.downloadImportLogFile(payload).subscribe(
      (result) => {
        this.loaderService.display(false);
        const date = new Date();
        let month = date.getMonth() + 1;
        const timestamp =
          date.getFullYear().toString() +
          month.toString() +
          date.getDate().toString() +
          date.getHours().toString() +
          date.getMinutes().toString() +
          date.getMilliseconds().toString();
        const filename = timestamp.toString() + '.xlsx';
        this.downloadFile(result, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', filename);
      },
      (error) => {
        console.log(
          'locationTypeService downloadImportLogFile Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }
  public downloadFile(blob: any, filetype: string, filename: string) {
    const binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: filetype }));

    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
  }

  get selectedElements() {
    return this.location.LocationElements.filter((e) => e.IsActive);
  }
  get canRebalance(): boolean {
    return this.selectedElements.length > 0;
  }

  public rebalanceClicked() {
    const rebalanced = this.locationSetupService.RebalanceElements(this.selectedElements);
    if (rebalanced) {
      this.recalculaTotalWeightage(this);
    }
  }
}
