<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 form-heading" *ngIf="sensormanufacturers">
        <i class="fa fa-podcast heading-icon"></i>
        <span class="list-heading">SENSOR MANUFACTURERS</span>
      </div>

      <div class="col-lg-6 col-md-8 col-sm-12 form-heading" *ngIf="!sensormanufacturers">
        <i class="fa fa-podcast heading-icon"></i>
        <span class="list-heading" *ngIf="!sensormanufacturers && !sensormanufacturer.SensorManufacturerId"
          >ADD SENSOR MANUFACTURER</span
        >
        <span class="list-heading" *ngIf="!sensormanufacturers && sensormanufacturer.SensorManufacturerId"
          >EDIT SENSOR MANUFACTURER</span
        >
      </div>

      <div class="col-lg-8 col-md-12 col-sm-12">
        <div
          id="page-size"
          class="col-lg-1 col-sm-3 col-md-4 col-xs-12 pull-right list-heading pageSize"
          *ngIf="sensormanufacturers"
        >
          <ngx-atlas-select
            (model)="SensorManufacturerPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="sensormanufacturers && !sensormanufacturer.SensorManufacturerId"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Sensor Manufacturer
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="sensormanufacturers">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="sensormanufacturers">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="sensorManufacturersSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="sensormanufacturers">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('SensorManufacturerName', columnSortOrder)">
                  Sensor Manufacturer
                  <span *ngIf="selectedColumnName == 'SensorManufacturerName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'SensorManufacturerName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let sensormanufacturer of sensormanufacturers
                  | paginate: { itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getSensorManufacturerById(sensormanufacturer.SensorManufacturerId)">
                <td>{{ sensormanufacturer.SensorManufacturerName }}</td>
                <td *ngIf="sensormanufacturer.IsActive" style="width: 7%">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!sensormanufacturer.IsActive" style="width: 7%">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!sensormanufacturers">
    <form #sensormanufacturerform="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!--<div class="col-lg-6 col-md-6 col-sm-12">
        <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label for="SensorManufacturerName"><span id="red-circle">&#x25CF;</span> Sensor Manufacturer Name</label>
            <div>
                <input id="SensormanufacturerName" type="text" placeholder="Sensor Manufacturer Name" name="SensormanufacturerName" class="form-control"
                       [(ngModel)]="sensormanufacturer.SensorManufacturerName" required maxlength="120" [pattern]="utilitiesService.alphanumericRegex" #SensorManufacturerName="ngModel" autocomplete="off" />
                <div *ngIf="SensorManufacturerName.errors && (SensorManufacturerName.dirty || SensorManufacturerName.touched)"
                     class="alert alert-danger">
                    <div [hidden]="!SensorManufacturerName.errors.required" class="text-danger">
                        Sensor manufacturer name is required.
                    </div>
                    <div [hidden]="!SensorManufacturerName.errors.pattern" class="text-danger">
                        {{utilitiesService.alphanumericRegexMessage}}
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group" *ngIf="sensormanufacturer.SensorManufacturerId">
                <div>
                    <mat-checkbox [(ngModel)]="sensormanufacturer.IsActive" name="isActive">Is Active</mat-checkbox>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
            <input type="button" value="Cancel" class="btn btn-type pull-right btn-cancel" (click)="clearFormData(sensormanufacturerform)" />
            <input type="button" value="Save" *ngIf="!sensormanufacturer.SensorManufacturerId" class="btn btn-type btn-save" [disabled]="!sensormanufacturerform.form.valid || isReqComplete" (click)="addSensorManufacturer(sensormanufacturerform)" />
            <input type="button" value="Save" *ngIf="sensormanufacturer.SensorManufacturerId" class="btn btn-type btn-save" [disabled]="!sensormanufacturerform.form.valid || isReqComplete" (click)="updateSensorManufacturer(sensormanufacturerform)" />
        </div>
    </div>-->

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="SensorManufacturerName"><span id="red-circle">&#x25CF;</span> Sensor Manufacturer Name</label>
            <div>
              <input
                id="SensormanufacturerName"
                type="text"
                placeholder="Sensor Manufacturer Name"
                name="SensormanufacturerName"
                class="form-control"
                [(ngModel)]="sensormanufacturer.SensorManufacturerName"
                required
                maxlength="120"
                [pattern]="utilitiesService.alphanumericRegex"
                #SensorManufacturerName="ngModel"
                autocomplete="off"
              />
              <div
                *ngIf="
                  SensorManufacturerName.errors && (SensorManufacturerName.dirty || SensorManufacturerName.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!SensorManufacturerName.errors.required" class="text-danger">
                  Sensor manufacturer name is required.
                </div>
                <div [hidden]="!SensorManufacturerName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="serviceProviderDivider" />
        <h3>Publisher Integration Key</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <div
            class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
            *ngIf="
              sensormanufacturer != null &&
              sensormanufacturer.SensorManufacturerIntegrationKey != null &&
              sensormanufacturer.SensorManufacturerIntegrationKey.length > 0 &&
              isPublisherKeySectionShow
            "
            style="margin-bottom: 15px; max-height: 300px"
          >
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Key</th>
                  <th style="text-align: center">Is Active</th>
                  <th style="text-align: center">Remove</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let integrationKey of sensormanufacturer.SensorManufacturerIntegrationKey">
                  <tr *ngIf="integrationKey.IsDelete == 0">
                    <td class="srno form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Integration Key Name"
                        [(ngModel)]="integrationKey.IntegrationKeyName"
                        id="IntegrationKeyName_{{ integrationKey.IntegrationKeyRowId }}"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </td>
                    <td class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Integration Key Value"
                        [(ngModel)]="integrationKey.IntegrationKeyValue"
                        id="IntegrationKeyValue_{{ integrationKey.IntegrationKeyRowId }}"
                        [ngModelOptions]="{ standalone: true }"
                        readonly
                      />
                    </td>
                    <td style="text-align: center; padding-top: 15px">
                      <mat-checkbox
                        [(ngModel)]="integrationKey.IsActive"
                        id="IntegrationKeyIsActive_{{ integrationKey.IntegrationKeyRowId }}"
                        [ngModelOptions]="{ standalone: true }"
                      ></mat-checkbox>
                    </td>
                    <td style="text-align: center; vertical-align: middle" rowspan="2">
                      <button class="btn btn-purple" (click)="removeIntegrationKey(integrationKey.IntegrationKeyRowId)">
                        <i class="fa fa-times"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <textarea
                        id="IntegrationKeyValue_{{ integrationKey.IntegrationKeyRowId }}"
                        [ngModelOptions]="{ standalone: true }"
                        #messageType="ngModel"
                        type="text"
                        class="form-control"
                        placeholder="Message Type"
                        [(ngModel)]="integrationKey.MessageTypes"
                        autocomplete="off"
                        maxlength="1000"
                      ></textarea>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <input
              type="button"
              value="Generate New Key"
              class="btn btn-type pull-right btn-cancel"
              (click)="generateIntegrationKey()"
            />
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="sensormanufacturer.SensorManufacturerId">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <div>
              <mat-checkbox [(ngModel)]="sensormanufacturer.IsActive" name="isActive">Is Active</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type pull-right btn-cancel"
            (click)="clearFormData(sensormanufacturerform)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!sensormanufacturer.SensorManufacturerId"
            class="btn btn-type btn-save"
            [disabled]="!sensormanufacturerform.form.valid || isReqComplete"
            (click)="addSensorManufacturer(sensormanufacturerform)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="sensormanufacturer.SensorManufacturerId"
            class="btn btn-type btn-save"
            [disabled]="!sensormanufacturerform.form.valid || isReqComplete"
            (click)="updateSensorManufacturer(sensormanufacturerform)"
          />
        </div>
      </div>
    </form>
  </div>
</section>
