import { environment as higherEnvironment } from './environment.flagship.prod';
export const environment = {
  ...higherEnvironment,
  production: false,
  server: 'https://api.sandbox.traxinsights.app/',
  touchlessFeedbackAppBaseURL: 'https://touchless.sandbox.flagship.traxinsights.app/#/',
  cleaningAlertRequestURL: 'https://ca.sandbox.flagship.traxinsights.app/#/',
  projectTitle: 'Flagship Intuition',
  faviconUrl: 'assets/flagship/flagship_fav_icon.ico',
};
