import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { LevelComponent } from './components/level.component';
import { LevelRoutes } from './level.routes';

import { VenueModule } from '../venue/venue.module';

@NgModule({
  imports: [CommonModule, LevelRoutes, NgxPaginationModule, FormsModule, HomeModule],
  declarations: [LevelComponent],
})
export class LevelModule extends GenericEntity {
  public LevelId: number;
  public LevelCPId: string;
  public LevelName: string;
  public Ordinal: number;
  public Venue: VenueModule;
}
