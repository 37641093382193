import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { ImportDataModule } from '../importdata.module';
@Injectable({
  providedIn: 'root',
})
export class ImportDataService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetAllData = (body: string, page: number, size: number): Observable<ImportDataModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/import/transactions/' + page + '/' + size;
    return this.http.post<ImportDataModule[]>(_url, body);
  };

  //this is being used for workorder import as well, as such, I think its best not to change for now
  public ImportTransactionFiles(filedata: any[], urlString: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + urlString;
      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      for (let i = 0; i < filedata.length; i++) {
        formData.append('file' + i, filedata[i], filedata[i].name);
      }
      xhr.send(formData);
    });
  }

  public ImportBulkFiles(filedata: any[], importType: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + 'venue/import/bulk/' + importType;
      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      for (let i = 0; i < filedata.length; i++) {
        formData.append('file' + i, filedata[i], filedata[i].name);
      }
      xhr.send(formData);
    });
  }

  public getFileDownload = (url: string): Observable<any> => {
    return this.http.get(url);
  };

  //search for this ed
  public DownloadData(importType: any): Observable<any> {
    const urlString = 'venue/export/template';
    const queryParam = '?importType=';
    const url: string = this.configuration.Server + urlString + queryParam + importType;
    return new Observable((obs) => {
      const oReq = new XMLHttpRequest();
      oReq.open('GET', url, true);
      oReq.setRequestHeader('content-type', 'application/json');
      oReq.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      oReq.responseType = 'arraybuffer';

      oReq.onload = function () {
        const arrayBuffer = oReq.response;
        const byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };
      oReq.send();
    });
  }
}
