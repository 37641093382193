import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Configuration } from 'src/app/app.constants';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { ElementModule } from '../element.module';
import { ElementService } from './element.http.service';

@Component({
  selector: 'element.component',
  templateUrl: 'element.component.html',
  styleUrls: ['./element.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ElementService, UtilitiesService],
})
export class ElementComponent implements OnInit {
  currentUser: User;

  @ViewChild('fileInput') fileInputVariable: any;

  public elements: ElementModule[] = [];
  public storeElements: any[] = [];
  public element: ElementModule = new ElementModule();

  public venues: VenueModule[] = [];

  page = 1;
  size = 10;
  count = 0;
  searchString = '';
  pageSizes: any[] = [];

  isReqComplete = false;
  taskTypes = [
    { id: 'B', name: 'Boolean' },
    { id: 'T', name: 'Text' },
  ];
  listviewtypes: any[];
  viewType: any = null;

  file: File = null;
  profileImg = 'assets/no-image.jpg';
  profileImgSrc: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  elementModal = false;
  elementImg = '';
  elementImgSrc = this.configuration.Server_Element_Image_Folder;
  public pageState: StateMaintain = {
    pageName: 'elementForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };
  public elementName = '';

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private elementService: ElementService,
    private loaderService: LoaderService,
    private configuration: Configuration,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.element.Venue = new VenueModule();
  }

  ngOnInit() {
    this.elementModal = false;
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;

    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllElements(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllElements(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setUpdateForm() {
    this.searchString = '';
    this.elements = null;
    this.count = 0;
  }

  public setAddForm() {
    this.profileImgSrc = null;
    this.searchString = '';
    this.elements = null;
    this.count = 0;
    this.file = null;
    this.element = new ElementModule();
    this.element.Venue = new VenueModule();
  }

  public hasData(): boolean {
    return this.element != null && this.elements.length > 0;
  }

  public clearFormData(ElementForm: NgForm) {
    if (this.storeElements.length === 1 && this.element.IsActive === 0) {
      this.page = 1;
      this.storeElements = [];
    }
    ElementForm.reset();
    this.searchString = '';
    this.element.Venue = new VenueModule();
    this.profileImgSrc = null;
    this.file = null;
    this.getAllElements(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllElements(this.searchString, this.page, this.size, this.viewType);
  }

  public elementsSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllElements(searchString, 1, this.size, this.viewType);
  }

  public getAllElements(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);

    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.elementService.GetElementList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.elements = data;
        this.storeElements = data;
        if (this.elements.length < 0 && this.elements[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'elementService getAllElements Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.elements[0] ? this.elements[0].Count : 0),
    );
  }

  public addElement(ElementForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (ElementForm.status == 'INVALID' && ElementForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Element', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.element.ElementName = this.utilitiesService.removeInnerSpaces(this.element.ElementName);
    if (this.element.ElementName == null || this.element.ElementName == '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.element.AddedBy = this.currentUser.UserId;
    this.elementService.Add(this.utilitiesService.stripScript(this.element)).subscribe(
      (data) => {
        this.page = 1;

        if (this.file) {
          let that = this;
          this.elementService
            .addElementImage(this.file, data.msg)
            .then(function (_response) {
              that.isReqComplete = false;
              that.loaderService.display(false);
              that.utilitiesService.smmodal('Element', data.Message);
              that.clearFormData(ElementForm);
            })
            .catch((err) => {
              console.log(err);
              this.isReqComplete = false;
              this.loaderService.display(false);
              this.utilitiesService.smmodal('Element', data.Message);
              this.clearFormData(ElementForm);
            });
        } else {
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.utilitiesService.smmodal('Element', data.Message);
          this.clearFormData(ElementForm);
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'elementService addElement Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public updateElement(ElementForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (ElementForm.status == 'INVALID' && ElementForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Element', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.element.ElementName = this.utilitiesService.removeInnerSpaces(this.element.ElementName);
    if (this.element.ElementName == null || this.element.ElementName == '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.element.ModifiedBy = this.currentUser.UserId;
    this.element.IsActive = this.element.IsActive ? 1 : 0;

    if (this.file) {
      let that = this;
      this.elementService
        .addElementImage(this.file, this.element.ElementId)
        .then(function (_response) {
          that.isReqComplete = false;
          that.loaderService.display(false);
          that.updateElementFun(ElementForm);
        })
        .catch((err) => {
          console.log(err);
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.updateElementFun(ElementForm);
        });
    } else if (this.element.ImageName && this.profileImgSrc == null) {
      const CPelement = new ElementModule();
      CPelement.ElementCPId = this.authState.AESEncryptText(this.element.ElementId);

      this.elementService.RemoveElementImage(CPelement).subscribe(
        (_res) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.updateElementFun(ElementForm);
        },
        (error) => {
          console.log(
            'elementService removeImage Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.updateElementFun(ElementForm);
        },
      );
    } else {
      this.isReqComplete = false;
      this.loaderService.display(false);
      this.updateElementFun(ElementForm);
    }
  }

  public updateElementFun(ElementForm: any) {
    this.isReqComplete = true;
    this.loaderService.display(true);

    this.elementService.Update(this.utilitiesService.stripScript(this.element)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Element', data.Message);
        this.clearFormData(ElementForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'elementService updateElement Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public openElementModal() {
    this.elementModal = true;
  }

  public closeElementModal() {
    this.elementModal = false;
  }

  public deleteElement() {
    if (this.element.ElementId) {
      this.isReqComplete = true;
      this.loaderService.display(true);
      const CPelment = new ElementModule();
      CPelment.ElementCPId = this.authState.AESEncryptText(this.element.ElementId);

      this.elementService.Delete(CPelment).subscribe(
        () => {
          this.closeElementModal();
          this.clearPopUpData();
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.utilitiesService.snackBarWithAutoDismiss('Element', 'Element deleted successfully!');
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          console.log(
            'elementService deleteElement Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    }
  }

  public getElementById(elementId: number) {
    this.loaderService.display(true);
    const CPlement = new ElementModule();
    CPlement.ElementCPId = this.authState.AESEncryptText(elementId);
    this.elementService.GetSingle(CPlement).subscribe(
      (data) => {
        this.element = data;

        if (this.element.AttachmentURL != null) {
          this.profileImgSrc = this.element.AttachmentURL;
        } else {
          this.profileImg = 'assets/no-image.jpg';
        }
        this.setUpdateForm();
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'elementService getElementById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllElements(this.searchString ? this.searchString : '', this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllElements(this.searchString, this.page, this.size, this.viewType);
  }

  public fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Element', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const extention = event.target.files[0].name.split('.');
      const ext = extention[extention.length - 1].toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
        if (event.target.files && event.target.files[0]) {
          const filesize = event.target.files[0].size / Math.pow(1024, 2);
          if (filesize > this.utilitiesService.fileUploadSize) {
            this.utilitiesService.mdmodal(
              'Element',
              'Picture should not be more than ' + this.utilitiesService.fileUploadSize + ' MB.',
            );
            event.target.value = '';
            return;
          } else {
            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.file = fileList[0];
              const reader = new FileReader();
              reader.onload = this._handleReaderLoaded.bind(this);
              reader.readAsBinaryString(this.file);
            }
          }
        }
      } else {
        this.utilitiesService.smmodal('Element', 'Please upload image of type .jpg, .jpeg, .png.');
        event.target.value = '';
      }
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.profileImgSrc = 'data:image/jpeg;base64,' + btoa(binaryString); // Converting binary string data.
  }

  fileReset() {
    this.fileInputVariable.nativeElement.value = '';
    this.file = null;
    this.profileImgSrc = null;
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllElements(this.searchString, this.page, this.size, this.viewType);
  }

  public clearPopUpData() {
    if (this.storeElements.length === 1) {
      this.page = 1;
      this.storeElements = [];
    }
    this.searchString = '';
    this.profileImgSrc = null;
    this.file = null;
    this.element = new ElementModule();
    this.element.Venue = new VenueModule();
    this.getAllElements(this.searchString, this.page, this.size, this.viewType);
  }

  public ElementPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllElements(this.searchString, this.page, this.size, this.viewType);
  }

  public openElementImage(imageName: string, elementName: string) {
    this.elementImg = '';
    this.elementImg = imageName;
    this.elementName = '';
    this.elementName = elementName;
  }
}
