import { createAction, props } from '@ngrx/store';
import { RelUserRolePermissions } from 'src/app/core/models/role.model';
import { User } from 'src/app/core/models/user.model';

// Defines an NgRx action named setUserData
// Actions are payloads of information that send data from your application to your store.
// This action will be used to set the current user data and user permissions in the state.
// props allows you to define the properties that the action will carry, in this case, an optional user object of type User

export const setUserData = createAction(
  '[User] Set Current User Data',
  props<{
    user: User;
  }>(),
);

export const setUserPermissions = createAction(
  '[User] Set Current User Permissions',
  props<{
    permissions: RelUserRolePermissions[];
  }>(),
);

export const setUserName = createAction(
  '[User] Set User Name',
  props<{
    FirstName: string;
    LastName: string;
  }>(),
);

export const setUserImage = createAction(
  '[User] Set User Image',
  props<{
    ImageName: string;
  }>(),
);

export const setUserCorporationAndVenue = createAction(
  '[User] Set User Corporation',
  props<{
    CorporationId: number;
    CorporationName: string;
    DefaultVenueId: number;
  }>(),
);
