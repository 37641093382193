import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/core/services/auth.service';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authState: AuthState) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authState.canActivate(_route, state);
  }
}
