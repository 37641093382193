import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { BuildingRoutes } from './building.routes';
import { BuildingComponent } from './components/building.component';

import { LevelModule } from '../level/level.module';
import { VenueModule } from '../venue/venue.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [CommonModule, BuildingRoutes, FormsModule, NgxPaginationModule, HomeModule, SharedModule],

  declarations: [BuildingComponent],
})
export class BuildingModule extends GenericEntity {
  public BuildingId: number;
  public BuildingCPId: string;
  public BuildingName: string;
  public BuildingMinTime: number;
  public BuildingMaxTime: number;
  public BuildingAvgTime: number;
  public BuildingMedianTime: number;
  public BuildingAvgTimeperHour: number;
  public BuildingAvgCount: number;
  public BuildingRefNumber: number;
  public BuildingStartTime: string;
  public BuildingEndTime: string;
  public BuildingClosedTime: string;
  public BuildingRefNo: number;
  public BuildingDisplayName: string;
  public Level: LevelModule;
  public Venue: VenueModule;
}

export class BuildingLite extends GenericEntity {
  public BuildingId: number;
  public BuildingCPId: string;
  public BuildingName: string;
}
