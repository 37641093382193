import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { CorporationModule } from '../../corporationmanagement/corporation/corporation.module';
import { ServiceProviderModule } from '../../serviceprovider/serviceprovider.module';
import { BadgeModule } from '../../venuemanagement/badge/badge.module';
import { CityModule } from '../../venuemanagement/city/city.module';
import { CountryModule } from '../../venuemanagement/country/country.module';
import { StateModule } from '../../venuemanagement/state/state.module';
import { VenueModule } from '../../venuemanagement/venue/venue.module';
import { UserRoleModule } from '../role/role.module';

export class User extends GenericEntity {
  public Password: any;
  public UserRole: UserRoleModule;
  public SessionTime: number;
  public Contact: string;
  public AddressLineOne: string;
  public AddressLineTwo: string;
  public ParentUserId: number;
  public ParentUserName: string;
  public ImageName: string;
  public Comment: string;
  public rememberMe: boolean;
  public Zipcode: string;
  public ParentUser: ParentUser;
  public Company: string;
  public TotalAmount: number;
  public UserTotalPayToVenue: number;
  public QRCode: any;
  public Gender: string;
  public RelUserVenues: RelUserVenue[];
  public RequesterId: number;
  public RequesterName: string;
  // public LicenseExpiryDate: any;
  // public LicenseKey: string;

  public Venue: VenueModule;
  public Country: CountryModule;
  public State: StateModule;
  public City: CityModule;
  public Corporation: CorporationModule;
  public DefaultVenueId: number;
  public Badge: BadgeModule;
  public ServiceProvider: ServiceProviderModule;
  public RoleCode: string;
  public DefaultVenue: VenueModule;
  public UserApplicationLevel: string;
  public RelUserCorporations: RelUserCoporation[];
  public userCorporations: any;
  public IsSharedUser: number;

  public ContextRoleId: number;
  public ContextRoleName: string;
  public UserType: UserTypeModule;
  public AttachmentURL: string;

  public lastUpdated?: Date;
}

// change parentUser - ParentUser for pascal case
export class ParentUser extends GenericEntity {
  public ParentUserId: number;
  public FirstName: string;
  public LastName: string;
}

// User venue assign
export class RelUserVenue extends GenericEntity {
  public RelUserVenueId: number;
  public UserId: number;
  public Venue: VenueModule;
}

export class UserLite extends GenericEntity {
  public Badge: BadgeModule;
  public UserRole: UserRoleModule;
  public Gender: string;
}

export class SalesforceUserLicenceRequest extends GenericEntity {
  public CorporationId: number;
  public CorporationName: string;
  public RequestedUserLicence: number;
}

export class RelUserCoporation extends GenericEntity {
  public CorporationId: number;
  public CorporationName: string;
}

export class UserTypeModule {
  public UserTypeId: number;
  public UserType: string;
}
