import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { UserStoreModule } from './user-data/user-store.module';

@NgModule({
  declarations: [],
  imports: [
    UserStoreModule,
    StoreModule.forRoot({}),
    // configuration for the Redux DevTools Extension
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25 }),
  ],
})
export class RootStoreModule {}
