import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { SensorManufacturerModule } from '../sensormanufacturer.module';

@Injectable({
  providedIn: 'root',
})
export class SensorManufacturerService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  // create Sensor Manufacturer
  public Add = (SensorManufacturer: SensorManufacturerModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/manufacturer';
    return this.http.post<ResponseMessage>(_url, SensorManufacturer);
  };

  // Update Sensor Manufacturer
  public Update = (SensorManufacturer: SensorManufacturerModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/manufacturer';
    return this.http.put<ResponseMessage>(_url, SensorManufacturer);
  };

  public GetSensorManufacturerList = (
    body: string,
    page: number,
    size: number,
  ): Observable<SensorManufacturerModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/all/sensor/manufactureres/' + page + '/' + size;
    return this.http.post<SensorManufacturerModule[]>(_url, body);
  };

  public GetSensorManufacturerDropdown = (
    body: string,
    page: number,
    size: number,
  ): Observable<SensorManufacturerModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/manufactureres/' + page + '/' + size;
    return this.http.post<SensorManufacturerModule[]>(_url, body);
  };

  // Get Sensor Manufacturer by id
  public GetSingle = (body: SensorManufacturerModule): Observable<SensorManufacturerModule> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/manufacturer/details';
    return this.http.post<SensorManufacturerModule>(_url, body);
  };
}
