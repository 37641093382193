import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  DefaultTreeviewEventParser,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewModule,
} from 'ngx-treeview';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { ParentPermissions } from '../role/role.module';
import { UserRoleTemplateComponent } from './components/userroletemplate.component';
import { UserRoleTemplateRoutes } from './userroletemplate.routes';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    UserRoleTemplateRoutes,
    HomeModule,
    TreeviewModule,
    SharedModule,
  ],
  providers: [
    TreeviewConfig,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],
  declarations: [UserRoleTemplateComponent],
})
export class UserRoleTemplateModule extends GenericEntity {
  public UserRoleTemplateId: number;
  public UserRoleTemplateCPId: string;
  public RoleTemplateName: string;
  public ParentPermissionsList: ParentPermissions[];
  public RoleTemplatePermissionList: UserRoleTemplatePermissions[];
}

export class UserRoleTemplatePermissions extends GenericEntity {
  public UserRoleTemplatePermissionsId: any;
  public PAccess: number;
  public UserRoleTemplateId: number;
  public UserRoleTemplateCPId: string;
  public RoleTemplateName: string;
  public Permission: ParentPermissions;
}
