import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Configuration } from 'src/app/app.constants';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { InspectionDetailService } from '../../../inspectionmanagement/inspection/components/inspection.http.service';
import { BeaconModule } from '../../../staffscheduling/beacon/beacon.module';
import { LocationModule } from '../../../staffscheduling/location/location.module';
import { User } from '../../../usermanagement/user/user.model';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { InspectionBeacon, InspectionModule, InspectionStatus } from '../inspectionlog.module';
import { InspectionLogService } from './inspectionlog.http.service';

@Component({
  selector: 'inspectionlog.component',
  templateUrl: 'inspectionlog.component.html',
  styleUrls: ['./inspectionlog.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [UtilitiesService, InspectionLogService, InspectionDetailService],
})
export class InspectionComponent implements OnInit {
  currentUser: User;
  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  searchTerm: string;
  isReadOnlyField = false;
  attachement = false;
  public status: InspectionStatus[] = [];
  public inspections: InspectionModule[] = [];
  public inspect: InspectionModule = new InspectionModule();
  // public inspectElement: ElementModule = new ElementModule();
  // public ElementList: ElementModule[] = [];
  // public AttachmentData: InspectionElement[] = [];
  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  public pageState: StateMaintain = { pageName: 'inspectionLogForm', page: undefined, size: undefined };
  isAssignedTo = false;
  InspectAttachment = '';
  OverallComment = 'No comment available';
  OverallRating = 'No rating available';
  StatusId: number;
  public inspectors: User[] = [];
  isAssignToReq = false;
  public isReAssignShow = false;
  disableStatusField = false;
  inspectionDetailsBeacons: InspectionBeacon[] = [];
  lastinspectionBeaconDetails: InspectionBeacon;
  isReqComplete = false;
  InspectionImg = '';
  public videoSource = ' ';
  public vedioModal = false;

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private InspectionService: InspectionLogService,
    private inspectionDetailService: InspectionDetailService,
    private loaderService: LoaderService,
    private configuration: Configuration,
  ) {
    this.inspect.Venue = new VenueModule();
    this.inspect.Location = new LocationModule();
    this.inspect.User = new User();
    this.inspect = new InspectionModule();
    this.inspect.InspectionStatus = new InspectionStatus();
    this.currentUser = this.authState.getCurrentUser();
  }

  ngOnInit() {
    this.InspectAttachment = this.configuration.Server_Inspection_Attachment_Folder;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    this.inspect.User = new User();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.getAllInspectList(this.searchString, this.page, this.size);
    } else {
      this.getAllInspectList(this.searchString, this.page, this.size);
    }
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllInspectList(this.searchString, this.page, this.size);
  }

  /// this funcation used to get result as per page size change
  public InspectLogPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllInspectList(this.searchString, this.page, this.size);
  }

  public clearFormData() {
    this.inspect = new InspectionModule();
    this.searchString = '';
    this.inspect.Venue = new VenueModule();
    this.inspect.Location = new LocationModule();
    this.inspect.User = new User();
    this.inspect.InspectionStatus = new InspectionStatus();
    this.getAllInspectList('', this.page, this.size);
    this.visibleIndex = -1;
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllInspectList('', this.page, this.size);
  }

  public inspectionLogSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllInspectList(searchString, 1, this.size);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.inspections = null;
    this.count = 0;
  }

  public hasData(): boolean {
    return this.inspections != null && this.inspections.length > 0;
  }

  // Get list of Inspection List
  public getAllInspectList(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = {
      ViewMyInspection: 1,
      Page: pageIndex,
      Size: pageSize,
      SearchQuery: searchTerm ? this.utilitiesService.removeInnerSpaces(searchTerm) : '',
      GenericSearch: 0,
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.InspectionService.GetAll(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.inspections = data;
        if (
          this.inspections.length < 0 &&
          this.inspections[0].Count / this.utilitiesService.initialPageSize < this.page
        ) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'InspectionService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.inspections[0] ? this.inspections[0].Count : 0),
    );
  }

  public getInspectionById(InspectionId: number) {
    if (
      this.authState.canAccess('INSPECTION_LOG_VIEW_MY_RECORDS') ||
      this.authState.canAccess('INSPECTION_LOG_VIEW_ALL_RECORDS') ||
      this.authState.canAccess('INSPECTION_LOG_VIEW_MY_LOCATIONS')
    ) {
      this.loaderService.display(true);
      this.isAssignToReq = false;
      this.getModulesData();
      const CPInspection = new InspectionModule();
      CPInspection.InspectionCPId = this.authState.AESEncryptText(InspectionId);

      this.InspectionService.GetSingle(CPInspection).subscribe(
        (data) => {
          this.inspect = data;
          this.inspect.User.FirstName = this.inspect.User.FirstName + ' ' + this.inspect.User.LastName;
          this.StatusId = this.inspect.InspectionStatus.InspectionStatusId;
          this.getAllStatus(this.StatusId);

          if (this.inspect.InspectionBeacons != null) {
            this.inspectionDetailsBeacons = this.getInspectionBeaconValues(this.inspect.InspectionBeacons);
          }

          this.getAllSupervisorsWorkersInspectors('INSPECT', InspectionId, this.inspect.Location.LocationId);
          this.isReadOnlyField = true;

          if (
            this.inspect.InspectionStatus.InsStatus == 'Complete' ||
            this.inspect.InspectionStatus.InsStatus == 'Cancel' ||
            this.inspect.InspectionStatus.InsStatus == 'Follow_up'
          ) {
            this.disableStatusField = true;
          } else {
            this.disableStatusField = false;
          }

          if (
            this.inspect.InspectionStatus.InsStatus == 'Open' ||
            this.inspect.InspectionStatus.InsStatus == 'Cancel'
          ) {
            this.isAssignToReq = false;
          } else {
            this.isAssignToReq = true;
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'InspectionService GetSingle Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Inspection Log', 'You do not have permission to view inspection details.');
    }
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllInspectList('', this.page, this.size);
  }

  visibleIndex = -1;
  public showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }
  public downloadCTImage(AttachmentName: string) {
    window.open(AttachmentName, '_blank');
  }

  public getAllStatus(StatusId: number) {
    const payload = { InspectionStatusId: StatusId };

    this.inspectionDetailService.GetStatus(JSON.stringify(payload)).subscribe(
      (data) => {
        this.status = data;
      },
      (error) => {
        console.log(
          'inspectionDetailService getAllStatus Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public changeStatus($event: number) {
    this.inspect.InspectionStatus.InspectionStatusId = $event;
    if (this.inspect.InspectionStatus.InspectionStatusId) {
      let obj = this.status.filter((s) => s.InspectionStatusId == $event);
      if (obj && obj[0]) {
        if (obj[0].InsStatus == 'Open' || obj[0].InsStatus == 'Cancel') {
          this.isAssignToReq = false;
        } else {
          this.isAssignToReq = true;
        }
      }
    } else {
      this.isAssignToReq = false;
    }
  }

  public getAllSupervisorsWorkersInspectors(eventType: string, inspectionId: number, locationId: number) {
    let payload = {
      LocationCPId: this.authState.AESEncryptText(locationId),
      InspectionCPId: this.authState.AESEncryptText(inspectionId),
    };

    if (eventType == 'INSPECT' && locationId) {
      this.loaderService.display(true);
      this.inspectionDetailService.GetAllSupervisorsWorkersInspectors(JSON.stringify(payload)).subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            data.forEach(function (value) {
              value.FirstName = value.FirstName + ' ' + value.LastName;
            });
            this.inspectors = data;
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'inspectionDetailService GetAllSupervisorsWorkersInspectors Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    }
  }

  public changeUser($event: number) {
    this.inspect.User.UserId = $event;
    if (this.inspect.User.UserId) {
      if (
        this.status &&
        this.status != null &&
        this.status.length > 0 &&
        this.inspect.User.UserId &&
        this.inspect.InspectionStatus.InspectionStatusId != 2
      ) {
        let statusObj = this.status.filter((s) => s.InspectionStatusId == 2);
        if (statusObj && statusObj.length > 0) {
          this.inspect.InspectionStatus.InspectionStatusId = statusObj[0].InspectionStatusId;
          this.isAssignToReq = true;
        }
      }
    } else {
      this.inspect.User.UserId = null;
    }
  }

  public addInspection(inspectionLogForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (inspectionLogForm.status == 'INVALID' && inspectionLogForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Inspection', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }
    const payload = {
      InspectionId: this.inspect.InspectionId,
      InspectionAssignedTo: this.inspect.User.UserId,
      InspectionStatusId: this.inspect.InspectionStatus.InspectionStatusId,
    };

    this.inspectionDetailService.ViewInspection(this.utilitiesService.stripScript(payload)).subscribe(
      (data) => {
        this.clearFormData();
        this.utilitiesService.smmodal('Inspection', data.Message);
        inspectionLogForm.reset();
        this.loaderService.display(false);
        this.isReqComplete = false;
      },
      (error) => {
        console.log(
          'inspectionDetailService ViewInspection Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
        this.isReqComplete = false;
      },
    );
  }

  public getModulesData() {
    this.isReAssignShow = this.utilitiesService.checkVenueFeatureConfiguration(
      'INS_ASSIGNMENT_REASSIGN_MANUAL_FROM_WEB',
    )
      ? false
      : true;
  }

  public getInspectionBeaconValues(beaconHistory: any[]) {
    const nameArrays = Object.create(null);

    let beaconname: any;
    let firstintime: any;
    let lastouttime: any;
    let lastArrayName: any;
    let batteryLevel: any;
    let timeElapse = '';
    // let lastEntryObject: any = {};
    const that = this;
    const newBeaconHistoryArray: InspectionBeacon[] = [];
    if (beaconHistory != null) {
      if (beaconHistory.length > 0) {
        beaconHistory.forEach(function (item, idx, array) {
          let nameArray = nameArrays[item.Beacon.BeaconName];
          if (nameArray != lastArrayName && lastArrayName != null) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntryObject = that.createNewInspectionObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );
            lastArrayName.push(lastEntryObject);

            newBeaconHistoryArray.push(lastEntryObject);
          }

          if (!nameArray) {
            nameArray = nameArrays[item.Beacon.BeaconName] = [];
            firstintime = item.OptIn;
          }

          nameArray.push(item);
          newBeaconHistoryArray.push(item);

          if (item.OptOut != null && item.OptOut != 'null') {
            lastouttime = item.OptOut;
          }

          if (array.length - 1 == 0) {
            lastArrayName = nameArray;
            beaconname = item.Beacon.BeaconName;
            batteryLevel = item.BatteryLevel;
          }

          if (idx == array.length - 1) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntry = that.createNewInspectionObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );

            lastArrayName.push(lastEntry);

            newBeaconHistoryArray.push(lastEntry);
          }
          lastArrayName = nameArray;
          beaconname = item.Beacon.BeaconName;
          batteryLevel = item.BatteryLevel;
        });
      }
    }
    return newBeaconHistoryArray;
  }

  public createNewInspectionObjectProperties(name: any, optin: any, optout: any, batteryLevel: any, timeElapsed: any) {
    this.lastinspectionBeaconDetails = new InspectionBeacon();
    this.lastinspectionBeaconDetails.Beacon = new BeaconModule();
    this.lastinspectionBeaconDetails.Beacon.BeaconName = name;
    this.lastinspectionBeaconDetails.OptIn = optin;
    this.lastinspectionBeaconDetails.OptOut = optout;
    this.lastinspectionBeaconDetails.BatteryLevel = batteryLevel;
    this.lastinspectionBeaconDetails.timeElapsed = timeElapsed;
    const entry = this.lastinspectionBeaconDetails;
    return entry;
  }

  public hasInspectionBeacons(): boolean {
    return this.inspectionDetailsBeacons != null && this.inspectionDetailsBeacons.length > 0;
  }

  public openInspectionLogImage(imageName: string) {
    this.InspectionImg = '';
    this.InspectionImg = imageName;
  }
}
