import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import {
  VenueSurveyConfiguration,
  VenueSurveyQuestionConfiguration,
} from '../../surveyquestionconfiguration/surveyquestionconfiguration.module';
import { SurveyQuestionResponseType } from '../surveyquestionresponseconfiguration.module';

@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionResponseConfigService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (surveyQuestion: VenueSurveyConfiguration): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/survey/question/response';
    return this.http.post<ResponseMessage>(_url, surveyQuestion);
  };

  public Update = (surveyQuestion: VenueSurveyConfiguration): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/survey/question/response';
    return this.http.put<ResponseMessage>(_url, surveyQuestion);
  };

  public GetSurveysByVenueId = (body: VenueModule): Observable<VenueSurveyConfiguration[]> => {
    const _url: string = this.configuration.Server + 'venue/survey/list';
    return this.http.post<VenueSurveyConfiguration[]>(_url, body);
  };

  public GetAllQuestionsBySurveyId = (
    body: string,
    page: number,
    size: number,
  ): Observable<VenueSurveyQuestionConfiguration[]> => {
    const _url: string = this.configuration.Server + 'venue/survey/question/' + page + '/' + size;
    return this.http.post<VenueSurveyQuestionConfiguration[]>(_url, body);
  };

  public GetSurveyQuestionResponseType = (): Observable<SurveyQuestionResponseType[]> => {
    const _url: string = this.configuration.Server + 'venue/survey/question/response/type';
    return this.http.get<SurveyQuestionResponseType[]>(_url);
  };
}
