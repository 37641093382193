import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { SubscriptionComponent } from './components/subscription.component';
import { SubscriptionRoutes } from './subscription.routes';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, SubscriptionRoutes, HomeModule],

  declarations: [SubscriptionComponent],
})
export class SubscriptionModule extends GenericEntity {
  public SubscriptionId: number;
  public SubscriptionCPId: string;
  public SubscriptionType: string;
  public ExpiryDate: any;
}
