import { Pipe, PipeTransform } from '@angular/core';
import { AuthState } from '../services/auth.service';

// This pipe returns whether the feature is accessible by the user.

@Pipe({
  name: 'canAccess',
})
export class CanAccessPipe implements PipeTransform {
  constructor(private authState: AuthState) {}

  transform(feature: string): boolean {
    return this.authState.canAccess(feature);
  }
}
