import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { ServiceProviderModule } from '../../../serviceprovider/serviceprovider.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { User } from '../../user/user.model';
@Injectable({
  providedIn: 'root',
})
export class ConfigureDefaultVenueService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetServiceProviderList = (serviceProvider: ServiceProviderModule): Observable<ServiceProviderModule[]> => {
    // const _url: string = this.configuration.Server + 'admin/user/configuration/service/providers';
    const _url: string = this.configuration.Server + 'admin/user/context/configuration/service/providers';
    return this.http.post<ServiceProviderModule[]>(_url, serviceProvider);
  };

  public GetServiceProviderCorporations = (corporation: CorporationModule): Observable<CorporationModule[]> => {
    // const _url: string = this.configuration.Server + 'admin/user/configuration/corporations';
    const _url: string = this.configuration.Server + 'admin/user/context/configuration/corporations';
    return this.http.post<CorporationModule[]>(_url, corporation);
  };

  public GetCorporationVenues = (venue: VenueModule): Observable<VenueModule[]> => {
    // const _url: string = this.configuration.Server + 'admin/user/configuration/venues';
    const _url: string = this.configuration.Server + 'admin/user/context/configuration/venues';
    return this.http.post<VenueModule[]>(_url, venue);
  };

  public GetCorporationsVenues = (venue: VenueModule): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/context/corporations/venues';
    return this.http.post<VenueModule[]>(_url, venue);
  };

  public setDefaultVenue = (user: User): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user/defaultvenue';
    return this.http.put<ResponseMessage>(_url, user);
  };
}
