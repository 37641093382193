import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirmmodal.component.html',
  styleUrls: ['./confirmmodal.scss'],
})
export class ConfirmComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

export class ModalConfirmData {
  icon = '';
  title = '';
  content = '';
  confirmButtonLabel = '';
  closeButtonLabel = '';

  constructor(data?: any) {
    if (data) {
      this.icon = data.icon;
      this.title = data.title;
      this.content = data.content;
      this.confirmButtonLabel = data.confirmButtonLabel;
      this.closeButtonLabel = data.closeButtonLabel;
    }
  }
}
