import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CorporationModule } from '../../corporationmanagement/corporation/corporation.module';
import { Module, ServiceProviderModule } from '../../serviceprovider/serviceprovider.module';
import { SubscriptionModule } from '../../usermanagement/subscription/subscription.module';
import { Pin, WorkorderPriority, WorkorderSeverity } from '../../workordermanagement/workorder/workorder.module';
import { CityModule } from '../city/city.module';
import { CountryModule } from '../country/country.module';
import { StateModule } from '../state/state.module';
import { VenueComponent } from './components/venue.component';
import { VenueRoutes } from './venue.routes';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import {
  DefaultTreeviewEventParser,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewModule,
} from 'ngx-treeview';

// import { ColorPickerModule } from 'ngx-color-picker';
// import { MatColorPickerComponent } from 'mat-color-picker/color-picker.component';
import { MatRadioModule } from '@angular/material/radio';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { ScrollSpyDirective } from 'src/wrappers/atlas.scroll/scroll.directive';
import { SharedModule } from 'src/app/shared/shared.module';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    VenueRoutes,
    FormsModule,

    NgxPaginationModule,
    HomeModule,
    RouterModule,
    TreeviewModule,
    MatRadioModule,
    SharedModule,
    // ColorPickerModule,
    // MatButtonModule,
    NgxMaskModule.forRoot(options),
    // NgMultiSelectDropDownModule
  ],

  providers: [
    TreeviewConfig,
    // MatColorPickerComponent,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],

  declarations: [VenueComponent, ScrollSpyDirective],
})
export class VenueModule extends GenericEntity {
  public CorporationIds?: string ;
  public VenueCPId: string;
  public VenueDefaultBuilding: number;
  public AgencyId: string;
  public VenueIcaoCode: string;
  public VenueIataCode: string;
  public VenueMinTime: number;
  public VenueMaxTime: number;
  public VenueAvgTime: number;
  public VenueMedianTime: number;
  public VenueAvgTimePerHour: number;
  public VenueAvgCount: number;
  public VenueStartTime: string;
  public VenueEndTime: string;
  public VenueClosedTime: string;
  public Timezone: string;
  public Timediff: any;
  public viewtype: any;
  public RelVenueAuthentication: RelVenueAuthentication[];
  public AuthenticationType: AuthenticationType;
  public ThresholdBeaconInRange: number;
  public ThresholdBeaconNotInRange: number;
  public Subscription: SubscriptionModule;
  public RelVenueSubscriptionId: number;
  public EnableEmergencyAlert: number;
  public EmergencyEmailAddresses: string;
  public IsPassive: any;
  public MaxBeaconScanTime: number;
  public MaxCleaningAlertCompletionTime: number;
  public VenueMapFiles: VenueMapFiles[] = [];
  public Latitude: any;
  public Longitude: any;
  public Zoom: number;
  public IsCustodialQcEnable: number;
  public IsMaintenanceQcEnable: number;
  public RestroomsUrl: string;
  public BeaconScanUrl: string;
  public BeaconBatteryLifeUrl: string;
  public DeviceBatteryLifeUrl: string;
  public CleaningAlertUrl: string;
  public IsBadgeEnable: any;
  public Pin: Pin;
  public WorkorderPriority: WorkorderPriority;
  public WorkorderSeverity: WorkorderSeverity;
  public Corporation: CorporationModule;
  public BeaconManufacturer: string;
  public VenueSurveyId: string;
  public MobileNumber: string;
  public AddressLineOne: string;
  public AddressLineTwo: string;
  public Country: CountryModule;
  public State: StateModule;
  public City: CityModule;
  public ZipCode: number;
  public UserAssignmentRule: UserAssignmentRuleModule;
  public Threshold: number;
  public WarnPercentage: number;
  public MapForCleaningAlert: any;
  public MapForMaintenance: any;
  public CmmsIntegrationType: CMMSIntegrationType;
  public RelVenueCmmsIntegrationTypeUrls: RelVenueCMMSIntegrationTypeUrl[];
  public CmmsIntegrationTypeKey: CMMSIntegrationTypeKey;
  public RelVenuePowerBI: RelVenuePowerBI;
  public CleaningAlertTriggerThreshold: number;

  public InspectionScoreGoal: number ;
  public NumberOfInspectionsGoal: number;

  public ServiceTicketResponseTimeGoal?: number ;
  public NumberOfServiceTicketsGoal?: number;

  public ThresholdReset: string;
  public FeedbackReset: string;
  public CreateAlertOnThresholdBreach: number;
  public EnableLocationStatus: number;
  public EnableDigitalScreen: number;

  public KontaktVenueId: string;
  public KontaktAPIKey: string;
  // public BadgeCleaningMinTime: number;

  public VenueTaskList: VenueTask[];
  public VenueInventoryList: VenueInventory[];
  public VenueElementList: VenueElement[];

  public Modules: Module[] = [];
  public FeatureConfiguration: FeatureConfiguration;

  public TimezoneCurrentDate: any;
  public InspectionScoreFactor: InspectionScoreFactor;
  public VenueInspectionScoreFactor: InspectionScoreFactor[];
  public VenueCurrrentTime: any;

  public LogoImageName: string;
  public LogoImageType: string;
  public ViewMyInspection: any;

  public PoweredBy: string;
  public VenueType: VenueType;

  public RelVenueMapIntegrationTypeUrls: RelVenueMapIntegrationTypeUrlModule[];
  public VenueMapIntegrationTypeId: number;
  public SignalRServiceURL: string;

  public EnableTouchlessFeedback: number;
  public EnableTouchlessFeedbackReason: number;
  public EnableTouchlessFeedbackComment: number;
  public SignalRMessageURL: string;
  public ServiceProvider: ServiceProviderModule;

  public VenueIntegrationKey: VenueIntegrationKey[];
  public Venue: LiteVenue;

  public ConsiderNonCompletedSurvey = 0;
  public ConsiderNeutralSurvey: string;

  public IsEquipmentPMEventEnabled: any;
  public EquimentPMConfigureTime: number;
  public IsProjectWorkEventEnabled: any;
  public ProjectWorkConfigureTime: number;

  public BadgeDefaultEndTime: number;
  public BadgeOutTime: number;

  public HideScheduleCA: any;

  public IsNotificationForNegativeFeedbackEnabled: number;
  public NumberOfNegativeFeedbacksForNotification: number;
  public AttachmentURL: string;
}

export class AuthenticationType extends GenericEntity {
  public AuthenticationTypeId: number;
  public AuthenticationTypeCPId: string;
  public AuthenticatonTypeName: string;
  public AuthenticationTypeKey: AuthenticationTypeKeys;
}

export class AuthenticationTypeKeys extends GenericEntity {
  public AuthenticationTypeKeyId: number;
  public AuthenticationTypeKeyName: string;
  public AuthenticationTypeKeyValue: string;
  public Sequence: number;
  public AuthenticatonTypeKeyNameLabel: string;
  public isVisible: boolean;
}

export class RelVenueAuthentication extends GenericEntity {
  public RelVenueAuthenticationId: number;
  public VenueId: number;
  public AuthenticationType: AuthenticationType;
}

export class VenueMapFiles {
  public serialNumber: number;
  public fileName: string;
}

export class UserAssignmentRuleModule extends GenericEntity {
  public UserAssignmentRuleId: any;
  public ShiftStaffSchedule: any;
  public LocationUserAssignment: any;
  public EitherOneOrThese: any;
  public OnDuty: any;
  public LocationStaffGender: any;
  public VenueId: any;
}

export class CMMSIntegrationType extends GenericEntity {
  public IntegrationTypeId: number;
  public IntegrationType: string;
  public IntegrationTypeCode: string;
  public CmmsIntegrationTypeKey: CMMSIntegrationTypeKey;
}

export class CMMSIntegrationTypeKey extends GenericEntity {
  public IntegrationTypeKeyId: number;
  public IntegrationTypeKeyName: string;
  public IntegrationTypeKeyLabelName: string;
  public IntegrationTypeId: number;
  public IntegrationTypeCPId: string;
  public isVisible: boolean;
  public IntegrationTypeKeyUrl: string;

  public ControlOptions: string;
  public ControlDataType: string;
  public ShowEye: boolean;
  public isShowEye: boolean;
  public Sequence: number;
  public IsRequired: number;
}

export class RelVenueCMMSIntegrationTypeUrl extends GenericEntity {
  public RelVenueCMMSIntegrationTypeUrlId: number;
  public VenueId: number;
  public CmmsIntegrationTypeKey: CMMSIntegrationTypeKey;
  public CmmsIntegrationType: CMMSIntegrationType;
}

export class RelVenuePowerBI {
  public RelVenuePowerBiId: number;
  public VenueId: number;
  public ThroughputReportURL: string;
  public SurveyReportURL: string;
  public CleaningReportURL: string;
  public PredictiveReportURL: string;
  public InspectionSummaryReportURL: string;
  public ZurnFlushValveSummaryReportURL: string;
}

export class FeatureConfiguration extends GenericEntity {
  public FeatureConfigurationId: number;
  public FeatureConfigurationName: string;
  public FeatureType: string;
  public DataType: string;
  public FeatureConfigurationCode: string;
  public VenueFeatureConfigurationId: number;
  public VenueId: number;
  public CorporationId: number;
  public VenueName: string;
  public CorporationName: string;

  public IsSelectedValue: any;
}

export class VenueTask extends GenericEntity {
  public TaskId: number;
  public VenueTaskId: number;
  public VenueTaskName: string;
  public Sequence: number;
  public ImageType: string;
  public ImageTypeName: string;
  public IsMasterTask: number;
  public MasterTaskId: number;
  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public VenueTaskRowID: number;
  public VenueLocationTypeMaster: number;

  public VenueTaskImageType: string;
  public VenueTaskImageTypeName: string;
  public ImageBase64String: string;
  public isAllowToEdit = false;
  public AttachmentURL: string;
}

export class VenueInventory extends GenericEntity {
  public InventoryId: number;
  public VenueInventoryId: number;
  public VenueInventoryName: string;
  public Sequence: number;
  public ImageType: string;
  public ImageTypeName: string;
  public IsMasterInventory: number;
  public MasterInventoryId: number;
  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public VenueInventoryRowID: number;
  public VenueLocationTypeMaster: number;

  public VenueInventoryImageType: string;
  public VenueInventoryImageTypeName: string;
  public ImageBase64String: string;
  public isAllowToEdit = false;
  public IsImageRemoved: any;
  public showInventoryImage: string;
  public AttachmentURL: string;
}

export class VenueElement extends GenericEntity {
  public ElementId: number;
  public VenueElementId: number;
  public VenueElementName: string;
  public Sequence: number;
  public ImageType: string;
  public ImageTypeName: string;
  public IsMasterElement: number;
  public MasterElementId: number;
  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public VenueElementRowID: number;
  public VenueLocationTypeMaster: number;

  public VenueElementImageType: string;
  public VenueElementImageTypeName: string;
  public ImageBase64String: string;
  public isAllowToEdit = false;
  public IsImageRemoved: any;
  public showElementImage: string;
  public AttachmentURL: string;
}

export class InspectionScoreFactor extends GenericEntity {
  public InspectionScoreFactorId: number;
  public ScoreFactorName: string;
  public ScoreFactorCode: string;
  public ScoreFactorValue: string;
  public VenueInspectionScoreFactorId: any;
}

export class DigitalDisplayDetails extends GenericEntity {
  public DigitalDisplayConfigurationsList: DigitalDisplayConfiguration[];
  public DigitalDisplayAdvertisementList: DigitalDisplayAdvertisement[];
}

export class DigitalDisplayConfiguration extends GenericEntity {
  public DigitalDisplayConfigurationId: number;
  public Category: string;
  public Code: string;
  public DisplayName: string;
  public Sequence: number;
  public VenueDigitalDisplayDetails: VenueDigitalDisplayConfiguration;
  public DigitalDisplayImageDetails: DigitalDisplayImageDetails;
  public IsRemoveButtonShow = false;
  public IsColorOneRemoveButtonShow = false;
  public IsColorTwoRemoveButtonShow = false;
}

export class VenueDigitalDisplayConfiguration extends GenericEntity {
  public VenueDigitalDisplayConfigurationId: number;
  public VenueId: number;
  public VenueName: string;
  public DigitalDisplayConfigurationId: number;
  public ConfigurationValueOne: any;
  public ConfigurationValueTwo: any;
}

export class DigitalDisplayImageDetails extends GenericEntity {
  public DigitalDisplayConfigurationId: number;
  public DigitalDisplayImageId: number;
  public IsDefaultImage: number;
  public ImageName: string;
}

export class DigitalDisplayImage extends GenericEntity {
  public DigitalDisplayImageId: number;
  public DigitalDisplayConfigurationId: number;
  public VenueId: number;
  public ImageType: string;
  public ImageName: string;
  public IsDefaultImage: number;
}

export class DigitalDisplayAdvertisement extends GenericEntity {
  public DigitalDisplayAdvertisementId: number;
  public AdvertisementName: string;
  public FrequencyTime: number;
  public ImageName: string;
  public ImageType: string;
  public VenueId: number;
  public ExecutionTime: number;
  public Sequence: number;
  public rowid: number;
  public AdvertisementFile: File;
}

export class ClientLogo extends GenericEntity {
  public LogoImageName: string;
  public LogoImageType: string;
  public ItemType: string;
}

export class VenueType extends GenericEntity {
  public VenueTypeId: number;
  public VenueTypeCPId: string;
  public VenueTypeName: string;
  public VenueTypeCode: string;
}

export class VenueMapIntegrationTypeModule extends GenericEntity {
  public VenueMapIntegrationTypeId: number;
  public VenueMapIntegrationTypeName: string;
  public VenueMapIntegrationTypeCode: string;
  public VenuemapIntegrationTypeKey: VenueMapIntegrationTypeKeyModule;
  public VenuemapIntegrationTypeKeys: VenueMapIntegrationTypeKeyModule[];
}

export class VenueMapIntegrationTypeKeyModule extends GenericEntity {
  public VenueMapIntegrationTypeKeyId: number;
  public VenueMapIntegrationTypeId: number;
  public VenueMapIntegrationTypeCPId: string;
  public VenueMapIntegrationTypeKeyName: string;
  public VenueMapIntegrationTypeKeyLabelName: string;
  public VenueMapIntegrationTypeKeyUrl: string;
  public ControlOptions: string;
  public ControlDataType: string;
  public ShowEye: number;
  public isShowEye: boolean;
  public Sequence: number;
  public IsRequired: number;
  public isVisible: boolean;
}

export class RelVenueMapIntegrationTypeUrlModule extends GenericEntity {
  public RelVenueMapIntegrationTypeUrlId: number;
  public VenuemapIntegrationType: VenueMapIntegrationTypeModule;
  public VenuemapIntegrationTypeKey: VenueMapIntegrationTypeKeyModule;
}

export class VenueIntegrationKey extends GenericEntity {
  public IntegrationKeyId: number;
  public IntegrationKeyName: string;
  public IntegrationKeyValue: string;
}

export class LiteVenue extends GenericEntity {
  public VenueId: any;
  public VenueName: string;
}
