import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, interval } from 'rxjs';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { VenueService } from 'src/app/features/venuemanagement/venue/components/venue.http.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { User } from '../../../usermanagement/user/user.model';
import { VenueInventory, VenueModule, VenueTask } from '../../../venuemanagement/venue/venue.module';
import { VenueElement } from '../../venueelementconfiguration/venueelementconfiguration.module';
import { ImportLocationTypeLog, LocationTypeModule, VenueArea } from '../locationtype.module';
import { LocationTypeService } from './locationtype.http.service';

@Component({
  selector: 'locationtype-component',
  templateUrl: 'locationtype.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./locationtype.scss'],
  providers: [UtilitiesService, LocationTypeService, VenueService],
})
export class LocationTypeComponent implements OnInit, OnDestroy {
  currentUser: User;

  public locationTypes: LocationTypeModule[] = [];
  public locationType: LocationTypeModule = new LocationTypeModule();

  public venues: VenueModule[] = [];

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  isReqComplete = false;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;

  listviewtypes: any[];
  viewType: any = null;
  public pageState: StateMaintain = {
    pageName: 'locationTypeForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };
  isSysAdmin = false;
  venueBind: number;

  public taskInstructionsList: VenueTask[] = [];
  public taskInstructions: VenueTask[] = [];
  public VenueTask: VenueTask = new VenueTask();
  taskrowid: number;
  taskindex = -1;

  public venueInventory: VenueInventory = new VenueInventory();
  public inventoryInstructionsList: VenueInventory[] = [];
  public inventoryInstructions: VenueInventory[] = [];
  inventoryrowid: number;
  inventoryindex = -1;

  public venueElement: VenueElement = new VenueElement();
  public elementInstructionsList: VenueElement[] = [];
  public elementInstructions: VenueElement[] = [];
  elementrowid: number;
  elementindex = -1;

  public venueArea: VenueArea = new VenueArea();
  public areaInstructionsList: VenueArea[] = [];
  public areaInstructions: VenueArea[] = [];
  areaRowId: number;
  areaIndex = -1;

  public isLocationTaskShow = false;
  public isLocationInventoryShow = false;
  public isLocationElementShow = false;
  public isLocationAreaShow = false;

  public selectedTab = 0;
  public isAllTaskSelected = false;
  public isAllInventoriesSelected = false;
  public isAllElementsSelected = false;
  public isAllAreasSelected = false;

  //// Import location types -------------------------------------
  public isImportLocationTypeScreen = false;
  public selectAllImportLocationTypes = false;
  public importLocationTypes: LocationTypeModule[] = [];
  public storeImportLocationTypes: LocationTypeModule[] = [];

  public importLocationTypeCorporations: CorporationModule[] = [];
  public isCorporationDisabled = true;
  public selectedCorporationId: any;
  public importLocationTypeVenues: VenueModule[] = [];
  public isVenueDisabled = true;
  public selectedVenueId: any;

  public importLocationType: ImportLocationTypeLog = new ImportLocationTypeLog();
  public importLogsData: ImportLocationTypeLog[] = [];
  public importConfirmationModal = false;
  public isImportReqComplete = false;
  public importLogPage = 1;
  public importLogCount = 0;
  public importLocationTypeForm: any;
  public isLocationTypeForm = false;
  myQuerySub: Subscription;

  constructor(
    private locationTypeService: LocationTypeService,
    private utilitiesService: UtilitiesService,
    private venueService: VenueService,
    private loaderService: LoaderService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.locationType = new LocationTypeModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getModulesData();
    this.getPageState();
    this.venueBind = null;
    this.venueBind = this.authState.getStoredContextVenueId();
  }

  ngOnDestroy() {
    if (this.myQuerySub) {
      this.myQuerySub.unsubscribe();
    }
  }

  public getModulesData() {
    this.isLocationTaskShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_TASK');
    this.isLocationInventoryShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_INVENTORIES');
    this.isLocationElementShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_ELEMENT');
    this.isLocationAreaShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_AREA');
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.isLocationTypeForm = true;
    this.searchString = '';
    this.locationTypes = null;
    this.count = 0;
    this.locationType = new LocationTypeModule();
    if (this.isLocationAreaShow) {
      this.selectedTab = 3;
    }
    if (this.isLocationElementShow) {
      this.selectedTab = 2;
    }
    if (this.isLocationInventoryShow) {
      this.selectedTab = 1;
    }
    if (this.isLocationTaskShow) {
      this.selectedTab = 0;
    }
    this.getAllVenues('', -1, -1);
    setTimeout(() => {
      const element = document.getElementById('locationTypeName');
      element.focus();
    }, 1000);
    if (this.isLocationTaskShow) {
      this.getLocationTypeTasksById(0);
    }
    if (this.isLocationInventoryShow) {
      this.getLocationTypeInventoriesById(0);
    }
    if (this.isLocationElementShow) {
      this.getLocationTypeElementsById(0);
    }
    if (this.isLocationAreaShow) {
      this.getLocationTypeAreasById(0);
    }
  }

  public setUpdateForm() {
    this.isLocationTypeForm = true;
    this.locationTypes = null;
    this.count = 0;
    this.searchString = '';
    if (this.isLocationAreaShow) {
      this.selectedTab = 3;
    }
    if (this.isLocationElementShow) {
      this.selectedTab = 2;
    }
    if (this.isLocationInventoryShow) {
      this.selectedTab = 1;
    }
    if (this.isLocationTaskShow) {
      this.selectedTab = 0;
    }
    this.getAllVenues('', -1, -1);
  }

  public hasData(): boolean {
    return this.locationTypes !== null && this.locationTypes.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public locationTypePageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public clearFormData(LocationTypeForm: NgForm) {
    this.isLocationTypeForm = false;
    this.searchString = '';
    this.venues = [];
    this.taskInstructions = [];
    this.inventoryInstructions = [];
    this.elementInstructions = [];
    this.areaInstructions = [];
    this.taskInstructionsList = [];
    this.inventoryInstructionsList = [];
    this.elementInstructionsList = [];
    this.areaInstructionsList = [];
    this.locationType = new LocationTypeModule();
    LocationTypeForm.reset();
    this.isAllTaskSelected = false;
    this.isAllInventoriesSelected = false;
    this.isAllElementsSelected = false;
    this.isAllAreasSelected = false;
    this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public locationTypeSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocationTypes(searchString, 1, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocationTypes('', this.page, this.size, this.viewType);
  }

  public getAllLocationTypes(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.locationTypeService.GetAll(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.locationTypes = data;
        if (
          this.locationTypes.length < 0 &&
          this.locationTypes[0].Count / this.utilitiesService.initialPageSize < this.page
        ) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'locationTypeService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.locationTypes[0] ? this.locationTypes[0].Count : 0),
    );
  }

  public getLocationTypeById(LocationTypeId: number) {
    if (this.authState.canAccess('LOCATION_TYPE_VIEW')) {
      this.loaderService.display(true);
      const CPLocationType = new LocationTypeModule();
      CPLocationType.VenueLocationTypeCPId = this.authState.AESEncryptText(LocationTypeId);

      this.locationTypeService.GetSingle(CPLocationType).subscribe(
        (data) => {
          this.locationType = data;
          this.loaderService.display(false);

          if (this.isLocationTaskShow) {
            this.getLocationTypeTasksById(LocationTypeId);
          }
          if (this.isLocationInventoryShow) {
            this.getLocationTypeInventoriesById(LocationTypeId);
          }
          if (this.isLocationElementShow) {
            this.getLocationTypeElementsById(LocationTypeId);
          }
          if (this.isLocationAreaShow) {
            this.getLocationTypeAreasById(LocationTypeId);
          }
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationTypeService GetSingle Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Location Type', 'You do not have permission to view location type details.');
    }
  }

  public addLocationType(LocationTypeForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (LocationTypeForm.status == 'INVALID' && LocationTypeForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Location Type',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.locationType.VenueLocationTypeName = this.utilitiesService.removeInnerSpaces(
      this.locationType.VenueLocationTypeName,
    );
    if (this.locationType.VenueLocationTypeName === null || this.locationType.VenueLocationTypeName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (this.selectedTab == 0) {
      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    } else if (this.selectedTab == 1) {
      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    } else if (this.selectedTab == 2) {
      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    } else if (this.selectedTab == 3) {
      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    }

    let that = this;
    this.taskInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.AddedBy = that.currentUser.UserId;
    });
    this.inventoryInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.AddedBy = that.currentUser.UserId;
    });
    this.elementInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.AddedBy = that.currentUser.UserId;
    });
    this.areaInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.AddedBy = that.currentUser.UserId;
    });

    this.locationType.VenueTasks = [];
    let filteredLocationTypeTasks = this.taskInstructions.filter((task) => task.IsActive == 1);
    this.locationType.VenueTasks = filteredLocationTypeTasks;

    this.locationType.VenueInventories = [];
    let filteredLocationTypeInventories = this.inventoryInstructions.filter((inv) => inv.IsActive == 1);
    this.locationType.VenueInventories = filteredLocationTypeInventories;

    this.locationType.VenueElements = [];
    let filteredLocationTypeElements = this.elementInstructions.filter((ele) => ele.IsActive == 1);
    this.locationType.VenueElements = filteredLocationTypeElements;

    this.locationType.Areas = [];
    let filteredLocationTypeAreas = this.areaInstructions.filter((ele) => ele.IsActive == 1);
    this.locationType.Areas = filteredLocationTypeAreas;

    this.locationType.VenueId = this.locationType.VenueId;
    this.locationType.AddedBy = this.currentUser.UserId;

    this.locationTypeService.Add(this.utilitiesService.stripScript(this.locationType)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Location Type', data.Message);
        this.clearFormData(LocationTypeForm);
      },
      (error) => {
        this.isReqComplete = false;
        if (
          error._body.match('UQ_IX_NC_venue_location_type_id_venue_task_id') ||
          error._body.match('UQ_IX_NC_venue_task')
        ) {
          this.selectedTab = 0;
        }
        if (
          error._body.match('UQ_IX_NC_venue_location_type_id_venue_inventory_id') ||
          error._body.match('UQ_IX_NC_venue_inventory')
        ) {
          this.selectedTab = 1;
        }
        if (
          error._body.match('UQ_IX_NC_venue_location_type_id_venue_element_id') ||
          error._body.match('UQ_IX_NC_venue_element')
        ) {
          this.selectedTab = 2;
        }
        if (error._body.match(error._body.match('uq_nc_ix_area_name'))) {
          this.selectedTab = 3;
        }
        this.loaderService.display(false);
        console.log('locationTypeService Add Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public updateLocationType(LocationTypeForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (LocationTypeForm.status == 'INVALID' && LocationTypeForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Location Type',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.locationType.VenueLocationTypeName = this.utilitiesService.removeInnerSpaces(
      this.locationType.VenueLocationTypeName,
    );
    if (this.locationType.VenueLocationTypeName === null || this.locationType.VenueLocationTypeName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (this.selectedTab == 0) {
      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    } else if (this.selectedTab == 1) {
      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    } else if (this.selectedTab == 2) {
      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    } else if (this.selectedTab == 3) {
      if (this.areaInstructions && this.areaInstructions.length > 0) {
        for (let i = 0; i < this.areaInstructions.length; i++) {
          if (this.areaInstructions[i].VenueLocationTypeMaster == 0) {
            if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
              this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.elementInstructions && this.elementInstructions.length > 0) {
        for (let i = 0; i < this.elementInstructions.length; i++) {
          if (this.elementInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.elementInstructions[i].Sequence == null ||
              this.elementInstructions[i].VenueElementName == '' ||
              this.elementInstructions[i].VenueElementName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.taskInstructions && this.taskInstructions.length > 0) {
        for (let i = 0; i < this.taskInstructions.length; i++) {
          if (this.taskInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.taskInstructions[i].Sequence == null ||
              this.taskInstructions[i].VenueTaskName == '' ||
              this.taskInstructions[i].VenueTaskName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }

      if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
        for (let i = 0; i < this.inventoryInstructions.length; i++) {
          if (this.inventoryInstructions[i].VenueLocationTypeMaster == 0) {
            if (
              this.inventoryInstructions[i].Sequence == null ||
              this.inventoryInstructions[i].VenueInventoryName == '' ||
              this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
            ) {
              this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
              this.loaderService.display(false);
              this.isReqComplete = false;
              return;
            }
          }
        }
      }
    }

    let that = this;
    this.taskInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.ModifiedBy = that.currentUser.UserId;
    });
    this.inventoryInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.ModifiedBy = that.currentUser.UserId;
    });
    this.elementInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.ModifiedBy = that.currentUser.UserId;
    });
    this.areaInstructions.forEach(function (item) {
      item.VenueId = that.locationType.VenueId;
      item.ModifiedBy = that.currentUser.UserId;
    });

    this.locationType.VenueTasks = [];
    let filteredLocationTypeTasks = this.taskInstructions.filter((task) => task.IsActive == 1);
    this.locationType.VenueTasks = filteredLocationTypeTasks;

    this.locationType.VenueInventories = [];
    let filteredLocationTypeInventories = this.inventoryInstructions.filter((inv) => inv.IsActive == 1);
    this.locationType.VenueInventories = filteredLocationTypeInventories;

    this.locationType.VenueElements = [];
    let filteredLocationTypeElements = this.elementInstructions.filter((ele) => ele.IsActive == 1);
    this.locationType.VenueElements = filteredLocationTypeElements;

    this.locationType.Areas = [];
    let filteredLocationTypeAreas = this.areaInstructions.filter((ele) => ele.IsActive == 1);
    this.locationType.Areas = filteredLocationTypeAreas;

    this.locationType.VenueId = this.locationType.VenueId;
    this.locationType.ModifiedBy = this.currentUser.UserId;
    this.locationType.IsActive = this.locationType.IsActive ? 1 : 0;

    this.locationTypeService.Update(this.utilitiesService.stripScript(this.locationType)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Location Type', data.Message);
        this.clearFormData(LocationTypeForm);
      },
      (error) => {
        this.isReqComplete = false;
        if (
          error._body.match('UQ_IX_NC_venue_location_type_id_venue_task_id') ||
          error._body.match('UQ_IX_NC_venue_task')
        ) {
          this.selectedTab = 0;
        }
        if (
          error._body.match('UQ_IX_NC_venue_location_type_id_venue_inventory_id') ||
          error._body.match('UQ_IX_NC_venue_inventory')
        ) {
          this.selectedTab = 1;
        }
        if (
          error._body.match('UQ_IX_NC_venue_location_type_id_venue_element_id') ||
          error._body.match('UQ_IX_NC_venue_element')
        ) {
          this.selectedTab = 2;
        }
        if (error._body.match(error._body.match('uq_nc_ix_area_name'))) {
          this.selectedTab = 3;
        }
        this.loaderService.display(false);
        console.log(
          'locationTypeService Update Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;
        if (!this.locationTypes && !this.locationType.VenueLocationTypeId) {
          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj[0]) {
            this.locationType.VenueId = obj[0].VenueId;
            this.locationType.VenueName = obj[0].VenueName;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService GetAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public addTask() {
    if (this.taskInstructions && this.taskInstructions.length > 0) {
      for (let i = 0; i < this.taskInstructions.length; i++) {
        if (this.taskInstructions[i].VenueTaskName == '' || this.taskInstructions[i].VenueTaskName.trim().length <= 0) {
          window.scrollTo(0, document.body.scrollHeight);
          this.utilitiesService.smmodal('Location Type', 'Task row should not be blank.');
          return;
        }
      }
    }

    this.taskrowid = this.taskInstructions.length;
    this.taskrowid = this.taskrowid + 1;

    const newTask = new VenueTask();
    newTask.Sequence = this.VenueTask.Sequence;
    newTask.VenueTaskName = '';
    newTask.VenueTaskRowID = this.taskrowid;
    newTask.IsActive = 1;
    newTask.VenueLocationTypeMaster = 0;
    this.taskInstructions.push(newTask);
    window.scrollTo(0, document.body.scrollHeight);
  }

  public removeTask(venueTaskId: number) {
    for (let i = 0; i < this.taskInstructions.length; i++) {
      if (this.taskInstructions[i].VenueTaskRowID == venueTaskId) {
        this.taskInstructions[i].IsActive = 0;
        this.taskindex = i;
        if (this.taskInstructions[i].VenueTaskId == null) {
          this.taskInstructions.splice(this.taskindex, 1);
          i = i - 1;
        }
      }
    }
  }

  public addInventory() {
    if (this.inventoryInstructions && this.inventoryInstructions.length > 0) {
      for (let i = 0; i < this.inventoryInstructions.length; i++) {
        if (
          this.inventoryInstructions[i].VenueInventoryName == '' ||
          this.inventoryInstructions[i].VenueInventoryName.trim().length <= 0
        ) {
          window.scrollTo(0, document.body.scrollHeight);
          this.utilitiesService.smmodal('Location Type', 'Inventory row should not be blank.');
          return;
        }
      }
    }

    this.inventoryrowid = this.inventoryInstructions.length;
    this.inventoryrowid = this.inventoryrowid + 1;

    const newInventory = new VenueInventory();
    newInventory.Sequence = this.venueInventory.Sequence;
    newInventory.VenueInventoryName = '';
    newInventory.VenueInventoryRowID = this.inventoryrowid;
    newInventory.IsActive = 1;
    newInventory.VenueLocationTypeMaster = 0;
    this.inventoryInstructions.push(newInventory);
    window.scrollTo(0, document.body.scrollHeight);
  }

  public removeVenueInventory(venueInventoryId: number) {
    for (let i = 0; i < this.inventoryInstructions.length; i++) {
      if (this.inventoryInstructions[i].VenueInventoryRowID == venueInventoryId) {
        this.inventoryInstructions[i].IsActive = 0;
        this.inventoryindex = i;
        if (this.inventoryInstructions[i].VenueInventoryId == null) {
          this.inventoryInstructions.splice(this.inventoryindex, 1);
          i = i - 1;
        }
      }
    }
  }

  public addElement() {
    if (this.elementInstructions && this.elementInstructions.length > 0) {
      for (let i = 0; i < this.elementInstructions.length; i++) {
        if (
          this.elementInstructions[i].VenueElementName == '' ||
          this.elementInstructions[i].VenueElementName.trim().length <= 0
        ) {
          window.scrollTo(0, document.body.scrollHeight);
          this.utilitiesService.smmodal('Location Type', 'Element row should not be blank.');
          return;
        }
      }
    }

    this.elementrowid = this.elementInstructions.length;
    this.elementrowid = this.elementrowid + 1;

    const newElement = new VenueElement();
    newElement.Sequence = this.venueElement.Sequence;
    newElement.VenueElementName = '';
    newElement.VenueElementRowID = this.elementrowid;
    newElement.IsActive = 1;
    newElement.VenueLocationTypeMaster = 0;
    this.elementInstructions.push(newElement);
    window.scrollTo(0, document.body.scrollHeight);
  }

  public removeElement(venueElementId: number) {
    for (let i = 0; i < this.elementInstructions.length; i++) {
      if (this.elementInstructions[i].VenueElementRowID == venueElementId) {
        this.elementInstructions[i].IsActive = 0;
        this.elementindex = i;
        if (this.elementInstructions[i].VenueElementId == null) {
          this.elementInstructions.splice(this.elementindex, 1);
          i = i - 1;
        }
      }
    }
  }

  public addArea() {
    if (this.areaInstructions && this.areaInstructions.length > 0) {
      for (let i = 0; i < this.areaInstructions.length; i++) {
        if (this.areaInstructions[i].AreaName == '' || this.areaInstructions[i].AreaName.trim().length <= 0) {
          window.scrollTo(0, document.body.scrollHeight);
          this.utilitiesService.smmodal('Location Type', 'Area row should not be blank.');
          return;
        }
      }
    }

    this.areaRowId = this.areaInstructions.length;
    this.areaRowId = this.areaRowId + 1;

    const newArea = new VenueArea();
    newArea.Sequence = this.venueArea.Sequence;
    newArea.AreaName = '';
    newArea.AreaRowID = this.areaRowId;
    newArea.IsActive = 1;
    newArea.VenueLocationTypeMaster = 0;
    this.areaInstructions.push(newArea);
    window.scrollTo(0, document.body.scrollHeight);
  }

  public removeArea(areaRowId: number) {
    for (let i = 0; i < this.areaInstructions.length; i++) {
      if (this.areaInstructions[i].AreaRowID == areaRowId) {
        this.areaInstructions[i].IsActive = 0;
        this.areaIndex = i;
        if (this.areaInstructions[i].AreaId == null) {
          this.areaInstructions.splice(this.areaIndex, 1);
          i = i - 1;
        }
      }
    }
  }

  keyPress($event: any) {
    if ($event.key == '+' || $event.key == '-' || $event.key == 'e' || $event.target.value.length + 1 > 4) {
      $event.preventDefault();
      return false;
    }
  }

  TabChange($event) {
    this.selectedTab = $event;
  }

  public getLocationTypeTasksById(LocationTypeId: number) {
    this.loaderService.display(true);
    const CPLocationType = new LocationTypeModule();
    CPLocationType.VenueLocationTypeCPId = this.authState.AESEncryptText(LocationTypeId);

    this.locationTypeService.GetLocationTypeTasks(CPLocationType).subscribe(
      (data) => {
        this.locationType.VenueTasks = [];
        this.locationType.VenueTasks = data;
        let taskIndex = 0;

        if (this.locationType.VenueTasks != null && this.locationType.VenueTasks.length > 0) {
          for (let i = 0; i < this.locationType.VenueTasks.length; i++) {
            const id = new VenueTask();
            taskIndex = i + 1;
            id.VenueTaskRowID = taskIndex;
            id.VenueTaskId = this.locationType.VenueTasks[i].VenueTaskId;
            id.VenueTaskName = this.locationType.VenueTasks[i].VenueTaskName;
            id.ImageTypeName = this.locationType.VenueTasks[i].ImageTypeName;
            id.Sequence = this.locationType.VenueTasks[i].Sequence;
            id.IsActive = this.locationType.VenueTasks[i].IsActive;
            id.MasterTaskId = this.locationType.VenueTasks[i].MasterTaskId;
            id.IsMasterTask = this.locationType.VenueTasks[i].IsMasterTask;
            id.VenueLocationTypeMaster = this.locationType.VenueTasks[i].VenueLocationTypeMaster;

            if (this.locationType.VenueTasks[i].VenueLocationTypeMaster > 0) {
              this.taskInstructionsList.push(id);
            }
            this.taskInstructions.push(id);
          }
        }

        if (this.taskInstructionsList && this.taskInstructionsList.length > 0) {
          let taskObj = this.taskInstructionsList.filter((e) => e.IsActive == 0);
          if (taskObj && taskObj.length > 0) {
            this.isAllTaskSelected = false;
          } else {
            this.isAllTaskSelected = true;
          }
        }

        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetLocationTypeTasks Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public getLocationTypeInventoriesById(LocationTypeId: number) {
    this.loaderService.display(true);
    const CPLocationType = new LocationTypeModule();
    CPLocationType.VenueLocationTypeCPId = this.authState.AESEncryptText(LocationTypeId);

    this.locationTypeService.GetLocationTypeInventories(CPLocationType).subscribe(
      (data) => {
        this.locationType.VenueInventories = [];
        this.locationType.VenueInventories = data;
        let inventoryIndex = 0;

        if (this.locationType.VenueInventories != null && this.locationType.VenueInventories.length > 0) {
          for (let i = 0; i < this.locationType.VenueInventories.length; i++) {
            const id = new VenueInventory();
            inventoryIndex = i + 1;
            id.VenueInventoryRowID = inventoryIndex;
            id.VenueInventoryId = this.locationType.VenueInventories[i].VenueInventoryId;
            id.VenueInventoryName = this.locationType.VenueInventories[i].VenueInventoryName;
            id.ImageTypeName = this.locationType.VenueInventories[i].ImageTypeName;
            id.Sequence = this.locationType.VenueInventories[i].Sequence;
            id.IsActive = this.locationType.VenueInventories[i].IsActive;
            id.MasterInventoryId = this.locationType.VenueInventories[i].MasterInventoryId;
            id.IsMasterInventory = this.locationType.VenueInventories[i].IsMasterInventory;
            id.Sequence = this.locationType.VenueInventories[i].Sequence;
            id.VenueLocationTypeMaster = this.locationType.VenueInventories[i].VenueLocationTypeMaster;

            if (this.locationType.VenueInventories[i].VenueLocationTypeMaster > 0) {
              this.inventoryInstructionsList.push(id);
            }
            this.inventoryInstructions.push(id);
          }
        }

        if (this.inventoryInstructionsList && this.inventoryInstructionsList.length > 0) {
          let inventoryObj = this.inventoryInstructionsList.filter((e) => e.IsActive == 0);
          if (inventoryObj && inventoryObj.length > 0) {
            this.isAllInventoriesSelected = false;
          } else {
            this.isAllInventoriesSelected = true;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetLocationTypeInventories Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public getLocationTypeElementsById(LocationTypeId: number) {
    this.loaderService.display(true);
    const CPLocationType = new LocationTypeModule();
    CPLocationType.VenueLocationTypeCPId = this.authState.AESEncryptText(LocationTypeId);

    this.locationTypeService.GetLocationTypeElements(CPLocationType).subscribe(
      (data) => {
        this.locationType.VenueElements = [];
        this.locationType.VenueElements = data;
        let elementIndex = 0;

        if (this.locationType.VenueElements != null && this.locationType.VenueElements.length > 0) {
          for (let i = 0; i < this.locationType.VenueElements.length; i++) {
            const id = new VenueElement();
            elementIndex = i + 1;
            id.VenueElementRowID = elementIndex;
            id.VenueElementId = this.locationType.VenueElements[i].VenueElementId;
            id.VenueElementName = this.locationType.VenueElements[i].VenueElementName;
            id.ImageTypeName = this.locationType.VenueElements[i].ImageTypeName;
            id.Sequence = this.locationType.VenueElements[i].Sequence;
            id.IsActive = this.locationType.VenueElements[i].IsActive;
            id.MasterElementId = this.locationType.VenueElements[i].MasterElementId;
            id.IsMasterElement = this.locationType.VenueElements[i].IsMasterElement;
            id.VenueLocationTypeMaster = this.locationType.VenueElements[i].VenueLocationTypeMaster;

            if (this.locationType.VenueElements[i].VenueLocationTypeMaster > 0) {
              this.elementInstructionsList.push(id);
            }
            this.elementInstructions.push(id);
          }
        }

        if (this.elementInstructionsList && this.elementInstructionsList.length > 0) {
          let elementObj = this.elementInstructionsList.filter((e) => e.IsActive == 0);
          if (elementObj && elementObj.length > 0) {
            this.isAllElementsSelected = false;
          } else {
            this.isAllElementsSelected = true;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetLocationTypeElements Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public getLocationTypeAreasById(LocationTypeId: number) {
    this.loaderService.display(true);
    const CPLocationType = new LocationTypeModule();
    CPLocationType.VenueLocationTypeCPId = this.authState.AESEncryptText(LocationTypeId);

    this.locationTypeService.GetLocationTypeAreas(CPLocationType).subscribe(
      (data) => {
        this.locationType.Areas = [];
        this.locationType.Areas = data;
        let areaIndex = 0;

        if (this.locationType.Areas != null && this.locationType.Areas.length > 0) {
          for (let i = 0; i < this.locationType.Areas.length; i++) {
            const id = new VenueArea();
            areaIndex = i + 1;
            id.AreaRowID = areaIndex;
            id.AreaId = this.locationType.Areas[i].AreaId;
            id.AreaName = this.locationType.Areas[i].AreaName;
            id.Sequence = this.locationType.Areas[i].Sequence;
            id.IsActive = this.locationType.Areas[i].IsActive;
            id.VenueLocationTypeId = this.locationType.Areas[i].VenueLocationTypeId;
            id.VenueLocationTypeName = this.locationType.Areas[i].VenueLocationTypeName;
            id.VenueLocationTypeMaster = this.locationType.Areas[i].VenueLocationTypeMaster;

            if (this.locationType.Areas[i].VenueLocationTypeMaster > 0) {
              this.areaInstructionsList.push(id);
            }
            this.areaInstructions.push(id);
          }
        }

        if (this.areaInstructionsList && this.areaInstructionsList.length > 0) {
          let areaObj = this.areaInstructionsList.filter((e) => e.IsActive == 0);
          if (areaObj && areaObj.length > 0) {
            this.isAllAreasSelected = false;
          } else {
            this.isAllAreasSelected = true;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetLocationTypeAreas Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public selectedTask(VenueLocationTypeId: number, taskId: number, taskName: string, sequence: number, isActive: any) {
    let filteredTaskObj = this.taskInstructions.filter((task) => task.VenueTaskId == taskId);
    let taskObj = filteredTaskObj;

    if (taskObj && taskObj.length > 0 && taskObj[0].VenueLocationTypeMaster > 0) {
      taskObj[0].MasterTaskId = taskId;
      taskObj[0].VenueTaskName = taskName;
      taskObj[0].Sequence = sequence;
      taskObj[0].VenueLocationTypeId = VenueLocationTypeId;
      taskObj[0].VenueLocationTypeMaster = 1;
      taskObj[0].IsActive = isActive ? 1 : 0;
    } else if (taskName != null && isActive == true) {
      const tasks = new VenueTask();
      tasks.MasterTaskId = taskId;
      tasks.VenueTaskName = taskName;
      tasks.Sequence = sequence;
      tasks.VenueLocationTypeId = VenueLocationTypeId;
      tasks.IsActive = 1;
      tasks.IsMasterTask = 1;
      tasks.VenueLocationTypeMaster = 0;
      this.taskInstructions.push(tasks);
    }

    if (this.taskInstructionsList && this.taskInstructionsList.length > 0) {
      let taskObj = this.taskInstructionsList.filter((e) => e.IsActive == 0);
      if (taskObj && taskObj.length > 0) {
        this.isAllTaskSelected = false;
      } else {
        this.isAllTaskSelected = true;
      }
    }

    if (taskName && isActive == false) {
      for (let i = 0; i < this.taskInstructions.length; i++) {
        if (this.taskInstructions[i].VenueTaskName == taskName) {
          this.taskInstructions[i].IsActive = 0;
          return;
        }
      }
    }
  }

  public selectedVenueInventory(
    VenueLocationTypeId: number,
    inventoryId: number,
    inventoryName: string,
    sequence: number,
    isActive: any,
  ) {
    let filteredInventoryObj = this.inventoryInstructions.filter(
      (inventory) => inventory.VenueInventoryId == inventoryId,
    );
    let inventoryObj = filteredInventoryObj;

    if (inventoryObj && inventoryObj.length > 0 && inventoryObj[0].VenueLocationTypeMaster > 0) {
      inventoryObj[0].MasterInventoryId = inventoryId;
      inventoryObj[0].VenueInventoryName = inventoryName;
      inventoryObj[0].Sequence = sequence;
      inventoryObj[0].VenueLocationTypeId = VenueLocationTypeId;
      inventoryObj[0].VenueLocationTypeMaster = 1;
      inventoryObj[0].IsActive = isActive ? 1 : 0;
    } else if (inventoryName != null && isActive == true) {
      const inventories = new VenueInventory();
      inventories.MasterInventoryId = inventoryId;
      inventories.VenueInventoryName = inventoryName;
      inventories.Sequence = sequence;
      inventories.VenueLocationTypeId = VenueLocationTypeId;
      inventories.IsActive = 1;
      inventories.IsMasterInventory = 1;
      inventories.VenueLocationTypeMaster = 0;
      this.inventoryInstructions.push(inventories);
    }

    if (this.inventoryInstructionsList && this.inventoryInstructionsList.length > 0) {
      let inventoryObj = this.inventoryInstructionsList.filter((e) => e.IsActive == 0);
      if (inventoryObj && inventoryObj.length > 0) {
        this.isAllInventoriesSelected = false;
      } else {
        this.isAllInventoriesSelected = true;
      }
    }

    if (inventoryName && isActive == false) {
      for (let i = 0; i < this.inventoryInstructions.length; i++) {
        if (this.inventoryInstructions[i].VenueInventoryName == inventoryName) {
          this.inventoryInstructions[i].IsActive = 0;
          return;
        }
      }
    }
  }

  public selectedVenueElement(
    VenueLocationTypeId: number,
    elementId: number,
    elementName: string,
    sequence: number,
    isActive: any,
  ) {
    let filteredVenueElementObj = this.elementInstructions.filter((element) => element.VenueElementId == elementId);
    let venueElementObj = filteredVenueElementObj;

    if (venueElementObj && venueElementObj.length > 0 && venueElementObj[0].VenueLocationTypeMaster > 0) {
      venueElementObj[0].MasterElementId = elementId;
      venueElementObj[0].VenueElementName = elementName;
      venueElementObj[0].Sequence = sequence;
      venueElementObj[0].VenueLocationTypeId = VenueLocationTypeId;
      venueElementObj[0].VenueLocationTypeMaster = 1;
      venueElementObj[0].IsActive = isActive ? 1 : 0;
    } else if (elementName != null && isActive == true) {
      const elements = new VenueElement();
      elements.MasterElementId = elementId;
      elements.VenueElementName = elementName;
      elements.Sequence = sequence;
      elements.VenueLocationTypeId = VenueLocationTypeId;
      elements.IsActive = 1;
      elements.IsMasterElement = 1;
      elements.VenueLocationTypeMaster = 0;
      this.elementInstructions.push(elements);
    }

    if (this.elementInstructionsList && this.elementInstructionsList.length > 0) {
      let elementObj = this.elementInstructionsList.filter((e) => e.IsActive == 0);
      if (elementObj && elementObj.length > 0) {
        this.isAllElementsSelected = false;
      } else {
        this.isAllElementsSelected = true;
      }
    }

    if (elementName && isActive == false) {
      for (let i = 0; i < this.elementInstructions.length; i++) {
        if (this.elementInstructions[i].VenueElementName == elementName) {
          this.elementInstructions[i].IsActive = 0;
          return;
        }
      }
    }
  }

  public selectedVenueArea(
    venueLocationTypeId: number,
    areaId: number,
    areaName: string,
    sequence: number,
    isActive: any,
  ) {
    let filteredVenueAreaObj = this.areaInstructions.filter((area) => area.AreaId == areaId);
    let venueAreaObj = filteredVenueAreaObj;

    if (venueAreaObj && venueAreaObj.length > 0 && venueAreaObj[0].VenueLocationTypeMaster > 0) {
      venueAreaObj[0].AreaId = areaId;
      venueAreaObj[0].AreaName = areaName;
      venueAreaObj[0].Sequence = sequence;
      venueAreaObj[0].VenueLocationTypeId = venueLocationTypeId;
      venueAreaObj[0].IsActive = isActive ? 1 : 0;
      venueAreaObj[0].VenueLocationTypeMaster = 1;
    } else if (areaName != null && isActive == true) {
      const areas = new VenueArea();
      areas.AreaId = areaId;
      areas.AreaName = areaName;
      areas.Sequence = sequence;
      areas.VenueLocationTypeId = venueLocationTypeId;
      areas.IsActive = 1;
      areas.VenueLocationTypeMaster = 0;
      this.areaInstructions.push(areas);
    }

    if (this.areaInstructionsList && this.areaInstructionsList.length > 0) {
      let areaObj = this.areaInstructionsList.filter((e) => e.IsActive == 0);
      if (areaObj && areaObj.length > 0) {
        this.isAllAreasSelected = false;
      } else {
        this.isAllAreasSelected = true;
      }
    }

    if (areaName && isActive == false) {
      for (let i = 0; i < this.areaInstructions.length; i++) {
        if (this.areaInstructions[i].AreaName == areaName) {
          this.areaInstructions[i].IsActive = 0;
          return;
        }
      }
    }
  }

  public selectAllTasks(isSelected: any) {
    if (this.taskInstructionsList && this.taskInstructionsList.length > 0) {
      if (isSelected == true) {
        this.taskInstructionsList.forEach(function (item) {
          item.IsActive = 1;
        });
      } else {
        this.taskInstructionsList.forEach(function (item) {
          item.IsActive = 0;
        });
      }
    }
  }

  public selectAllInventories(isSelected: any) {
    if (this.inventoryInstructionsList && this.inventoryInstructionsList.length > 0) {
      if (isSelected == true) {
        this.inventoryInstructionsList.forEach(function (item) {
          item.IsActive = 1;
        });
      } else {
        this.inventoryInstructionsList.forEach(function (item) {
          item.IsActive = 0;
        });
      }
    }
  }

  public selectAllElements(isSelected: any) {
    if (this.elementInstructionsList && this.elementInstructionsList.length > 0) {
      if (isSelected == true) {
        this.elementInstructionsList.forEach(function (item) {
          item.IsActive = 1;
        });
      } else {
        this.elementInstructionsList.forEach(function (item) {
          item.IsActive = 0;
        });
      }
    }
  }

  public selectAllAreas(isSelected: any) {
    if (this.areaInstructionsList && this.areaInstructionsList.length > 0) {
      if (isSelected == true) {
        this.areaInstructionsList.forEach(function (item) {
          item.IsActive = 1;
        });
      } else {
        this.areaInstructionsList.forEach(function (item) {
          item.IsActive = 0;
        });
      }
    }
  }

  //// Import location types code ----------------------------------------------
  public goToImportLocationType() {
    this.isLocationTypeForm = false;
    this.isImportLocationTypeScreen = true;
    this.isCorporationDisabled = true;
    this.importLocationTypeCorporations = [];
    this.selectedCorporationId = null;
    this.importLogPage = 1;

    this.getAllImportLocationTypeCorporations();
  }

  public getAllImportLocationTypeCorporations() {
    this.loaderService.display(true);
    const payload = {};

    this.locationTypeService.GetAllCorporations(payload).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.isCorporationDisabled = false;
          this.importLocationTypeCorporations = data;
        }
        this.getImportLogData(this.importLogPage, true);
        this.interval();
      },
      (error) => {
        this.loaderService.display(false);
        this.getImportLogData(this.importLogPage, true);
        console.log(
          'locationTypeService GetAllCorporations call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public corporationChange($event: any) {
    this.selectedCorporationId = $event;
    if (this.selectedCorporationId) {
      this.importLocationTypeVenues = [];
      this.isVenueDisabled = true;
      this.selectedVenueId = null;

      this.importLocationTypes = [];
      this.storeImportLocationTypes = [];

      this.selectAllImportLocationTypes = false;
      this.getAllImportLocationTypeVenues(this.selectedCorporationId);
    } else {
      this.importLocationTypeVenues = [];
      this.isVenueDisabled = true;
      this.selectedVenueId = null;
      this.selectedCorporationId = null;

      this.importLocationTypes = [];
      this.storeImportLocationTypes = [];
      this.selectAllImportLocationTypes = false;
    }
  }

  public getAllImportLocationTypeVenues(corporationId: number) {
    this.loaderService.display(true);
    const payload = { CorporationId: corporationId };

    this.locationTypeService.GetAllVenues(payload).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.isVenueDisabled = false;
          this.importLocationTypeVenues = data;
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetAllVenues call failed. status:' + error.status + 'Status Text: ' + error.statusText,
        );
      },
    );
  }

  public venueChange($event: any) {
    this.selectedVenueId = $event;
    if (this.selectedVenueId) {
      this.importLocationTypes = [];
      this.storeImportLocationTypes = [];
      this.selectAllImportLocationTypes = false;
      this.getAllImportLocationTypes(this.selectedVenueId);
    } else {
      this.selectedVenueId = null;
      this.importLocationTypes = [];
      this.storeImportLocationTypes = [];
      this.selectAllImportLocationTypes = false;
    }
  }

  public getAllImportLocationTypes(venueId: number) {
    this.loaderService.display(true);
    const payload = new ImportLocationTypeLog();
    payload.SourceVenueId = venueId;
    payload.DestinationVenueId = this.authState.getStoredContextVenueId();

    this.locationTypeService.GetAllImportLocationTypes(payload).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.importLocationTypes = data;
          this.importLocationTypes.forEach(function (item) {
            item.IsSelected = 0;
          });
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTypeService GetAllImportLocationTypes call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public hasImportLocationTypesData(): boolean {
    return this.importLocationTypes !== null && this.importLocationTypes.length > 0;
  }

  public selectedAllLocationTypes(isSelected: boolean) {
    if (isSelected) {
      this.storeImportLocationTypes = [];

      let that = this;
      this.importLocationTypes.forEach(function (locType) {
        let locTypeObj = new LocationTypeModule();
        locTypeObj.VenueLocationTypeId = locType.VenueLocationTypeId;
        locTypeObj.VenueLocationTypeName = locType.VenueLocationTypeName;
        locTypeObj.IsSelected = 1;
        that.storeImportLocationTypes.push(locTypeObj);

        locType.IsSelected = 1;
      });
    } else {
      this.storeImportLocationTypes = [];

      this.importLocationTypes.forEach(function (locType) {
        locType.IsSelected = 0;
      });
    }
  }

  public selectedImportLocationType(locationTypeId: number, locationTypeName: string, isSelected: boolean) {
    if (isSelected) {
      let locTypeObj = new LocationTypeModule();
      locTypeObj.VenueLocationTypeId = locationTypeId;
      locTypeObj.VenueLocationTypeName = locationTypeName;
      locTypeObj.IsSelected = 1;
      this.storeImportLocationTypes.push(locTypeObj);

      if (
        this.importLocationTypes.length > 0 &&
        this.storeImportLocationTypes.length > 0 &&
        this.importLocationTypes.length === this.storeImportLocationTypes.length
      ) {
        this.selectAllImportLocationTypes = true;
      }
    } else {
      let locationTypeIndex = -1;
      for (let i = 0; i < this.storeImportLocationTypes.length; i++) {
        if (this.storeImportLocationTypes[i].VenueLocationTypeId === locationTypeId) {
          this.storeImportLocationTypes[i].IsSelected = 0;
          locationTypeIndex = i;
          if (this.storeImportLocationTypes[i].IsSelected === 0) {
            this.storeImportLocationTypes.splice(locationTypeIndex, 1);
            i = i - 1;
          }
        }
      }
      this.selectAllImportLocationTypes = false;
    }
  }

  public openImportConfirmationModal(importLocationTypeForm: NgForm) {
    this.importLocationTypeForm = importLocationTypeForm;
    this.importConfirmationModal = true;
  }

  public closeImportConfirmationModal() {
    this.importConfirmationModal = false;
  }

  public saveImportLocationTypes() {
    this.loaderService.display(true);
    this.isImportReqComplete = false;

    this.importLocationType.SourceVenueId = this.selectedVenueId;
    this.importLocationType.DestinationVenueId = this.authState.getStoredContextVenueId();
    this.importLocationType.AddedBy = this.currentUser.UserId;
    this.importLocationType.LocationTypes = [];
    this.importLocationType.LocationTypes = this.storeImportLocationTypes;

    this.locationTypeService.saveImportLocationTypes(this.importLocationType).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.isImportReqComplete = false;
        this.clearData();
        this.utilitiesService.mdmodal('Import Location Type', data.Message);
      },
      (error) => {
        this.loaderService.display(false);
        this.isImportReqComplete = false;

        if (error.status == 409) {
          if (error._body) {
            let obj = JSON.parse(error._body);
            this.utilitiesService.smmodal('Import Location Type', obj.exception);
            this.clearData();
          }
        }
        console.log(error);
        console.log(
          'locationTypeService saveImportLocationTypes call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public clearData() {
    this.importConfirmationModal = false;
    this.importLocationTypeVenues = [];
    this.isVenueDisabled = true;
    this.selectedVenueId = null;

    this.importLocationTypes = [];
    this.storeImportLocationTypes = [];

    this.importLocationTypeCorporations = [];
    this.selectedCorporationId = null;
    this.importLogsData = [];
    this.importLogPage = 1;

    if (this.importLocationTypeForm) {
      this.importLocationTypeForm.reset();
    }

    this.importLocationType = new ImportLocationTypeLog();
    this.getAllImportLocationTypeCorporations();
  }

  public backToLocationTypeList() {
    if (this.importLocationTypeForm) {
      this.importLocationTypeForm.reset();
    }
    this.isImportLocationTypeScreen = false;

    this.importLocationTypeCorporations = [];
    this.isCorporationDisabled = true;
    this.selectedCorporationId = null;

    this.importLocationTypeVenues = [];
    this.isVenueDisabled = true;
    this.selectedVenueId = null;

    this.importLocationTypes = [];
    this.importLogsData = [];
    this.importLogPage = 1;
    this.importLogCount = 0;

    if (this.myQuerySub) {
      this.myQuerySub.unsubscribe();
    }
    this.getAllLocationTypes(this.searchString, this.page, this.size, this.viewType);
  }

  //// Import logs --------------------------------
  public async interval() {
    this.myQuerySub = interval(1000 * 60).subscribe(() => {
      this.getImportLogData(this.importLogPage, false);
    });
  }

  public getImportLogData(page: number, isLoaderDisplay: boolean) {
    if (isLoaderDisplay) {
      this.loaderService.display(true);
    }
    const payload = {};

    this.locationTypeService.GetAllImportedLocationTypes(payload, page, 10).subscribe(
      (data) => {
        if (isLoaderDisplay) {
          this.loaderService.display(false);
        }
        this.importLogsData = data;
        if (
          this.importLogsData.length < 0 &&
          this.importLogsData[0].Count / this.utilitiesService.initialPageSize < this.page
        ) {
          this.importLogPage = 1;
        }
      },
      (error) => {
        if (isLoaderDisplay) {
          this.loaderService.display(false);
        }
        console.log(
          'locationTypeService GetAllImportedLocationTypes call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
      () => (this.importLogCount = this.importLogsData[0] ? this.importLogsData[0].Count : 0),
    );
  }

  public hasImportLogData() {
    return this.importLogsData !== null && this.importLogsData.length > 0;
  }

  public importLogDataPageChanged(p: number) {
    this.importLogPage = p;
    this.getImportLogData(this.importLogPage, true);
  }

  public downloadImportLocationTypeFile(
    SourceVenueId: number,
    DestinationVenueId: number,
    ImportLocationTypeLogId: number,
  ) {
    this.loaderService.display(true);
    const payload = new ImportLocationTypeLog();
    payload.SourceVenueId = SourceVenueId;
    payload.DestinationVenueId = DestinationVenueId;
    payload.ImportLocationTypeLogId = ImportLocationTypeLogId;

    this.locationTypeService.downloadImportLogFile(payload).subscribe(
      (result) => {
        this.loaderService.display(false);
        const date = new Date();
        let month = date.getMonth() + 1;
        const timestamp =
          date.getFullYear().toString() +
          month.toString() +
          date.getDate().toString() +
          date.getHours().toString() +
          date.getMinutes().toString() +
          date.getMilliseconds().toString();
        const filename = timestamp.toString() + '.xlsx';
        this.downloadFile(result, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', filename);
      },
      (error) => {
        console.log(
          'locationTypeService downloadImportLogFile Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public downloadFile(blob: any, filetype: string, filename: string) {
    const binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: filetype }));

    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
  }
}
