import { createReducer, on } from '@ngrx/store';
import * as UserDataActions from './user-action';
import { UserStoreState, initialUserStoreState } from './user-state';

// defines the reducer function userDataReducer.
// A reducer specifies how the application's state changes in response to actions sent to the store.
// createReducer is used to create the reducer, and on is used to handle the specific setUserData action.
// When setUserData is dispatched, the reducer updates the state by spreading the current state and the action's properties.

export const userDataReducer = createReducer(
  initialUserStoreState,
  on(UserDataActions.setUserData, (state, { user }): UserStoreState => ({ ...state, user })),
  on(UserDataActions.setUserPermissions, (state, { permissions }): UserStoreState => ({ ...state, permissions })),
  on(
    UserDataActions.setUserName,
    (state, { FirstName, LastName }): UserStoreState => ({
      ...state,
      user: { ...state.user, FirstName, LastName },
    }),
  ),
  on(
    UserDataActions.setUserImage,
    (state, { ImageName }): UserStoreState => ({
      ...state,
      user: { ...state.user, ImageName },
    }),
  ),
  on(
    UserDataActions.setUserCorporationAndVenue,
    (state, { CorporationId, CorporationName, DefaultVenueId }): UserStoreState => ({
      ...state,
      user: {
        ...state.user,
        DefaultVenueId,
        Corporation: {
          ...state.user.Corporation,
          CorporationId: CorporationId || state.user.Corporation.CorporationId,
          CorporationName: CorporationName || state.user.Corporation.CorporationName,
        },
      },
    }),
  ),
);
