import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { CountryModule } from '../country.module';
import { CountryService } from './country.http.service';

@Component({
  selector: 'country-component',
  templateUrl: 'country.component.html',
  providers: [CountryService, UtilitiesService],
})
export class CountryComponent implements OnInit {
  currentUser: User;

  public countries: CountryModule[] = [];
  public country: CountryModule = new CountryModule();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];

  searchString = '';
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  public pageState: StateMaintain = {
    pageName: 'countryForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  constructor(
    private countryService: CountryService,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    private authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
    }
  }

  /// this funcation used to get result as per page size change
  public CountryPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.countries = null;
    this.count = 0;
  }

  public setAddForm() {
    this.country = new CountryModule();
    this.searchString = '';
    this.countries = null;
    this.count = 0;

    setTimeout(() => {
      const element = document.getElementById('CountryName');
      element.focus();
    }, 1000);
  }

  public clearFormData(countryForm: NgForm) {
    this.searchString = '';
    countryForm.reset();
    this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
  }

  public countriesSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCountries(searchString, 1, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.countries !== null && this.countries.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
  }

  public addCountry(countryForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (countryForm.status == 'INVALID' && countryForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Country', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.country.CountryName = this.utilitiesService.removeInnerSpaces(this.country.CountryName);
    if (this.country.CountryName === null || this.country.CountryName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }
    this.country.AddedBy = this.currentUser.UserId;

    this.countryService.Add(this.utilitiesService.stripScript(this.country)).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Country', data.Message);
        this.clearFormData(countryForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'countryService addCountry Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public updateCountry(countryForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (countryForm.status == 'INVALID' && countryForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Country', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.country.CountryName = this.utilitiesService.removeInnerSpaces(this.country.CountryName);
    if (this.country.CountryName === null || this.country.CountryName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }
    this.country.IsActive = this.country.IsActive ? 1 : 0;
    this.country.ModifiedBy = this.currentUser.UserId;

    this.countryService.Update(this.utilitiesService.stripScript(this.country)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Country', data.Message);
        this.clearFormData(countryForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'countryService updateCountry Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllCountries(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      genericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.countryService.GetCountryList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.countries = data;
        if (this.countries.length < 0 && this.countries[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'countryService getAllCountries Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.countries[0] ? this.countries[0].Count : 0),
    );
  }

  public getCountryById(countryId: number) {
    this.loaderService.display(true);
    const CPcountry = new CountryModule();
    CPcountry.CountryCPId = this.authState.AESEncryptText(countryId);

    this.countryService.GetSingle(CPcountry).subscribe(
      (data) => {
        this.country = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'countryService getCountryById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllCountries(this.searchString, this.page, this.size, this.viewType);
  }
}
