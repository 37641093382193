<h2 mat-dialog-title>
  {{ data.title }}
  <button *ngIf="data.flagVal" [mat-dialog-close]="data.title" class="closeBtn">
    <mat-icon style="font-weight: 900">close</mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <div style="text-align: center">
    <!--<mat-icon [ngStyle]="{'color':getAlertColor()}">{{ getAlertIcon() }}</mat-icon>-->
    <p>{{ data.content }}</p>
  </div>
</mat-dialog-content>

<div *ngIf="!data.flagVal"><mat-divider></mat-divider></div>

<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [mat-dialog-close]="data.title">
    {{ data.closeButtonLabel }}
  </button>
</mat-dialog-actions>
