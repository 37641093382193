import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'atlas-theme/dist/js/adminApp';
import 'atlas-theme/dist/js/app';

// Pagination
import 'ngx-pagination/dist/ngx-pagination';

import 'crypto-js/crypto-js.js';
////////////////////

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
