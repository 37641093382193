import { DatePipe } from '@angular/common';
import { Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { Configuration } from 'src/app/app.constants';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { setUserData } from 'src/app/root-store/user-data/user-action';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { User } from '../../usermanagement/user/user.model';
import { CityModule } from '../../venuemanagement/city/city.module';
import { CityService } from '../../venuemanagement/city/components/city.http.service';
import { CountryService } from '../../venuemanagement/country/components/country.http.service';
import { CountryModule } from '../../venuemanagement/country/country.module';
import { StateService } from '../../venuemanagement/state/components/state.http.service';
import { StateModule } from '../../venuemanagement/state/state.module';
import { Module, ServiceProviderModule } from '../serviceprovider.module';
import { ServiceProviderService } from './serviceprovider.http.service';
@Injectable({
  providedIn: 'root',
})
export class config extends TreeviewConfig {
  hasAllCheckBox = false;
  hasFilter = false;
  hasCollapseExpand = false;
  maxHeight = 400;
}

@Component({
  selector: 'serviceprovider-component',
  templateUrl: 'serviceprovider.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    ServiceProviderService,
    UtilitiesService,
    CountryService,
    StateService,
    CityService,
    DatePipe,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: config },
  ],
})
export class ServiceProviderComponent implements OnInit {
  @ViewChild('fileInput') fileInputVariable: any;
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;
  currentUser: User;

  public ServiceProviders: ServiceProviderModule[] = [];
  public ServiceProvider: ServiceProviderModule = new ServiceProviderModule();

  public countries: CountryModule[] = [];
  public states: StateModule[] = [];
  public cities: CityModule[] = [];

  // public Module: Module = new Module();
  public Modules: Module[] = [];

  page = 1;
  size = 10;
  count = 0;
  searchString = '';

  isReqComplete = false;
  searchTerm: string;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;

  showHidePassword = false;
  stateDisabled = true;
  cityDisabled = true;
  activeNavigationUrls: any;
  activeNavigationName: string;
  pageSizes: any[] = [];

  public pageState: StateMaintain = {
    pageName: 'serviceprovidersForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  items: TreeviewItem[];
  rows: string[];
  public config = {};

  public isAllFeatureConfigurationChecked = false;
  public isCheckBoxVisible = false;
  public isSysAdmin = false;

  file: File = null;
  serviceProviderDefaultLogo = 'assets/uploadImage.png';
  serviceProviderLogoSrc: any = null;
  isInspectionColorConfigShow = false;
  currentDate: any;

  constructor(
    private authState: AuthState,
    private utilitiesService: UtilitiesService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private loaderService: LoaderService,
    private configuration: Configuration,
    private datePipe: DatePipe,
    private serviceprovidersService: ServiceProviderService,
    private readonly store: Store,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.isSysAdmin = this.currentUser.UserApplicationLevel == 'ADMIN';

    this.ServiceProvider = new ServiceProviderModule();
    this.ServiceProvider.Country = new CountryModule();
    this.ServiceProvider.State = new StateModule();
    this.ServiceProvider.City = new CityModule();
    this.ServiceProvider.Modules = [];

    this.config = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500,
    };
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    this.currentFullDate();

    this.activeNavigationUrls = document.getElementsByClassName('treeview');
    for (let i = 0; i < this.activeNavigationUrls.length; i++) {
      this.activeNavigationName = this.utilitiesService.removeInnerSpaces(this.activeNavigationUrls[i].textContent);

      if (this.activeNavigationName.toLowerCase() != 'service providers') {
        this.activeNavigationUrls[i].classList.remove('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'none';
        this.activeNavigationUrls[i].classList.remove('active');
      }
      if (this.activeNavigationName.toLowerCase() == 'service providers') {
        this.activeNavigationUrls[i].classList.add('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'block';
        this.activeNavigationUrls[i].classList.add('active');
      }
    }
  }

  public currentFullDate() {
    const cDate = new Date();
    this.currentDate = cDate.toISOString().split('T')[0];
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllServiceProviders(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllServiceProviders(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.serviceProviderLogoSrc = null;
    this.file = null;

    this.count = 0;
    this.searchString = '';
    this.ServiceProviders = null;
    this.isCheckBoxVisible = false;

    // this.Module = new Module();
    this.ServiceProvider = new ServiceProviderModule();
    this.ServiceProvider.Country = new CountryModule();
    this.ServiceProvider.State = new StateModule();
    this.ServiceProvider.City = new CityModule();

    this.getAllcountries('', -1, -1);
    this.GetModuleList();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public setUpdateForm() {
    this.count = 0;
    this.searchString = '';
    this.ServiceProviders = null;

    this.getAllcountries('', -1, -1);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public clearFormData(serviceproviderForm: NgForm) {
    serviceproviderForm.reset();
    this.serviceProviderLogoSrc = null;
    this.isCheckBoxVisible = false;
    this.searchString = '';
    this.showHidePassword = false;
    this.isInspectionColorConfigShow = false;
    this.items = [];
    this.Modules = [];

    this.ServiceProvider = new ServiceProviderModule();
    this.ServiceProvider.Country = new CountryModule();
    this.ServiceProvider.State = new StateModule();
    this.ServiceProvider.City = new CityModule();

    this.getAllServiceProviders(this.searchString, this.page, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.ServiceProviders !== null && this.ServiceProviders.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllServiceProviders(this.searchString, this.page, this.size, this.viewType);
  }

  public showHide(event: any) {
    if (event === 'Password') {
      this.showHidePassword = !this.showHidePassword;
    }
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllServiceProviders(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllServiceProviders(this.searchString, this.page, this.size, this.viewType);
  }

  public getAllServiceProviders(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.serviceprovidersService.GetServiceProviderList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.ServiceProviders = data;
        if (
          this.ServiceProviders.length < 0 &&
          this.ServiceProviders[0].Count / this.utilitiesService.initialPageSize < this.page
        ) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'serviceproviderService getAllServiceProviders Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
      () => (this.count = this.ServiceProviders[0] ? this.ServiceProviders[0].Count : 0),
    );
  }

  public getServiceProviderById(ServiceProviderId: number) {
    if (this.authState.canAccess('SERVICE_PROVIDER_VIEW')) {
      this.loaderService.display(true);
      this.isCheckBoxVisible = false;
      const CPServiceProvider = new ServiceProviderModule();
      CPServiceProvider.ServiceProviderCPId = this.authState.AESEncryptText(ServiceProviderId);

      this.serviceprovidersService.GetSingle(CPServiceProvider).subscribe(
        (data) => {
          this.ServiceProvider = data;

          if (this.ServiceProvider.SPLicenseExpiryDate) {
            this.ServiceProvider.SPLicenseExpiryDate = this.ServiceProvider.SPLicenseExpiryDate.split('T')[0];
          }

          if (this.ServiceProvider.AttachmentURL != null) {
            this.serviceProviderLogoSrc = this.ServiceProvider.AttachmentURL;
          } else {
            this.serviceProviderDefaultLogo = 'assets/uploadImage.png';
          }
          this.loaderService.display(false);

          if (this.ServiceProvider.Country.CountryId != null) {
            this.getAllstatesByCountry(this.ServiceProvider.Country.CountryId);
          }

          if (this.ServiceProvider.State.StateId != null) {
            this.getAllcitiesByState(this.ServiceProvider.State.StateId);
          }

          let that = this;
          if (this.ServiceProvider.Modules != null && this.ServiceProvider.Modules.length > 0) {
            this.isCheckBoxVisible = true;
            this.items = [];
            this.ServiceProvider.Modules.forEach(function (item) {
              let childrens = [];
              item.IsActive = item.IsActive ? true : false;

              if (item.ModuleCode == 'INSPECT' && item.IsActive == true) {
                that.isInspectionColorConfigShow = true;
              }
              if (that.isSysAdmin) {
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    disabled: false,
                    value: item.ModuleId,
                    checked: item.IsActive,
                    children: childrens,
                  }),
                );
              } else {
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    disabled: true,
                    value: item.ModuleId,
                    checked: item.IsActive,
                    children: childrens,
                  }),
                );
              }
            });

            if (this.items && this.items.length > 0) {
              let itemObj = this.items.filter((tree) => tree.checked == false || tree.checked == undefined);
              if (itemObj && itemObj.length > 0) {
                this.isAllFeatureConfigurationChecked = false;
              } else {
                this.isAllFeatureConfigurationChecked = true;
              }
            }
          }
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'serviceproviderService GetServiceProviderById Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.mdmodal('Service Provider', 'You do not have permission to view service provider details.');
    }
  }

  public addServiceProvider(serviceproviderForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (serviceproviderForm.status == 'INVALID' && serviceproviderForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.mdmodal(
        'Service Provider',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.ServiceProvider.SPLicenseExpiryDate = this.datePipe.transform(
      this.ServiceProvider.SPLicenseExpiryDate,
      'yyyy-MM-dd',
    );
    if (this.ServiceProvider.SPLicenseExpiryDate < this.currentDate) {
      this.utilitiesService.mdmodal('Service Provider', 'Selected license expiry date is less than current date.');
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.ServiceProvider.ServiceProviderName = this.utilitiesService.removeInnerSpaces(
      this.ServiceProvider.ServiceProviderName,
    );
    if (this.ServiceProvider.ServiceProviderName === null || this.ServiceProvider.ServiceProviderName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let that = this;
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked == true) {
          let Mod = new Module();
          Mod.ModuleId = it.value;
          Mod.ModuleName = it.text;
          Mod.IsActive = 1;
          that.ServiceProvider.Modules.push(Mod);
        }
      });
    } else {
      this.ServiceProvider.Modules = [];
    }
    this.ServiceProvider.AddedBy = this.currentUser.UserId;

    if (
      this.ServiceProvider.RedColourMin &&
      this.ServiceProvider.RedColourMax &&
      this.ServiceProvider.YellowColourMin &&
      this.ServiceProvider.YellowColourMax &&
      this.ServiceProvider.GreenColourMin &&
      this.ServiceProvider.GreenColourMax
    ) {
      let colorArray = [
        {
          ColorId: 1,
          Color: 'Red',
          MinValue: Number(this.ServiceProvider.RedColourMin),
          MaxValue: Number(this.ServiceProvider.RedColourMax),
        },
        {
          ColorId: 2,
          Color: 'Yellow',
          MinValue: Number(this.ServiceProvider.YellowColourMin),
          MaxValue: Number(this.ServiceProvider.YellowColourMax),
        },
        {
          ColorId: 3,
          Color: 'Green',
          MinValue: Number(this.ServiceProvider.GreenColourMin),
          MaxValue: Number(this.ServiceProvider.GreenColourMax),
        },
      ];

      for (let c = 0; c < colorArray.length; c++) {
        if (Number(colorArray[c].MinValue) > Number(colorArray[c].MaxValue)) {
          this.utilitiesService.mdmodal(
            'Service Provider',
            colorArray[c].Color + ' color minimum value should be less than maximum value.',
          );
          this.loaderService.display(false);
          this.isReqComplete = false;
          return;
        }

        if (
          colorArray[c + 1] &&
          (Number(colorArray[c].MaxValue) == colorArray[c + 1].MinValue ? Number(colorArray[c + 1].MinValue) : null)
        ) {
          this.utilitiesService.mdmodal(
            'Service Provider',
            colorArray[c + 1].Color +
              ' color minimum value should be greater than ' +
              colorArray[c].Color +
              ' maximum value.',
          );
          this.loaderService.display(false);
          this.isReqComplete = false;
          return;
        }
      }

      colorArray = colorArray.sort((a, b) => a.ColorId - b.ColorId);
      //// check added color score values are not overlaping each other
      const rpy = this.isOverlap(colorArray);
      if (rpy != undefined && rpy && rpy.length > 1) {
        this.utilitiesService.mdmodal(
          'Service Provider',
          rpy[0].Color + ' color minimum value is overlaping with ' + rpy[1].Color + ' color value.',
        );
        this.loaderService.display(false);
        this.isReqComplete = false;
        return;
      }
    }
    this.ServiceProvider.AllowToShareUser = this.ServiceProvider.AllowToShareUser ? 1 : 0;
    // console.log(this.ServiceProvider);

    this.serviceprovidersService.Add(this.utilitiesService.stripScript(this.ServiceProvider)).subscribe(
      (data) => {
        if (this.file) {
          let that = this;
          this.serviceprovidersService
            .AddServiceProviderLogo(this.file, data.id)
            .then(function (_response) {
              that.restFun(serviceproviderForm, data.Message);
            })
            .catch((err) => {
              console.log(err);
              this.restFun(serviceproviderForm, data.Message);
            });
        } else {
          this.restFun(serviceproviderForm, data.Message);
        }
      },
      (error) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.clearFormData(serviceproviderForm);
        console.log(
          'serviceproviderService addServiceProvider Call Failed. Status:' +
            error.status +
            'Status Text' +
            error.statusText,
        );
      },
    );
  }

  public restFun(serviceproviderForm: any, msg: any) {
    this.page = 1;
    this.isReqComplete = false;
    this.loaderService.display(false);
    this.clearFormData(serviceproviderForm);
    this.utilitiesService.mdmodal('Service Provider', msg);
  }

  public updateServiceProvider(serviceproviderForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (serviceproviderForm.status == 'INVALID' && serviceproviderForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.mdmodal(
        'Service Provider',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.ServiceProvider.ServiceProviderName = this.utilitiesService.removeInnerSpaces(
      this.ServiceProvider.ServiceProviderName,
    );
    if (this.ServiceProvider.ServiceProviderName === null || this.ServiceProvider.ServiceProviderName === '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let that = this;
      this.ServiceProvider.Modules = [];
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked == true) {
          let Mod = new Module();
          Mod.ModuleId = it.value;
          Mod.ModuleName = it.text;
          Mod.IsActive = 1;
          that.ServiceProvider.Modules.push(Mod);
        }
      });
    } else {
      this.ServiceProvider.Modules = [];
    }

    this.ServiceProvider.ModifiedBy = this.currentUser.UserId;
    this.ServiceProvider.IsActive = this.ServiceProvider.IsActive ? 1 : 0;

    if (this.file) {
      this.serviceprovidersService.AddServiceProviderLogo(this.file, this.ServiceProvider.ServiceProviderId);
    } else if (this.serviceProviderLogoSrc == null) {
      const CPServiceProvider = new ServiceProviderModule();
      CPServiceProvider.ServiceProviderCPId = this.authState.AESEncryptText(this.ServiceProvider.ServiceProviderId);
      this.serviceprovidersService.RemoveServiceProviderLogo(CPServiceProvider).subscribe((_res) => {
        // console.log(res);
      });
    }

    if (
      this.ServiceProvider.RedColourMin &&
      this.ServiceProvider.RedColourMax &&
      this.ServiceProvider.YellowColourMin &&
      this.ServiceProvider.YellowColourMax &&
      this.ServiceProvider.GreenColourMin &&
      this.ServiceProvider.GreenColourMax
    ) {
      let colorArray = [
        {
          ColorId: 1,
          Color: 'Red',
          MinValue: Number(this.ServiceProvider.RedColourMin),
          MaxValue: Number(this.ServiceProvider.RedColourMax),
        },
        {
          ColorId: 2,
          Color: 'Yellow',
          MinValue: Number(this.ServiceProvider.YellowColourMin),
          MaxValue: Number(this.ServiceProvider.YellowColourMax),
        },
        {
          ColorId: 3,
          Color: 'Green',
          MinValue: Number(this.ServiceProvider.GreenColourMin),
          MaxValue: Number(this.ServiceProvider.GreenColourMax),
        },
      ];

      for (let c = 0; c < colorArray.length; c++) {
        if (Number(colorArray[c].MinValue) > Number(colorArray[c].MaxValue)) {
          this.utilitiesService.mdmodal(
            'Service Provider',
            colorArray[c].Color + ' color minimum value should be less than maximum value.',
          );
          this.loaderService.display(false);
          this.isReqComplete = false;
          return;
        }

        if (
          colorArray[c + 1] &&
          (Number(colorArray[c].MaxValue) == colorArray[c + 1].MinValue ? Number(colorArray[c + 1].MinValue) : null)
        ) {
          this.utilitiesService.mdmodal(
            'Service Provider',
            colorArray[c + 1].Color +
              ' color minimum value should be greater than ' +
              colorArray[c].Color +
              ' maximum value.',
          );
          this.loaderService.display(false);
          this.isReqComplete = false;
          return;
        }
      }

      colorArray = colorArray.sort((a, b) => a.ColorId - b.ColorId);
      //// check added color score values are not overlaping each other
      const rpy = this.isOverlap(colorArray);
      if (rpy != undefined && rpy && rpy.length > 1) {
        this.utilitiesService.mdmodal(
          'Service Provider',
          rpy[0].Color + ' color minimum value is overlaping with ' + rpy[1].Color + ' color value.',
        );
        this.loaderService.display(false);
        this.isReqComplete = false;
        return;
      }
    }
    this.ServiceProvider.SPLicenseExpiryDate = this.datePipe.transform(
      this.ServiceProvider.SPLicenseExpiryDate,
      'yyyy-MM-dd',
    );
    this.ServiceProvider.AllowToShareUser = this.ServiceProvider.AllowToShareUser ? 1 : 0;
    // console.log(this.ServiceProvider);

    this.serviceprovidersService.Update(this.utilitiesService.stripScript(this.ServiceProvider)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);

        if (this.ServiceProvider.ServiceProviderId == this.authState.getStoredContextServiceProviderId()) {
          this.authState.storedIsAllowToShareUser(this.ServiceProvider.AllowToShareUser);
          this.store.dispatch(setUserData({ user: { ...this.currentUser, lastUpdated: new Date() } }));
        }

        this.clearFormData(serviceproviderForm);
        this.utilitiesService.mdmodal('Service Provider', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'serviceprovidersService updateServiceProvider Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public getAllcountries(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.countryService.GetCountryDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.countries = data;
        this.loaderService.display(false);

        if (!this.ServiceProvider.ServiceProviderId) {
          const obj = this.countries.filter(
            (c) => c.CountryName === 'USA' || c.CountryName == 'United States Of America',
          );
          if (obj[0]) {
            this.ServiceProvider.Country.CountryId = obj[0].CountryId;
            this.getAllstatesByCountry(this.ServiceProvider.Country.CountryId);
          }
        }
      },
      (error) => {
        console.log(
          'countryService GetCountryDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public countryChange($event: any) {
    this.ServiceProvider.Country.CountryId = $event;

    if (this.ServiceProvider.Country.CountryId) {
      this.states = [];
      this.ServiceProvider.State.StateId = null;
      this.getAllstatesByCountry(this.ServiceProvider.Country.CountryId);

      this.cities = [];
      this.cityDisabled = true;
      this.ServiceProvider.City.CityId = null;
    } else {
      this.states = [];
      this.stateDisabled = true;
      this.ServiceProvider.State.StateId = null;

      this.cities = [];
      this.cityDisabled = true;
      this.ServiceProvider.City.CityId = null;
    }
  }

  public getAllstatesByCountry(CountryId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      country: { CountryId },
    };
    if (CountryId) {
      this.stateService.GetStateDropdown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.states = data;
          this.stateDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'stateService GetStateDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    } else {
      this.states = [];
      this.stateDisabled = true;
    }
  }

  public stateChange($event: any) {
    this.ServiceProvider.State.StateId = $event;

    if (this.ServiceProvider.State.StateId) {
      this.cities = [];
      this.ServiceProvider.City.CityId = null;

      this.getAllcitiesByState(this.ServiceProvider.State.StateId);
    } else {
      this.cities = [];
      this.cityDisabled = true;
      this.ServiceProvider.City.CityId = null;
    }
  }

  public getAllcitiesByState(stateId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      state: { stateId },
    };
    if (stateId) {
      this.cityService.GetCitiesDropDown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.cities = data;
          this.cityDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'cityService GetCitiesDropDown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    } else {
      this.cities = [];
      this.cityDisabled = true;
    }
  }

  public serviceproviderSearch(searchString: string, page: number, size: number) {
    this.page = page;
    this.getAllServiceProviders(searchString, page, size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllServiceProviders('', this.page, this.size, this.viewType);
  }

  public GetModuleList() {
    this.loaderService.display(true);

    this.serviceprovidersService.GetModueleList().subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.isCheckBoxVisible = true;
          let that = this;
          this.Modules = [];
          this.Modules = data;
          if (this.Modules != null && this.Modules.length > 0) {
            this.items = [];

            this.Modules.forEach(function (item) {
              let childrens = [];
              if (that.isSysAdmin) {
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    disabled: false,
                    value: item.ModuleId,
                    checked: false,
                    children: childrens,
                  }),
                );
              } else {
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    disabled: true,
                    value: item.ModuleId,
                    checked: false,
                    children: childrens,
                  }),
                );
              }
            });
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'venueService GetModueleList Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public onSelectedChange(_downlineItems: DownlineTreeviewItem[]) {
    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let item = this.treeviewComponent.items.filter((tree) => tree.checked == false || tree.checked == undefined);

      if (item && item.length > 0) {
        let that = this;
        item.forEach(function (it) {
          if (it.text == 'Inspect' && it.checked == true) {
            that.isInspectionColorConfigShow = true;
          } else if (it.text == 'Inspect' && (it.checked == false || it.checked == undefined)) {
            that.isInspectionColorConfigShow = false;
          }
        });

        this.isAllFeatureConfigurationChecked = false;
      } else {
        this.isAllFeatureConfigurationChecked = true;
      }
    }

    if (_downlineItems != null && _downlineItems.length > 0) {
      for (let j = 0; j < _downlineItems.length; j++) {
        if (_downlineItems[j].item.text == 'Inspect' && _downlineItems[j].item.checked == true) {
          this.isInspectionColorConfigShow = true;
        } else if (
          _downlineItems[j].item.text == 'Inspect' &&
          (_downlineItems[j].item.checked == false || _downlineItems[j].item.checked == undefined)
        ) {
          this.isInspectionColorConfigShow = false;
        }
      }
    }
  }

  public onAllCheckedChange(event: any) {
    let that = this;
    this.treeviewComponent.items.forEach(function (it) {
      it.checked = event.checked;

      if (it.text == 'Inspect' && it.checked == true) {
        that.isInspectionColorConfigShow = true;
      } else if (it.text == 'Inspect' && (it.checked == false || it.checked == undefined)) {
        that.isInspectionColorConfigShow = false;
      }
      that.changeChildrenSelection(it, event.checked);
    });
  }

  public changeChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  public ServiceproviderPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllServiceProviders(this.searchString, this.page, this.size, this.viewType);
  }

  public serviceprovidersSearch(searchString: string, size: number) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllServiceProviders(searchString, this.page, size, this.viewType);
  }

  fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Service Provider', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const extention = event.target.files[0].name.split('.');
      const ext = extention[extention.length - 1].toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg') {
        if (event.target.files && event.target.files[0]) {
          const filesize = event.target.files[0].size / Math.pow(1024, 2);
          if (filesize > this.utilitiesService.fileUploadSize) {
            this.utilitiesService.mdmodal(
              'Service Provider',
              'Picture should not be more than ' + this.utilitiesService.fileUploadSize + ' MB.',
            );
            event.target.value = '';
            return;
          } else {
            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.file = fileList[0];
              const reader = new FileReader();
              reader.onload = this._handleReaderLoaded.bind(this);
              reader.readAsBinaryString(this.file);
            }
          }
        }
      } else {
        this.utilitiesService.smmodal('Service Provider', 'Please upload image of type .jpg, .jpeg, .png, .svg.');
        event.target.value = '';
      }
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.serviceProviderLogoSrc = 'data:image/jpeg;base64,' + btoa(binaryString); // Converting binary string data.
  }

  fileReset() {
    this.fileInputVariable.nativeElement.value = '';
    this.file = null;
    this.serviceProviderLogoSrc = null;
  }

  public isOverlap(colorMinMax: any[]) {
    const getMaxEnd = function (array: any[]) {
      if (array.length == 0) {
        return false;
      }
      array.sort(function (a, b) {
        if (a.MaxValue < b.MaxValue) {
          return 1;
        }
        if (a.MaxValue > b.MaxValue) {
          return -1;
        }
        return 0;
      });
      return array[0].MaxValue;
    };

    const rarray = [];
    let g = 0;
    rarray[g] = [colorMinMax[0]];

    colorMinMax = colorMinMax.sort((a, b) => a.ColorId - b.ColorId);
    for (let i = 1, l = colorMinMax.length; i < l; i++) {
      if (colorMinMax[i].MinValue >= colorMinMax[i - 1].MinValue && colorMinMax[i].MinValue < getMaxEnd(rarray[g])) {
        rarray[g].push(colorMinMax[i]);
        if (rarray[g].length > 1) {
          return rarray[g];
        }
      } else {
        g++;
        rarray[g] = [colorMinMax[i]];
      }
    }
  }
}
