import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { LocationModule } from '../../../staffscheduling/location/location.module';
import { ZoneModule } from '../../../staffscheduling/zone/zone.module';
import { User } from '../../../usermanagement/user/user.model';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { LevelModule } from '../../../venuemanagement/level/level.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import {
  FilterInputs,
  InspectorAssignmentCorporations,
  InspectorLocationAssignmentModule,
  LocModule,
  VenModule,
} from '../inspectorlocationassignment.module';
import { InspectorLocationAssignmentService } from './inspectorlocationassignment.http.service';

@Component({
  selector: 'inspectorlocationassignment-component',
  templateUrl: 'inspectorlocationassignment.component.html',
  styleUrls: ['./inspectorlocationassignment.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [InspectorLocationAssignmentService, UtilitiesService],
})
export class InspectorLocationAssignmentComponent implements OnInit {
  currentUser: User;

  public userLocations: InspectorAssignmentCorporations[] = [];
  public storeUserLocations: VenModule[] = [];
  public userLocationAssignment: InspectorLocationAssignmentModule = new InspectorLocationAssignmentModule();

  public users: User[] = [];
  public isReqComplete = false;

  public corporationDropdownSettings: any = {};
  public venueDropdownSettings: any = {};
  public buildingDropdownSettings: any = {};
  public zoneDropdownSettings: any = {};
  public levelDropdownSettings: any = {};
  public locationDropdownSettings: any = {};

  public corporationIds: any[] = [];
  public venueIds: any[] = [];
  public buildingIds: any[] = [];
  public zoneIds: any[] = [];
  public levelIds: any[] = [];

  public corporations: CorporationModule[] = [];
  public venues: VenueModule[] = [];
  public buildings: BuildingModule[] = [];
  public zones: ZoneModule[] = [];
  public levels: LevelModule[] = [];

  public Locations: LocationModule[] = [];
  public LocationIds: any[] = [];

  constructor(
    private inspectorLocationAssignmentService: InspectorLocationAssignmentService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.userLocationAssignment = new InspectorLocationAssignmentModule();
    this.userLocationAssignment.User = new User();
    this.userLocationAssignment.Venue = new VenModule();
    this.userLocationAssignment.Venue.Location = new LocModule();
  }

  ngOnInit() {
    this.corporationDropdownSettings = {
      singleSelection: false,
      idField: 'CorporationId',
      textField: 'CorporationName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.venueDropdownSettings = {
      singleSelection: false,
      idField: 'VenueId',
      textField: 'VenueName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.buildingDropdownSettings = {
      singleSelection: false,
      idField: 'BuildingId',
      textField: 'BuildingName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.zoneDropdownSettings = {
      singleSelection: false,
      idField: 'ZoneId',
      textField: 'ZoneName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.levelDropdownSettings = {
      singleSelection: false,
      idField: 'LevelId',
      textField: 'LevelName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.locationDropdownSettings = {
      singleSelection: false,
      idField: 'LocationId',
      textField: 'LocationName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.getAllUsers('');
  }

  canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature);
  }

  public clearFormData() {
    this.userLocationAssignment = new InspectorLocationAssignmentModule();
    this.userLocationAssignment.User = new User();
    this.users = [];
    this.userLocations = [];
    this.storeUserLocations = [];

    this.Locations = [];
    this.LocationIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    this.venues = [];
    this.venueIds = [];

    this.corporations = [];
    this.corporationIds = [];

    this.getAllUsers('');
  }

  public addInspectorLocationAssignment(userLocationAssignmentForm: NgForm) {
    this.isReqComplete = true;
    this.loaderService.display(true);

    if (userLocationAssignmentForm.status == 'INVALID' && userLocationAssignmentForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.mdmodal(
        'Assign Inspection Locations',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.userLocationAssignment.InspectorId = this.userLocationAssignment.User.UserId;
    this.userLocationAssignment.Venue = new VenModule();
    this.userLocationAssignment.Venues = [];
    this.userLocationAssignment.Venue.Locations = [];
    let that = this;
    if (this.storeUserLocations && this.storeUserLocations.length > 0) {
      this.storeUserLocations.forEach(function (venue) {
        if (venue.Locations != null && venue.Locations.length > 0) {
          let venueObj = new VenModule();
          venueObj.VenueId = venue.VenueId;
          venueObj.VenueName = venue.VenueName;
          venueObj.Locations = [];

          venue.Locations.forEach(function (loc) {
            if (loc.IsActive == 1 || loc.IsActive == true) {
              let locObj = new LocModule();
              locObj.LocationId = loc.LocationId;
              locObj.LocationName = loc.LocationName;
              venueObj.Locations.push(locObj);
            }
          });
          that.userLocationAssignment.Venues.push(venueObj);
        }
      });
    }

    this.inspectorLocationAssignmentService
      .AddInspectorLocationAssignment(this.utilitiesService.stripScript(this.userLocationAssignment))
      .subscribe(
        () => {
          userLocationAssignmentForm.reset();
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.clearFormData();
          this.utilitiesService.snackBarWithAutoDismiss(
            'Assign Inspection Locations',
            'Assign inspection locations modified successfully!',
          );
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          console.log(
            'inspectionLocationAssignmentService Add Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
  }

  //// Get Users ------------------------------------------------------
  public getAllUsers(searchTerm: string) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', genericSearch: 0, '': {} };

    this.inspectorLocationAssignmentService.GetAllInspectors(JSON.stringify(searchfilter)).subscribe(
      (data) => {
        this.users = data;
        this.users.forEach(function (value) {
          value.FirstName = value.FirstName + ' ' + value.LastName;
        });
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectionLocationAssignmentService GetAllInspectors Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public changeUser($event: any) {
    this.userLocationAssignment.User.UserId = $event;
    if (this.userLocationAssignment.User.UserId) {
      this.userLocations = [];
      this.storeUserLocations = [];
      this.Locations = [];
      this.LocationIds = [];
      this.levels = [];
      this.levelIds = [];

      this.zones = [];
      this.zoneIds = [];

      this.buildings = [];
      this.buildingIds = [];

      this.venues = [];
      this.venueIds = [];

      this.corporations = [];
      this.corporationIds = [];

      this.getLocationAssignmentDetails(this.userLocationAssignment.User.UserId);
    } else {
      this.userLocationAssignment.User.UserId = null;
      this.userLocations = [];
      this.storeUserLocations = [];
      this.Locations = [];
      this.LocationIds = [];

      this.levels = [];
      this.levelIds = [];

      this.zones = [];
      this.zoneIds = [];

      this.buildings = [];
      this.buildingIds = [];

      this.venues = [];
      this.venueIds = [];

      this.corporations = [];
      this.corporationIds = [];
    }
  }

  //// Get Location assignment details based on selected user ----------------------------------
  public getLocationAssignmentDetails(userId: number) {
    this.loaderService.display(true);
    const payload = new FilterInputs();
    payload.UserCPId = this.authState.AESEncryptText(userId);

    this.inspectorLocationAssignmentService.GetAssignmentDetails(payload).subscribe(
      (data) => {
        if (data != null && data.Corporations != null && data.Corporations.length > 0) {
          if (data.Corporations != null && data.Corporations.length > 0) {
            let corpArray: any[] = [];
            data.Corporations.forEach(function (corp) {
              if (corp.IsActive != null && corp.IsActive == 1) {
                let corpObj: CorporationModule = new CorporationModule();
                corpObj.CorporationId = corp.CorporationId;
                corpObj.CorporationName = corp.CorporationName;
                corpArray.push(corpObj);
              }
            });
            this.corporations = data.Corporations;
            this.corporationIds = corpArray;
          }

          if (data.Venues != null && data.Venues.length > 0) {
            let venueArray: any[] = [];
            data.Venues.forEach(function (v) {
              if (v.IsActive != null && v.IsActive == 1) {
                let venueObj: VenueModule = new VenueModule();
                venueObj.VenueId = v.VenueId;
                venueObj.VenueName = v.VenueName;
                venueArray.push(venueObj);
              }
            });
            this.venues = data.Venues;
            this.venueIds = venueArray;
          }

          if (data.Buildings != null && data.Buildings.length > 0) {
            this.buildings = data.Buildings;
            for (let b = 0; b < data.Buildings.length; b++) {
              let bldgObj: BuildingModule = new BuildingModule();
              bldgObj.BuildingId = this.buildings[b].BuildingId;
              bldgObj.BuildingName = this.buildings[b].BuildingName;
              this.buildingIds.push(bldgObj);
            }
          }

          if (data.Zones != null && data.Zones.length > 0) {
            this.zones = data.Zones;
            for (let z = 0; z < data.Zones.length; z++) {
              let zoneObj: ZoneModule = new ZoneModule();
              zoneObj.ZoneId = this.zones[z].ZoneId;
              zoneObj.ZoneName = this.zones[z].ZoneName;
              this.zoneIds.push(zoneObj);
            }
          }

          if (data.Levels != null && data.Levels.length > 0) {
            this.levels = data.Levels;
            for (let l = 0; l < data.Levels.length; l++) {
              let levelObj: LevelModule = new LevelModule();
              levelObj.LevelId = this.levels[l].LevelId;
              levelObj.LevelName = this.levels[l].LevelName;
              this.levelIds.push(levelObj);
            }
          }

          if (data.Locations != null && data.Locations.length > 0) {
            this.Locations = data.Locations;
            for (let z = 0; z < data.Locations.length; z++) {
              let locObj: LocationModule = new LocationModule();
              locObj.LocationId = this.Locations[z].LocationId;
              locObj.LocationName = this.Locations[z].LocationName;
              this.LocationIds.push(locObj);
            }
          }

          if (data.InspectorAssignmentCorporations != null && data.InspectorAssignmentCorporations.length > 0) {
            let that = this;
            data.InspectorAssignmentCorporations.forEach(function (corp) {
              if (corp.InspectorAssignmentVenuesList != null && corp.InspectorAssignmentVenuesList.length > 0) {
                corp.InspectorAssignmentVenuesList.forEach(function (venue) {
                  let venueObj = new VenModule();
                  let locationObj: any[] = [];
                  venue.IsActive = venue.IsActive ? 1 : 0;

                  if (venue.Locations != null && venue.Locations.length > 0) {
                    venue.Locations.forEach(function (l) {
                      l.IsActive = l.IsActive ? 1 : 0;

                      if (l.IsActive == 1) {
                        let locObj = new LocModule();
                        locObj.LocationId = l.LocationId;
                        locObj.LocationName = l.LocationName;
                        locObj.IsActive = l.IsActive;
                        locationObj.push(locObj);
                      }
                    });

                    let obj = venue.Locations.filter((l) => l.IsActive == false);
                    if (obj && obj.length > 0) {
                      venue.IsActive = false;
                    } else {
                      venue.IsActive = true;
                    }
                  }

                  // Push active location into array
                  if (locationObj != null && locationObj.length > 0) {
                    venueObj.VenueId = venue.VenueId;
                    venueObj.VenueName = venue.VenueName;
                    venueObj.Locations = [];
                    venueObj.Locations = locationObj;
                    that.storeUserLocations.push(venueObj);
                  }
                });
              }
            });
            this.userLocations = data.InspectorAssignmentCorporations;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectionLocationAssignmentService GetAssignmentDetails Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  //// Get Corporations ------------------------------------------------------
  public getAllCorporations(isReload: any) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = 'INSPECT';
    payload.ScreenCode = 'INSPECTION_LOCATION_ASSIGNMENT_SCREEN';

    this.inspectorLocationAssignmentService.GetCoporationList(payload).subscribe(
      (data) => {
        this.corporations = data;
        this.loaderService.display(false);
        if (isReload) {
          let corpObj = this.corporations.filter(
            (c) => c.CorporationId == this.authState.getStoredContextCorporationId(),
          );
          if (corpObj && corpObj.length > 0) {
            let corpArray: any[] = [];
            let corp: CorporationModule = new CorporationModule();
            corp.CorporationId = corpObj[0].CorporationId;
            corp.CorporationName = corpObj[0].CorporationName;
            corpArray.push(corp);
            this.corporationIds = corpArray;
          }

          if (this.corporationIds.length > 0 && isReload) {
            this.getAllVenues(this.corporationIds, isReload);
          }
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetCoporationList call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public bindCorporationIds(isReload: any) {
    if (this.corporations != null && this.corporations.length > 0) {
      for (let i = 0; i < this.corporations.length; i++) {
        let corpObj: CorporationModule = new CorporationModule();
        corpObj.CorporationId = this.corporations[i].CorporationId;
        corpObj.CorporationName = this.corporations[i].CorporationName;
        this.corporationIds.push(corpObj);
      }

      this.getAllVenues(this.corporationIds, isReload);
    }
  }

  changeCorporation(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    this.venues = [];
    this.venueIds = [];
    this.getAllVenues(this.corporationIds, false);
  }

  selectAllCorporations($event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    this.venues = [];
    this.venueIds = [];

    this.corporationIds = [];
    this.corporationIds = $event;
    this.getAllVenues(this.corporationIds, false);
  }

  deSelectCorporation(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    this.venues = [];
    this.venueIds = [];

    if (this.corporationIds.length == 0) {
      this.bindCorporationIds(false);
    } else {
      this.getAllVenues(this.corporationIds, false);
    }
  }

  deSelectAllCorporations(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    this.venues = [];
    this.venueIds = [];

    this.corporationIds = [];
    if (this.corporationIds.length == 0) {
      this.bindCorporationIds(false);
    } else {
      this.getAllVenues(this.corporationIds, false);
    }
  }

  //// Get Venues ------------------------------------------------------
  public getAllVenues(corporationIds: any, isReload: any) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = 'INSPECT';
    payload.ScreenCode = 'INSPECTION_LOCATION_ASSIGNMENT_SCREEN';
    payload.Corporations = [];
    payload.Corporations = corporationIds;
    payload.UserId = this.userLocationAssignment.User.UserId;

    this.inspectorLocationAssignmentService.GetVenueList(payload).subscribe(
      (data) => {
        this.venues = data;
        this.loaderService.display(false);
        if (isReload) {
          let vObj = this.venues.filter((v) => v.VenueId == this.authState.getStoredContextVenueId());
          if (vObj && vObj.length > 0) {
            let vArray: any[] = [];
            let venue: VenueModule = new VenueModule();
            venue.VenueId = vObj[0].VenueId;
            venue.VenueName = vObj[0].VenueName;
            vArray.push(venue);
            this.venueIds = vArray;
          }

          if (this.corporationIds.length > 0 && this.venueIds.length > 0 && isReload) {
            this.getAllBuildings(this.corporationIds, this.venueIds, isReload);
          }
        } else {
          this.bindVenueIds(isReload);
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetVenueList call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public bindVenueIds(isReload: any) {
    if (this.venues != null && this.venues.length > 0) {
      for (let i = 0; i < this.venues.length; i++) {
        let venueObj: VenueModule = new VenueModule();
        venueObj.VenueId = this.venues[i].VenueId;
        venueObj.VenueName = this.venues[i].VenueName;
        this.venueIds.push(venueObj);
      }

      this.getAllBuildings(this.corporationIds, this.venueIds, isReload);
    }
  }

  changeVenue(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];
    this.getAllBuildings(this.corporationIds, this.venueIds, false);
  }

  selectAllVenues($event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    this.venueIds = [];
    this.venueIds = $event;
    this.getAllBuildings(this.corporationIds, this.venueIds, false);
  }

  deSelectVenue(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    if (this.venueIds.length == 0) {
      this.bindVenueIds(false);
    } else {
      this.getAllBuildings(this.corporationIds, this.venueIds, false);
    }
  }

  deSelectAllVenues(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];

    this.zones = [];
    this.zoneIds = [];

    this.buildings = [];
    this.buildingIds = [];

    this.venueIds = [];
    if (this.venueIds.length == 0) {
      this.bindVenueIds(false);
    } else {
      this.getAllBuildings(this.corporationIds, this.venueIds, false);
    }
  }

  //// Get Buildings ------------------------------------------------------
  public getAllBuildings(corporationIds: any, venueIds: any, isReload: any) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = 'INSPECT';
    payload.ScreenCode = 'INSPECTION_LOCATION_ASSIGNMENT_SCREEN';
    payload.Corporations = [];
    payload.Corporations = corporationIds;
    payload.Venues = [];
    payload.Venues = venueIds;

    this.inspectorLocationAssignmentService.GetBuildingList(payload).subscribe(
      (data) => {
        this.buildings = data;
        this.loaderService.display(false);
        this.bindBuildingIds(isReload);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetBuildingList call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public bindBuildingIds(isReload: any) {
    if (this.buildings != null && this.buildings.length > 0) {
      for (let i = 0; i < this.buildings.length; i++) {
        let bldgObj: BuildingModule = new BuildingModule();
        bldgObj.BuildingId = this.buildings[i].BuildingId;
        bldgObj.BuildingName = this.buildings[i].BuildingName;
        this.buildingIds.push(bldgObj);
      }

      this.getAllZones(this.corporationIds, this.venueIds, this.buildingIds, isReload);
    }
  }

  changeBuilding(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.zones = [];
    this.zoneIds = [];
    this.getAllZones(this.corporationIds, this.venueIds, this.buildingIds, false);
  }

  selectAllBuildings($event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.zones = [];
    this.zoneIds = [];

    this.buildingIds = [];
    this.buildingIds = $event;
    this.getAllZones(this.corporationIds, this.venueIds, this.buildingIds, false);
  }

  deSelectBuilding(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.zones = [];
    this.zoneIds = [];

    if (this.buildingIds.length == 0) {
      this.bindBuildingIds(false);
    } else {
      this.getAllZones(this.corporationIds, this.venueIds, this.buildingIds, false);
    }
  }

  deSelectAllBuildings(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.zones = [];
    this.zoneIds = [];

    this.buildingIds = [];
    if (this.buildingIds.length == 0) {
      this.bindBuildingIds(false);
    } else {
      this.getAllZones(this.corporationIds, this.venueIds, this.buildingIds, false);
    }
  }

  //// Get Zones ------------------------------------------------------
  public getAllZones(corporationIds: any, venueIds: any, buildingIds: any, isReload: any) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = 'INSPECT';
    payload.ScreenCode = 'INSPECTION_LOCATION_ASSIGNMENT_SCREEN';
    payload.Corporations = [];
    payload.Corporations = corporationIds;
    payload.Venues = [];
    payload.Venues = venueIds;
    payload.Buildings = [];
    payload.Buildings = buildingIds;

    this.inspectorLocationAssignmentService.GetZoneList(payload).subscribe(
      (data) => {
        this.zones = data;
        this.loaderService.display(false);
        this.getAllLevels(this.corporationIds, this.venueIds, this.buildingIds, isReload);
        //this.getAllLocationsDropdown(isReload);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetZoneList call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  // public bindZoneIds(isReload: any) {
  //    if (this.zones != null && this.zones.length > 0) {
  //        for (let i = 0; i < this.zones.length; i++) {
  //            let zoneObj: ZoneModule = new ZoneModule();
  //            zoneObj.ZoneId = this.zones[i].ZoneId;
  //            zoneObj.ZoneName = this.zones[i].ZoneName;
  //            this.zoneIds.push(zoneObj);
  //        }

  //        //if (this.corporationIds.length > 0 && this.venueIds.length > 0 && isReload) {
  //            this.getAllLocationsDropdown(isReload);
  //        //}
  //    }
  // }

  changeZone(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.getAllLevels(this.corporationIds, this.venueIds, this.buildingIds, false);
    //this.getAllLocationsDropdown(false);
  }

  selectAllZones($event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.zoneIds = [];
    this.zoneIds = $event;
    this.getAllLevels(this.corporationIds, this.venueIds, this.buildingIds, false);
    //this.getAllLocationsDropdown(false);
  }

  deSelectZone(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.getAllLevels(this.corporationIds, this.venueIds, this.buildingIds, false);
    //this.getAllLocationsDropdown(false);
  }

  deSelectAllZones(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levels = [];
    this.levelIds = [];
    this.zoneIds = [];
    this.getAllLevels(this.corporationIds, this.venueIds, this.buildingIds, false);
    //this.getAllLocationsDropdown(false);
  }

  //// Get Levels ------------------------------------------------------
  public getAllLevels(corporationIds: any, venueIds: any, buildingIds: any, isPageLoad: any) {
    this.loaderService.display(true);
    const payload = new FilterInputs();
    payload.FilterIdentifier = 'INSPECT';
    payload.ScreenCode = 'INSPECTION_LOCATION_ASSIGNMENT_SCREEN';
    payload.Corporations = [];
    payload.Corporations = corporationIds;
    payload.Venues = [];
    payload.Venues = venueIds;
    payload.Buildings = [];
    payload.Buildings = buildingIds;
    this.inspectorLocationAssignmentService.GetLevelList(payload).subscribe(
      (data) => {
        this.levels = data;
        this.loaderService.display(false);
        //this.bindLevelIds(isPageLoad);
        this.getAllLocationsDropdown(isPageLoad);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetLevelList call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  changeLevel(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    //this.locationIds = [];
    this.getAllLocationsDropdown(false);
  }

  selectAllLevels($event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levelIds = [];
    this.levelIds = $event;
    this.getAllLocationsDropdown(false);
  }

  deSelectLevel(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.getAllLocationsDropdown(false);
  }

  deSelectAllLevels(_$event: any) {
    this.Locations = [];
    this.LocationIds = [];
    this.levelIds = [];
    this.getAllLocationsDropdown(false);
  }

  //// Get Locations ------------------------------------------------------
  public getAllLocations(
    corporationIds: any,
    venueIds: any,
    buildingIds: any,
    zonesIds: any,
    levelIds: any,
    _locationId,
  ) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = 'INSPECT';
    payload.ScreenCode = 'INSPECTION_LOCATION_ASSIGNMENT_SCREEN';
    payload.Corporations = [];
    payload.Corporations = corporationIds;
    payload.Venues = [];
    payload.Venues = venueIds;
    payload.Buildings = [];
    payload.Buildings = buildingIds;
    payload.Zones = [];
    payload.Zones = zonesIds;
    payload.Levels = [];
    payload.Levels = levelIds;
    payload.Locations = [];
    payload.Locations = _locationId;
    payload.UserCPId = this.authState.AESEncryptText(this.userLocationAssignment.User.UserId);

    this.inspectorLocationAssignmentService.GetAllLocationsByInspector(payload).subscribe(
      (data) => {
        this.userLocations = [];
        if (data != null && data.length > 0) {
          let that = this;
          data.forEach(function (userCorp) {
            if (userCorp.InspectorAssignmentVenuesList != null && userCorp.InspectorAssignmentVenuesList.length > 0) {
              userCorp.InspectorAssignmentVenuesList.forEach(function (userVenue) {
                userVenue.IsActive = userVenue.IsActive ? 1 : 0;
                if (userVenue.Locations != null && userVenue.Locations.length > 0) {
                  userVenue.Locations.forEach(function (userLoc) {
                    userLoc.IsActive = userLoc.IsActive ? 1 : 0;

                    // Compare recieved location with stored array
                    that.storeUserLocations.forEach(function (storeV) {
                      storeV.Locations.forEach(function (storeLoc) {
                        if (storeLoc.LocationId == userLoc.LocationId) {
                          userLoc.IsActive = storeLoc.IsActive ? 1 : 0;
                        }
                      });
                    });
                  });
                }
              });
            }
          });

          data.forEach(function (corp) {
            if (corp.InspectorAssignmentVenuesList != null && corp.InspectorAssignmentVenuesList.length > 0) {
              corp.InspectorAssignmentVenuesList.forEach(function (venue) {
                if (venue.Locations != null && venue.Locations.length > 0) {
                  let obj = venue.Locations.filter((l) => l.IsActive == false);
                  if (obj && obj.length > 0) {
                    venue.IsActive = false;
                  } else {
                    venue.IsActive = true;
                  }
                }
              });
            }
          });

          this.userLocations = data;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetAllLocationsByInspector Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public hasData(): boolean {
    return this.userLocations != null && this.userLocations.length > 0;
  }

  public venueCheckboxChange(flag: boolean, venueId: number) {
    this.userLocations.forEach(function (corp) {
      corp.InspectorAssignmentVenuesList.forEach(function (v) {
        if (v.VenueId == venueId) {
          if (flag == true) {
            v.Locations.forEach(function (loc) {
              loc.IsActive = 1;
            });
          } else {
            v.Locations.forEach(function (loc) {
              loc.IsActive = 0;
            });
          }
        }
      });
    });

    //// Maintain location array -------------------------------------
    let that = this;
    if (this.storeUserLocations != null && this.storeUserLocations.length > 0) {
      let storeObj = this.storeUserLocations.filter((a) => a.VenueId == venueId);
      if (storeObj != null && storeObj.length > 0) {
        for (let a = 0; a < this.storeUserLocations.length; a++) {
          if (this.storeUserLocations[a].VenueId == storeObj[0].VenueId) {
            if (flag == true) {
              for (let x = 0; x < this.userLocations.length; x++) {
                for (let y = 0; y < this.userLocations[x].InspectorAssignmentVenuesList.length; y++) {
                  if (this.userLocations[x].InspectorAssignmentVenuesList[y].VenueId == venueId) {
                    for (let z = 0; z < this.userLocations[x].InspectorAssignmentVenuesList[y].Locations.length; z++) {
                      let obj = this.storeUserLocations[a].Locations.filter(
                        (l) =>
                          l.LocationId ==
                          this.userLocations[x].InspectorAssignmentVenuesList[y].Locations[z].LocationId,
                      );
                      if (obj.length == 0) {
                        let locObj = new LocModule();
                        locObj.LocationId =
                          this.userLocations[x].InspectorAssignmentVenuesList[y].Locations[z].LocationId;
                        locObj.LocationName =
                          this.userLocations[x].InspectorAssignmentVenuesList[y].Locations[z].LocationName;
                        locObj.IsActive = this.userLocations[x].InspectorAssignmentVenuesList[y].Locations[z].IsActive
                          ? 1
                          : 0;
                        this.storeUserLocations[a].Locations.push(locObj);
                      } else if (obj && obj.length > 0 && (obj[0].IsActive == false || obj[0].IsActive == 0)) {
                        obj[0].IsActive = 1;
                      }
                    }
                  }
                }
              }
            } else {
              for (let u = 0; u < this.userLocations.length; u++) {
                for (let v = 0; v < this.userLocations[u].InspectorAssignmentVenuesList.length; v++) {
                  if (this.userLocations[u].InspectorAssignmentVenuesList[v].VenueId == venueId) {
                    for (let w = 0; w < this.userLocations[u].InspectorAssignmentVenuesList[v].Locations.length; w++) {
                      for (let b = 0; b < this.storeUserLocations[a].Locations.length; b++) {
                        if (
                          this.userLocations[u].InspectorAssignmentVenuesList[v].Locations[w].LocationId ==
                          this.storeUserLocations[a].Locations[b].LocationId
                        ) {
                          this.storeUserLocations[a].Locations[b].IsActive = false;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        this.userLocations.forEach(function (corp) {
          corp.InspectorAssignmentVenuesList.forEach(function (v) {
            if (v.VenueId == venueId) {
              if (flag == true) {
                that.storeUserLocations.push(v);
              }
            }
          });
        });
      }
    } else {
      this.userLocations.forEach(function (corp) {
        corp.InspectorAssignmentVenuesList.forEach(function (v) {
          if (v.VenueId == venueId) {
            if (flag == true) {
              that.storeUserLocations.push(v);
            }
          }
        });
      });
    }
  }

  public locationCheckboxChange(venueId: number, locationId: number, isActive: any) {
    this.userLocations.forEach(function (corp) {
      corp.InspectorAssignmentVenuesList.forEach(function (v) {
        if (v.VenueId == venueId) {
          let obj = v.Locations.filter((l) => l.IsActive == 0 || l.IsActive == false);
          if (obj && obj.length > 0) {
            v.IsActive = 0;
          } else {
            v.IsActive = 1;
          }
        }
      });
    });

    //// Maintain location array -------------------------------------
    let that = this;
    if (this.storeUserLocations != null && this.storeUserLocations.length > 0) {
      let vobj = this.storeUserLocations.filter((a) => a.VenueId == venueId);
      if (vobj && vobj.length > 0) {
        //// If location is present in the array then update location "isactive"
        for (let a = 0; a < this.storeUserLocations.length; a++) {
          if (this.storeUserLocations[a].VenueId == venueId) {
            for (let b = 0; b < this.storeUserLocations[a].Locations.length; b++) {
              if (this.storeUserLocations[a].Locations[b].LocationId == locationId) {
                this.storeUserLocations[a].Locations[b].IsActive = isActive ? 1 : 0;
              }
            }
          }
        }

        //// If location is not present in the array then push selected location into array
        let locObj = vobj[0].Locations.filter((a) => a.LocationId == locationId);
        if (locObj.length == 0) {
          this.userLocations.forEach(function (corp) {
            corp.InspectorAssignmentVenuesList.forEach(function (v) {
              v.Locations.forEach(function (l) {
                if (l.LocationId == locationId) {
                  let obj = new LocModule();
                  obj.LocationId = l.LocationId;
                  obj.LocationName = l.LocationName;
                  obj.IsActive = l.IsActive ? 1 : 0;

                  that.storeUserLocations.forEach(function (v) {
                    if (v.VenueId == venueId) {
                      v.Locations.push(obj);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        this.userLocations.forEach(function (corp) {
          corp.InspectorAssignmentVenuesList.forEach(function (v) {
            v.Locations.forEach(function (l) {
              if (v.VenueId == venueId && l.LocationId == locationId) {
                let venueObj = new VenModule();
                venueObj.VenueId = v.VenueId;
                venueObj.VenueName = v.VenueName;

                let locationObj: any[] = [];
                let obj = new LocModule();
                obj.LocationId = l.LocationId;
                obj.LocationName = l.LocationName;
                obj.IsActive = l.IsActive ? 1 : 0;
                locationObj.push(obj);

                venueObj.Locations = locationObj;
                that.storeUserLocations.push(venueObj);
              }
            });
          });
        });
      }
    } else {
      this.userLocations.forEach(function (corp) {
        corp.InspectorAssignmentVenuesList.forEach(function (v) {
          v.Locations.forEach(function (l) {
            if (v.VenueId == venueId && l.LocationId == locationId) {
              let venueObj = new VenModule();
              venueObj.VenueId = v.VenueId;
              venueObj.VenueName = v.VenueName;

              let locationObj: any[] = [];
              let obj = new LocModule();
              obj.LocationId = l.LocationId;
              obj.LocationName = l.LocationName;
              obj.IsActive = l.IsActive ? 1 : 0;
              locationObj.push(obj);

              venueObj.Locations = locationObj;
              that.storeUserLocations.push(venueObj);
            }
          });
        });
      });
    }
  }

  public getAllLocationsDropdown(isReload: any) {
    this.loaderService.display(true);
    const searchFilter = {
      UserCPId: this.authState.AESEncryptText(this.userLocationAssignment.User.UserId),
      Corporations: this.corporationIds,
      Venues: this.venueIds,
      Buildings: this.buildingIds,
      Zones: this.zoneIds.length > 0 ? this.zoneIds : null,
      Levels: this.levelIds.length > 0 ? this.levelIds : null,
    };
    this.inspectorLocationAssignmentService.GetAllLocationsDropdownList(JSON.stringify(searchFilter)).subscribe(
      (data) => {
        this.Locations = [];
        this.LocationIds = [];
        this.Locations = data;
        this.loaderService.display(false);

        if (this.corporationIds.length > 0 && this.venueIds.length > 0 && isReload) {
          this.getAllLocations(
            this.corporationIds,
            this.venueIds,
            this.buildingIds,
            this.zoneIds,
            this.levelIds,
            this.LocationIds,
          );
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'dashboardService GetAllRestRoomDetails call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  // public bindLocationIds(isReload: any) {
  //    if (this.Locations != null && this.Locations.length > 0) {
  //        for (let i = 0; i < this.Locations.length; i++) {
  //            let locObj: LocationModule = new LocationModule();
  //            locObj.LocationId = this.Locations[i].LocationId;
  //            locObj.LocationName = this.Locations[i].LocationName;
  //            this.LocationIds.push(locObj);
  //        }

  //        if (this.corporationIds.length > 0 && this.venueIds.length > 0 && isReload) {
  //            this.getAllLocations(this.corporationIds, this.venueIds, this.buildingIds, this.zoneIds, this.LocationIds);
  //        }
  //    }
  // }

  public changeLocation(_$event: any) {
    // this.getAllLocations(this.corporationIds, this.venueIds, this.buildingIds, this.zoneIds, this.LocationIds);
  }

  public onSelectAllLocation(_$event: any) {
    // this.getAllLocations(this.corporationIds, this.venueIds, this.buildingIds, this.zoneIds, this.LocationIds);
  }

  public onItemDeSelectLocation(_$event: any) {
    // this.getAllLocations(this.corporationIds, this.venueIds, this.buildingIds, this.zoneIds, this.LocationIds);
    //this.LocationIds = _$event;
  }

  public onItemDeSelectAllLocation(_$event: any) {
    this.LocationIds = _$event;
    // this.getAllLocations(this.corporationIds, this.venueIds, this.buildingIds, this.zoneIds, this.LocationIds);
  }
}
