<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-8 col-sm-12 form-heading" *ngIf="locations && !isImportLocationTemplateScreen">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading">LOCATION TEMPLATES</span>
      </div>

      <div class="col-lg-6 col-md-8 col-sm-12 form-heading" *ngIf="!locations">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="!locations && !location.LocationTemplateId">ADD LOCATION TEMPLATE</span>
        <span class="list-heading" *ngIf="!locations && location.LocationTemplateId">EDIT LOCATION TEMPLATE</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12" *ngIf="!isImportLocationTemplateScreen">
        <div
          id="page-size"
          class="col-lg-1 col-sm-3 col-md-4 col-xs-12 pull-right list-heading pageSize"
          *ngIf="locations && !isImportLocationTemplateScreen"
        >
          <ngx-atlas-select
            (model)="locationTypePageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          style="margin: 0px 0px 0px 15px"
          *ngIf="!isLocationTemplateForm && ('LOCATION_TEMPLATE_ADD' | canAccess)"
        >
          <button
            type="submit"
            class="btn btn-add col-lg-12 col-md-12 col-xs-12"
            (click)="goToImportLocationTemplates()"
          >
            Import Location Template
          </button>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="locations && !location.LocationTemplateId && ('LOCATION_TEMPLATE_ADD' | canAccess)"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Location Template
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="locations">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="locations">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="locationsSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isImportLocationTemplateScreen">
        <div class="col-lg-2 col-md-4 col-sm-3 col-xs-12 add-section" style="">
          <button
            type="submit"
            class="btn btn-add col-lg-12 col-md-12 col-xs-12"
            style="width: 65%"
            (click)="backToLocationTemplateList()"
          >
            <i class="fa fa-chevron-left"></i> Back
          </button>
        </div>

        <div class="col-lg-4 col-md-8 col-sm-12 form-heading">
          <i class="fa fa-building heading-icon"></i>
          <span class="list-heading">IMPORT LOCATION TEMPLATES</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12" *ngIf="!isImportLocationTemplateScreen">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('LocationTemplateName', columnSortOrder)">
                  Location Template Name
                  <span *ngIf="selectedColumnName == 'LocationTemplateName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'LocationTemplateName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueLocationTypeName', columnSortOrder)">
                  Location Type
                  <span *ngIf="selectedColumnName == 'VenueLocationTypeName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueLocationTypeName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('BuildingName', columnSortOrder)">
                  Building
                  <span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('ZoneName', columnSortOrder)">
                  Zone
                  <span *ngIf="selectedColumnName == 'ZoneName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ZoneName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="
                  let location of locations
                    | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
                "
              >
                <td (click)="getLocationById(location.LocationTemplateId)">{{ location.LocationTemplateName }}</td>
                <td (click)="getLocationById(location.LocationTemplateId)">{{ location.VenueLocationTypeName }}</td>
                <td (click)="getLocationById(location.LocationTemplateId)">{{ location.VenueName }}</td>
                <td (click)="getLocationById(location.LocationTemplateId)">{{ location.Building.BuildingName }}</td>
                <td (click)="getLocationById(location.LocationTemplateId)">{{ location.Zone.ZoneName }}</td>
                <td *ngIf="location.IsActive" (click)="getLocationById(location.LocationTemplateId)">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!location.IsActive" (click)="getLocationById(location.LocationTemplateId)">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!locations">
    <form #locationForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h3>Basic Information</h3>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="LocationTemplateName"><span id="red-circle">&#x25CF;</span> Location Template Name</label>
              <div>
                <input
                  id="LocationTemplateName"
                  type="text"
                  placeholder="Location Template Name"
                  name="LocationTemplateName"
                  #LocationTemplateName="ngModel"
                  class="form-control"
                  [(ngModel)]="location.LocationTemplateName"
                  required
                  maxlength="120"
                  autocomplete="off"
                  [pattern]="utilitiesService.alphanumericRegex"
                />
                <div
                  *ngIf="LocationTemplateName.errors && (LocationTemplateName.dirty || LocationTemplateName.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!LocationTemplateName.errors.required" class="text-danger">
                    Location template name is required.
                  </div>
                  <div [hidden]="!LocationTemplateName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="locationType"><span id="red-circle">&#x25CF;</span> Location Type</label>
              <div>
                <ngx-atlas-select
                  (model)="changeLocationType($event)"
                  [list]="locationTypes"
                  [idField]="'VenueLocationTypeId'"
                  [textField]="'VenueLocationTypeName'"
                  [placeholder]="'Select Location Type'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="location.VenueLocationTypeId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="zone"><span id="red-circle">&#x25CF;</span> Venue</label>
              <!--<div>
                                <ngx-atlas-select (model)="changeVenue($event)"
                                                  [list]="venues"
                                                  [idField]="'VenueId'"
                                                  [textField]="'VenueName'"
                                                  [placeholder]="'Select Venue'"
                                                  [allowClear]="true"
                                                  [multiple]="false"
                                                  [required]="true"
                                                  [selectedValue]="location.Building.Venue.VenueId">
                                </ngx-atlas-select>
                            </div>-->
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ location.Building.Venue.VenueName }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="zone">Building</label>
              <div>
                <ngx-atlas-select
                  (model)="changeBuilding($event)"
                  [list]="buildings"
                  [idField]="'BuildingId'"
                  [textField]="'BuildingName'"
                  [placeholder]="'Select Building'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="buildingDisabled"
                  [required]="false"
                  [selectedValue]="location.Building.BuildingId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="level">Level</label>
              <div>
                <ngx-atlas-select
                  (model)="location.Building.Level.LevelId = $event"
                  [list]="levels"
                  [idField]="'LevelId'"
                  [textField]="'LevelName'"
                  [placeholder]="'Select Level'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="levelDisabled"
                  [required]="false"
                  [selectedValue]="location.Building.Level.LevelId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="zone">Zone</label>
              <div>
                <ngx-atlas-select
                  (model)="changeZone($event)"
                  [list]="zones"
                  [idField]="'ZoneId'"
                  [textField]="'ZoneName'"
                  [placeholder]="'Select Zone'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="zoneDisabled"
                  [required]="false"
                  [selectedValue]="location.Zone.ZoneId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationTaskShow">
          <h3 *ngIf="isLocationTasks && isLocationTaskShow">Task Configuration</h3>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div *ngIf="isLocationTasks && isLocationTaskShow && hasVenueTasksData()">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Allow?</th>
                      <th>Task</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="isAllTaskChecked"
                          id="select_all_task"
                          name="select_all_task"
                          (change)="ChangeSelectAllTask()"
                        ></mat-checkbox>
                      </td>
                      <td style="font-weight: 600">Select All</td>
                    </tr>
                    <tr *ngFor="let lTask of location.LocationTasks">
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="lTask.IsActive"
                          id="task_{{ lTask.VenueTaskId }}"
                          name="taskSelect_{{ lTask.LocationTaskName }}"
                          (change)="ChangeTask()"
                        ></mat-checkbox>
                      </td>
                      <td>{{ lTask.LocationTaskName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hasVenueTasksData()">
              <h3 class="listerror">No record found for tasks!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isLocationTaskShow" />

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationInventoryShow">
          <h3 *ngIf="isLocationInventories && isLocationInventoryShow">Inventory Configuration</h3>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div *ngIf="isLocationInventories && isLocationInventoryShow && hasVenueInventoriesData()">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Allow?</th>
                      <th>Inventory</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="isAllInventoryChecked"
                          id="select_all_inventory"
                          name="select_all_inventory"
                          (change)="ChangeSelectAllInventory()"
                        ></mat-checkbox>
                      </td>
                      <td style="font-weight: 600">Select All</td>
                    </tr>
                    <tr *ngFor="let lInventory of location.LocationInventories">
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="lInventory.IsActive"
                          id="inventory_{{ lInventory.VenueInventoryId }}"
                          name="inventorySelect_{{ lInventory.InventoryName }}"
                          (change)="ChangeInventory()"
                        ></mat-checkbox>
                      </td>
                      <td>{{ lInventory.InventoryName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hasVenueInventoriesData()">
              <h3 class="listerror">No record found for inventories!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isLocationInventoryShow" />

        <div id="elementConfig" class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationElementShow">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <h3 *ngIf="isLocationElements">Element Configuration</h3>
          </div>

          <div
            class="col-lg-6 col-md-6 col-sm-6"
            style="text-align: right; margin-top: 20px; margin-bottom: 10px"
            *ngIf="hasVenueElementsData()"
          >
            <span id="red-circle">(Total weight of elements should equal 100%.)</span>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div *ngIf="isLocationElements && isLocationElementShow && hasVenueElementsData()">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <mat-checkbox
                          [(ngModel)]="isAllElementChecked"
                          id="select_all_element"
                          name="select_all_element"
                          (change)="ChangeSelectAllElement()"
                          >Select All</mat-checkbox
                        >
                      </th>
                      <th>Element</th>
                      <th><span id="red-circle">&#x25CF;</span> Score Factor</th>
                      <th *ngIf="isLocationAreaShow">Area</th>
                      <th>
                        Weight ({{ totalElementWeightage }}%)
                        <button *ngIf="canRebalance" mat-button (click)="rebalanceClicked()">Auto-Distribute</button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let lElement of location.LocationElements">
                      <td style="width: 10%">
                        <mat-checkbox
                          [(ngModel)]="lElement.IsActive"
                          id="element_{{ lElement.VenueElementId }}"
                          name="elementSelect_{{ lElement.ElementName }}"
                          (change)="
                            checkLocationElementIsChecked(
                              'locationTemplateElement',
                              lElement.IsActive,
                              lElement.VenueElementId
                            )
                          "
                        ></mat-checkbox>
                      </td>
                      <td>{{ lElement.ElementName }}</td>
                      <td style="width: 25%">
                        <ngx-atlas-select
                          (model)="lElement.InspectionScoreFactor.InspectionScoreFactorId = $event"
                          id="elementScoreFactor_{{ lElement.VenueElementId }}"
                          [list]="scoreFactors"
                          [idField]="'SPInspectionScoreFactorId'"
                          [textField]="'SPInspectionScoreFactorTitle'"
                          [placeholder]="'Select Score Factor'"
                          [allowClear]="true"
                          [multiple]="false"
                          [required]="lElement.IsActive"
                          [disabled]="!lElement.IsActive"
                          [selectedValue]="lElement.InspectionScoreFactor.InspectionScoreFactorId"
                        >
                        </ngx-atlas-select>
                      </td>
                      <td *ngIf="isLocationAreaShow" style="width: 25%">
                        <ngx-atlas-select
                          (model)="lElement.Area.AreaId = $event"
                          id="elementArea_{{ lElement.VenueElementId }}"
                          [list]="areas"
                          [idField]="'AreaId'"
                          [textField]="'AreaName'"
                          [placeholder]="'Select Area'"
                          [allowClear]="true"
                          [multiple]="false"
                          [required]="false"
                          [disabled]="!lElement.IsActive"
                          [selectedValue]="lElement.Area.AreaId"
                        >
                        </ngx-atlas-select>
                      </td>
                      <td style="width: 15%">
                        <input
                          type="number"
                          autocomplete="invalid"
                          class="form-control"
                          id="elementWeightage_{{ lElement.VenueElementId }}"
                          name="weightage_{{ lElement.ElementName }}"
                          placeholder="Weight (%)"
                          [(ngModel)]="lElement.ElementWeightage"
                          #ElementWeightage="ngModel"
                          minlength="1"
                          maxlength="3"
                          pattern="^[1-9]$|^[1-9][0-9]$|^(100)$"
                          [required]="lElement.IsActive"
                          (keypress)="keyPressCheck($event)"
                          (input)="
                            checkLocationElementIsChecked(
                              'locationTemplateElement',
                              lElement.IsActive,
                              lElement.VenueElementId
                            )
                          "
                          [disabled]="!lElement.IsActive"
                        />
                        <div
                          *ngIf="ElementWeightage.errors && (ElementWeightage.dirty || ElementWeightage.touched)"
                          class="alert alert-danger"
                        >
                          <div [hidden]="!ElementWeightage.errors.required" class="text-danger">
                            Element weight is required.
                          </div>
                          <div [hidden]="!ElementWeightage.errors.pattern" class="text-danger">
                            Element weight accepts only numeric and in between 1 to 100.
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hasVenueElementsData()">
              <h3 class="listerror">No record found for elements!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isLocationElementShow" />

        <div class="col-lg-12 col-md-12 col-sm-12">
          <h3>Feedback Reason Configuration</h3>
          <div class="col-md-6 col-sm-6 col-lg-6 form-group">
            <label for="LocationStatus">Feedback Identifier</label>
            <div>
              <ngx-atlas-select
                (model)="ChangeFeedbackIdentifier($event)"
                [list]="feedbackIdentifiers"
                [idField]="'TouchlessFeedbackIdentifier'"
                [textField]="'TouchlessFeedbackTitle'"
                [placeholder]="'Select Identifier'"
                [allowClear]="true"
                [multiple]="false"
                [required]="false"
                [selectedValue]="location.TouchlessFeedbackIdentifier"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-12 col-sm-12 col-lg-12">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="hasVenueReasonsData()">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Allow?</th>
                      <th>Reason</th>
                      <th>Reason Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let lReason of location.LocationFeedbackReasons">
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="lReason.IsActive"
                          id="reason_{{ lReason.VenueFeedbackReasonId }}"
                          name="reasonSelect_{{ lReason.Reason }}"
                        ></mat-checkbox>
                      </td>
                      <td>{{ lReason.Reason }}</td>
                      <td>{{ lReason.ResponseType }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!--<div *ngIf="!hasVenueReasonsData()">
                                <h3 class="listerror">No record found for reasons!</h3>
                            </div>-->
            </div>
            <div *ngIf="!hasFeedbackData()" class="col-lg-12 col-md-12 col-sm-12">
              <span>No record found for feedback!</span>
            </div>

            <div
              *ngIf="location.TouchlessFeedbackIdentifier != null && !hasVenueReasonsData()"
              class="col-lg-12 col-md-12 col-sm-12"
            >
              <span id="configuration-error">No record found for feedback reason!</span>
            </div>
          </div>
        </div>

        <hr />

        <div class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Feature Configuration</h3>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-12 form-group">
              <div class="d-inline-block form-group">
                <div *ngIf="isCheckBoxVisible" style="margin-left: -15px">
                  <mat-checkbox
                    class="form-check-input"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="isAllFeatureConfigurationChecked"
                    (change)="onAllCheckedChange($event)"
                    >All</mat-checkbox
                  >
                </div>
                <ngx-treeview
                  #treeviewComponent
                  [items]="items"
                  [config]="config"
                  [itemTemplate]="itemTemplate"
                  (selectedChange)="onSelectedChange($event)"
                >
                </ngx-treeview>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="location.LocationTemplateId" style="margin-top: 15px">
          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <div class="chkbox">
              <mat-checkbox [(ngModel)]="location.IsActive" name="isActive">Is Active</mat-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type1 pull-right btn-cancel"
              (click)="clearFormData(locationForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!location.LocationTemplateId"
              class="btn btn-type btn-save"
              [disabled]="!locationForm.form.valid || isReqComplete || !location.VenueLocationTypeId"
              (click)="addLocation(locationForm)"
            />
            <input
              type="button"
              style="margin-right: 20px"
              value="Delete"
              *ngIf="location.LocationTemplateId"
              class="btn btn-type pull-right btn-delete"
              [disabled]="!locationForm.form.valid || isReqComplete || !('LOCATION_TEMPLATE_DELETE' | canAccess)"
              (click)="openModal(locationForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="location.LocationTemplateId"
              class="btn btn-type btn-save"
              [disabled]="
                !locationForm.form.valid ||
                isReqComplete ||
                !location.VenueLocationTypeId ||
                !('LOCATION_TEMPLATE_EDIT' | canAccess)
              "
              (click)="updateLocation(locationForm, 1)"
            />
            <input
              type="button"
              style="margin-left: 66px"
              value="Save and Apply This Template To Locations"
              *ngIf="location.LocationTemplateId"
              class="btn btn-type btn-save"
              [disabled]="
                !locationForm.form.valid ||
                isReqComplete ||
                !location.VenueLocationTypeId ||
                !('LOCATION_TEMPLATE_EDIT' | canAccess)
              "
              (click)="openLocationModal(locationForm, 0)"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<ng-template
  #itemTemplate
  let-item="item"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
>
  <div class="form-inline row-item" style="display: inline-flex">
    <i
      *ngIf="item.children"
      (click)="onCollapseExpand()"
      aria-hidden="true"
      class="fa"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed"
    ></i>
    <div class="form-check">
      <mat-checkbox
        class="form-check-input"
        [(ngModel)]="item.checked"
        (change)="onCheckedChange()"
        [disabled]="item.disabled"
        [indeterminate]="item.indeterminate"
      >
        {{ item.text }}</mat-checkbox
      >
    </div>
  </div>
</ng-template>

<div class="modal" id="isModal" [ngStyle]="isModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Location Template</h3>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" style="margin-top: -35px">
          &times;
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>This action cannot be undone. Do you really want to delete "{{ location.LocationTemplateName }}"?</h4>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <button class="btn btn-type btn-save" [disabled]="isReqComplete" (click)="deleteTemplate()">Yes</button>
          <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeModal()">No</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="templateModal" [ngStyle]="locationModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Apply This Template To Locations</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeLocationModal()"
          style="margin-top: -35px"
        >
          &times;
        </button>
      </div>
      <div class="modal-body" #scrolltop id="scrolltop" style="max-height: calc(100vh - 210px)">
        <form #locationsForm="ngForm" *ngIf="hasLocationsData()" class="form-horizontal">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h3>Select locations</h3>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="form-group" id="accordion" style="border-bottom: 1px solid">
              <label class="pull-left" style="text-align: left; padding-left: 15px; width: 90%; font-size: 18px"
                >Select All</label
              >
              <mat-checkbox
                style="display: flex; padding-right: 15px"
                class="pull-right"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="selectAllLocations"
                (change)="changeAllLocationSelect($event)"
              ></mat-checkbox>
            </div>
            <div
              class="form-group"
              id="accordion"
              *ngFor="let location of LocationsData"
              style="border-bottom: 1px solid"
            >
              <label class="pull-left" style="text-align: left; padding-left: 15px; width: 90%">{{
                location.LocationName
              }}</label>
              <mat-checkbox
                style="display: flex; padding-right: 15px"
                class="pull-right"
                [(ngModel)]="location.IsActive"
                name="locationSelect_{{ location.LocationId }}"
                (change)="changeLocationSelect($event)"
              ></mat-checkbox>
            </div>
          </div>
        </form>
        <div *ngIf="!hasLocationsData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
      </div>
      <div class="modal-footer" *ngIf="hasLocationsData()">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <input
            type="button"
            value="Close"
            class="btn btn-type pull-right btn-cancel"
            style="border-radius: 25px; padding: 7px 17px"
            (click)="closeLocationModal()"
          />
          <input
            type="button"
            value="Save"
            style="border-radius: 25px; padding: 7px 17px"
            class="btn btn-type btn-save pull-left"
            (click)="ApplyTemplateToLocations(locationForm)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="isImportLocationTemplateScreen">
  <div>
    <form #importlocationTemplateForm="ngForm" name="locationTemplate" role="form" class="form-horizontal">
      <div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <label for="Corporation"><span id="red-circle">&#x25CF;</span> Corporation</label>
          <div>
            <ngx-atlas-select
              (model)="corporationChange($event)"
              [list]="importLocationTemplateCorporations"
              [idField]="'CorporationId'"
              [textField]="'CorporationName'"
              [placeholder]="'Select Corporation'"
              [allowClear]="true"
              [multiple]="false"
              [required]="true"
              [selectedValue]="selectedCorporationId"
            >
            </ngx-atlas-select>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-xs-12">
          <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
          <div>
            <ngx-atlas-select
              (model)="venueChange($event)"
              [list]="importLocationTypeVenues"
              [idField]="'VenueId'"
              [textField]="'VenueName'"
              [placeholder]="'Select Venue'"
              [allowClear]="true"
              [multiple]="false"
              [required]="true"
              [disabled]="isVenueDisabled"
              [selectedValue]="selectedVenueId"
            >
            </ngx-atlas-select>
          </div>
        </div>
      </div>
      <div *ngIf="!showTemplateImportCheckbox" class="col-lg-12 col-md-12 col-sm-12">
        <h3 class="listerror">Sorry no data found!</h3>
      </div>
      <div *ngIf="showTemplateImportCheckbox" class="col-lg-12 col-md-12 col-sm-12 tboday-scroll">
        <div *ngIf="showTemplateImportCheckbox" class="box-body table-responsive">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th style="border-radius: 5px 5px 0 0 !important">Location Templates</th>
                <!--<th style="width: 15%;">Is Imported?</th>
                                  <th style="width: 15%;">Active/Inactive</th>-->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="background-color: #d5dde6">
                  <mat-checkbox
                    [(ngModel)]="selectAllImportLocationTemplates"
                    name="selectAllLocation"
                    (change)="selectedAllLocationTemplates(selectAllImportLocationTemplates)"
                  ></mat-checkbox>
                  <span style="font-weight: bold; word-break: break-word"> Select All</span>
                </td>
              </tr>

              <tr *ngFor="let lt of importLocationTemplates">
                <td style="border-top: 1px solid #ddd">
                  <mat-checkbox
                    [(ngModel)]="lt.IsSelected"
                    name="lt.LocationTemplateName"
                    (change)="
                      selectedImportLocationTemplate(lt.LocationTemplateId, lt.LocationTemplateName, lt.IsSelected)
                    "
                  ></mat-checkbox
                  >&nbsp;
                  <span style="font-weight: bold; word-break: break-word">{{ lt.LocationTemplateName }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 15px">
        <input
          type="button"
          value="Import"
          class="btn btn-type btn-save"
          (click)="openImportConfirmationModal(importlocationTemplateForm)"
          [disabled]="
            !importlocationTemplateForm.form.valid ||
            !selectedCorporationId ||
            !selectedVenueId ||
            storeImportLocationTemplates.length === 0
          "
        />
      </div>
    </form>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div style="margin-top: 10px; margin-bottom: 10px">
        <span style="font-size: 20px; font-weight: bold; color: #333">IMPORT LOGS</span>
      </div>
    </div>

    <div class="box-body table-responsive">
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th class="table-sort-header" style="cursor: auto">Import Date</th>
            <th class="table-sort-header" style="cursor: auto">From Venue</th>
            <th class="table-sort-header" style="cursor: auto">Status</th>
            <th class="table-sort-header" style="cursor: auto">Imported By</th>
            <th class="table-sort-header" style="cursor: auto">Download Log</th>
            <th class="table-sort-header" style="border-radius: 0 5px 0 0 !important; cursor: auto">
              <a
                (click)="getImportLogData(importLogPage, true)"
                class="fa fa-refresh"
                data-toggle="tooltip"
                data-placement="top"
                title="Refresh"
              ></a>
            </th>
          </tr>
        </thead>

        <tbody
          *ngFor="
            let importLog of importLogsData
              | paginate: { filter: search, itemsPerPage: size, currentPage: importLogPage, totalItems: importLogCount }
          "
        >
          <tr>
            <td>{{ importLog.AddedOn | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
            <td>{{ importLog.SourceVenueName }}</td>
            <td>{{ importLog.Status }}</td>
            <td>{{ importLog.UploadedBy }}</td>
            <td>
              <a
                (click)="DownloadImportLogFile(importLog.DownloadId)"
                class="fa fa-download"
                style="color: black; cursor: pointer"
                download
              ></a>
            </td>
            <td style="width: 35px"></td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        class="pagination pull-right"
        (pageChange)="importLogDataPageChanged($event)"
        *ngIf="importLogCount > 10"
      ></pagination-controls>
    </div>
  </div>
</div>

<div
  class="modal"
  id="importConfirmationModalBackEndMessage"
  [ngStyle]="importConfirmationModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Import Location Templates</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeImportConfirmationModal()"
          style="margin-top: -27px"
        >
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>Proceed to importing location templates? This action cannot be undone.</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <button class="btn btn-type btn-save" (click)="saveImportLocationTemplates()">Yes</button>
          <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeImportConfirmationModal()">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="importConfirmationErrorModal"
  [ngStyle]="importConfirmationErrorModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Import Location Templates</h3>
        <button type="button" class="close" data-dismiss="modal" (click)="cancelImport()" style="margin-top: -27px">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>{{ templateImportErrorStringLineOne }}</h4>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>{{ templateImportErrorStringLineTwo }}</h4>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>{{ templateImportErrorStringLineThree }}</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <button class="btn btn-type btn-save" (click)="proceedImport()">Yes</button>
          <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="cancelImport()">No</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="importLocationTypeNotFoundModel"
  [ngStyle]="importLocationTypeNotFoundModel ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Import Location Templates</h3>
        <button type="button" class="close" data-dismiss="modal" (click)="closeImportPopup()" style="margin-top: -27px">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>{{ templateImportErrorStringLineOne }}</h4>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>{{ templateImportErrorStringLineTwo }}</h4>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>{{ templateImportErrorStringLineThree }}</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <button class="btn btn-type btn-save" (click)="closeImportPopup()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
