<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="locations">LOCATIONS</span>
        <span class="list-heading" *ngIf="!locations && !location.LocationId">ADD LOCATION</span>
        <span class="list-heading" *ngIf="!locations && location.LocationId">EDIT LOCATION</span>
      </div>

      <div
        class="col-lg-9 col-sm-12 col-md-8 col-xs-12 pull-right list-heading"
        style="display: inline-flex"
        *ngIf="!locations"
      >
        <div class="Location-template-dropdown" style="padding-right: 10px; width: 31%">
          <ngx-atlas-select
            (model)="changeLocationTemplate($event)"
            [list]="locationTemplates"
            [idField]="'LocationTemplateId'"
            [textField]="'LocationTemplateName'"
            [placeholder]="'Select Location Template'"
            [allowClear]="true"
            [multiple]="false"
            [required]="false"
            [selectedValue]="location.LocationTemplateId"
          >
          </ngx-atlas-select>
        </div>

        <div class="display-page" *ngIf="!locations && location.LocationId" style="padding-right: 10px">
          <span style="font-size: 14px"
            ><label style="padding: 5px 6px 0 0">Digital Display Page:</label
            ><a (click)="getSignagePage(location.LocationId, location.Building.Venue.VenueName)"
              ><i class="fa fa-external-link" aria-hidden="true" style="color: black; cursor: pointer"></i></a
          ></span>
        </div>

        <div
          class="touchless-feedback-page"
          *ngIf="!locations && location.LocationId && location.TouchlessFeedbackIdentifier != null"
          style="padding-right: 10px"
        >
          <span style="font-size: 14px"
            ><label style="padding: 5px 6px 0 0">Touchless Feedback Page:</label
            ><a (click)="getTouchlessFeedbackPage(location.LocationId)"
              ><i class="fa fa-external-link" aria-hidden="true" style="color: black; cursor: pointer"></i></a
          ></span>
        </div>

        <div class="touchless-feedback-page" *ngIf="!locations && location.LocationId">
          <span style="font-size: 14px"
            ><label style="padding: 5px 6px 0 0">CA Request Page:</label
            ><a (click)="getCleaningAlertRequestPage(location.LocationId)"
              ><i class="fa fa-external-link" aria-hidden="true" style="color: black; cursor: pointer"></i></a
          ></span>
        </div>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12 location-header flex-nowrap">
        <!-- Search Bar -->
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 input-group p-search" *ngIf="locations">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              id="location-search-input"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button
                  id="location-search-close-btn"
                  type="button"
                  name="clear"
                  class="btn"
                  (click)="clearSearch()"
                  *ngIf="searchString"
                >
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <div id="search-btn">
                <button
                  type="submit"
                  id="location-search-icon-btn"
                  name="search"
                  class="btn"
                  (click)="locationsSearch(searchString)"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- Status Filter -->
        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 list-heading" *ngIf="locations">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <!-- Import Export Locations -->
        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group add-section export-btn"
          *ngIf="locations && !location.LocationId && ('EXPORT_LOCATIONS' | canAccess)"
        >
          <button
            type="submit"
            class="btn btn-add col-lg-12 col-md-12 col-xs-12"
            id="location-import-export-btn"
            (click)="openImpExpDataPopUp()"
          >
            Import Export Locations
          </button>
        </div>

        <!-- Add Location -->
        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group add-section"
          *ngIf="locations && !location.LocationId && ('LOCATION_ADD' | canAccess)"
        >
          <button
            type="submit"
            id="location-add-location-btn"
            class="btn btn-add col-lg-12 col-md-12 col-xs-12"
            (click)="setAddForm()"
          >
            Add Location
          </button>
        </div>

        <!-- Actions button -->
        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 add-section action-btn"
          *ngIf="locations && !location.LocationId && ('LOCATION_ADD' | canAccess)"
        >
          <button
            type="submit"
            id="location-actions-btn"
            class="btn btn-add col-lg-12 col-md-12 col-xs-12"
            (click)="onPrintQRBtnClick()"
          >
            Actions
          </button>
        </div>

        <!-- Page size dropdown -->
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 list-heading" *ngIf="locations">
          <ngx-atlas-select
            (model)="LocationPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="locations">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="print-qr-checkbox">
                  <span class="select-all-checkbox"
                    ><mat-checkbox
                      name="isSelectAll"
                      [(ngModel)]="isAllSelected"
                      (change)="onCheckboxChange($event.checked)"
                    ></mat-checkbox
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('LocationName', columnSortOrder)">
                  Location Name
                  <span *ngIf="selectedColumnName == 'LocationName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'LocationName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueLocationTypeName', columnSortOrder)">
                  Location Type
                  <span *ngIf="selectedColumnName == 'VenueLocationTypeName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueLocationTypeName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('BuildingName', columnSortOrder)">
                  Building
                  <span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('ZoneName', columnSortOrder)">
                  Zone
                  <span *ngIf="selectedColumnName == 'ZoneName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ZoneName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
                <th *ngIf="'CLEANING_ALERT_ADD' | canAccess">Create Alert</th>
                <th>Digital Display Page</th>
                <th>Touchless Feedback Page</th>
                <th>Cleaning Alert Request Page</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="
                  let location of locations
                    | paginate
                      : {
                          filter: search,
                          itemsPerPage: size,
                          currentPage: page,
                          totalItems: count,
                          id: 'locPagination'
                        }
                "
              >
                <td class="print-qr-checkbox">
                  <span
                    ><mat-checkbox
                      name="isSelected"
                      [(ngModel)]="location.isSelected"
                      [checked]="location.isSelected"
                      (change)="onLocationSelectionChange()"
                    ></mat-checkbox
                  ></span>
                </td>
                <td (click)="getLocationById(location.LocationId)" style="word-break: break-word">
                  {{ location.LocationName }}
                </td>
                <td (click)="getLocationById(location.LocationId)" style="word-break: break-word">
                  {{ location.VenueLocationTypeName }}
                </td>
                <td (click)="getLocationById(location.LocationId)" style="word-break: break-word">
                  {{ location.VenueName }}
                </td>
                <td (click)="getLocationById(location.LocationId)" style="word-break: break-word">
                  {{ location.Building.BuildingName }}
                </td>
                <td (click)="getLocationById(location.LocationId)" style="word-break: break-word">
                  {{ location.Zone.ZoneName }}
                </td>
                <td *ngIf="location.IsActive" (click)="getLocationById(location.LocationId)">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!location.IsActive" (click)="getLocationById(location.LocationId)">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
                <td *ngIf="location.IsActive && ('CLEANING_ALERT_ADD' | canAccess)">
                  <a
                    *ngIf="location.isCA && isCleaningAlert"
                    class="btn-listview"
                    (click)="
                      createWorkorder(
                        location.VenueName,
                        location.Building.BuildingName,
                        location.LocationName,
                        location.LocationId,
                        location.VenueId,
                        true
                      )
                    "
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Create Alert"
                  >
                    <i class="fa fa-paper-plane"></i>
                  </a>
                </td>
                <td *ngIf="!location.IsActive">
                  <a class="btn-listview isDisabled"><i class="fa fa-paper-plane"></i></a>
                </td>
                <td>
                  <a (click)="getSignagePage(location.LocationId, location.VenueName)"
                    ><i
                      class="fa fa-external-link"
                      aria-hidden="true"
                      style="color: black; cursor: pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Digital Display Page"
                    ></i
                  ></a>
                </td>
                <td *ngIf="location.IsTouchlessFeedbackEnableForVenue && location.IsTouchlessFeedbackEnableForLocation">
                  <a (click)="getTouchlessFeedbackPage(location.LocationId)"
                    ><i
                      class="fa fa-external-link"
                      aria-hidden="true"
                      style="color: black; cursor: pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Touchless Feedback Page"
                    ></i
                  ></a>
                </td>
                <td
                  *ngIf="!location.IsTouchlessFeedbackEnableForVenue || !location.IsTouchlessFeedbackEnableForLocation"
                >
                  <a class="btn-listview isDisabled"
                    ><i
                      class="fa fa-external-link"
                      aria-hidden="true"
                      style="color: black; cursor: pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Touchless Feedback Page"
                    ></i
                  ></a>
                </td>
                <td>
                  <a
                    [ngClass]="{ 'btn-listview isDisabled': !location.isCA }"
                    (click)="getCleaningAlertRequestPage(location.LocationId)"
                    aria-label="Cleaning Alert Request Page"
                    ><i
                      class="fa fa-external-link"
                      aria-hidden="true"
                      style="color: black; cursor: pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Cleaning Alert Request Page"
                    ></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          id="locPagination"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!locations">
    <form #locationForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h3>Basic Information</h3>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="LocationName"><span id="red-circle">&#x25CF;</span> Location Name</label>
              <div>
                <input
                  id="locationName"
                  type="text"
                  placeholder="Location Name"
                  name="Locationname"
                  #LocationName="ngModel"
                  class="form-control"
                  [(ngModel)]="location.LocationName"
                  required
                  maxlength="120"
                  autocomplete="off"
                  [pattern]="utilitiesService.alphanumericRegex"
                />
                <div
                  *ngIf="LocationName.errors && (LocationName.dirty || LocationName.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!LocationName.errors.required" class="text-danger">Location name is required.</div>
                  <div [hidden]="!LocationName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="locationType"><span id="red-circle">&#x25CF;</span> Location Type</label>
              <div>
                <ngx-atlas-select
                  (model)="changeLocationType($event, 0)"
                  [list]="locationTypes"
                  [idField]="'VenueLocationTypeId'"
                  [textField]="'VenueLocationTypeName'"
                  [placeholder]="'Select Location Type'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="location.VenueLocationTypeId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Latitude"> Latitude</label>
              <div>
                <input
                  type="number"
                  placeholder="Latitude"
                  name="latitude"
                  #Latitude="ngModel"
                  min="-90"
                  max="90"
                  class="form-control"
                  [(ngModel)]="location.Latitude"
                  pattern="^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)"
                />
                <div *ngIf="Latitude.errors && (Latitude.dirty || Latitude.touched)" class="alert alert-danger">
                  <div [hidden]="!Latitude.errors.pattern" class="text-danger">
                    {{ utilitiesService.latitudeRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Longitude"> Longitude</label>
              <div>
                <input
                  type="number"
                  placeholder="Longitude"
                  name="longitude"
                  #Longitude="ngModel"
                  min="-180"
                  max="180"
                  class="form-control"
                  [(ngModel)]="location.Longitude"
                  pattern="[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$"
                />
                <div *ngIf="Longitude.errors && (Longitude.dirty || Longitude.touched)" class="alert alert-danger">
                  <div [hidden]="!Longitude.errors.pattern" class="text-danger">
                    {{ utilitiesService.longitudeRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Altitude">Altitude</label>
              <div>
                <input
                  type="number"
                  placeholder="Altitude"
                  name="altitude"
                  #Altitude="ngModel"
                  class="form-control"
                  [(ngModel)]="location.Altitude"
                  pattern="\d+(\.\d*)?|\.\d+"
                />
                <div *ngIf="Altitude.errors && (Altitude.dirty || Altitude.touched)" class="alert alert-danger">
                  <div [hidden]="!Altitude.errors.pattern" class="text-danger">Altitude pattern does not match.</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="zone"><span id="red-circle">&#x25CF;</span> Venue</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ location.Building.Venue.VenueName }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="zone"><span id="red-circle">&#x25CF;</span> Building</label>
              <div>
                <ngx-atlas-select
                  (model)="changeBuilding($event, 0)"
                  [list]="buildings"
                  [idField]="'BuildingId'"
                  [textField]="'BuildingName'"
                  [placeholder]="'Select Building'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="buildingDisabled"
                  [required]="true"
                  [selectedValue]="location.Building.BuildingId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="level"><span id="red-circle">&#x25CF;</span> Level</label>
              <div>
                <ngx-atlas-select
                  (model)="location.Building.Level.LevelId = $event"
                  [list]="levels"
                  [idField]="'LevelId'"
                  [textField]="'LevelName'"
                  [placeholder]="'Select Level'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="levelDisabled"
                  [required]="true"
                  [selectedValue]="location.Building.Level.LevelId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="zone"><span id="red-circle">&#x25CF;</span> Zone</label>
              <div>
                <ngx-atlas-select
                  (model)="changeZone($event, 0)"
                  [list]="zones"
                  [idField]="'ZoneId'"
                  [textField]="'ZoneName'"
                  [placeholder]="'Select Zone'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="zoneDisabled"
                  [required]="true"
                  [selectedValue]="location.Zone.ZoneId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <div class="chkbox1">
                <mat-checkbox
                  name="AllowGender"
                  [(ngModel)]="location.AllowGender"
                  [checked]="location.AllowGender"
                  (change)="changeAllowGender($event)"
                >
                  Allow Gender Type?</mat-checkbox
                >
              </div>
            </div>
          </div>

          <div class="col-md-12 cool-sm-12 col-lg-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="NextRestroomDistance">Next Location Distance (min)</label>
              <div>
                <input
                  type="number"
                  placeholder="Next Location Distance"
                  name="NextRestroomDistance"
                  #NextRestroomDistance="ngModel"
                  class="form-control"
                  [(ngModel)]="location.NextRestroomDistance"
                  min="1"
                  (keypress)="keyPressCheck($event)"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="isStatus">
              <label for="locationGenderType"><span id="red-circle">&#x25CF;</span> Location Gender Type</label>
              <div>
                <ngx-atlas-select
                  (model)="location.LocationType = $event"
                  [list]="locationGenderTypes"
                  [idField]="'LocationTypeCode'"
                  [textField]="'LocationTypeName'"
                  [placeholder]="'Select Location Gender Type'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="location.LocationType"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-md-6 col-sm-6 col-lg-6 form-group" *ngIf="!isStatus">
              <label for="NextRestroomDirection">Next Location Direction</label>
              <div>
                <ngx-atlas-select
                  (model)="location.NextRestroomDirection = $event"
                  [list]="NextRestroomDirections"
                  [idField]="'id'"
                  [textField]="'name'"
                  [placeholder]="'Select Next Location Direction'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="location.NextRestroomDirection"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-md-12 cool-sm-12 col-lg-12 form-section">
            <div class="col-md-6 col-sm-6 col-lg-6 form-group" *ngIf="isStatus">
              <label for="NextRestroomDirection">Next Location Direction</label>
              <div>
                <ngx-atlas-select
                  (model)="location.NextRestroomDirection = $event"
                  [list]="NextRestroomDirections"
                  [idField]="'id'"
                  [textField]="'name'"
                  [placeholder]="'Select Next Location Direction'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="location.NextRestroomDirection"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div
              class="col-md-6 col-sm-6 col-lg-6 form-group"
              *ngIf="enableLocationStatus && ('location-status' | canAccess)"
            >
              <label for="LocationStatus"><span id="red-circle">&#x25CF;</span> Location Status</label>
              <div>
                <ngx-atlas-select
                  (model)="location.LocationStatus = $event"
                  [list]="LocationStatusList"
                  [idField]="'id'"
                  [textField]="'name'"
                  [placeholder]="'Select Location Status'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="location.LocationStatus"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-md-12 cool-sm-12 col-lg-12 form-section">
            <div class="col-md-6 col-sm-6 col-lg-6 form-group">
              <label for="CapacityCount">Capacity Count</label>
              <div>
                <input
                  type="text"
                  autocomplete="invalid"
                  class="form-control"
                  name="CapacityCount"
                  placeholder="Capacity Count"
                  [(ngModel)]="location.CapacityCount"
                  #CapacityCount="ngModel"
                  pattern="^[1-9][0-9]*$"
                  maxlength="5"
                />
                <div
                  *ngIf="CapacityCount.errors && (CapacityCount.dirty || CapacityCount.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!CapacityCount.errors.pattern" class="text-danger">
                    Capacity count accepts only numeric.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-6 col-lg-6 form-group">
              <label for="AvailabilityPercentage"> Availability Percentage</label>
              <div>
                <input
                  type="text"
                  autocomplete="invalid"
                  class="form-control"
                  name="AvailabilityPercentage"
                  placeholder="Availability Percentage"
                  [(ngModel)]="location.AvailabilityPercentage"
                  #AvailabilityPercentage="ngModel"
                  pattern="^[1-9][0-9]?$|^100$"
                  maxlength="3"
                />
                <div
                  *ngIf="
                    AvailabilityPercentage.errors && (AvailabilityPercentage.dirty || AvailabilityPercentage.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!AvailabilityPercentage.errors.pattern" class="text-danger">
                    Availability percentage accepts only numeric and in between 1 to 100.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-12">
          <div class="col-md-12 col-sm-12 col-lg-12 form-group" *ngIf="location.LocationId">
            <label>Digital Display URL</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ editsignagePageUrl }}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 col-sm-12 col-lg-12"
          *ngIf="location.LocationId && location.TouchlessFeedbackIdentifier != null"
        >
          <div class="col-md-12 col-sm-12 col-lg-12 form-group" *ngIf="location.LocationId">
            <label>Touchless Feedback URL</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ editTouchlessFeedbackPageUrl }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-sm-12 col-lg-12">
          <div class="col-md-12 col-sm-12 col-lg-12 form-group" *ngIf="location.LocationId">
            <label>Cleaning Alert Request URL</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ editCleaningAlertRequestPageUrl }}</p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!isBadgeShow || isLocationBeaconShow">
          <h3 *ngIf="isLocationBeacons && isLocationBeaconShow">Beacon Configuration</h3>
          <div class="col-lg-8 col-md-8 col-sm-8">
            <div *ngIf="isLocationBeacons && isLocationBeaconShow">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Allow?</th>
                      <th>Name</th>
                      <th>Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let lBeacon of location.LocationBeacons">
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="lBeacon.IsActive"
                          id="beacon_{{ lBeacon.Beacon.BeaconId }}"
                          name="beaconSelect_{{ lBeacon.Beacon.BeaconId }}"
                        ></mat-checkbox>
                      </td>
                      <td style="word-break: break-word">{{ lBeacon.Beacon.BeaconName }}</td>
                      <td style="word-break: break-word">{{ lBeacon.Beacon.BeaconCode }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!isLocationBeacons && isLocationBeaconShow">
              <h3 class="listerror">No record found for beacons!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isLocationBeaconShow" />

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isBadgeShow">
          <h3 *ngIf="isLocationGateways">Gateway Configuration</h3>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div *ngIf="isLocationGateways">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Allow?</th>
                      <th>Gateway</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let lGateway of location.LocationGateways">
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="lGateway.IsActive"
                          id="gateway_{{ lGateway.Gateway.GatewayId }}"
                          name="gatewaySelect_{{ lGateway.Gateway.GatewayName }}"
                        ></mat-checkbox>
                      </td>
                      <td>{{ lGateway.Gateway.GatewayName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hasVenueGatewaysData()">
              <h3 class="listerror">No record found for gateways!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isBadgeShow" />

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationTaskShow">
          <h3 *ngIf="isLocationTasks && isLocationTaskShow">Task Configuration</h3>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div *ngIf="isLocationTasks && isLocationTaskShow && hasVenueTasksData()">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Allow?</th>
                      <th>Task</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="isAllTaskChecked"
                          id="select_all_task"
                          name="select_all_task"
                          (change)="ChangeSelectAllTask()"
                        ></mat-checkbox>
                      </td>
                      <td style="font-weight: 600">Select All</td>
                    </tr>
                    <tr *ngFor="let lTask of location.LocationTasks">
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="lTask.IsActive"
                          id="task_{{ lTask.VenueTaskId }}"
                          name="taskSelect_{{ lTask.LocationTaskName }}"
                          (change)="ChangeTask()"
                        ></mat-checkbox>
                      </td>
                      <td>{{ lTask.LocationTaskName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hasVenueTasksData()">
              <h3 class="listerror">No record found for tasks!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isLocationTaskShow" />

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationInventoryShow">
          <h3 *ngIf="isLocationInventories && isLocationInventoryShow">Inventory Configuration</h3>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div *ngIf="isLocationInventories && isLocationInventoryShow && hasVenueInventoriesData()">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Allow?</th>
                      <th>Inventory</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="isAllInventoryChecked"
                          id="select_all_inventory"
                          name="select_all_inventory"
                          (change)="ChangeSelectAllInventory()"
                        ></mat-checkbox>
                      </td>
                      <td style="font-weight: 600">Select All</td>
                    </tr>
                    <tr *ngFor="let lInventory of location.LocationInventories">
                      <td style="width: 20px">
                        <mat-checkbox
                          [(ngModel)]="lInventory.IsActive"
                          id="inventory_{{ lInventory.VenueInventoryId }}"
                          name="inventorySelect_{{ lInventory.InventoryName }}"
                          (change)="ChangeInventory()"
                        ></mat-checkbox>
                      </td>
                      <td>{{ lInventory.InventoryName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hasVenueInventoriesData()">
              <h3 class="listerror">No record found for inventories!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isLocationInventoryShow" />

        <div id="elementConfig" class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationElementShow">
          <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="isLocationElements && isLocationElementShow">
            <h3>Element Configuration</h3>
          </div>

          <div
            class="col-lg-6 col-md-6 col-sm-6"
            style="text-align: right; margin-top: 20px; margin-bottom: 10px"
            *ngIf="hasVenueElementsData()"
          >
            <span id="red-circle">(Total weight of elements should equal 100%.)</span>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div *ngIf="isLocationElements && isLocationElementShow && hasVenueElementsData()">
              <div class="table-responsive tableScroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <mat-checkbox
                          [(ngModel)]="isAllElementChecked"
                          id="select_all_element"
                          name="select_all_element"
                          (change)="ChangeSelectAllElement()"
                          >Select All</mat-checkbox
                        >
                      </th>
                      <th>Element</th>
                      <th><span id="red-circle">&#x25CF;</span> Score Factor</th>
                      <th *ngIf="isLocationAreaShow">Area</th>
                      <th>
                        Weight ({{ totalElementWeightage }}%)
                        <button *ngIf="canRebalance" mat-button (click)="rebalanceClicked()">Auto-Distribute</button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let lElement of location.LocationElements">
                      <td style="width: 10%">
                        <mat-checkbox
                          [(ngModel)]="lElement.IsActive"
                          id="element_{{ lElement.VenueElementId }}"
                          name="elementSelect_{{ lElement.ElementName }}"
                          (change)="
                            checkLocationElementIsChecked('locationElement', lElement.IsActive, lElement.VenueElementId)
                          "
                        ></mat-checkbox>
                      </td>
                      <td style="word-break: break-word">{{ lElement.ElementName }}</td>
                      <td style="width: 25%">
                        <ngx-atlas-select
                          (model)="lElement.InspectionScoreFactor.InspectionScoreFactorId = $event"
                          id="elementScoreFactor_{{ lElement.VenueElementId }}"
                          [list]="scoreFactors"
                          [idField]="'SPInspectionScoreFactorId'"
                          [textField]="'SPInspectionScoreFactorTitle'"
                          [placeholder]="'Select Score Factor'"
                          [allowClear]="true"
                          [multiple]="false"
                          [required]="lElement.IsActive"
                          [disabled]="!lElement.IsActive"
                          [selectedValue]="lElement.InspectionScoreFactor.InspectionScoreFactorId"
                        >
                        </ngx-atlas-select>
                      </td>
                      <td *ngIf="isLocationAreaShow" style="width: 25%">
                        <ngx-atlas-select
                          (model)="lElement.Area.AreaId = $event"
                          id="elementArea_{{ lElement.VenueElementId }}"
                          [list]="areas"
                          [idField]="'AreaId'"
                          [textField]="'AreaName'"
                          [placeholder]="'Select Area'"
                          [allowClear]="true"
                          [multiple]="false"
                          [required]="false"
                          [disabled]="!lElement.IsActive"
                          [selectedValue]="lElement.Area.AreaId"
                        >
                        </ngx-atlas-select>
                      </td>
                      <td style="width: 15%">
                        <input
                          type="number"
                          autocomplete="invalid"
                          class="form-control"
                          id="weightage_{{ lElement.VenueElementId }}"
                          name="weightage_{{ lElement.ElementName }}"
                          placeholder="Weight (%)"
                          [(ngModel)]="lElement.ElementWeightage"
                          min="1"
                          #ElementWeightage="ngModel"
                          minlength="1"
                          maxlength="3"
                          pattern="^[1-9]$|^[1-9][0-9]$|^(100)$"
                          [required]="lElement.IsActive"
                          (keypress)="keyPressCheck($event)"
                          (input)="
                            checkLocationElementIsChecked('locationElement', lElement.IsActive, lElement.VenueElementId)
                          "
                          [disabled]="!lElement.IsActive"
                        />
                        <div
                          *ngIf="ElementWeightage.errors && (ElementWeightage.dirty || ElementWeightage.touched)"
                          class="alert alert-danger"
                        >
                          <div [hidden]="!ElementWeightage.errors.required" class="text-danger">
                            Element weight is required.
                          </div>
                          <div [hidden]="!ElementWeightage.errors.pattern" class="text-danger">
                            Element(s) weight accepts only numeric and in between 1 to 100.
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="!hasVenueElementsData()">
              <h3 class="listerror">No record found for elements!</h3>
            </div>
          </div>
        </div>

        <hr *ngIf="isLocationElementShow" />

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isFeedbackReasonshow">
          <h3>Feedback Reason Configuration</h3>
          <div class="col-md-6 col-sm-6 col-lg-6 form-group">
            <label for="LocationStatus">Feedback Identifier</label>
            <div>
              <ngx-atlas-select
                (model)="ChangeFeedbackIdentifier($event)"
                [list]="feedbackIdentifiers"
                [idField]="'TouchlessFeedbackIdentifier'"
                [textField]="'TouchlessFeedbackTitle'"
                [placeholder]="'Select Identifier'"
                [allowClear]="true"
                [multiple]="false"
                [required]="false"
                [disabled]="feedbackIdentifierdisabled"
                [selectedValue]="location.TouchlessFeedbackIdentifier"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-12 col-sm-12 col-lg-12 form-group">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div *ngIf="hasFeedbackReasonsData()">
                <div class="table-responsive tableScroll">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Allow?</th>
                        <th>Reason</th>
                        <th>Reason Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let FeedbackReason of this.location.LocationFeedbackReasons">
                        <td style="width: 20px">
                          <mat-checkbox
                            [(ngModel)]="FeedbackReason.IsActive"
                            id="FeedbackReason_{{ FeedbackReason.VenueFeedbackReasonId }}"
                            name="FeedbackReasonSelect_{{ FeedbackReason.VenueFeedbackReasonId }}"
                          ></mat-checkbox>
                        </td>
                        <td>{{ FeedbackReason.Reason }}</td>
                        <td>{{ FeedbackReason.ResponseType }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div *ngIf="!hasFeedbackData()" class="col-lg-12 col-md-12 col-sm-12">
                <span>No record found for feedback!</span>
              </div>

              <div
                *ngIf="location.TouchlessFeedbackIdentifier != null && !hasFeedbackReasonsData()"
                class="col-lg-12 col-md-12 col-sm-12"
              >
                <span id="configuration-error">No record found for feedback reason!</span>
              </div>
            </div>
          </div>
        </div>

        <hr *ngIf="isFeedbackReasonshow" />

        <div class="col-lg-12 col-md-12 col-sm-12 sensorbeacon-scroll">
          <h3>Sensor Configuration</h3>
          <div class="col-sm-6 col-md-6 col-lg-6 form-group">
            <label for="locationSensors">Sensor Type</label>
            <div>
              <ngx-atlas-select
                (model)="getVenueSensors($event)"
                [list]="sensorTypes"
                [idField]="'SensorTypeId'"
                [textField]="'SensorTypeName'"
                [placeholder]="'Select Sensor Type'"
                [allowClear]="true"
                [multiple]="false"
                [selectedValue]="selectedSensorType"
              >
              </ngx-atlas-select>
            </div>

            <div id="selectedSensorTypeSection" *ngIf="isSensorTypeSelected && filteredSensors.length > 0">
              <div class="col-md-12">
                <div *ngIf="!isAirQualityDetection || !isGuestFeedback || !isVendingMachine">
                  <!--!isPeopleCounterRestroom ||-->
                  <div class="checkbox" *ngFor="let sensor of filteredSensors">
                    <mat-checkbox
                      [(ngModel)]="sensor.Sensor.IsActive"
                      id="sen_{{ sensor.Sensor.SensorId }}"
                      name="sen_{{ sensor.Sensor.SensorId }}"
                      (click)="sensorChecked(sensor.Sensor.SensorId)"
                      >{{ sensor.Sensor.SensorName }}</mat-checkbox
                    >
                  </div>
                </div>

                <div class="checkbox" *ngIf="isAirQualityDetection || isGuestFeedback || isVendingMachine">
                  <!--isPeopleCounterRestroom ||-->
                  <div class="checkbox" *ngFor="let sensor of filteredSensors">
                    <mat-checkbox
                      [(ngModel)]="sensor.Sensor.IsActive"
                      id="sen_{{ sensor.Sensor.SensorId }}"
                      name="sen_{{ sensor.Sensor.SensorId }}"
                      (click)="sensorChecked(sensor.Sensor.SensorId)"
                      >{{ sensor.Sensor.SensorName }}</mat-checkbox
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-12 form-group" style="margin-top: 15px" *ngIf="isThresholdWarnShow">
                <label class="col-lg-12 col-md-12 col-sm-12" for="sensorthreshold"
                  ><span id="red-circle">&#x25CF;</span> Threshold</label
                >
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <input
                    type="number"
                    placeholder="Threshold"
                    name="sensorthreshold"
                    #sensorthreshold="ngModel"
                    class="form-control"
                    [(ngModel)]="SensorThreshold"
                    required
                    (input)="sensorThresholdChange()"
                    pattern="^[1-9][0-9]*$"
                  />
                  <div
                    *ngIf="sensorthreshold.errors && (sensorthreshold.dirty || sensorthreshold.touched)"
                    class="alert alert-danger"
                  >
                    <div [hidden]="!sensorthreshold.errors.required" class="text-danger">
                      Sensor Threshold is required.
                    </div>
                    <div [hidden]="!sensorthreshold.errors.pattern" class="text-danger">
                      Sensor Threshold does not accepting value less than 0.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 form-group" *ngIf="isThresholdWarnShow">
                <label class="col-lg-12 col-md-12 col-sm-12" for="sensorwarnpercentage"
                  ><span id="red-circle">&#x25CF;</span> Warn %</label
                >
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <input
                    type="number"
                    placeholder="Warn %"
                    name="sensorwarnpercentage"
                    #sensorwarnpercentage="ngModel"
                    class="form-control"
                    [(ngModel)]="SensorWarnPercentage"
                    required
                    (input)="sensorWarnPercentageChange()"
                    pattern="^[1-9][0-9]*$"
                  />
                  <div
                    *ngIf="sensorwarnpercentage.errors && (sensorwarnpercentage.dirty || sensorwarnpercentage.touched)"
                    class="alert alert-danger"
                  >
                    <div [hidden]="!sensorwarnpercentage.errors.required" class="text-danger">
                      Sensor Warn % is required.
                    </div>
                    <div [hidden]="!sensorwarnpercentage.errors.pattern" class="text-danger">
                      Sensor Warn % does not accepting value less than 0.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isSensorAvailable" class="col-lg-12 col-md-12 col-sm-12">
              <h3 class="listerror">No sensor details available for sensor type!</h3>
            </div>

            <div
              *ngIf="location != null && location.TouchlessFeedbackIdentifier != null && isGuestFeedbackSelected"
              class="col-lg-12 col-md-12 col-sm-12"
              style="margin-top: 15px"
            >
              <span id="configuration-error">
                Touchless feedback is already enabled. Additional surveys cannot be added.
              </span>
            </div>
          </div>

          <div *ngIf="!isLocationSensors" class="col-lg-12 col-md-12 col-sm-12">
            <h3 class="listerror">No record found for sensors!</h3>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6" id="selected-sensor-details">
            <div class="col-md-12" *ngFor="let lsd of locationSensorDetailsDisplay">
              <div *ngIf="lsd.Sensors.length > 0">
                <div class="col-md-12">
                  <div class="col-md-12">
                    <table class="table table-hover" style="border: 1px solid">
                      <thead>
                        <tr>
                          <td>Sensor Type</td>
                          <td>Name</td>
                          <td>Threshold</td>
                          <td>Warn %</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let se of lsd.Sensors">
                          <td>{{ lsd.SensorType.SensorTypeName }}</td>
                          <td>{{ se.SensorName }}</td>
                          <td>{{ se.Threshold == null ? 0 : se.Threshold }}</td>
                          <td>{{ se.WarnPercentage == null ? 0 : se.WarnPercentage }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Feature Configuration</h3>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-12 form-group">
              <div class="d-inline-block form-group">
                <div *ngIf="isCheckBoxVisible" style="margin-left: -15px">
                  <mat-checkbox
                    class="form-check-input"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="isAllFeatureConfigurationChecked"
                    (change)="onAllCheckedChange($event)"
                    >All</mat-checkbox
                  >
                </div>
                <ngx-treeview
                  #treeviewComponent
                  [items]="items"
                  [config]="config"
                  [itemTemplate]="itemTemplate"
                  (selectedChange)="onSelectedChange($event)"
                >
                </ngx-treeview>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="col-lg-12 col-md-12 col-sm-12">
          <h3>Map Configuration</h3>
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="uploadSvg">Upload (SVG)</label>
            <div>
              <input
                id="uploadSvg"
                #svgFileInput
                name="uploadSvg"
                type="file"
                class="btn btn-default form-control"
                value="Choose Files"
                (change)="fileChange($event)"
                accept=".svg"
              />
            </div>
            <div *ngIf="selectedSVGFile">
              <input
                type="button"
                value="Remove"
                class="btn btn-type btn-cancel btn-remove"
                (click)="fileReset('svg')"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group uploaded-files">
            <div *ngIf="location.LocationMapFiles">
              <div *ngFor="let lFile of location.LocationMapFiles">
                <span id="selectedfile" style="color: black">{{ lFile.serialNumber }}. {{ lFile.fileName }}</span>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <div>
              <span id="selectedSvgfile" style="color: black">{{ selectedSVGFile }}</span>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 uploaded-files pull-right" *ngIf="IsSVGTable">
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ location.LocationSVGMap }}</td>
                  <td>
                    <button class="btn btn-purple" (click)="removeSVG()"><i class="fa fa-times"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="location.LocationId" style="margin-top: 15px">
          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <div class="chkbox">
              <mat-checkbox [(ngModel)]="location.IsActive" name="isActive">Is Active</mat-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type1 pull-right btn-cancel"
              (click)="clearFormData(locationForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!location.LocationId"
              class="btn btn-type btn-save"
              [disabled]="
                !locationForm.form.valid ||
                !location.Zone.ZoneId ||
                !location.Building.Venue.VenueId ||
                !location.Building.BuildingId ||
                isReqComplete ||
                (location.AllowGender && !location.LocationType) ||
                !location.Building.Level.LevelId ||
                !location.LocationStatus ||
                !location.VenueLocationTypeId
              "
              (click)="addLocation(locationForm, 'single')"
            />
            <input
              type="button"
              value="Save and Add New"
              *ngIf="!location.LocationId"
              class="btn btn-type btn-save save-and-new"
              [disabled]="
                !locationForm.form.valid ||
                !location.Zone.ZoneId ||
                !location.Building.Venue.VenueId ||
                !location.Building.BuildingId ||
                isReqComplete ||
                (location.AllowGender && !location.LocationType) ||
                !location.Building.Level.LevelId ||
                !location.LocationStatus ||
                !location.VenueLocationTypeId
              "
              (click)="addLocation(locationForm, 'multiple')"
            />
            <input
              type="button"
              value="Save"
              *ngIf="location.LocationId"
              class="btn btn-type btn-save"
              [disabled]="
                !locationForm.form.valid ||
                !location.Zone.ZoneId ||
                !location.Building.Venue.VenueId ||
                !location.Building.BuildingId ||
                isReqComplete ||
                (location.AllowGender && !location.LocationType) ||
                !location.Building.Level.LevelId ||
                !location.LocationStatus ||
                !location.VenueLocationTypeId ||
                !('LOCATION_EDIT' | canAccess)
              "
              (click)="updateLocation(locationForm)"
            />
            <input
              type="button"
              value="Create Template From This Location"
              *ngIf="location.LocationId && ('LOCATION_TEMPLATE_ADD' | canAccess)"
              class="btn btn-type btn-save save-and-new"
              [disabled]="!locationForm.form.valid || !location.VenueLocationTypeId"
              (click)="openLocationTemplate()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<div class="modal" id="svgModal" [ngStyle]="svgModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Location</h3>
        <button type="button" class="close" data-dismiss="modal" (click)="closeSVGModal()" style="margin-top: -27px">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>Do you really want to delete?</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <button class="btn btn-type btn-save" [disabled]="isReqComplete" (click)="removeSvgFile()">Yes</button>
          <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeSVGModal()">No</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="templateModal" [ngStyle]="templateModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Location Template</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeTemplateModal()"
          style="margin-top: -35px"
        >
          &times;
        </button>
      </div>
      <div class="modal-body" #scrolltop id="scrolltop">
        <form #locationTemplateForm="ngForm" class="form-horizontal">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h3>Basic Information</h3>

              <div class="col-lg-12 col-md-12 col-sm-12 form-section">
                <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                  <label for="LocationTemplateName"><span id="red-circle">&#x25CF;</span> Location Template Name</label>
                  <div>
                    <input
                      id="LocationTemplateName"
                      type="text"
                      placeholder="Location Template Name"
                      name="LocationTemplateName"
                      #LocationTemplateName="ngModel"
                      class="form-control"
                      [(ngModel)]="locationTemplateModalObj.LocationTemplateName"
                      required
                      maxlength="120"
                      autocomplete="off"
                      [pattern]="utilitiesService.alphanumericRegex"
                    />
                    <div
                      *ngIf="
                        LocationTemplateName.errors && (LocationTemplateName.dirty || LocationTemplateName.touched)
                      "
                      class="alert alert-danger"
                    >
                      <div [hidden]="!LocationTemplateName.errors.required" class="text-danger">
                        Location template name is required.
                      </div>
                      <div [hidden]="!LocationTemplateName.errors.pattern" class="text-danger">
                        {{ utilitiesService.alphanumericRegexMessage }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                  <label for="locationType"><span id="red-circle">&#x25CF;</span> Location Type</label>
                  <div>
                    <ngx-atlas-select
                      (model)="changeLocationType($event, 2)"
                      [list]="locationTypes"
                      [idField]="'VenueLocationTypeId'"
                      [textField]="'VenueLocationTypeName'"
                      [placeholder]="'Select Location Type'"
                      [allowClear]="true"
                      [multiple]="false"
                      [required]="true"
                      [selectedValue]="locationTemplateModalObj.VenueLocationTypeId"
                    >
                    </ngx-atlas-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-section">
                <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                  <label for="zone"><span id="red-circle">&#x25CF;</span> Venue</label>
                  <div>
                    <div class="clipping-wrapper">
                      <p class="readOnly clipping">{{ locationTemplateModalObj.Building.Venue.VenueName }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                  <label for="zone">Building</label>
                  <div>
                    <ngx-atlas-select
                      (model)="changeBuilding($event, 1)"
                      [list]="buildings"
                      [idField]="'BuildingId'"
                      [textField]="'BuildingName'"
                      [placeholder]="'Select Building'"
                      [allowClear]="true"
                      [multiple]="false"
                      [disabled]="buildingDisabled"
                      [required]="false"
                      [selectedValue]="locationTemplateModalObj.Building.BuildingId"
                    >
                    </ngx-atlas-select>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-section">
                <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                  <label for="level">Level</label>
                  <div>
                    <ngx-atlas-select
                      (model)="locationTemplateModalObj.Building.Level.LevelId = $event"
                      [list]="levelsTemplate"
                      [idField]="'LevelId'"
                      [textField]="'LevelName'"
                      [placeholder]="'Select Level'"
                      [allowClear]="true"
                      [multiple]="false"
                      [disabled]="levelDisabledTemplate"
                      [required]="false"
                      [selectedValue]="locationTemplateModalObj.Building.Level.LevelId"
                    >
                    </ngx-atlas-select>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                  <label for="zone">Zone</label>
                  <div>
                    <ngx-atlas-select
                      (model)="changeZone($event, 1)"
                      [list]="zonesTemplate"
                      [idField]="'ZoneId'"
                      [textField]="'ZoneName'"
                      [placeholder]="'Select Zone'"
                      [allowClear]="true"
                      [multiple]="false"
                      [disabled]="zoneDisabledTemplate"
                      [required]="false"
                      [selectedValue]="locationTemplateModalObj.Zone.ZoneId"
                    >
                    </ngx-atlas-select>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationTaskShow">
              <h3 *ngIf="isLocationTasksTemplate && isLocationTaskShow">Task Configuration</h3>
              <div class="col-md-12 col-sm-12 col-lg-12">
                <div *ngIf="isLocationTasksTemplate && isLocationTaskShow && hasVenueTasksDataTemplate()">
                  <div class="table-responsive tableScroll">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Allow?</th>
                          <th>Task</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="width: 20px">
                            <mat-checkbox
                              [(ngModel)]="isAllTemplateTaskChecked"
                              id="select_all_template_task"
                              name="select_all_template_task"
                              (change)="ChangeSelectAllTaskTemplate()"
                            ></mat-checkbox>
                          </td>
                          <td style="font-weight: 600">Select All</td>
                        </tr>
                        <tr *ngFor="let lTaskTemplate of locationTemplateModalObj.LocationTasks">
                          <td style="width: 20px">
                            <mat-checkbox
                              [(ngModel)]="lTaskTemplate.IsActive"
                              id="taskTemplate_{{ lTaskTemplate.VenueTaskId }}"
                              name="taskSelectTemplate_{{ lTaskTemplate.LocationTaskName }}"
                              (change)="ChangeTaskTemplate()"
                            ></mat-checkbox>
                          </td>
                          <td>{{ lTaskTemplate.LocationTaskName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="!hasVenueTasksDataTemplate()">
                  <h3 class="listerror">No record found for tasks!</h3>
                </div>
              </div>
            </div>

            <hr *ngIf="isLocationTaskShow" />

            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationInventoryShow">
              <h3 *ngIf="isLocationInventoriesTemplate && isLocationInventoryShow">Inventory Configuration</h3>
              <div class="col-md-12 col-sm-12 col-lg-12">
                <div
                  *ngIf="isLocationInventoriesTemplate && isLocationInventoryShow && hasVenueInventoriesDataTemplate()"
                >
                  <div class="table-responsive tableScroll">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Allow?</th>
                          <th>Inventory</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="width: 20px">
                            <mat-checkbox
                              [(ngModel)]="isAllTemplateInventoryChecked"
                              id="select_all_template_inventory"
                              name="select_all_template_inventory"
                              (change)="ChangeSelectAllInventoryTemplate()"
                            ></mat-checkbox>
                          </td>
                          <td style="font-weight: 600">Select All</td>
                        </tr>
                        <tr *ngFor="let lInventoryTemplate of locationTemplateModalObj.LocationInventories">
                          <td style="width: 20px">
                            <mat-checkbox
                              [(ngModel)]="lInventoryTemplate.IsActive"
                              id="inventoryTemplate_{{ lInventoryTemplate.VenueInventoryId }}"
                              name="inventorySelectTemplate_{{ lInventoryTemplate.InventoryName }}"
                              (change)="ChangeInventoryTemplate()"
                            ></mat-checkbox>
                          </td>
                          <td>{{ lInventoryTemplate.InventoryName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="!hasVenueInventoriesDataTemplate()">
                  <h3 class="listerror">No record found for inventories!</h3>
                </div>
              </div>
            </div>

            <hr *ngIf="isLocationInventoryShow" />

            <div id="elementConfig" class="col-lg-12 col-md-12 col-sm-12" *ngIf="isLocationElementShow">
              <div class="col-md-6 col-sm-6 col-lg-6" *ngIf="isLocationElementsTemplate && isLocationElementShow">
                <h3>Element Configuration</h3>
              </div>

              <div
                class="col-lg-6 col-md-6 col-sm-6"
                style="text-align: right; margin-top: 20px; margin-bottom: 10px"
                *ngIf="hasVenueElementsDataTemplate()"
              >
                <span id="red-circle">(Total weight of elements should equal 100%.)</span>
              </div>

              <div class="col-md-12 col-sm-12 col-lg-12">
                <div *ngIf="isLocationElementsTemplate && isLocationElementShow && hasVenueElementsDataTemplate()">
                  <div class="table-responsive tableScroll">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <mat-checkbox
                              [(ngModel)]="isAllTemplateElementChecked"
                              id="select_all_template_element"
                              name="select_all_template_element"
                              (change)="ChangeSelectAllElementTemplate()"
                              >Select All</mat-checkbox
                            >
                          </th>
                          <th>Element</th>
                          <th><span id="red-circle">&#x25CF;</span> Score Factor</th>
                          <th *ngIf="isLocationAreaShow">Area</th>
                          <th *ngIf="totalTemplateElementWeightage">Weight ({{ totalTemplateElementWeightage }}%)</th>
                          <th *ngIf="!totalTemplateElementWeightage">Weight (0%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let lElementTemplate of locationTemplateModalObj.LocationElements">
                          <td style="width: 10%">
                            <mat-checkbox
                              [(ngModel)]="lElementTemplate.IsActive"
                              id="elementTemplate_{{ lElementTemplate.VenueElementId }}"
                              name="elementSelectTemplate_{{ lElementTemplate.ElementName }}"
                              (change)="
                                checkLocationElementIsChecked(
                                  'locationTemplateElement',
                                  lElementTemplate.IsActive,
                                  lElementTemplate.VenueElementId
                                )
                              "
                            ></mat-checkbox>
                          </td>
                          <td>{{ lElementTemplate.ElementName }}</td>
                          <td style="width: 25%">
                            <ngx-atlas-select
                              (model)="lElementTemplate.InspectionScoreFactor.InspectionScoreFactorId = $event"
                              id="elementTemplateScoreFactor_{{ lElementTemplate.VenueElementId }}"
                              [list]="scoreFactors"
                              [idField]="'SPInspectionScoreFactorId'"
                              [textField]="'SPInspectionScoreFactorTitle'"
                              [placeholder]="'Select Score Factor'"
                              [allowClear]="true"
                              [multiple]="false"
                              [required]="lElementTemplate.IsActive"
                              [disabled]="!lElementTemplate.IsActive"
                              [selectedValue]="lElementTemplate.InspectionScoreFactor.InspectionScoreFactorId"
                            >
                            </ngx-atlas-select>
                          </td>
                          <td *ngIf="isLocationAreaShow" style="width: 25%">
                            <ngx-atlas-select
                              (model)="lElementTemplate.Area.AreaId = $event"
                              id="elementTemplateArea_{{ lElementTemplate.VenueElementId }}"
                              [list]="areas"
                              [idField]="'AreaId'"
                              [textField]="'AreaName'"
                              [placeholder]="'Select Area'"
                              [allowClear]="true"
                              [multiple]="false"
                              [required]="false"
                              [disabled]="!lElementTemplate.IsActive"
                              [selectedValue]="lElementTemplate.Area.AreaId"
                            >
                            </ngx-atlas-select>
                          </td>
                          <td style="width: 15%">
                            <input
                              type="number"
                              autocomplete="invalid"
                              class="form-control"
                              id="weightage_{{ lElementTemplate.VenueElementId }}"
                              name="elementWeightage_{{ lElementTemplate.ElementName }}"
                              placeholder="Weight (%)"
                              [(ngModel)]="lElementTemplate.ElementWeightage"
                              #ElementWeightage="ngModel"
                              minlength="1"
                              maxlength="3"
                              pattern="^[1-9]$|^[1-9][0-9]$|^(100)$"
                              [required]="lElementTemplate.IsActive"
                              (keypress)="keyPressCheck($event)"
                              (input)="
                                checkLocationElementIsChecked(
                                  'locationTemplateElement',
                                  lElementTemplate.IsActive,
                                  lElementTemplate.VenueElementId
                                )
                              "
                              [disabled]="!lElementTemplate.IsActive"
                            />
                            <div
                              *ngIf="ElementWeightage.errors && (ElementWeightage.dirty || ElementWeightage.touched)"
                              class="alert alert-danger"
                            >
                              <div [hidden]="!ElementWeightage.errors.required" class="text-danger">
                                Element weight is required.
                              </div>
                              <div [hidden]="!ElementWeightage.errors.pattern" class="text-danger">
                                Element weight accepts only numeric and in between 1 to 100.
                              </div>
                              <div [hidden]="!ElementWeightage.errors.minlength" class="text-danger">
                                Element weight accepts only numeric and in between 1 to 100.
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="!hasVenueElementsDataTemplate()">
                  <h3 class="listerror">No record found for elements!</h3>
                </div>
              </div>
            </div>

            <hr *ngIf="isLocationElementShow" />

            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isFeedbackReasonshowTemplate">
              <h3>Feedback Reason Configuration</h3>
              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label for="LocationStatus">Feedback Identifier</label>
                <div>
                  <ngx-atlas-select
                    (model)="ChangeFeedbackIdentifierTemplate($event)"
                    [list]="feedbackIdentifiers"
                    [idField]="'TouchlessFeedbackIdentifier'"
                    [textField]="'TouchlessFeedbackTitle'"
                    [placeholder]="'Select Identifier'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="locationTemplateModalObj.TouchlessFeedbackIdentifier"
                  >
                  </ngx-atlas-select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div *ngIf="hasLocationFeedbackReasonsDataTemplate()">
                  <div class="table-responsive tableScroll">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Allow?</th>
                          <th>Reason</th>
                          <th>Reason Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let FeedbackReason of locationTemplateModalObj.LocationFeedbackReasons">
                          <td style="width: 20px">
                            <mat-checkbox
                              [(ngModel)]="FeedbackReason.IsActive"
                              id="FeedbackReasonTemplate_{{ FeedbackReason.VenueFeedbackReasonId }}"
                              name="FeedbackReasonSelect_{{ FeedbackReason.VenueFeedbackReasonId }}"
                            ></mat-checkbox>
                          </td>
                          <td>{{ FeedbackReason.Reason }}</td>
                          <td>{{ FeedbackReason.ResponseType }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div *ngIf="!hasFeedbackData()" class="col-lg-12 col-md-12 col-sm-12">
                  <span>No record found for feedback!</span>
                </div>

                <div
                  *ngIf="
                    locationTemplateModalObj.TouchlessFeedbackIdentifier != null &&
                    !hasLocationFeedbackReasonsDataTemplate()
                  "
                  class="col-lg-12 col-md-12 col-sm-12"
                >
                  <span id="configuration-error">No record found for feedback reason!</span>
                </div>
              </div>
            </div>

            <hr *ngIf="isFeedbackReasonshow" />

            <div class="col-lg-12 col-md-12 col-sm-12 venue-sections">
              <h3>Feature Configuration</h3>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="col-12 form-group">
                  <div class="d-inline-block form-group">
                    <div *ngIf="isCheckBoxVisibleTemplate" style="margin-left: -15px">
                      <mat-checkbox
                        class="form-check-input"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="isAllFeatureConfigurationCheckedTemplate"
                        (change)="onAllCheckedChangeTemplate($event)"
                        >All</mat-checkbox
                      >
                    </div>
                    <ngx-treeview
                      #treeviewComponentTemplate
                      [items]="itemsTemplate"
                      [config]="config"
                      [itemTemplate]="itemTemplate"
                      (selectedChange)="onSelectedChangeTemplate($event)"
                    >
                    </ngx-treeview>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <input
                  type="button"
                  value="Close"
                  class="btn btn-type pull-right btn-cancel"
                  (click)="closeTemplateModal()"
                />
                <input
                  type="button"
                  value="Save"
                  class="btn btn-type btn-save pull-left"
                  [disabled]="
                    !locationTemplateForm.form.valid || isReqComplete || !locationTemplateModalObj.VenueLocationTypeId
                  "
                  (click)="addTemplateLocation(locationTemplateForm)"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal col-lg-12 col-md-12 col-sm-12 col-xs-12"
  id="caAttachmentPopUp"
  [ngStyle]="caAttachmentPopUp ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h3 style="margin: 0 auto">Create Cleaning Alert</h3>
        <button type="button" class="close" data-dismiss="modal" (click)="closeCAAttachmentPopUp()">&times;</button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Do you want to add pictures or videos?</label>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <label class="custom-file-selector-label pull-left">
              + Add Attachments
              <input
                type="file"
                id="custom-file-selector-input"
                #caFileInput
                name="file-selector"
                (change)="caFileChange($event)"
                accept=".jpg, .jpeg, .png, .mp4, .mov, .m4v"
              />
            </label>
          </div>
        </div>

        <div
          class="col-lg-12 col-md-12 col-sm-12 form-group"
          style="display: flex"
          *ngIf="caAttachments != null && caAttachments.length > 0"
        >
          <div
            class="ca-attachment-box"
            *ngFor="let attach of caAttachments"
            style="width: 175px; height: 150px; padding: 5px"
          >
            <button
              id="templateReset_{{ attach.rowid }}"
              class="btn-primary btn btn-xs button-text"
              type="button"
              *ngIf="attach.attachmentImage"
              (click)="removeAttachment(attach.rowid)"
              style="z-index: 1"
            >
              <i class="fa fa-times"></i> Remove
            </button>

            <a
              *ngIf="
                attach.attachmentImageType == 'mp4' ||
                attach.attachmentImageType == 'm4v' ||
                attach.attachmentImageType == 'mov'
              "
              class="fa fa-video-camera"
              style="position: absolute; display: block; color: black; padding: 55px 0px 0px 65px; font-size: xx-large"
            ></a>
            <img
              id="file_{{ attach.rowid }}"
              [src]="attach.attachmentImage != null ? attach.attachmentImage : DefaultImage"
              class="user-profile"
            />
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <label style="float: left">Comments</label>
          <textarea
            class="form-control"
            rows="4"
            maxlength="500"
            id="Creator Comments"
            placeholder="Creator Comments"
            name="CreatorComments"
            [(ngModel)]="CreatorComments"
          ></textarea>
        </div>

        <div class="modal-footer col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <input
            type="button"
            value="Create Alert"
            class="btn btn-type btn-save btn-left"
            (click)="createWorkorder(venueName, buildingName, restRoom, locationId, venueId, false)"
          />
          <input
            type="button"
            value="Cancel"
            class="btn btn-type btn-cancel btn-right"
            (click)="closeCAAttachmentPopUp()"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal col-lg-12 col-md-12 col-sm-12 col-xs-12"
  id="impExpDataPopUp"
  [ngStyle]="impExpDataPopUp ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h3 style="margin: 0 auto">Import Export Location</h3>
        <button type="button" class="close" data-dismiss="modal" (click)="closeImpExpDataPopUp()">&times;</button>
      </div>
      <div class="modal-body">
        <div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <input
                type="button"
                value="Download Import Template"
                class="btn btn-type btn-add pull-right"
                (click)="downloadTemplate()"
              />
              <input
                type="button"
                value="Export Existing Locations"
                class="btn btn-type btn-add pull-right"
                (click)="checkExportLocation('Export Location')"
                style="margin-right: 20px"
              />
            </div>
          </div>

          <div class="row importfile">
            <div class="col-sm-4 col-md-4 col-lg-4">
              <label for="choosefile">Choose Excel File To Import</label>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-6 uploadfile">
              <input
                #fileInput
                type="file"
                #ImportTransactionFile
                id="chooseFile"
                class="btn btn-default form-control"
                value="Choose Files"
                (change)="locationFileChange($event)"
                accept=".xls,.xlsx,.xlsm"
                required
              />
            </div>

            <div class="col-sm-2 col-md-2 col-lg-2" *ngIf="!isFileSelected">
              <input
                type="button"
                value="Save"
                [disabled]="isFileSelected"
                class="btn btn-type btn-save btn-right pull-right"
                (click)="AddFile()"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 10px">
              <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!hasLogData()">
                <h3 class="listerror">Sorry no data found!</h3>
              </div>

              <div class="box-body table-responsive">
                <table class="table table-hover table-responsive" *ngIf="hasLogData()">
                  <thead>
                    <tr>
                      <th>Import Date</th>
                      <th>Current Status</th>
                      <th>Imported By</th>
                      <th>View Log</th>
                      <th>
                        <a
                          (click)="getAllData('', logpage, logsize)"
                          class="fa fa-refresh"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Refresh"
                        ></a>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      *ngFor="
                        let transaction of transactions
                          | paginate
                            : { itemsPerPage: logsize, currentPage: logpage, totalItems: logcount, id: 'logPagination' }
                      "
                    >
                      <td>{{ transaction.ModifiedOn | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                      <td>{{ transaction.Message }}</td>
                      <td>{{ transaction.User.FirstName }} {{ transaction.User.LastName }}</td>
                      <td *ngIf="transaction.LogFiles != null && transaction.LogFiles.length > 0">
                        <a
                          (click)="downloadTransactionFiles(transaction.Id)"
                          class="fa fa-download"
                          style="color: black; cursor: pointer"
                          download
                        ></a>
                      </td>
                      <td *ngIf="transaction.LogFiles == null">-</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <pagination-controls
                class="pagination pull-right"
                (pageChange)="logPageChanged($event)"
                id="logPagination"
                *ngIf="hasLogData() && logcount > 10"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  #itemTemplate
  let-item="item"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
>
  <div class="form-inline row-item" style="display: inline-flex">
    <i
      *ngIf="item.children"
      (click)="onCollapseExpand()"
      aria-hidden="true"
      class="fa"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed"
    ></i>
    <div class="form-check">
      <mat-checkbox
        class="form-check-input"
        [(ngModel)]="item.checked"
        (change)="onCheckedChange()"
        [disabled]="item.disabled"
        [indeterminate]="item.indeterminate"
      >
        {{ item.text }}</mat-checkbox
      >
    </div>
  </div>
</ng-template>

<div class="modal qr-modal" *ngIf="showPrintPopup">
  <div class="modal-dialog modal-md">
    <div class="modal-content">

   

      <button type="button" class="pdf-close-btn" aria-label="Close" (click)="closePrintPopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">

        <div class="row" style="margin-bottom: 10px">
          <div class="pull-left">
            <div class="col-md-8" style="padding-top: 8px; padding-left: 0px;"><label for="QRSize">Size(Inches):</label></div>
            <div class="col-md-4" style="padding: 0px">

              <ngx-atlas-select
             
              [list]="qrSizes"
              [idField]="'id'"
              [textField]="'name'"
              [placeholder]="'Select Size'"
              (model)="qrSizeChange($event)"
              [multiple]="false"
              [required]="true"
              [selectedValue]="QRSize"
            >
            </ngx-atlas-select>

            </div>
          </div>

          <div class="pull-right">
            <mat-slide-toggle [checked]="isBlackWhitePrint" (change)="toggle($event)"> B & W </mat-slide-toggle>
          </div>
        </div>

        <div class="row btn-mb-20">
          <div class="col">
            <button
              type="button"
              id="location-print-touchless-fb-btn"
              (click)="generatePdf(qrCodeType.TOUCHLESS_FEEDBACK)"
              class="btn btn-primary btn-block"
            >
              Export Touchless Feedback QR Code
            </button>
          </div>
        </div>
        <div class="row btn-mb-20">
          <div class="col">
            <button
              type="button"
              id="location-print-cleaning-alert-btn"
              (click)="generatePdf(qrCodeType.CLEANING_ALERT)"
              class="btn btn-warning btn-block"
            >
              Export CA Request QR Code
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              type="button"
              id="location-print-generic-locations-btn"
              (click)="generatePdf(qrCodeType.LOCATIONS)"
              class="btn btn-success btn-block"
            >
              Export Generic Locations QR Code
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal pdf-modal" *ngIf="showQRPdf">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Export {{ pdfModalTitle }} QR Codes</h3>
        <button
          id="location-print-qr-close-btn"
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeQRPdfModal()"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <ngx-extended-pdf-viewer
          [src]="pdfSrc"
          [height]="'auto'"
          [textLayer]="true"
          [showOpenFileButton]="false"
          (pdfLoaded)="afterLoadComplete()"
          [showToolbar]="showToolbar"
          [showTextEditor]="false"
          [showStampEditor]="false"
          [showDrawEditor]="false"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
</div>
