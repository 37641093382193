import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { User } from '../../usermanagement/user/user.model';
import { CalModule, ScheduleIntervalMinutes, ScheduleTypeModule } from '../calendar.module';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private headers: HttpHeaders = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  private setHTTPHeaders() {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Accept', 'application/json');
    this.headers.append('Authorization', 'bearer ' + this.configuration.Token);
    this.headers.append('Local-TimeZone', this.configuration.currentTimezone);
    return this.headers;
  }

  GetAllScheduleByDate = (body: string): Observable<CalModule[]> => {
    const _url: string = this.configuration.Server + 'schedule/schedules';
    return this.http.post<CalModule[]>(_url, body);
  };

  public GetScheduleDetails = (schedule: CalModule): Observable<CalModule> => {
    const _url: string = this.configuration.Server + 'schedule/schedule/details';
    return this.http.post<CalModule>(_url, schedule);
  };

  public Add = (schedule: CalModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'schedule/schedule';
    return this.http.post<ResponseMessage>(_url, schedule);
  };

  public UpdateSchedule = (schedule: CalModule): Observable<CalModule> => {
    const _url: string = this.configuration.Server + 'schedule/schedule';
    return this.http.put<CalModule>(_url, schedule);
  };

  public DeleteSchedule = (schedule: CalModule): Observable<CalModule> => {
    const _url: string = this.configuration.Server + 'schedule/delete/schedule';
    return this.http.put<CalModule>(_url, schedule);
  };

  public GetAllScheduleTypes = (body: any): Observable<ScheduleTypeModule[]> => {
    const _url: string = this.configuration.Server + 'schedule/user/schedule/types';
    return this.http.post<ScheduleTypeModule[]>(_url, body);
  };

  public GetScheduleTypesList = (): Observable<ScheduleTypeModule[]> => {
    const _url: string = this.configuration.Server + 'schedule/schedule/types';
    return this.http.get<ScheduleTypeModule[]>(_url);
  };

  public GetAllSupervisorsWorkersInspectors = (body: string): Observable<User[]> => {
    // const _url: string = this.configuration.Server + 'schedule/users';
    const _url: string = this.configuration.Server + 'schedule/users';
    return this.http.post<User[]>(_url, body);
  };

  public GetTimeIntervalList = (): Observable<ScheduleIntervalMinutes[]> => {
    const _url: string = this.configuration.Server + 'schedule/interval/minutes';
    return this.http.get<ScheduleIntervalMinutes[]>(_url);
  };
}
