import { Component, EventEmitter, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'ngx-atlas-select',
  templateUrl: './atlas.select.component.html',
  inputs: [
    'list',
    'idField',
    'textField',
    'placeholder',
    'allowClear',
    'disabled',
    'multiple',
    'selectedValue',
    'required',
  ],
})
export class NgxAtlasSelectComponent implements OnChanges {
  @Output() model = new EventEmitter<any>();
  @Output() typed = new EventEmitter<any>();
  @Output() removed = new EventEmitter<any>();
  @Output() multiselect = new EventEmitter<any>();
  list: any[];
  items: any[];
  idField: string;
  textField: string;
  placeholder: string;
  allowClear: boolean;
  disabled: boolean;
  multiple = false;
  selectedValue: any;
  selectedItem: Array<any>;
  isValueSelected = false;
  required: boolean;

  ngOnChanges() {
    this.items = [];
    this.selectedItem = [];
    this.isValueSelected = false;

    if (this.list) {
      this.selectedItem = [];

      for (let i = 0; i < this.list.length; i++) {
        const obj = {
          id: this.list[i][this.idField],
          text: this.list[i][this.textField],
        };

        this.items.push(obj);

        if (this.selectedValue) {
          if (!(this.selectedValue instanceof Array) && this.list[i][this.idField] === this.selectedValue) {
            this.selectedItem.push(obj);
          } else {
            for (let j = 0; j < this.selectedValue.length; j++) {
              if (this.list[i][this.idField] === this.selectedValue[j]) {
                this.selectedItem.push(obj);
              }
            }
          }

          this.isValueSelected = true;
        }
      }
    }
  }

  public selected(value: any): void {
    if (value) {
      this.model.emit(value.id);
      this.isValueSelected = true;
    } else {
      this.model.emit(null);
      this.isValueSelected = false;
    }
  }

  public typedfn(value: any): void {
    this.typed.emit(value.id);
  }

  public removedfn(value: any): void {
    this.isValueSelected = false;
    if (value) {
      this.removed.emit(value.id);
    } else {
      for (let j = 0; j < this.selectedItem.length; j++) {
        this.removed.emit(this.selectedItem[j].id);
      }
    }
    this.model.emit(null);
  }

  public refreshValue(value: any): void {
    const output: any[] = [];
    for (let i = 0; i < value.length; i++) {
      output.push(value[i].id);
    }
    this.multiselect.emit(output);
  }
}
