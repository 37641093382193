<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-search heading-icon" aria-hidden="true"></i>
        <span class="list-heading" *ngIf="elements">ELEMENTS</span>
        <span class="list-heading" *ngIf="!elements && !element.ElementId">ADD ELEMENT</span>
        <span class="list-heading" *ngIf="!elements && element.ElementId">EDIT ELEMENT</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12" *ngIf="elements">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading">
          <ngx-atlas-select
            (model)="ElementPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section">
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Element
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="elementsSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="elements">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive">
          <table class="table table-hover table-responsive" *ngIf="hasData()">
            <thead>
              <tr>
                <th>Element Image</th>
                <th class="table-sort-header" (click)="sortColumn('elementName', columnSortOrder)">
                  Element Name
                  <span *ngIf="selectedColumnName == 'elementName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'elementName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header text-right" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let element of elements
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr>
                <td *ngIf="element.ImageName" class="width-20">
                  <img
                    src="{{ element.AttachmentURL }}"
                    class="img-responsive element-image"
                    alt="{{ element.ImageName }}"
                    data-placement="top"
                    title="{{ element.ImageName }}"
                    data-toggle="modal"
                    data-target="#imageModal"
                    (click)="openElementImage(element.AttachmentURL, element.ElementName)"
                  />
                </td>
                <td *ngIf="!element.ImageName" class="width-20">-</td>
                <td class="verticalalign" (click)="getElementById(element.ElementId)">{{ element.ElementName }}</td>
                <td
                  class="verticalalign"
                  *ngIf="element.IsActive"
                  style="width: 5%"
                  (click)="getElementById(element.ElementId)"
                >
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td
                  class="verticalalign"
                  *ngIf="!element.IsActive"
                  style="width: 5%"
                  (click)="getElementById(element.ElementId)"
                >
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!elements">
    <form #ElementForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-3 col-md-3 col-sm-3">
          <div>
            <div class="form-group">
              <div class="imgBox">
                <button
                  class="btn-primary btn btn-xs button-text"
                  *ngIf="profileImgSrc"
                  id="templateReset"
                  type="button"
                  (click)="fileReset()"
                >
                  <i class="fa fa-times"></i> Remove
                </button>
                <label class="custom-file">
                  <input
                    type="file"
                    #fileInput
                    id="file"
                    name="Elementimage"
                    class="custom-file-input"
                    (change)="fileChange($event)"
                    style="display: none"
                    accept="image/*"
                  />
                  <span class="custom-file-control"></span>
                  <img id="preview" [src]="profileImgSrc != null ? profileImgSrc : profileImg" class="user-profile" />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-9 col-md-9 col-sm-9">
          <div>
            <div class="col-md-6 form-group">
              <label for="elementname"><span id="red-circle">&#x25CF;</span> Element Name</label>
              <div>
                <input
                  type="text"
                  class="form-control"
                  name="Elementname"
                  placeholder="Element Name"
                  [(ngModel)]="element.ElementName"
                  required
                  #ElementName="ngModel"
                  maxlength="500"
                  autocomplete="off"
                  [pattern]="utilitiesService.alphanumericRegex"
                />
                <div
                  *ngIf="ElementName.errors && (ElementName.dirty || ElementName.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!ElementName.errors.required" class="text-danger beacons">
                    Element name is required.
                  </div>
                  <div [hidden]="!ElementName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label for="sequence"> Sequence</label>
              <div>
                <input
                  type="number"
                  class="form-control"
                  name="Sequence"
                  placeholder="Sequence"
                  [(ngModel)]="element.Sequence"
                  #Sequence="ngModel"
                  min="1"
                  pattern="[0-9]+"
                  autocomplete="off"
                  (keypress)="utilitiesService.keyPress($event)"
                />
                <div *ngIf="Sequence.errors && (Sequence.dirty || Sequence.touched)" class="alert alert-danger">
                  <div [hidden]="!Sequence.errors.pattern" class="text-danger">Sequence accept only numeric value.</div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group" *ngIf="element.ElementId">
                <div>
                  <div>
                    <mat-checkbox [(ngModel)]="element.IsActive" name="isActive">Is Active</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 btn-section1">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type1 pull-right btn-cancel"
              style="margin: 0"
              (click)="clearFormData(ElementForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!element.ElementId"
              class="btn btn-type btn-save"
              [disabled]="!ElementForm.form.valid || isReqComplete"
              (click)="addElement(ElementForm)"
            />
            <input
              type="button"
              value="Delete"
              *ngIf="element.ElementId"
              class="btn btn-type pull-right btn-delete"
              [disabled]="!ElementForm.form.valid || isReqComplete"
              (click)="openElementModal()"
            />
            <input
              type="button"
              value="Save"
              *ngIf="element.ElementId"
              class="btn btn-type btn-save"
              [disabled]="!ElementForm.form.valid || isReqComplete"
              (click)="updateElement(ElementForm)"
            />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="imageModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black; margin-top: -10px">
            &times;
          </button>
          <h4>{{ elementName }}</h4>
          <img class="img-responsive" src="{{ elementImg }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal" id="elementModal" [ngStyle]="elementModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title">Element</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeElementModal()"
          style="margin-top: -27px"
        >
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>This action cannot be undone. Do you really want to delete "{{ element.ElementName }}"?</h4>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <button class="btn btn-type btn-save" [disabled]="isReqComplete" (click)="deleteElement()">Yes</button>
          <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeElementModal()">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
