import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { WorkorderModule } from '../../workorder/workorder.module';
import { QCMaster, QCTransaction, WorkorderInspection, WorkorderTaskComment } from '../qcworkorder.module';

@Injectable({
  providedIn: 'root',
})
export class QCWorkOrderService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetAllQCWOrkorders = (body: WorkorderModule, page: number, size: number): Observable<QCMaster[]> => {
    const _url: string = this.configuration.Server + 'workorder/venue/qc/list/' + page + '/' + size;
    return this.http.post<QCMaster[]>(_url, body);
  };

  public GetAllMaintenanceWorkorders = (
    body: VenueModule,
    page: number,
    size: number,
  ): Observable<WorkorderModule[]> => {
    const _url: string =
      this.configuration.Server + 'workorder/venue/maintenance/workorderorder/qc/' + page + '/' + size;
    return this.http.post<WorkorderModule[]>(_url, body);
  };

  public GetQCWorkorderById = (body: QCMaster): Observable<QCMaster> => {
    // WorkorderModule
    // const _url: string = this.configuration.Server + 'workorder/' + workorderId;
    const _url: string = this.configuration.Server + 'workorder/qc/details';
    return this.http.post<QCMaster>(_url, body);
  };

  public GetMaintenanceTaskByWOId = (body: WorkorderModule): Observable<WorkorderTaskComment> => {
    const _url: string = this.configuration.Server + 'workorder/asset/inspections';
    return this.http.post<WorkorderTaskComment>(_url, body);
  };

  public GetQCTaskWOById = (body: WorkorderModule): Observable<WorkorderTaskComment> => {
    const _url: string = this.configuration.Server + 'workorder/task/inspection';
    return this.http.post<WorkorderTaskComment>(_url, body);
  };

  public saveQCInput = (body: WorkorderInspection): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/inspection/list';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public QCAttachmentFile(filedata: any[], userId: any, qcId: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + 'workorder/qc/images';

      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      xhr.setRequestHeader('addedby', userId);
      xhr.setRequestHeader('qcId', qcId);

      const formData = new FormData();

      for (let i = 0; i < filedata.length; i++) {
        // formData.append("fileUpload", filedata[i]);
        formData.append('file' + i.toString(), filedata[i], filedata[i].name);
        // console.log(formData);
      }
      xhr.send(formData);
    });
  }

  public actionOnCleaningWOQC = (body: QCTransaction): Observable<QCTransaction> => {
    const _url: string = this.configuration.Server + 'workorder/qc/transaction';
    return this.http.post<QCTransaction>(_url, body);
  };

  public CleaningAlertAcceptRejectQC = (body: QCMaster): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/qc/details';
    return this.http.put<ResponseMessage>(_url, body);
  };
}
