export class GenericEntity {
  public AddedBy: number;
  public AddedOn: any;

  public ModifiedBy: number;
  public ModifiedOn: any;

  public Count: number;
  public IsActive: any;
  public Ids: string;

  public SearchQuery: string;
  public GenericSearch: number;
  public SortColumn: string;
  public SortOrder: string;

  public Page: any;
  public Size: any;

  public ContextVenueId: number;

  public UserId: number;
  public UserCPId: string;
  public UserName: string;
  public FirstName: string;
  public LastName: string;
  public Email: string;

  public ServiceProviderId: number;
  public ServiceProviderCPId: string;
  public ServiceProviderName: string;
  public ContextServiceProviderId: number;

  public CorporationId: number;
  public CorporationCPId: string;
  public CorporationName: string;
  public ContextCorporationId: number;

  public VenueId: number;
  public VenueCPId: string;
  public VenueName: string;

  public LocationId: number;
  public LocationCPId: string;
  public LocationName: string;

  public WorkorderId: number;
  public WorkorderCPId: string;

  public CustomField1: string;
  public CustomField2: string;
  public CustomField3: string;
  public CustomField4: string;

  public IsServiceProviderAllowToShare: number;

  public BuildingIds: string;
  public ZoneIds: string;
  public ApplicationType: string;
}

export class ResponseMessage {
  public message: string;
  public Message: string;
  public msg: number;
  public id: number;
}
