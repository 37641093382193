import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { ServiceProviderModule } from '../../../serviceprovider/serviceprovider.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { UserRoleModule } from '../../role/role.module';
import { UserRoleTemplateModule } from '../userroletemplate.module';

@Injectable({
  providedIn: 'root',
})
export class UserRoleTemplateService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (addUserRole: UserRoleTemplateModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user/role/permission/template';
    return this.http.post<ResponseMessage>(_url, addUserRole);
  };

  public Update = (updateUserRrole: UserRoleTemplateModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user/role/permission/template';
    return this.http.put<ResponseMessage>(_url, updateUserRrole);
  };

  public GetUserroleList = (body: string, page: number, size: number): Observable<UserRoleTemplateModule[]> => {
    const _url: string = this.configuration.Server + 'admin/all/user/roles/templates/' + page + '/' + size;
    return this.http.post<UserRoleTemplateModule[]>(_url, body);
  };

  public GetSingle = (body: UserRoleTemplateModule): Observable<UserRoleTemplateModule> => {
    const _url: string = this.configuration.Server + 'admin/user/role/permission/template/details';
    return this.http.post<UserRoleTemplateModule>(_url, body);
  };

  public GetServiceProviderListForDropdown = (body: UserRoleModule): Observable<ServiceProviderModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/role/serviceproviders';
    return this.http.post<ServiceProviderModule[]>(_url, body);
  };

  public GetVenueDropdown = (body: UserRoleTemplateModule): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/role/venues';
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetRolePermissions = (
    body: UserRoleTemplateModule,
    page: number,
    size: number,
  ): Observable<UserRoleTemplateModule> => {
    const _url: string = this.configuration.Server + 'admin/user/role/permissions/' + page + '/' + size;
    return this.http.post<UserRoleTemplateModule>(_url, body);
  };
}
