import { Injectable } from '@angular/core';
import { LocationElementModule } from 'src/app/features/staffscheduling/location/location.module';

@Injectable({
  providedIn: 'root',
})
export class LocationSetupService {
  public RebalanceElements(selectedElements: LocationElementModule[]): boolean {
    if (selectedElements) {
      const avgWeight = Math.floor(100 / selectedElements.length);
      let discrepency = 100 - selectedElements.length * avgWeight;
      selectedElements.forEach((e) => {
        e.ElementWeightage = avgWeight;
        if (discrepency >= 1) {
          e.ElementWeightage++;
          discrepency--;
        }
      });
      return true;
    }
    return false;
  }

  public totalWeightage(selectedElements: LocationElementModule[]): number {
    return selectedElements
      .filter((e) => e.IsActive)
      .map((e) => e.ElementWeightage)
      .reduce((p, n) => p + n);
  }
}
