<section class="content col-xs-12">
  <div class="row spacetop" *ngIf="formList">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg-4 col-md-12 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i><span class="list-heading">Levels</span>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 pull-right search-box">
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 input-group pull-right add-section">
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Level
          </button>
        </div>
        <div class="col-md-6 col-lg-3 col-sm-6 col-xs-6 pull-right list-heading">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-8 input-group pull-right p-search">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="levelsSearch(searchString, 1, 10, viewType)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <hr class="hr-m" />

    <div class="col-lg-12 col-md-12 col-sm-12">
      <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
        <h3 class="listerror">Sorry no data found!</h3>
      </div>
      <div class="box-body table-responsive">
        <table class="table table-responsive table-hover box1" *ngIf="hasData()">
          <thead>
            <tr>
              <th class="table-sort-header" (click)="sortColumn('LevelName', columnSortOrder)">
                Level Name
                <span *ngIf="selectedColumnName == 'LevelName' && columnSortOrder == 'DESC'"
                  ><i class="fa fa-sort-up"></i></span
                ><span *ngIf="selectedColumnName == 'LevelName' && columnSortOrder == 'ASC'"
                  ><i class="fa fa-sort-down"></i
                ></span>
              </th>
              <th class="table-sort-header" (click)="sortColumn('Ordinal', columnSortOrder)">
                Ordinal
                <span *ngIf="selectedColumnName == 'ordinal' && columnSortOrder == 'DESC'"
                  ><i class="fa fa-sort-up"></i></span
                ><span *ngIf="selectedColumnName == 'ordinal' && columnSortOrder == 'ASC'"
                  ><i class="fa fa-sort-down"></i
                ></span>
              </th>
              <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                Venue Name
                <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                  ><i class="fa fa-sort-up"></i></span
                ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                  ><i class="fa fa-sort-down"></i
                ></span>
              </th>
              <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
            </tr>
          </thead>
          <tbody
            *ngFor="
              let level of levels
                | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
            "
          >
            <tr (click)="getLevelById(level.LevelId)">
              <td>{{ level.LevelName }}</td>
              <td>{{ level.Ordinal }}</td>
              <td>{{ level.VenueName }}</td>
              <td *ngIf="level.IsActive">
                <span
                  ><i class="fa fa-check-square-o" data-toggle="tooltip" data-placement="top" title="Active"></i
                ></span>
              </td>
              <td *ngIf="!level.IsActive">
                <span
                  ><i class="fa fa-window-close-o" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && Isdataloaded && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row spacetop mobile-view iespace" *ngIf="!formList">
    <form #levelForm="ngForm" class="form-horizontal">
      <i class="fa fa-building heading-icon" *ngIf="isNewState"></i
      ><span class="col-lg-12 col-md-12 col-sm-12 list-heading1" *ngIf="isNewState">Add Level</span>
      <i class="fa fa-building heading-icon" *ngIf="!isNewState"></i
      ><span class="col-lg-12 col-md-12 col-sm-12 list-heading1" *ngIf="!isNewState">Edit Level</span>
      <hr />
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <label class="col-lg-12 col-md-12 col-sm-12" for="RoleName"
                ><span id="red-circle">&#x25CF;</span> Level Name</label
              >
              <div class="col-lg-12 col-md-12 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  name="levelName"
                  placeholder="Level Name"
                  [(ngModel)]="level.LevelName"
                  required
                  [pattern]="utilitiesService.alphanumericRegex"
                  #levelName="ngModel"
                  autocomplete="off"
                />
                <div *ngIf="levelName.errors && (levelName.dirty || levelName.touched)" class="alert alert-danger">
                  <div [hidden]="!levelName.errors.required" class="text-danger">Level name is required</div>
                  <!--<div [hidden]="!levelName.errors.pattern" class="text-danger">
                                        Level name accepts only alphanumaric & single space between words
                                    </div>-->
                  <div [hidden]="!levelName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <label class="col-lg-12 col-md-12 col-sm-12" for="levelordinal"
                ><span id="red-circle">&#x25CF;</span> Level Ordinal</label
              >
              <div class="col-lg-12 col-md-12 col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  name="levelordinal"
                  placeholder="Level Ordinal"
                  [(ngModel)]="level.Ordinal"
                  required
                  #levelordinal="ngModel"
                  autocomplete="off"
                  pattern="(\-)?([0-8])"
                />
                <div
                  *ngIf="levelordinal.errors && (levelordinal.dirty || levelordinal.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!levelordinal.errors.required" class="text-danger">Level Ordinal is required</div>
                  <div [hidden]="!levelordinal.errors.pattern" class="text-danger">
                    Level Ordinal accepts only single digit between -8 and 8
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <label class="col-lg-12 col-md-12 col-sm-12" for="RoleName"
                ><span id="red-circle">&#x25CF;</span> Venue</label
              >
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ngx-atlas-select
                  (model)="level.Venue.VenueId = $event"
                  [list]="venues"
                  [idField]="'VenueId'"
                  [textField]="'VenueName'"
                  [placeholder]="'Select Venue'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="level.Venue.VenueId"
                >
                </ngx-atlas-select>
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <label class="col-lg-12 col-md-12 col-sm-12"> Corporation Name</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                {{ corporationNameForm }}
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
              <div class="col-lg-12 col-md-12 col-sm-12 form-group" *ngIf="!isNewState">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <mat-checkbox [(ngModel)]="level.IsActive" name="isActive">Is Active</mat-checkbox>
                </div>
              </div>
            </div>

            <div class="form-group col-lg-12 col-md-12 col-sm-12 btn-section1">
              <input
                type="button"
                value="Cancel"
                class="btn btn-type pull-right btn-cancel"
                (click)="clearFormData(levelForm)"
              />
              <input
                type="button"
                value="Save"
                *ngIf="isNewState"
                class="btn btn-type btn-save2"
                [disabled]="!levelForm.form.valid || isReqComplete || !level.Venue.VenueId"
                (click)="addLevel(levelForm)"
              />
              <input
                type="button"
                value="Save"
                *ngIf="!isNewState"
                class="btn btn-type btn-save2"
                [disabled]="!levelForm.form.valid || isReqComplete || !level.Venue.VenueId"
                (click)="updateLevel(levelForm)"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
