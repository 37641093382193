<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-5 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="Corporations">CORPORATIONS</span>
        <span class="list-heading" *ngIf="!Corporations && !Corporation.CorporationId">ADD CORPORATION</span>
        <span class="list-heading" *ngIf="!Corporations && Corporation.CorporationId">EDIT CORPORATION</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="Corporations">
          <ngx-atlas-select
            (model)="CorporationPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-3 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="Corporations && !Corporation.CorporationId && ('CORPORATION_ADD' | canAccess)"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Corporation
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="Corporations">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="Corporations">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="corporationSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="Corporations">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive">
          <table class="table table-hover table-responsive" *ngIf="hasData()">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('CorporationName', columnSortOrder)">
                  Corporation Name
                  <span *ngIf="selectedColumnName == 'CorporationName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'CorporationName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('ServiceProviderName', columnSortOrder)">
                  Service Provider
                  <span *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ServiceProviderName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('StateName', columnSortOrder)">
                  State
                  <span *ngIf="selectedColumnName == 'StateName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'StateName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('CityName', columnSortOrder)">
                  City
                  <span *ngIf="selectedColumnName == 'CityName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'CityName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let Corporation of Corporations
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getCorporationById(Corporation.CorporationId)">
                <td>{{ Corporation.CorporationName }}</td>
                <td>{{ Corporation.ServiceProviderName }}</td>
                <td>{{ Corporation.State.StateName }}</td>
                <td>{{ Corporation.City.CityName }}</td>
                <td *ngIf="Corporation.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!Corporation.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!Corporations">
    <form #CorporationForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-md-12 form-section">
          <div class="col-lg-3 col-md-3 col-sm-3">
            <div>
              <div class="form-group imgBoxHeight">
                <div class="imgBox" style="background: none">
                  <button
                    class="btn-primary btn btn-xs button-text"
                    *ngIf="corporationLogoSrc && isSysAdmin"
                    id="applicationLogoReset"
                    type="button"
                    (click)="fileReset()"
                  >
                    <i class="fa fa-times"></i> Remove
                  </button>
                  <label class="custom-file">
                    <input
                      type="file"
                      #fileInput
                      id="file"
                      name="corporationLogo"
                      class="custom-file-input"
                      (change)="fileChange($event)"
                      style="display: none"
                      accept="image/*"
                      *ngIf="isSysAdmin"
                    />
                    <span class="custom-file-control"></span>
                    <img
                      id="preview"
                      [src]="corporationLogoSrc != null ? corporationLogoSrc : corporationDefaultLogo"
                      class="application-logo"
                      [ngStyle]="{ cursor: isSysAdmin ? 'pointer' : 'default' }"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-9 col-md-9 col-sm-9">
            <div class="col-md-12 form-group">
              <label for="corporationName"><span id="red-circle">&#x25CF;</span> Corporation Name</label>
              <div>
                <input
                  id="corporationName"
                  name="corporationname"
                  #corporationName="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Corporation Name"
                  [(ngModel)]="Corporation.CorporationName"
                  required
                  maxlength="50"
                  autocomplete="off"
                  [pattern]="utilitiesService.alphanumericRegex"
                />
                <div
                  *ngIf="corporationName.errors && (corporationName.dirty || corporationName.touched)"
                  class="alert alert-danger desc-alert"
                >
                  <div [hidden]="!corporationName.errors.required" class="text-danger">
                    Corporation name is required.
                  </div>
                  <div [hidden]="!corporationName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 form-group">
              <label for="serviceProvider"><span id="red-circle">&#x25CF;</span> Service Provider Name</label>
              <div>
                <ngx-atlas-select
                  (model)="changeServiceProvider($event)"
                  [list]="serviceProviders"
                  [idField]="'ServiceProviderId'"
                  [textField]="'ServiceProviderName'"
                  [placeholder]="'Select Service Provider'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="Corporation.ServiceProvider.ServiceProviderId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 form-group">
          <div>
            <label for="description">Description</label>
            <div>
              <textarea
                id="description"
                name="description"
                #description="ngModel"
                type="text"
                class="form-control"
                placeholder="Description"
                [(ngModel)]="Corporation.Description"
                autocomplete="off"
                maxlength="500"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="firstname">Contact First Name</label>
            <div>
              <input
                type="text"
                autocomplete="invalid"
                class="form-control"
                name="Firstname"
                placeholder="Contact First Name"
                [(ngModel)]="Corporation.FirstName"
                #FirstName="ngModel"
                maxlength="49"
              />
              <div *ngIf="FirstName.errors && (FirstName.dirty || FirstName.touched)" class="alert alert-danger">
                <div [hidden]="!FirstName.errors.pattern" class="text-danger">
                  First name accepts only alphabets & single space between words.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="lastname">Contact Last Name</label>
            <div>
              <input
                type="text"
                autocomplete="invalid"
                class="form-control"
                name="Lastname"
                placeholder="Contact Last Name"
                [(ngModel)]="Corporation.LastName"
                #LastName="ngModel"
                maxlength="49"
              />
              <div *ngIf="LastName.errors && (LastName.dirty || LastName.touched)" class="alert alert-danger">
                <div [hidden]="!LastName.errors.pattern" class="text-danger">
                  Last name accepts only alphabets & single space between words.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="email">Contact Email</label>
            <div>
              <input
                type="email"
                autocomplete="invalid"
                class="form-control"
                name="Email"
                placeholder="Contact Email"
                [(ngModel)]="Corporation.Email"
                #Email="ngModel"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                maxlength="319"
              />
              <div *ngIf="Email.errors && (Email.dirty || Email.touched)" class="alert alert-danger">
                <div [hidden]="!Email.errors.pattern" class="text-danger">Contact email is invalid.</div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="contactNumber">Contact Number</label>
            <div>
              <input
                type="text"
                autocomplete="invalid"
                class="form-control"
                name="contactNumber"
                placeholder="Mobile No. (123) 456-7890"
                [(ngModel)]="Corporation.MobileNumber"
                #contactNumber="ngModel"
                minlength="10"
                mask="(000) 000-0000"
              />
              <div
                *ngIf="contactNumber.errors && (contactNumber.dirty || contactNumber.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!contactNumber.errors.minlength" class="text-danger">
                  Mobile number should be 10 digit.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="addressLine1">Address Line 1</label>
            <div>
              <input
                type="text"
                autocomplete="invalid"
                class="form-control"
                name="AddressLine1"
                placeholder="Address Line 1"
                [(ngModel)]="Corporation.AddressLineOne"
                #AddressLineOne="ngModel"
                maxlength="249"
              />
              <!--pattern="^\S+$"-->
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="addressLine2">Address Line 2</label>
            <div>
              <input
                type="text"
                class="form-control"
                name="AddressLine2"
                placeholder="Address Line 2"
                [(ngModel)]="Corporation.AddressLineTwo"
                #addressLineTwo="ngModel"
                maxlength="249"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="CountryName">Country</label>
            <div>
              <ngx-atlas-select
                (model)="countryChange($event)"
                [list]="countries"
                [idField]="'CountryId'"
                [textField]="'CountryName'"
                [placeholder]="'Select Country'"
                [allowClear]="true"
                [multiple]="false"
                [selectedValue]="Corporation.Country.CountryId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="StateName">State</label>
            <div>
              <ngx-atlas-select
                (model)="stateChange($event)"
                [list]="states"
                [idField]="'StateId'"
                [textField]="'StateName'"
                [placeholder]="'Select State'"
                [allowClear]="true"
                [multiple]="false"
                [disabled]="stateDisabled"
                [selectedValue]="Corporation.State.StateId"
              >
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="CityName">City</label>
            <div>
              <ngx-atlas-select
                (model)="Corporation.City.CityId = $event"
                [list]="cities"
                [idField]="'CityId'"
                [textField]="'CityName'"
                [placeholder]="'Select City'"
                [allowClear]="true"
                [multiple]="false"
                [disabled]="cityDisabled"
                [selectedValue]="Corporation.City.CityId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="Pincode">Zip Code</label>
            <div>
              <input
                type="text"
                autocomplete="invalid"
                class="form-control"
                name="Zipcode"
                placeholder="Zip Code"
                [(ngModel)]="Corporation.ZipCode"
                #Zipcode="ngModel"
                pattern="[0-9]*$"
                minlength="5"
                maxlength="5"
              />
              <div *ngIf="Zipcode.errors && (Zipcode.dirty || Zipcode.touched)" class="alert alert-danger">
                <div [hidden]="!Zipcode.errors.pattern" class="text-danger">Zip code accepts only numeric.</div>
                <div [hidden]="!Zipcode.errors.minlength || Zipcode.errors.pattern" class="text-danger">
                  Zip code should be 5 digit.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="RoleName"><span id="red-circle">&#x25CF;</span> Subscription Type</label>
            <div>
              <ngx-atlas-select
                (model)="Corporation.Subscription.SubscriptionId = $event"
                [list]="subscriptions"
                [idField]="'SubscriptionId'"
                [textField]="'SubscriptionType'"
                [placeholder]="'Select Subscription Type'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="Corporation.Subscription.SubscriptionId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="IsInspectionScoringBenchmarkShow">
            <label for="InspectionScoringBenchmark"
              ><span id="red-circle">&#x25CF;</span> Inspection Scoring Benchmark %</label
            >
            <div>
              <input
                type="text"
                autocomplete="invalid"
                class="form-control"
                name="InspectionScoringBenchmark"
                placeholder="Inspection Scoring Benchmark"
                [(ngModel)]="Corporation.InspectionScoringBenchmark"
                #InspectionScoringBenchmark="ngModel"
                minlength="0"
                maxlength="5"
                pattern="^(\d{0,2}(\.\d{1,2})?|100(\?)?)$"
                [disabled]="formFieldDisabled"
                required
              />
              <div
                *ngIf="
                  InspectionScoringBenchmark.errors &&
                  (InspectionScoringBenchmark.dirty || InspectionScoringBenchmark.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!InspectionScoringBenchmark.errors.required" class="text-danger">
                  Inspection scoring benchmark is required.
                </div>
                <div [hidden]="!InspectionScoringBenchmark.errors.pattern" class="text-danger">
                  Inspection scoring benchmark accepts only numeric and in between 0 to 100.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--<hr class="serviceProviderDivider">
                <h3>License Configuration</h3>

                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                        <label for="SalesForceNumber"> Salesforce Number</label>
                        <input id="SalesForceNumber" name="SalesForceNumber" #SalesForceNumber="ngModel" type="text" class="form-control" placeholder="Salesforce Number"
                               [(ngModel)]="Corporation.SalesForceNumber" autocomplete="off" pattern="^[a-zA-Z0-9]*$" maxlength="250" />
                        <div *ngIf="SalesForceNumber.errors && (SalesForceNumber.dirty || SalesForceNumber.touched)" class="alert alert-danger">
                            <div [hidden]="!SalesForceNumber.errors.pattern" class="text-danger">
                                Space not allowed in salesforce number.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                        <label for="LicenseUserCount"> Licensed User Count</label>
                        <input type="text" autocomplete="invalid" class="form-control" name="LicenseUserCount" placeholder="Licensed User Count"
                               [(ngModel)]="Corporation.LicenseUserCount" #LicenseUserCount="ngModel" pattern="^[1-9][0-9]*$" maxlength="7" />
                        <div *ngIf="LicenseUserCount.errors && (LicenseUserCount.dirty || LicenseUserCount.touched)" class="alert alert-danger">
                            <div [hidden]="!LicenseUserCount.errors.pattern" class="text-danger">
                                Licensed user count accepts only numeric and in between 1 to 100.
                            </div>
                        </div>
                    </div>
                </div>-->

        <hr class="serviceProviderDivider" />
        <h3>Module Assignment</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group accessPermissns">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <div class="d-inline-block form-group">
                <div *ngIf="isCheckBoxVisible" style="margin-left: -15px">
                  <mat-checkbox
                    class="form-check-input"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="isAllFeatureConfigurationChecked"
                    (change)="onAllCheckedChange($event)"
                    >All</mat-checkbox
                  >
                </div>
                <ngx-treeview
                  #treeviewComponent
                  [items]="items"
                  [config]="config"
                  [itemTemplate]="itemTemplate"
                  (selectedChange)="onSelectedChange($event)"
                >
                </ngx-treeview>
              </div>
            </div>
          </div>
        </div>

        <hr class="serviceProviderDivider" />
        <h3>Publisher Integration Key</h3>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <div
            class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
            *ngIf="
              Corporation != null &&
              Corporation.CorporationIntegrationKey != null &&
              Corporation.CorporationIntegrationKey.length > 0 &&
              isPublisherKeySectionShow &&
              ('CORPORATION_VIEW_INTEGRATION_KEY' | canAccess)
            "
            style="margin-bottom: 15px; max-height: 300px"
          >
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Key</th>
                  <th style="text-align: center">Is Active</th>
                  <th style="text-align: center" *ngIf="'CORPORATION_DELETE_INTEGRATION_KEY' | canAccess">Remove</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let integrationKey of Corporation.CorporationIntegrationKey">
                  <tr *ngIf="integrationKey.IsDelete == 0">
                    <td class="srno form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Integration Key Name"
                        [(ngModel)]="integrationKey.IntegrationKeyName"
                        id="IntegrationKeyName_{{ integrationKey.IntegrationKeyRowId }}"
                        [ngModelOptions]="{ standalone: true }"
                        [readonly]="integrationKey.isAllowToEdit"
                      />
                    </td>
                    <td class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Integration Key Value"
                        [(ngModel)]="integrationKey.IntegrationKeyValue"
                        id="IntegrationKeyValue_{{ integrationKey.IntegrationKeyRowId }}"
                        [ngModelOptions]="{ standalone: true }"
                        readonly
                      />
                    </td>
                    <td style="text-align: center; padding-top: 15px">
                      <mat-checkbox
                        [(ngModel)]="integrationKey.IsActive"
                        id="IntegrationKeyIsActive_{{ integrationKey.IntegrationKeyRowId }}"
                        [ngModelOptions]="{ standalone: true }"
                      ></mat-checkbox>
                    </td>
                    <td style="text-align: center" *ngIf="'CORPORATION_DELETE_INTEGRATION_KEY' | canAccess">
                      <button class="btn btn-purple" (click)="removeIntegrationKey(integrationKey.IntegrationKeyRowId)">
                        <i class="fa fa-times"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div
            class="col-lg-12 col-md-12 col-sm-12 form-group"
            *ngIf="('CORPORATION_ADD_INTEGRATION_KEY' | canAccess) && ('CORPORATION_VIEW_INTEGRATION_KEY' | canAccess)"
          >
            <input
              type="button"
              value="Generate New Key"
              class="btn btn-type pull-right btn-cancel"
              (click)="GenerateIntegrationKey()"
            />
          </div>
        </div>

        <hr class="serviceProviderDivider" />

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="'VENUE_ADD' | canAccess">
            <div class="form-group">
              <div>
                <div>
                  <mat-checkbox [(ngModel)]="Corporation.IsVenue" name="isVenue">Set As Venue</mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="Corporation.CorporationId">
            <div class="form-group">
              <div>
                <mat-checkbox [(ngModel)]="Corporation.IsActive" name="isActive">Is Active</mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(CorporationForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!Corporation.CorporationId"
            class="btn btn-type btn-save"
            [disabled]="
              !CorporationForm.form.valid ||
              isReqComplete ||
              !Corporation.Subscription.SubscriptionId ||
              !Corporation.ServiceProvider.ServiceProviderId
            "
            (click)="addCorporation(CorporationForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="Corporation.CorporationId"
            class="btn btn-type btn-save"
            [disabled]="
              !CorporationForm.form.valid ||
              isReqComplete ||
              !Corporation.Subscription.SubscriptionId ||
              !Corporation.ServiceProvider.ServiceProviderId ||
              !('CORPORATION_EDIT' | canAccess)
            "
            (click)="updateCorporation(CorporationForm)"
          />
        </div>
      </div>
    </form>
  </div>
</section>

<ng-template
  #itemTemplate
  let-item="item"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
>
  <div class="form-inline row-item" style="display: inline-flex">
    <i
      *ngIf="item.children"
      (click)="onCollapseExpand()"
      aria-hidden="true"
      class="fa"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed"
    ></i>
    <div class="form-check">
      <mat-checkbox
        class="form-check-input"
        [(ngModel)]="item.checked"
        (change)="onCheckedChange()"
        [disabled]="item.disabled"
        [indeterminate]="item.indeterminate"
      >
        {{ item.text }}</mat-checkbox
      >
    </div>
  </div>
</ng-template>
