import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { UserModule } from '../../usermanagement/user/user.module';
import { SensorLite, SensorTransactionModule } from '../sensor/sensor.module';
import { SensorTypeComponent } from './components/sensortype.component';
import { SensorTypeRoutes } from './sensortype.routes';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, SensorTypeRoutes, HomeModule],

  declarations: [SensorTypeComponent],
})
export class SensorTypeModule extends GenericEntity {
  public SensorTypeId: number;
  public SensorTypeCPId: string;
  public SensorTypeName: string;
  public SensorTypeCode: string;
  public SensorTypeImageType: any;
  public SensorTypeImageName: number;
  public User: UserModule;
  public Threshold: number;
  public VisitorCount: number;
  public LastVisit: any;
  public SensorColor: string;
  public SensorStatusColor: string;

  public HalfCountYellow: number;
  public EmptyOrFullCountRed: number;
  public EmptyOrFullCountGreen: number;

  public Sensors: SensorLite[];
  public SensorTransaction: SensorTransactionModule;

  public WarnPercentage: number;
  public FillingPercentage: number;

  public AmountUses: number;
  public WaterUsed: number;

  public TotalCapacity: number;
  public StockLevel: number;
  public Percentage: any;

  public SensorManufacturerName: string;
  public SensorManufacturerCode: string;
  public SensorStatusCount: any;
}
