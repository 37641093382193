import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { VenueElement, VenueInventory, VenueTask } from '../../venuemanagement/venue/venue.module';
import { LocationTypeComponent } from './components/locationtype.component';
import { LocationTypeRoutes } from './locationtype.routes';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, LocationTypeRoutes, HomeModule, SharedModule],
  declarations: [LocationTypeComponent],
})
export class LocationTypeModule extends GenericEntity {
  public VenueLocationTypeId: number;
  public VenueLocationTypeCPId: number;
  public VenueLocationTypeName: string;
  public VenueTasks: VenueTask[];
  public VenueInventories: VenueInventory[];
  public VenueElements: VenueElement[];
  public Areas: VenueArea[];
  public IsSelected: any;
  public IsImported: any;
}

export class ImportLocationTypeLog extends GenericEntity {
  public ImportLocationTypeLogId: number;
  public SourceVenueId: number;
  public DestinationVenueId: number;
  public ImportLocationTypeTransactionId: number;
  public ImportLocationTemplateLogId: number;
  public UniqueLogId: number;
  public IsImportComplete: number;
  public TransactionStatus: string;
  public TransactionMessage: string;
  public LocationTypes: LocationTypeModule[];
}

export class VenueArea extends GenericEntity {
  public AreaId: number;
  public AreaName: string;
  public Sequence: number;
  public AreaRowID: number;
  public IsMasterArea: number;
  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public VenueLocationTypeMaster: number;
}
