import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { User } from 'src/app/features/usermanagement/user/user.model';
import { VenueService } from 'src/app/features/venuemanagement/venue/components/venue.http.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { UserModule } from '../../../usermanagement/user/user.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { SensorManufacturerService } from '../../sensormanufacturer/components/sensormanufacturer.http.service';
import { SensorManufacturerModule } from '../../sensormanufacturer/sensormanufacturer.module';
import { SensorTypeService } from '../../sensortype/components/sensortype.http.service';
import { SensorTypeModule } from '../../sensortype/sensortype.module';
import { AirQualitySensorConfiguration, LocationSensor, SensorModule } from '../sensor.module';
import { SensorService } from './sensor.http.service';

@Component({
  selector: 'sensor.component',
  templateUrl: 'sensor.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [SensorService, UtilitiesService, VenueService, SensorTypeService, SensorManufacturerService],
})
export class SensorComponent implements OnInit {
  currentUser: User;

  public sensors: SensorModule[] = [];
  public sensor: SensorModule = new SensorModule();
  initialSensorForm: SensorModule;

  public venues: VenueModule[] = [];
  public sensorTypes: SensorTypeModule[] = [];
  public sensormanufactureres: SensorManufacturerModule[] = [];

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';

  selectedColumnName: string;
  sensorImg = 'assets/no-image.jpg';
  sensorImgSrc: any = null;
  isAirQuality = false;
  isHaltian = false;
  venueBind: number;
  public pageState: StateMaintain = {
    pageName: 'sensorForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  constructor(
    private authState: AuthState,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private sensorService: SensorService,
    private venueService: VenueService,
    private sensorManufacturerService: SensorManufacturerService,
    private sensorTypeService: SensorTypeService,
  ) {
    this.currentUser = this.authState.getCurrentUser();

    this.sensor = new SensorModule();
    this.sensor.SensorManufacturer = new SensorManufacturerModule();
    this.sensor.SensorType = new SensorTypeModule();
    this.sensor.Venue = new VenueModule();
    this.sensor.User = new UserModule();
    this.sensor.RelLocationSensor = new LocationSensor();
    this.sensor.AirQualitySensorConfiguration = new AirQualitySensorConfiguration();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;

    this.venueBind = this.authState.getStoredContextVenueId();
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
    }
    this.getAllSensors(this.searchString, this.page, this.size, this.viewType);
  }

  public setAddForm() {
    this.searchString = '';
    this.sensors = null;
    this.count = 0;

    this.sensor = new SensorModule();
    this.sensor.SensorManufacturer = new SensorManufacturerModule();
    this.sensor.SensorType = new SensorTypeModule();
    this.sensor.Venue = new VenueModule();
    this.sensor.User = new UserModule();
    this.sensor.RelLocationSensor = new LocationSensor();
    this.sensor.AirQualitySensorConfiguration = new AirQualitySensorConfiguration();

    this.getAllVenues('', -1, -1);
    this.getAllSensorTypes('', -1, -1);
    this.getAllSensorManufacture('', -1, -1);

    setTimeout(() => {
      this.updateFocus();
    }, 1000);
  }

  public setUpdateForm() {
    this.sensors = null;
    this.count = 0;
    this.searchString = '';

    this.getAllVenues('', -1, -1);
    this.getAllSensorTypes('', -1, -1);
    this.getAllSensorManufacture('', -1, -1);
  }

  /**
   * Method to reset the form and navigate to list/navigate to add new sensor screens
   */
  public clearFormData(SensorForm: NgForm, isAddNew = false) {
    SensorForm.reset();
    this.searchString = '';
    this.sensorImgSrc = null;
    this.isAirQuality = false;
    this.isHaltian = false;

    this.sensor = new SensorModule();
    this.sensor.SensorType = new SensorTypeModule();
    this.sensor.Venue = new VenueModule();
    this.sensor.User = new UserModule();
    this.sensor.RelLocationSensor = new LocationSensor();
    this.sensor.AirQualitySensorConfiguration = new AirQualitySensorConfiguration();
    this.sensor.SensorManufacturer = new SensorManufacturerModule();

    if (!isAddNew) {
      this.getAllSensors(this.searchString, this.page, this.size, this.viewType);
    } else {
      setTimeout(() => {
        this.sensor = cloneDeep(this.initialSensorForm);
        this.updateSensorFormOnAddNew();
        this.updateFocus();
      });
    }
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllSensors(this.searchString, this.page, this.size, this.viewType);
  }

  public sensorsSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensors(searchString, 1, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.sensors != null && this.sensors.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensors(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public SensorPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensors(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensors(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllSensors(this.searchString, this.page, this.size, this.viewType);
  }

  public getAllSensors(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);

    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };
    this.sensorService.GetSensorList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.sensors = data;
        if (this.sensors.length < 0 && this.sensors[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'sensorService getAllSensors Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.sensors[0] ? this.sensors[0].Count : 0),
    );
  }

  public getSensorById(SensorId: number, SensorTypeCode: string) {
    if (this.authState.canAccess('SENSORS_VIEW')) {
      this.loaderService.display(true);
      if (SensorTypeCode == 'AIR_QUALITY_DETECTION') {
        const CPsensor = new SensorModule();
        CPsensor.SensorCPId = this.authState.AESEncryptText(SensorId);

        this.sensorService.GetAirQualitySingle(CPsensor).subscribe(
          (data) => {
            this.sensor = data;
            if (this.sensor) {
              if (
                this.checkForAirQualityDetection(
                  this.sensor.SensorType?.SensorTypeCode,
                  this.sensor.SensorManufacturer?.SensorManufacturerCode,
                )
              ) {
                this.isAirQuality = true;
              } else if (
                this.checkForHaltian(
                  this.sensor.SensorType?.SensorTypeCode,
                  this.sensor.SensorManufacturer?.SensorManufacturerCode,
                )
              ) {
                this.isHaltian = true;
              }

              if (this.sensor.RelLocationSensor) {
                this.sensor.WarnPercentage = this.sensor.RelLocationSensor.WarnPercentage;
                this.sensor.Threshold = this.sensor.RelLocationSensor.Threshold;
              }
            }
            this.loaderService.display(false);
          },
          (error) => {
            console.log(
              'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
            );
            this.loaderService.display(false);
          },
          () => this.setUpdateForm(),
        );
      } else {
        const CPsensor = new SensorModule();
        CPsensor.SensorCPId = this.authState.AESEncryptText(SensorId);

        this.sensorService.GetSingle(CPsensor).subscribe(
          (data) => {
            this.sensor = data;
            if (this.sensor) {
              if (
                this.checkForAirQualityDetection(
                  this.sensor.SensorType?.SensorTypeCode,
                  this.sensor.SensorManufacturer?.SensorManufacturerCode,
                )
              ) {
                this.isAirQuality = true;
              } else if (
                this.checkForHaltian(
                  this.sensor.SensorType?.SensorTypeCode,
                  this.sensor.SensorManufacturer?.SensorManufacturerCode,
                )
              ) {
                this.isHaltian = true;
              }

              if (this.sensor.RelLocationSensor) {
                this.sensor.WarnPercentage = this.sensor.RelLocationSensor.WarnPercentage;
                this.sensor.Threshold = this.sensor.RelLocationSensor.Threshold;
              }
            }
            this.loaderService.display(false);
          },
          (error) => {
            console.log(
              'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
            );
            this.loaderService.display(false);
          },
          () => this.setUpdateForm(),
        );
      }
    } else {
      this.utilitiesService.smmodal('Sensor', 'You do not have permission to view sensor details.');
    }
  }

  /**
   * Method to add a new sensor
   */
  public addSensor(_SensorForm: NgForm, isAddNew = false) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (_SensorForm.status == 'INVALID' && _SensorForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Sensor', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.sensor.SensorName = this.utilitiesService.removeInnerSpaces(this.sensor.SensorName);
    if (this.sensor.SensorName == null || this.sensor.SensorName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.sensor.SensorIdentifier = this.utilitiesService.removeInnerSpaces(this.sensor.SensorIdentifier);
    if (this.sensor.SensorIdentifier == null || this.sensor.SensorIdentifier == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.sensor.InternalSensorId = this.utilitiesService.removeInnerSpaces(this.sensor.InternalSensorId);
    const firstChar = this.sensor.InternalSensorId.charAt(0);
    if (firstChar == ':' || firstChar == '-' || firstChar == '_') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      this.utilitiesService.smmodal(
        'Sensor',
        'Internal Sensor Id should not begin with underscore(_) , hyphen(-) or colon(:).',
      );
      return;
    }

    this.sensor.SensorRule = this.sensor.SensorRule;
    this.sensor.Threshold = this.sensor.Threshold;
    this.sensor.WarnPercentage = this.sensor.WarnPercentage;
    this.sensor.SensorType.SensorTypeId = this.sensor.SensorType.SensorTypeId;

    if (this.sensor.SensorType.SensorTypeId) {
      const sensorType = this.sensorTypes.filter((st) => st.SensorTypeId == this.sensor.SensorType.SensorTypeId);
      const sensorM = this.sensormanufactureres.filter(
        (st) => st.SensorManufacturerId == this.sensor.SensorManufacturer.SensorManufacturerId,
      );
      this.sensor.SensorType.SensorTypeCode = sensorType[0].SensorTypeCode;
      this.sensor.SensorManufacturer.SensorManufacturerCode = sensorM[0].SensorManufacturerCode;

      if (this.checkForAirQualityDetection(sensorType[0]?.SensorTypeCode, sensorM[0]?.SensorManufacturerCode)) {
        this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureFId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureFId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureCId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureCId;

        this.sensor.AirQualitySensorConfiguration.SensorAirQualityId =
          this.sensor.AirQualitySensorConfiguration.SensorAirQualityId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorHumidityId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorHumidityId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorCO2Id =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorCO2Id;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorCH2OId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorCH2OId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorPressureId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorPressureId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorStatusId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorStatusId;
      }
    }

    this.sensor.Venue.VenueId = this.sensor.Venue.VenueId;
    this.sensor.AddedBy = this.currentUser.UserId;
    if (isAddNew) {
      this.initialSensorForm = cloneDeep(this.sensor);
      this.initialSensorForm.SensorName = '';
      this.initialSensorForm.InternalSensorId = '';
      this.initialSensorForm.SensorIdentifier = '';
      this.updateSensorFormOnAddNew();
    }
    this.sensorService.Add(this.utilitiesService.stripScript(this.sensor)).subscribe(
      (data) => this.onSuccessfulSensorAddition(_SensorForm, data, isAddNew),
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('sensorService addSensor Call Failed. Status:' + error.status + 'Status Text' + error.statusText);
      },
    );
  }

  public updateSensor(SensorForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (SensorForm.status == 'INVALID' && SensorForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Sensor', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.sensor.SensorName = this.utilitiesService.removeInnerSpaces(this.sensor.SensorName);
    if (this.sensor.SensorName == null || this.sensor.SensorName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.sensor.SensorIdentifier = this.utilitiesService.removeInnerSpaces(this.sensor.SensorIdentifier);
    if (this.sensor.SensorIdentifier == null || this.sensor.SensorIdentifier == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }
    this.sensor.InternalSensorId = this.utilitiesService.removeInnerSpaces(this.sensor.InternalSensorId);
    const firstChar = this.sensor.InternalSensorId.charAt(0);
    if (firstChar == ':' || firstChar == '-' || firstChar == '_') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      this.utilitiesService.smmodal(
        'Sensor',
        'Internal Sensor Id should not begin with underscore(_) , hyphen(-) or colon(:).',
      );
      return;
    }
    this.sensor.SensorRule = this.sensor.SensorRule;
    this.sensor.Threshold = this.sensor.Threshold;
    this.sensor.WarnPercentage = this.sensor.WarnPercentage;
    this.sensor.SensorType.SensorTypeId = this.sensor.SensorType.SensorTypeId;

    if (this.sensor.SensorType.SensorTypeId) {
      const sensorType = this.sensorTypes.filter((st) => st.SensorTypeId == this.sensor.SensorType.SensorTypeId);
      const sensorM = this.sensormanufactureres.filter(
        (st) => st.SensorManufacturerId == this.sensor.SensorManufacturer.SensorManufacturerId,
      );
      this.sensor.SensorType.SensorTypeCode = sensorType[0].SensorTypeCode;
      this.sensor.SensorManufacturer.SensorManufacturerCode = sensorM[0].SensorManufacturerCode;

      if (this.checkForAirQualityDetection(sensorType[0]?.SensorTypeCode, sensorM[0]?.SensorManufacturerCode)) {
        this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureFId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureFId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureCId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureCId;

        this.sensor.AirQualitySensorConfiguration.SensorAirQualityId =
          this.sensor.AirQualitySensorConfiguration.SensorAirQualityId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorHumidityId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorHumidityId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorCO2Id =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorCO2Id;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorCH2OId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorCH2OId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorPressureId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorPressureId;

        this.sensor.AirQualitySensorConfiguration.AirQualitySensorStatusId =
          this.sensor.AirQualitySensorConfiguration.AirQualitySensorStatusId;
      } else {
        this.sensor.AirQualitySensorConfiguration = null;
      }
    }

    this.sensor.Venue.VenueId = this.sensor.Venue.VenueId;

    this.sensor.IsActive = this.sensor.IsActive ? 1 : 0;
    this.sensor.ModifiedBy = this.currentUser.UserId;

    this.sensorService.Update(this.utilitiesService.stripScript(this.sensor)).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.clearFormData(SensorForm);
        this.utilitiesService.smmodal('Sensor', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'sensorService updateSensor Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;
        if (!this.sensors && !this.sensor.SensorId) {
          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj && obj[0]) {
            this.sensor.Venue.VenueId = obj[0].VenueId;
          }
        }
      },
      (error) => {
        console.log(
          'venueService GetVenueDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllSensorTypes(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.sensorTypeService.GetSensorTypesDropDown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.sensorTypes = data;
      },
      (error) => {
        console.log(
          'sensorTypeService GetAllSensorTypes Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public getAllSensorManufacture(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };
    this.sensorManufacturerService
      .GetSensorManufacturerDropdown(JSON.stringify(searchfilter), pageIndex, pageSize)
      .subscribe(
        (data) => {
          this.sensormanufactureres = data;
        },
        (error) => {
          console.log(
            'assetTypeService GetAssetTypeDropdown Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
  }

  public sensorManufactureChange(event: any) {
    this.sensor.SensorManufacturer.SensorManufacturerId = event;
    this.isAirQuality = false;
    this.isHaltian = false;

    if (this.sensor.SensorManufacturer.SensorManufacturerId) {
      const SensorType = this.sensorTypes.filter((st) => st.SensorTypeId == this.sensor.SensorType.SensorTypeId);
      const SensorM = this.sensormanufactureres.filter(
        (st) => st.SensorManufacturerId == this.sensor.SensorManufacturer.SensorManufacturerId,
      );

      if (this.checkForAirQualityDetection(SensorType[0]?.SensorTypeCode, SensorM[0]?.SensorManufacturerCode)) {
        this.isAirQuality = true;
      } else if (this.checkForHaltian(SensorType[0]?.SensorTypeCode, SensorM[0]?.SensorManufacturerCode)) {
        this.isHaltian = true;
      }
    } else {
      this.isAirQuality = false;
      this.isHaltian = false;

      this.clearAirthinxSensorDetails();
    }
  }

  public changeSensorType($event: any) {
    this.sensor.SensorType.SensorTypeId = $event;
    this.isAirQuality = false;
    this.isHaltian = false;

    if (this.sensor.SensorType.SensorTypeId) {
      const SensorType = this.sensorTypes.filter((st) => st.SensorTypeId == $event);
      const SensorM = this.sensormanufactureres.filter(
        (st) => st.SensorManufacturerId == this.sensor.SensorManufacturer.SensorManufacturerId,
      );

      if (this.checkForAirQualityDetection(SensorType[0]?.SensorTypeCode, SensorM[0]?.SensorManufacturerCode)) {
        this.isAirQuality = true;
      } else if (this.checkForHaltian(SensorType[0]?.SensorTypeCode, SensorM[0]?.SensorManufacturerCode)) {
        this.isHaltian = true;
      }
    } else {
      this.isAirQuality = false;
      this.isHaltian = false;

      this.clearAirthinxSensorDetails();
    }
  }

  public clearAirthinxSensorDetails() {
    this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureFId = '';
    this.sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureCId = '';
    this.sensor.AirQualitySensorConfiguration.SensorAirQualityId = '';
    this.sensor.AirQualitySensorConfiguration.AirQualitySensorHumidityId = '';
    this.sensor.AirQualitySensorConfiguration.AirQualitySensorCO2Id = '';
    this.sensor.AirQualitySensorConfiguration.AirQualitySensorCH2OId = '';
    this.sensor.AirQualitySensorConfiguration.AirQualitySensorPressureId = '';
    this.sensor.AirQualitySensorConfiguration.AirQualitySensorStatusId = '';
  }

  /**
   * Method to be called on successful sensor addition
   */
  onSuccessfulSensorAddition(_SensorForm: NgForm, response: ResponseMessage, isAddNew: boolean) {
    this.page = 1;
    this.loaderService.display(false);
    this.isReqComplete = false;
    if (!isAddNew) {
      this.clearFormData(_SensorForm);
    } else {
      this.clearFormData(_SensorForm, true);
    }
    this.utilitiesService.smmodal('Sensor', response.Message);
  }

  /**
   * Method to update the sensor form on clicking Save and Add new button
   */
  updateSensorFormOnAddNew() {
    if (
      this.checkForAirQualityDetection(
        this.sensor.SensorType?.SensorTypeCode,
        this.sensor.SensorManufacturer?.SensorManufacturerCode,
      )
    ) {
      this.isAirQuality = true;
    } else {
      this.clearAirthinxSensorDetails();
    }

    if (
      this.checkForHaltian(
        this.sensor.SensorType?.SensorTypeCode,
        this.sensor.SensorManufacturer?.SensorManufacturerCode,
      )
    ) {
      this.isHaltian = true;
    } else {
      this.sensor.FilledDistanceInMillimeters = null;
      this.sensor.EmptyDistanceInMillimeters = null;
    }
  }

  /**
   * Update the focus in Add screen to the first field
   */
  updateFocus() {
    const element = document.getElementById('SensorName');
    element.focus();
  }

  /**
   * Method determines if the sensor type is air quality detection with AIRTHINX as the manufacturer.
   */
  checkForAirQualityDetection(sensorType: string, sensorManufacturer: string) {
    return sensorType === 'AIR_QUALITY_DETECTION' && sensorManufacturer === 'AIRTHINX';
  }

  /**
   * Method determines if the sensor type is toilet paper/paper towel/hand soap with HALTIAN as the manufacturer.
   */
  checkForHaltian(sensorType: string, sensorManufacturer: string) {
    return (
      (sensorType === 'TOILET_PAPER' || sensorType === 'PAPER_TOWEL' || sensorType === 'HAND_SOAP') &&
      sensorManufacturer === 'HALTIAN'
    );
  }
}
