import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Configuration } from 'src/app/app.constants';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import {
  InternalExternalUserModule,
  SProviderSalesforceLicenseRequestModule,
  SProviderSalesforceRequestResponseLogModule,
  ServiceProviderActiveUserLicensesModule,
  UserLicenseRequestLogModule,
} from '../userlicenserequestlog.module';
import { UserLicenseRequestLogService } from './userlicenserequestlog.http.service';
@Component({
  selector: 'userlicenserequestlog-component',
  templateUrl: 'userlicenserequestlog.component.html',
  providers: [UserLicenseRequestLogService, UtilitiesService],
})
export class UserLicenseRequestLogComponent implements OnInit {
  public userLicenseRequests: SProviderSalesforceLicenseRequestModule[] = [];
  public userLicenseRequestLog: SProviderSalesforceLicenseRequestModule = new SProviderSalesforceLicenseRequestModule();

  public userLicenceRequest: UserLicenseRequestLogModule = new UserLicenseRequestLogModule();
  public licenseRequestPayload: SProviderSalesforceLicenseRequestModule = new SProviderSalesforceLicenseRequestModule();
  public deactivateUserPayload: ServiceProviderActiveUserLicensesModule = new ServiceProviderActiveUserLicensesModule();

  public licenseRequestDetail: ServiceProviderActiveUserLicensesModule = new ServiceProviderActiveUserLicensesModule();
  public internalEnternalUsers: ServiceProviderActiveUserLicensesModule = new ServiceProviderActiveUserLicensesModule();
  public internalExternalUserList: InternalExternalUserModule[] = [];

  public page = 1;
  public size = 10;
  public count = 0;
  public pageSizes: any[] = [];
  public searchString = '';
  public isReqComplete = false;

  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;
  public pageState: StateMaintain = { pageName: 'userLicenseRequestForm', page: undefined, size: undefined };

  public viewRequestLogModal = false;
  public traxRequestId = '';

  public licenseRequestModal = false;
  public licenseRequestTypes = [
    { RequestTypeId: '1', RequestTypeName: 'Add License', IsActive: true },
    { RequestTypeId: '2', RequestTypeName: 'Remove License', IsActive: false },
  ];
  public perMonthCharges = null;
  public selectedRequestType = null;
  public isAddLicenseRequestShow = false;
  public isRemoveLicenseRequestShow = false;

  public licenseRequestErrorModal = false;
  public requestErrorMessage = '';

  public internalExternalUserModal = false;
  public deleteUserConfirmationModal = false;
  public checkContractIdModal = false;

  public internalProRateTotal: number;
  public internalProRate: number;
  public externalProRateTotal: number;
  public externalProRate: number;
  public checkBillingDate = false;

  constructor(
    private userLicenseRequestService: UserLicenseRequestLogService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    public configuration: Configuration,
    public authState: AuthState,
  ) {
    this.userLicenseRequestLog = new SProviderSalesforceLicenseRequestModule();

    const SPforceRequestResponseLog: SProviderSalesforceRequestResponseLogModule[] = [];
    this.userLicenseRequestLog.SProviderSalesforceRequestResponseLogs = SPforceRequestResponseLog;
    this.internalEnternalUsers = new ServiceProviderActiveUserLicensesModule();
  }

  ngOnInit() {
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.getAllUserLicenseRequests(this.searchString, this.page, this.size);
    } else {
      this.getAllUserLicenseRequests(this.searchString, this.page, this.size);
    }
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllUserLicenseRequests(this.searchString, this.page, this.size);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllUserLicenseRequests('', this.page, this.size);
  }

  public userLicenseRequestSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllUserLicenseRequests(searchString, 1, this.size);
  }

  public userLicenseRequestPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllUserLicenseRequests(this.searchString, this.page, this.size);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllUserLicenseRequests(this.searchString, this.page, this.size);
  }

  public getAllUserLicenseRequests(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: 1,
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
      ServiceProviderCPId: this.authState.AESEncryptText(this.authState.getStoredContextServiceProviderId()),
    };

    this.userLicenseRequestService
      .GetUserLicenseRequestList(JSON.stringify(searchfilter), pageIndex, pageSize)
      .subscribe(
        (data) => {
          this.userLicenseRequests = data;
          if (
            this.userLicenseRequests.length < 0 &&
            this.userLicenseRequests[0].Count / this.utilitiesService.initialPageSize < this.page
          ) {
            this.page = 1;
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'userLicenseRequestService getAllUserLicenseRequests Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
        () => (this.count = this.userLicenseRequests[0] ? this.userLicenseRequests[0].Count : 0),
      );
  }

  public hasData(): boolean {
    return this.userLicenseRequests !== null && this.userLicenseRequests.length > 0;
  }

  public getUserLicenseRequestLogById(traxRequestId: any) {
    if (this.authState.canAccess('USER_LICENSE_REQUEST_VIEW')) {
      this.traxRequestId = traxRequestId;
      this.userLicenseRequestLog = new SProviderSalesforceLicenseRequestModule();
      const SPforceRequestResponseLog: SProviderSalesforceRequestResponseLogModule[] = [];
      this.userLicenseRequestLog.SProviderSalesforceRequestResponseLogs = SPforceRequestResponseLog;

      for (let log = 0; log < this.userLicenseRequests.length; log++) {
        if (this.userLicenseRequests[log].TraxRequestId == traxRequestId) {
          this.userLicenseRequestLog = this.userLicenseRequests[log];
        }
      }
      this.viewRequestLogModal = true;
    } else {
      this.utilitiesService.smmodal(
        'User License Request',
        'You do not have permission to view user license request log details.',
      );
    }
  }

  public licenseRequestLogHasData(): boolean {
    return (
      this.userLicenseRequestLog.SProviderSalesforceRequestResponseLogs !== null &&
      this.userLicenseRequestLog.SProviderSalesforceRequestResponseLogs.length > 0
    );
  }

  public closeRequestLogModal() {
    this.traxRequestId = '';
    this.userLicenseRequestLog = new SProviderSalesforceLicenseRequestModule();
    const SPforceRequestResponseLog: SProviderSalesforceRequestResponseLogModule[] = [];
    this.userLicenseRequestLog.SProviderSalesforceRequestResponseLogs = SPforceRequestResponseLog;
    this.viewRequestLogModal = false;
  }

  public checkLicenseRequestType(requestTypeId: number, requestTypeName: string) {
    this.licenseRequestPayload.IsUserAgree = 0;

    if (requestTypeId == 1 && requestTypeName == 'Add License') {
      this.isRemoveLicenseRequestShow = false;
      this.isAddLicenseRequestShow = true;
      this.changeUserAgree();
    } else if (requestTypeId == 2 && requestTypeName == 'Remove License') {
      this.perMonthCharges = null;
      this.isAddLicenseRequestShow = false;
      this.isRemoveLicenseRequestShow = true;
    }
  }

  public getLicenseRequestDetails() {
    this.licenseRequestDetail = new ServiceProviderActiveUserLicensesModule();
    this.licenseRequestPayload = new SProviderSalesforceLicenseRequestModule();
    this.internalEnternalUsers = new ServiceProviderActiveUserLicensesModule();

    this.loaderService.display(true);
    const requestPayload = { ServiceProviderId: this.authState.getStoredContextServiceProviderId() };

    this.userLicenseRequestService.licenseRequest(JSON.stringify(requestPayload)).subscribe(
      (data) => {
        if (data != null) {
          if (data.ContractId && data.ContractId != null) {
            this.selectedRequestType = this.licenseRequestTypes[0].RequestTypeId;
            this.isAddLicenseRequestShow = true;

            this.licenseRequestDetail = data;
            // console.log(this.licenseRequestDetail);

            this.licenseRequestPayload.RequestedInternalLicenseCount = null;
            this.licenseRequestPayload.RequestedExternalLicenseCount = null;
            this.licenseRequestModal = true;
          } else {
            this.checkContractIdModal = true;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'userLicenseRequestService licenseRequest Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public closecheckContractIdModalModal() {
    this.checkContractIdModal = false;
  }

  public changeUserAgree() {
    let internalUserLicenseFeeTotal = 0;
    let externalUserLicenseFee = 0;

    if (
      this.licenseRequestPayload.RequestedInternalLicenseCount != null &&
      this.licenseRequestPayload.RequestedInternalLicenseCount != 0
    ) {
      internalUserLicenseFeeTotal =
        this.licenseRequestDetail.InternalUserLicenseFee * this.licenseRequestPayload.RequestedInternalLicenseCount;
    }
    if (
      this.licenseRequestPayload.RequestedExternalLicenseCount != null &&
      this.licenseRequestPayload.RequestedExternalLicenseCount != 0
    ) {
      externalUserLicenseFee =
        this.licenseRequestDetail.ExternalUserLicenseFee * this.licenseRequestPayload.RequestedExternalLicenseCount;
    }

    this.perMonthCharges = internalUserLicenseFeeTotal + externalUserLicenseFee;

    const getDay = this.getDiffDetweenTwoDays(this.licenseRequestDetail.BillingDay);
    if (getDay) {
      let internObj = this.licenseRequestDetail.InternalUserLicenseFee / getDay.DateDifference;
      this.internalProRate = internObj * getDay.Days;

      let externObj = this.licenseRequestDetail.ExternalUserLicenseFee / getDay.DateDifference;
      this.externalProRate = externObj * getDay.Days;

      this.internalProRateTotal = this.internalProRate * this.licenseRequestPayload.RequestedInternalLicenseCount;
      this.externalProRateTotal = this.externalProRate * this.licenseRequestPayload.RequestedExternalLicenseCount;
    }
  }

  public getDiffDetweenTwoDays(billingDay: any) {
    this.checkBillingDate = true;

    let currentDate = new Date();
    let bill = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + billingDay;
    let a1 = new Date(bill);

    let current = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getDate();
    let b1 = new Date(current);

    //// calculate difference between billing date with current date -------------------------------------
    let d1 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + (billingDay + 1);
    let d2 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 2) + '/' + (billingDay + 1);

    const bill1Date = new Date(d1);
    const current2Date = new Date(d2);
    const Difference_In_Time_1 = current2Date.getTime() - bill1Date.getTime();
    let diffBtn = Difference_In_Time_1 / (1000 * 3600 * 24);

    if (a1 < b1) {
      let c2 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 2) + '/' + (billingDay + 1);

      const billDate = new Date(c2);
      const Difference_In_Time = billDate.getTime() - currentDate.getTime();
      const dayDiff = Difference_In_Time / (1000 * 3600 * 24);

      let obj = { Days: Math.floor(dayDiff), DateDifference: diffBtn };
      return obj;
    } else if (a1 > b1) {
      let c2 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + (billingDay + 1);

      const billDate = new Date(c2);
      const Difference_In_Time = billDate.getTime() - currentDate.getTime();
      const dayDiff = Difference_In_Time / (1000 * 3600 * 24);

      let obj = { Days: Math.floor(dayDiff), DateDifference: diffBtn };
      return obj;
    } else {
      this.checkBillingDate = false;
    }
  }

  public clearUserLicenceRequestData(requestLicenseForm: NgForm) {
    this.perMonthCharges = null;
    this.selectedRequestType = null;
    this.userLicenceRequest = new UserLicenseRequestLogModule();

    this.checkBillingDate = false;
    this.internalProRate = null;
    this.externalProRate = null;
    this.internalProRateTotal = null;
    this.externalProRateTotal = null;

    if (requestLicenseForm != null) {
      requestLicenseForm.reset();
    }
    this.licenseRequestModal = false;

    this.getAllUserLicenseRequests('', this.page, this.size);
  }

  public createLicenseRequest(requestLicenseForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (requestLicenseForm.status == 'INVALID' && requestLicenseForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'User License Request',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    if (this.isAddLicenseRequestShow) {
      this.licenseRequestPayload.RequestType = 1;
    } else if (this.isRemoveLicenseRequestShow) {
      this.licenseRequestPayload.RequestType = 0;

      if (
        this.licenseRequestDetail.TotalInternalUserLicenseCount <
        Number(this.licenseRequestPayload.RequestedInternalLicenseCount)
      ) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal(
          'Request User License',
          'Requested Internal/External user count is greater than current Internal/External user count.',
        );
        return;
      } else if (
        this.licenseRequestDetail.TotalExternalUserLicenseCount <
        Number(this.licenseRequestPayload.RequestedExternalLicenseCount)
      ) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal(
          'Request User License',
          'Requested Internal/External user count is greater than current Internal/External user count.',
        );
        return;
      }
    }

    this.licenseRequestPayload.ServiceProviderId = this.authState.getStoredContextServiceProviderId();
    this.licenseRequestPayload.ServiceProviderName = this.authState.getStoredServiceProviderName();
    this.licenseRequestPayload.IsUserAgree = this.licenseRequestPayload.IsUserAgree ? 1 : 0;

    this.userLicenseRequestService
      .createLicenseRequest(this.utilitiesService.stripScript(this.licenseRequestPayload))
      .subscribe(
        (data) => {
          this.loaderService.display(false);
          this.isReqComplete = false;
          this.utilitiesService.smmodal('Request User License', data.Message);
          this.clearUserLicenceRequestData(requestLicenseForm);
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          if (error.status == 409) {
            if (error._body) {
              let obj = JSON.parse(error._body);
              this.requestErrorMessage = obj.exception;
              this.licenseRequestErrorModal = true;
            }
          }
          console.log(
            'userLicenseRequestService createLicenseRequest Service Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
  }

  public getInternalExternalUsersList() {
    this.internalExternalUserList = [];
    this.loaderService.display(true);
    const requestPayload = { ServiceProviderId: this.authState.getStoredContextServiceProviderId() };

    this.userLicenseRequestService.getInternalExternalUsers(JSON.stringify(requestPayload)).subscribe(
      (data) => {
        if (data != null) {
          if (data.InternalUserList != null && data.InternalUserList.length > 0) {
            for (let internal = 0; internal < data.InternalUserList.length; internal++) {
              data.InternalUserList[internal].UserType = 'Internal';
            }
          }

          if (data.ExternalUserList != null && data.ExternalUserList.length > 0) {
            for (let external = 0; external < data.ExternalUserList.length; external++) {
              data.ExternalUserList[external].UserType = 'External';
            }
          }
          this.internalEnternalUsers = data;

          if (
            this.licenseRequestPayload.RequestedInternalLicenseCount &&
            this.licenseRequestPayload.RequestedExternalLicenseCount == null
          ) {
            this.internalExternalUserList = this.internalEnternalUsers.InternalUserList;
          } else if (
            this.licenseRequestPayload.RequestedExternalLicenseCount &&
            this.licenseRequestPayload.RequestedInternalLicenseCount == null
          ) {
            this.internalExternalUserList = this.internalEnternalUsers.ExternalUserList;
          } else {
            this.internalExternalUserList = this.internalEnternalUsers.InternalUserList.concat(
              this.internalEnternalUsers.ExternalUserList,
            );
          }
        }
        this.loaderService.display(false);
        this.licenseRequestErrorModal = false;
        this.internalExternalUserModal = true;
      },
      (error) => {
        console.log(
          'userLicenseRequestService getInternalExternalUsers Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public closeLicenseRequestLicenseErrorModal() {
    this.licenseRequestErrorModal = false;
    //this.licenseRequestModal = false;
    //this.licenseRequestDetail = new ServiceProviderActiveUserLicensesModule();
    //this.licenseRequestPayload = new SProviderSalesforceLicenseRequestModule();
  }

  public closeInternalExternalUserModal() {
    this.internalEnternalUsers = new ServiceProviderActiveUserLicensesModule();
    this.licenseRequestErrorModal = false;
    this.internalExternalUserModal = false;
  }

  public deactivateUser() {
    this.loaderService.display(true);
    this.isReqComplete = true;
    this.deactivateUserPayload = new ServiceProviderActiveUserLicensesModule();

    this.deactivateUserPayload.ServiceProviderId = this.internalEnternalUsers.ServiceProviderId;

    if (this.internalExternalUserList != null && this.internalExternalUserList.length > 0) {
      let internalUsers = [];
      let externalUsers = [];
      for (let user = 0; user < this.internalExternalUserList.length; user++) {
        if (
          this.internalExternalUserList[user].IsUserDelete == true &&
          this.internalExternalUserList[user].UserType == 'Internal'
        ) {
          this.internalExternalUserList[user].IsActive = this.internalExternalUserList[user].IsUserDelete ? 1 : 0;
          internalUsers.push(this.internalExternalUserList[user]);
        }

        if (
          this.internalExternalUserList[user].IsUserDelete == true &&
          this.internalExternalUserList[user].UserType == 'External'
        ) {
          this.internalExternalUserList[user].IsActive = this.internalExternalUserList[user].IsUserDelete ? 1 : 0;
          externalUsers.push(this.internalExternalUserList[user]);
        }
      }
      this.deactivateUserPayload.InternalUserList = internalUsers;
      this.deactivateUserPayload.ExternalUserList = externalUsers;
    }
    console.log(this.deactivateUserPayload);

    this.userLicenseRequestService
      .DeactivateUsers(this.utilitiesService.stripScript(this.deactivateUserPayload))
      .subscribe(
        (data) => {
          this.loaderService.display(false);
          this.isReqComplete = false;
          this.closeInternalExternalUserModal();
          this.closeDeleteUserConfirmationModal();

          if (data != null) {
            this.licenseRequestDetail.ServiceProviderId = data.ServiceProviderId;
            this.licenseRequestDetail.ServiceProviderName = data.ServiceProviderName;

            this.licenseRequestDetail.TotalInternalUserLicenseCount = data.TotalInternalUserLicenseCount;
            this.licenseRequestDetail.TotalExternalUserLicenseCount = data.TotalExternalUserLicenseCount;

            this.licenseRequestDetail.TotalInternalActiveUsersPresent = data.TotalInternalActiveUsersPresent;
            this.licenseRequestDetail.TotalExternalActiveUsersPresent = data.TotalExternalActiveUsersPresent;

            this.licenseRequestDetail.TotalUserLicenseCount = data.TotalUserLicenseCount;

            this.licenseRequestDetail.InternalUserLicenseFee = data.InternalUserLicenseFee;
            this.licenseRequestDetail.ExternalUserLicenseFee = data.ExternalUserLicenseFee;
            this.utilitiesService.mdmodal(
              'User License Removal Request',
              'Users are deleted successfully. Please send license request to remove the licenses.',
            );
          }
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.closeInternalExternalUserModal();
          this.closeDeleteUserConfirmationModal();

          if (error.status == 409) {
            if (error._body) {
              let obj = JSON.parse(error._body);
              this.requestErrorMessage = obj.exception;
              this.licenseRequestErrorModal = true;
            }
          }
          console.log(
            'userLicenseRequestService createLicenseRequest Service Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
  }

  public openTermsandConditions() {
    let pageUrl = '';
    const baseURL = window.location.href.split('#')[0];
    pageUrl = baseURL + '#' + '/terms-and-conditions/';
    window.open(pageUrl, '_blank');
  }

  public openDeleteUserConfirmationModal() {
    if (
      this.licenseRequestPayload.RequestedInternalLicenseCount &&
      !this.licenseRequestPayload.RequestedExternalLicenseCount
    ) {
      if (this.internalExternalUserList != null && this.internalExternalUserList.length > 0) {
        const obj = this.internalEnternalUsers.InternalUserList.filter(
          (v) => v.IsUserDelete == true && v.UserType == 'Internal',
        );
        if (obj.length == 0) {
          this.utilitiesService.mdmodal('User License Removal Request', 'Please select atleast one user.');
        } else {
          this.deleteUserConfirmationModal = true;
        }
      }
    }

    if (
      this.licenseRequestPayload.RequestedExternalLicenseCount &&
      !this.licenseRequestPayload.RequestedInternalLicenseCount
    ) {
      if (this.internalExternalUserList != null && this.internalExternalUserList.length > 0) {
        const obj = this.internalEnternalUsers.ExternalUserList.filter(
          (v) => v.IsUserDelete == true && v.UserType == 'External',
        );
        if (obj.length == 0) {
          this.utilitiesService.mdmodal('User License Removal Request', 'Please select atleast one user.');
        } else {
          this.deleteUserConfirmationModal = true;
        }
      }
    }

    if (
      this.licenseRequestPayload.RequestedExternalLicenseCount &&
      this.licenseRequestPayload.RequestedInternalLicenseCount
    ) {
      if (this.internalExternalUserList != null && this.internalExternalUserList.length > 0) {
        const internalObj = this.internalExternalUserList.filter(
          (v) => v.IsUserDelete == true && v.UserType == 'Internal',
        );
        const externalObj = this.internalExternalUserList.filter(
          (v) => v.IsUserDelete == true && v.UserType == 'External',
        );
        if (internalObj.length == 0 && externalObj.length == 0) {
          this.utilitiesService.mdmodal('User License Removal Request', 'Please select atleast one user.');
        } else {
          this.deleteUserConfirmationModal = true;
        }
      }
    }
  }

  public closeDeleteUserConfirmationModal() {
    this.deleteUserConfirmationModal = false;
  }
}
