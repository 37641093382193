import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { BeaconModule } from '../beacon.module';
@Injectable({
  providedIn: 'root',
})
export class BeaconService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetBeaconList = (body: string, page: number, size: number): Observable<BeaconModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/all/beacons/' + page + '/' + size;
    return this.http.post<BeaconModule[]>(_url, body);
  };

  public GetBeaconDropDown = (body: string, page: number, size: number): Observable<BeaconModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/beacons/' + page + '/' + size;
    return this.http.post<BeaconModule[]>(_url, body);
  };

  public GetSingle = (body: BeaconModule): Observable<BeaconModule> => {
    const _url: string = this.configuration.Server + 'workorder/beacon/details';
    return this.http.post<BeaconModule>(_url, body);
  };

  public Add = (body: BeaconModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/beacon/beacon';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: BeaconModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/beacon';
    return this.http.put<ResponseMessage>(_url, body);
  };
}
