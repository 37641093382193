import { RelUserRolePermissions } from 'src/app/core/models/role.model';
import { User } from 'src/app/core/models/user.model';

// This file defines the type of the state for the user store.
// UserStoreState interface defines a state with a user property of type User
// initialUserStoreState provides the initial state for the user store, setting user to null

export interface UserStoreState {
  user: User;
  permissions: RelUserRolePermissions[];
}

// Initial user store state
export const initialUserStoreState: UserStoreState = {
  user: null,
  permissions: [],
};
