import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[app_onlynumber]',
})
export class OnlyNumber {
  @HostListener('keypress', ['$event']) onkeypress(event: KeyboardEvent) {
    this.AcceptOnlyNymber(event);
  }

  private AcceptOnlyNymber(event: any) {
    if (
      [8, 9, 27, 13].indexOf(event.keyCode) !== -1 || // Allow: Delete, Backspace, Tab, Escape, Enter
      (event.keyCode === 65 && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.keyCode === 67 && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.keyCode === 86 && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.keyCode === 88 && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.keyCode === 65 && event.metaKey === true) || // Cmd+A (Mac)
      (event.keyCode === 67 && event.metaKey === true) || // Cmd+C (Mac)
      (event.keyCode === 86 && event.metaKey === true) || // Cmd+V (Mac)
      (event.keyCode === 88 && event.metaKey === true) // Cmd+X (Mac)
      // (event.keyCode >= 35 && event.keyCode < 39) // Home, End, Left, Right
    ) {
      return;
    } else if (event.which != 8 && event.which != 0 && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
    // if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105))
  }
}
