import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { Module, ServiceProviderModule } from '../../serviceprovider/serviceprovider.module';
import { SubscriptionModule } from '../../usermanagement/subscription/subscription.module';
import { CityModule } from '../../venuemanagement/city/city.module';
import { CountryModule } from '../../venuemanagement/country/country.module';
import { StateModule } from '../../venuemanagement/state/state.module';
import { CorporationComponent } from './components/corporation.component';
import { CorporationRoutes } from './corporation.routes';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import {
  DefaultTreeviewEventParser,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewModule,
} from 'ngx-treeview';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    CorporationRoutes,
    FormsModule,

    NgxPaginationModule,
    HomeModule,
    TreeviewModule,
    NgxMaskModule.forRoot(options),
    SharedModule,
  ],

  providers: [
    TreeviewConfig,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],

  declarations: [CorporationComponent],
})
export class CorporationModule extends GenericEntity {
  public Description: string;
  public Password: string;
  public MobileNumber: string;
  public AddressLineOne: string;
  public AddressLineTwo: string;
  public Country: CountryModule;
  public State: StateModule;
  public City: CityModule;
  public ZipCode: number;
  public IsVenue: number;
  public Subscription: SubscriptionModule;
  public PowerBIReportURL: string;
  public ServiceProvider: ServiceProviderModule;

  public RelCorporationModuleFeature: RelCorporationModuleFeature;

  public LogoImageName: string;
  public LogoImageType: string;
  public InspectionScoringBenchmark: number;

  public CorporationIntegrationKey: CorporationIntegrationKey[];
  public SalesForceNumber: number;
  public LicenseUserCount: number;
  public AttachmentURL: string;
}

export class RelCorporationModuleFeature extends GenericEntity {
  public RelCorporationModuleFeatureId: number;
  public ModuleId: number;
  public ModuleFeatureId: number;
  public CorporationId: number;
  public IsSelected: number;
  public Module: Module;
  public Modules: Module[] = [];
}

export class CorporationIntegrationKey extends GenericEntity {
  public IntegrationKeyId: number;
  public IntegrationKeyName: string;
  public IntegrationKeyValue: string;
  public IntegrationKeyRowId: number;
  public IsDelete: number;
  public isAllowToEdit: any;
}
