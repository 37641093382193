<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-12 form-heading">
        <i class="fa fa-search heading-icon" aria-hidden="true"></i>
        <span class="list-heading">VENUE ELEMENTS</span>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row">
    <form #VenueElementForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div>
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ VenueElement.VenueName }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 form-group" *ngIf="'VENUE_ELEMENT_ADD' | canAccess">
            <div class="col-lg-6 col-md-6 col-sm-6" style="margin-top: 20px">
              <button
                type="submit"
                class="btn btn-type pull-left btn-cancel col-lg-12 col-md-12 col-xs-12"
                (click)="addElement()"
              >
                Add New Element
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="hasElementData()">
          <div class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive" *ngIf="hasElementData()">
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>Element Image</th>
                  <th>Sequence</th>
                  <th>Element</th>
                  <th>Select Existing Element</th>
                </tr>
              </thead>

              <tbody *ngFor="let element of masterElementList">
                <tr>
                  <td style="width: 25%">
                    <div class="imgBox">
                      <button
                        *ngIf="element.showElementImage && element.IsActive"
                        id="templateReset_{{ element.ElementId }}"
                        class="btn-primary btn btn-xs button-text remove-venue-element-img"
                        type="button"
                        (click)="fileReset(element.ElementId, element.IsMasterElement)"
                        style="position: absolute; border-radius: 7px; background: #3d5c70; border-color: #3d5c70"
                      >
                        <i class="fa fa-times"></i>
                      </button>

                      <label class="custom-file" *ngIf="element.IsActive">
                        <input
                          type="file"
                          #fileInput
                          id="file_{{ element.ElementId }}"
                          name="file_{{ element.ElementId }}"
                          class="custom-file-input"
                          (change)="fileChange($event, element.ElementId, element.IsMasterElement)"
                          style="display: none"
                          accept="image/*"
                          [disabled]="element.isAllowToEdit"
                        />
                        <span class="custom-file-control"></span>
                        <img
                          id="file_{{ element.ElementId }}"
                          [src]="
                            element.showElementImage != null && element.showElementImage != ''
                              ? element.showElementImage
                              : noImage
                          "
                          class="venue-element-img"
                          style="width: 100%; height: 100%"
                        />
                      </label>

                      <label class="custom-file" *ngIf="!element.IsActive">
                        <img
                          id="file_{{ element.ElementId }}"
                          [src]="
                            element.showElementImage != null && element.showElementImage != ''
                              ? element.showElementImage
                              : noImage
                          "
                          class="venue-element-img"
                          style="width: 100%; height: 100%"
                        />
                      </label>
                    </div>
                  </td>

                  <td class="srno">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Sequence"
                      [(ngModel)]="element.Sequence"
                      *ngIf="element.IsActive"
                      id="elementOrder_{{ element.ElementId }}"
                      [ngModelOptions]="{ standalone: true }"
                      #taskOrder="ngModel"
                      pattern="[0-9]+"
                      min="1"
                      (input)="
                        selectedVenueElement(
                          element.ElementId,
                          element.ElementName,
                          element.Sequence,
                          element.IsActive,
                          element.showElementImage
                        )
                      "
                      [disabled]="element.isAllowToEdit"
                    />
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Sequence"
                      [(ngModel)]="element.Sequence"
                      *ngIf="!element.IsActive"
                      id="elementOrder_{{ element.ElementId }}"
                      [ngModelOptions]="{ standalone: true }"
                      #taskOrder="ngModel"
                      pattern="[0-9]+"
                      min="1"
                      (input)="
                        selectedVenueElement(
                          element.ElementId,
                          element.ElementName,
                          element.Sequence,
                          element.IsActive,
                          element.showElementImage
                        )
                      "
                      disabled
                    />
                  </td>

                  <td>{{ element.ElementName }}</td>

                  <td
                    style="width: 17% !important; text-align: center; vertical-align: middle"
                    *ngIf="!('VENUE_ELEMENT_EDIT' | canAccess)"
                  >
                    <span *ngIf="element.IsActive"><i class="fa fa-check-square"></i></span
                    ><span *ngIf="!element.IsActive">-</span>
                  </td>
                  <td
                    style="width: 17% !important; text-align: center; vertical-align: middle"
                    *ngIf="'VENUE_ELEMENT_EDIT' | canAccess"
                  >
                    <mat-checkbox
                      id="Element_{{ element.ElementId }}"
                      name="Element_{{ element.ElementId }}"
                      [(ngModel)]="element.IsActive"
                      (change)="
                        selectedVenueElement(
                          element.ElementId,
                          element.ElementName,
                          element.Sequence,
                          element.IsActive,
                          element.showElementImage
                        )
                      "
                    ></mat-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="instructions.length > 0">
          <div class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top" style="margin-bottom: 20px">
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th>Element Image</th>
                  <th class="sequence"><span id="red-circle">&#x25CF;</span> Sequence</th>
                  <th><span id="red-circle">&#x25CF;</span> Element</th>
                  <th style="text-align: center" *ngIf="'VENUE_ELEMENT_DELETE' | canAccess">Remove</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let instruction of instructions">
                  <tr *ngIf="instruction.IsActive">
                    <td style="width: 25%">
                      <div class="imgBox">
                        <button
                          id="templateReset_{{ instruction.VenueElementRowID }}"
                          class="btn-primary btn btn-xs button-text remove-venue-element-img"
                          *ngIf="instruction.showElementImage"
                          type="button"
                          (click)="fileReset(instruction.VenueElementRowID, instruction.IsMasterElement)"
                          style="position: absolute; border-radius: 7px; background: #3d5c70; border-color: #3d5c70"
                        >
                          <i class="fa fa-times"></i>
                        </button>
                        <label class="custom-file">
                          <input
                            type="file"
                            #addNewElementFileInput
                            id="file_{{ instruction.VenueElementRowID }}"
                            name="file_{{ instruction.VenueElementRowID }}"
                            class="custom-file-input"
                            (change)="fileChange($event, instruction.VenueElementRowID, instruction.IsMasterElement)"
                            style="display: none"
                            accept="image/*"
                          />
                          <span class="custom-file-control"></span>
                          <img
                            id="file_{{ instruction.VenueElementRowID }}"
                            [src]="
                              instruction.showElementImage != null && instruction.showElementImage != ''
                                ? instruction.showElementImage
                                : noImage
                            "
                            class="venue-element-img"
                            style="width: 100%; height: 100%"
                          />
                        </label>
                      </div>
                    </td>

                    <td class="srno form-group">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Sequence"
                        [(ngModel)]="instruction.Sequence"
                        id="StepOrder_{{ instruction.VenueElementRowID }}"
                        [ngModelOptions]="{ standalone: true }"
                        #StepOrder="ngModel"
                        pattern="[0-9]+"
                        min="1"
                        required
                        [readonly]="instruction.isAllowToEdit"
                        (keypress)="utilitiesService.keyPress($event)"
                      />
                      <div
                        *ngIf="StepOrder.errors && (StepOrder.dirty || StepOrder.touched)"
                        class="alert alert-danger"
                      >
                        <div [hidden]="!StepOrder.errors.required" class="text-danger">Sequence is required.</div>
                        <div [hidden]="!StepOrder.errors.pattern" class="text-danger">
                          Sequence accepts only numeric.
                        </div>
                      </div>
                    </td>
                    <td class="form-group">
                      <textarea
                        type="text"
                        class="form-control"
                        placeholder="Element"
                        [(ngModel)]="instruction.VenueElementName"
                        id="VenueElementName_{{ instruction.VenueElementRowID }}"
                        #VenueElementName="ngModel"
                        [ngModelOptions]="{ standalone: true }"
                        maxlength="500"
                        required
                        [readonly]="instruction.isAllowToEdit"
                      ></textarea>
                      <div
                        *ngIf="VenueElementName.errors && (VenueElementName.dirty || VenueElementName.touched)"
                        class="alert alert-danger"
                      >
                        <div [hidden]="!VenueElementName.errors.required" class="text-danger">
                          Element name is required.
                        </div>
                      </div>
                    </td>
                    <td class="table-center" *ngIf="'VENUE_ELEMENT_DELETE' | canAccess">
                      <button class="btn btn-purple" (click)="removeElement(instruction.VenueElementRowID)">
                        <i class="fa fa-times"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 btn-section1">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(VenueElementForm)"
          />
          <input
            type="button"
            value="Save"
            class="btn btn-type1 btn-save"
            [disabled]="!VenueElementForm.form.valid || isReqComplete"
            (click)="updateVenueElement(VenueElementForm)"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="imageModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black; margin-top: -10px">
            &times;
          </button>
          <img class="img-responsive" src="{{ sensorImg }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>
</section>
